import styled from "styled-components";

export const AdminInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 25%;
`;

export const FormsContainer = styled.div<{direction: string}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;

export const SectionHeader = styled.div`
  margin: 1.25rem 0;
  font-size: 1.25rem;
  width: 100%;
`;
