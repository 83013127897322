import React, {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import {useMap} from "../../../../../_shared/hooks/useMap";
import {scrollToTop} from "../../../../../_shared/navigation/_helpers";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {IPublicProjectInfoModalPayload} from "../../../../../mapping/views/components/ProjectDetailsModal/constants";
import {MAP_MAIN_BUILD_CONTAINER_DIV_ID} from "../../constants";
import {useAddress} from "../../../../../_shared/hooks/useAddress";
import {useSelectedProduct} from "../../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {ProductView} from "../../../../../catalog/views/ProductView";
import {MapProjectsSidebarContent} from "./MapProjectsSidebarContent";
import {SmartCatalogHeading} from "../../../../_shared/components/ParcelInfo";
import {CatalogPath} from "../../../../../catalog/_shared/components/CatalogPath";
import {ProductCards} from "../../../../../catalog/views/MainView/components/ProductCards";
import {MapCatalogBar} from "../../../../../catalog/_shared/components/CatalogBar/MapCatalogBar";
import {HEADER_Z_INDEX} from "../../../../../_shared/styles";
import {ProductHeader} from "./MapCatalogContainer/MapProductDetailContainer/components/ProductHeader/ProductHeader";
import {NoHomesResetFiltersCTA} from "../../../../../catalog/views/MainView/components/NoHomesResetFiltersCTA";
import {IProduct} from "@natomas-org/core";
import {
  MapRightStickyHeaderContainer,
  MapStickyHeaderItemsContainer,
} from "./styles";
import styled from "styled-components";

export const MapContentSidebar = (props: {
  onClickDesign: () => void;
  mappingPreview?: JSX.Element;
  setProjectModalPayload?: Dispatch<
    SetStateAction<IPublicProjectInfoModalPayload | null>
  > | null;
  showProjectMap: boolean;
  setShowProjectMap: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {setProjectModalPayload, showProjectMap, setShowProjectMap} = props;
  const selectedProduct = useSelectedProduct();
  const {parcelView} = useMap();
  const {catalogAddress} = useAddress();
  const {isNatMobile} = usePage();

  useEffect(() => {
    if (isNatMobile) {
      scrollToTop(false);
    } else {
      document.getElementById(MAP_MAIN_BUILD_CONTAINER_DIV_ID)?.scroll({
        top: 0,
        left: 0,
      });
      scrollToTop(false);
    }
  }, [selectedProduct]);

  enum MapContentMode {
    CATALOG_MOBILE,
    CATALOG,
    CATALOG_PRODUCT,
    PROJECTS,
  }

  const mode: MapContentMode = useMemo(() => {
    if (selectedProduct) {
      return MapContentMode.CATALOG_PRODUCT;
    }
    if (!parcelView) {
      return MapContentMode.PROJECTS;
    }
    if (isNatMobile) {
      return MapContentMode.CATALOG_MOBILE;
    }
    return MapContentMode.CATALOG;
  }, [
    MapContentMode.CATALOG,
    MapContentMode.CATALOG_MOBILE,
    MapContentMode.CATALOG_PRODUCT,
    MapContentMode.PROJECTS,
    isNatMobile,
    parcelView,
    selectedProduct,
  ]);
  switch (mode) {
    case MapContentMode.CATALOG_PRODUCT:
      return (
        <ThemedCatalogWrapper>
          <ProductView JUST_CONTENT />
        </ThemedCatalogWrapper>
      );
    case MapContentMode.PROJECTS:
      return (
        <ThemedCatalogWrapper>
          {/*{isNatMobile && <AppSwitcher />}*/}
          <MapProjectsSidebarContent
            setProjectModalPayload={setProjectModalPayload}
            showProjectMap={showProjectMap}
            setShowProjectMap={setShowProjectMap}
          />
        </ThemedCatalogWrapper>
      );
    case MapContentMode.CATALOG_MOBILE:
      return (
        <>
          <ThemedCatalogWrapper>
            {/*<AppSwitcher />*/}
            <SmartCatalogHeading
              address={catalogAddress ?? undefined}
              isSticky={false}
              includeJurisdictionText={true}
              includeFilterAndSortBanner={false}
            />
          </ThemedCatalogWrapper>
          <ThemedCatalogWrapper>
            <NoHomesResetFiltersCTA
              style={{margin: "auto 0", height: "100%"}}
              imageHeightRem={6}
            />
          </ThemedCatalogWrapper>
          <ThemedCatalogWrapper>
            <ProductCards setProductOnHover={true} />
          </ThemedCatalogWrapper>
        </>
      );
    case MapContentMode.CATALOG:
      return (
        <>
          <SmartCatalogHeading
            address={catalogAddress ?? undefined}
            isSticky={false}
            includeJurisdictionText={true}
            includeFilterAndSortBanner={false}
          />
          <NoHomesResetFiltersCTA
            style={{margin: "auto 0", height: "calc(100vh - 22rem)"}}
            imageHeightRem={6}
          />
          <ProductCards setProductOnHover={true} />
        </>
      );
  }
};

const MAP_FILTERS_COUNT = 2;
export const MapStickyHeaders = (props: {
  parcelViewAvailable?: boolean;
  parcelView?: boolean;
  selectedProduct?: IProduct | null;
}) => {
  const {isNatMobile} = usePage();
  const {parcelViewAvailable, parcelView, selectedProduct} = props;
  if (parcelViewAvailable === undefined || !parcelView) {
    return null;
  }
  let content = null;
  if (parcelView) {
    if (selectedProduct) {
      content = (
        <ThemedCatalogWrapper>
          <CatalogPath
            showMainView={true}
            border={false}
            inventory={!!selectedProduct?.inventory_info}
          />
          <ProductHeader product={selectedProduct} />
        </ThemedCatalogWrapper>
      );
    } else {
      content = (
        <MapCatalogWrapper isMobile={isNatMobile}>
          <MapCatalogBar
            hideSort={true}
            hideFilter={false}
            isSticky={false}
            filtersCountToDisplay={MAP_FILTERS_COUNT}
            RIGHT_ALIGN_LAST_FILTER
          />
          {/*<PageContainer*/}
          {/*  backgroundColor={WHITE}*/}
          {/*  overridePadding={true}*/}
          {/*  stickyOptions={{*/}
          {/*    offset: "0rem",*/}
          {/*    height: "4rem",*/}
          {/*    mode: PageContainerStickyMode.MODAL,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  /!*<Filters*!/*/}
          {/*  /!*  filtersCountToDisplay={MAP_FILTERS_COUNT}*!/*/}
          {/*  /!*  RIGHT_ALIGN_LAST_FILTER={true}*!/*/}
          {/*/>*/}
          {/*</PageContainer>*/}
        </MapCatalogWrapper>
      );
    }
  }
  return (
    <MapRightStickyHeaderContainer isMobile={isNatMobile}>
      <MapStickyHeaderItemsContainer>{content}</MapStickyHeaderItemsContainer>
    </MapRightStickyHeaderContainer>
  );
};

const MapCatalogWrapper = styled.div<{isMobile: boolean}>`
  position: relative;
  padding: ${(props) => (props.isMobile ? "0 1rem" : "0 0")};
  justify-content: space-between;
  align-content: center;
  max-height: 7rem;
  align-items: center;
  column-gap: ${(props) => (props.isMobile ? undefined : "0")};
  width: ${(props) => (props.isMobile ? undefined : "40vw")};
  min-width: ${(props) => (props.isMobile ? undefined : "26rem")};
  margin: ${(props) => (props.isMobile ? undefined : "auto")};
  margin-bottom: 0;
  background-color: transparent;
  z-index: ${HEADER_Z_INDEX} + 2;
`;
const ThemedCatalogWrapper = styled.div`
  ${(props) =>
    props.theme?.Page?.isNatMobile
      ? {
          marginRight: props.theme?.Catalog?.marginLeftRight,
          marginLeft: props.theme?.Catalog?.marginLeftRight,
        }
      : {width: "40vw", minWidth: "26rem", margin: "auto"}}
`;
