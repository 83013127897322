export const getFormStructure = (formValues: any) => {
  return Object.keys(formValues)
    .map((key) => {
      return {
        key: key,
        ...formValues[key],
      };
    })
    .sort((a, b) => a.index - b.index);
};

export const getFormInitialValues = (formValues: any) => {
  const initialValues = {};
  Object.keys(formValues).forEach((key) => {
    const field = formValues[key];
    // @ts-ignore TODO initialValues is a key/value pair system - ensure that it can receive 'any'
    initialValues[key] = field.defaultValue != null ? field.defaultValue : "";
  });

  return initialValues;
};
