import React, {useEffect, useMemo} from "react";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {AuthenticationFormState} from "./interface";
import {useFormState} from "./useFormState";
import ResetPasswordContent from "./ResetPasswordContent";
import LoginContent from "./LoginContent";
import CreateContactContent from "./CreateContactContent";
import ConfirmCustomerTypeContent from "./ConfirmCustomerTypeContent";
import ConfirmCustomerContent from "./ConfirmCustomerContent";
import {
  formatPhoneNumber,
  IAddressDetails,
  PhoneNumberFormatType,
} from "@natomas-org/core";
import ConfirmBusinessContent from "./ConfirmBusinessContent";
import LoggingOutContent from "./LoggingOut";
import {ContentContainer, SpinnerContainer} from "./style";
import LoggedInAs from "./LoggedInAs";
import {ButtonProps} from "../../2-component/Button/interface";
import Header from "../../2-component/Header";
import Logo from "../../1-container/images/Logo";
import SignUpContent from "./SignUpContent";
import {useTracking} from "../../../components/_shared/hooks/useTracking";
import ConfirmDeveloperContent from "./ConfirmDeveloperContent";
import NotAvailableYetContent from "./NotAvailableYetContent";
import {LoadingSpinner} from "../../../components/mapping/views/components/Common/LoadingSpinner";

export interface AuthenticationContentProps {
  redirect?: () => void;
}

const LoadingContent = () => (
  <SpinnerContainer>
    <LoadingSpinner isFullScreen={false} />
  </SpinnerContainer>
);

const AuthenticationContent = (props: AuthenticationContentProps) => {
  const {
    setMode,
    // handleMarketingQuestion,
    mode,
    schema,
    setSchema,
    authenticatedEmail,
    isFormLoading,
    logOutLead,
    // setMarketingChannel,
    createLead,
    isSubmitting,
  } = useFormState(props);
  const {trackEvent} = useTracking();

  const backToCustomerType: ButtonProps = useMemo(() => {
    return {
      id: "back-to-customer-type",
      label: "Go back",
      theme: "secondary",
      type: "button",
      onClick: () => setMode(AuthenticationFormState.SELECT_CUSTOMER_TYPE),
    };
  }, [setMode]);

  const content = useMemo(() => {
    if (isFormLoading || isSubmitting) {
      return <LoadingContent />;
    }
    switch (mode) {
      case AuthenticationFormState.RESET_PASSWORD:
        return (
          <>
            <Header>{"Reset your password"}</Header>
            <ResetPasswordContent
              email={schema?.email}
              toLogin={() => setMode(AuthenticationFormState.LOGIN_LANDING)}
            />
          </>
        );
      case AuthenticationFormState.LOGIN_LANDING:
        return (
          <>
            <Header>{"Welcome back!"}</Header>
            <LoginContent
              setSchema={setSchema}
              toSignUp={() => setMode(AuthenticationFormState.SIGN_UP_LANDING)}
              toResetPassword={() =>
                setMode(AuthenticationFormState.RESET_PASSWORD)
              }
            />
          </>
        );
      case AuthenticationFormState.SIGN_UP_LANDING:
        return (
          <>
            <Header>{"Create an account"}</Header>
            <SignUpContent
              // On close, go back to the login landing
              close={() => setMode(AuthenticationFormState.LOGIN_LANDING)}
              onSignUp={(values?: CreateLeadSchema) => {
                // If no values are passed, the user is creating a new account
                if (!values) {
                  setMode(AuthenticationFormState.CREATE_CONTACT);
                } else {
                  trackEvent("Registration Sign Up", {
                    auth_user_id: values?.auth_user_id,
                  });
                  setSchema(values);
                }
              }}
            />
          </>
        );
      case AuthenticationFormState.CREATE_CONTACT:
        return (
          <>
            <Header>{"Create an account"}</Header>
            <CreateContactContent
              close={() => setMode(AuthenticationFormState.SIGN_UP_LANDING)}
              onSubmit={(values: CreateLeadSchema) => {
                // Assign the credential to the useFormState
                trackEvent("Registration Create Contact", {
                  auth_user_id: values?.auth_user_id,
                });
                setSchema(values);
                setMode(AuthenticationFormState.SELECT_CUSTOMER_TYPE);
              }}
            />
          </>
        );
      case AuthenticationFormState.SELECT_CUSTOMER_TYPE:
        return (
          <>
            <Header>{"Which best describes you?"}</Header>
            <ConfirmCustomerTypeContent
              setCustomerType={(value) => {
                trackEvent("Registration Customer Type", {
                  selection: value,
                });
                switch (value) {
                  case "D2C ADUs":
                    setMode(AuthenticationFormState.CONFIRM_CUSTOMER);
                    break;
                  case "B2B ADUs":
                    setMode(AuthenticationFormState.CONFIRM_BUSINESS);
                    break;
                  case "Fee Building Development":
                    setMode(AuthenticationFormState.CONFIRM_DEVELOPMENT);
                    break;
                  default:
                    setMode(AuthenticationFormState.CONFIRM_CUSTOMER);
                    break;
                }
              }}
            />
          </>
        );
      case AuthenticationFormState.CONFIRM_CUSTOMER:
        return (
          <>
            <Header>{"Complete your account"}</Header>
            <ConfirmCustomerContent
              schema={schema}
              additionalButtons={[backToCustomerType]}
              onSubmit={(values: any, addressDetails: IAddressDetails) => {
                trackEvent("Registration Customer Complete", {
                  auth_user_id: schema?.auth_user_id,
                });
                createLead(
                  {
                    ...schema,
                    auth_user_id: schema?.auth_user_id,
                    created_from: schema?.created_from,
                    email: schema?.email ?? "",
                    phone_number:
                      formatPhoneNumber(
                        values?.["phone-number"],
                        PhoneNumberFormatType.RAW
                      ) ?? "",
                    marketing_opt_in:
                      values?.["marketing-consent"] === undefined
                        ? true
                        : values?.["marketing-consent"],
                    first_name: values?.["first-name"],
                    last_name: values?.["last-name"],
                    address: values?.["project-address"],
                    lead_segment: "D2C ADUs",
                  },
                  addressDetails
                );
              }}
            />
          </>
        );
      case AuthenticationFormState.CONFIRM_DEVELOPMENT:
        return (
          <>
            <Header>{"How can we get in touch?"}</Header>
            <ConfirmDeveloperContent
              schema={schema}
              additionalButtons={[backToCustomerType]}
              onSubmit={(values: any, addressDetails: IAddressDetails) => {
                trackEvent("Registration Developer Complete", {
                  auth_user_id: values?.auth_user_id,
                });
                createLead(
                  {
                    ...schema,
                    auth_user_id: schema?.auth_user_id,
                    created_from: schema?.created_from,
                    email: schema?.email ?? "",
                    phone_number:
                      formatPhoneNumber(
                        values?.["phone-number"],
                        PhoneNumberFormatType.RAW
                      ) ?? "",
                    marketing_opt_in:
                      values?.["marketing-consent"] === undefined
                        ? true
                        : values?.["marketing-consent"],
                    first_name: values?.["first-name"],
                    last_name: values?.["last-name"],
                    address: values?.["project-address"],
                    lead_segment: "Fee Building Development",
                  },
                  addressDetails
                );
              }}
            />
          </>
        );
      case AuthenticationFormState.CONFIRM_BUSINESS:
        return (
          <>
            <Header>
              {"Provide a few details for your initial property evaluation"}
            </Header>
            <ConfirmBusinessContent
              schema={schema}
              additionalButtons={[backToCustomerType]}
              onSubmit={(values: any, addressDetails: IAddressDetails) => {
                trackEvent("Registration Business Complete", {
                  auth_user_id: values?.auth_user_id,
                });
                createLead(
                  {
                    ...schema,
                    auth_user_id: schema?.auth_user_id,
                    created_from: schema?.created_from,
                    email: schema?.email ?? "",
                    phone_number:
                      formatPhoneNumber(
                        values?.["phone-number"],
                        PhoneNumberFormatType.RAW
                      ) ?? "",
                    marketing_opt_in:
                      values?.["marketing-consent"] === undefined
                        ? true
                        : values?.["marketing-consent"],
                    first_name: values?.["first-name"],
                    last_name: values?.["last-name"],
                    address: values?.["project-address"],
                    property_type: values?.["property-type"],
                    company: values?.["company-name"],
                    lead_segment: "B2B ADUs",
                  },
                  addressDetails
                );
              }}
            />
          </>
        );
      case AuthenticationFormState.LOGGING_OUT:
        setTimeout(() => {
          setMode(AuthenticationFormState.LOGIN_LANDING);
        }, 4000);
        return (
          <>
            <Header>{"One moment..."}</Header>
            <LoggingOutContent />
          </>
        );
      case AuthenticationFormState.NOT_AVAILABLE_YET:
        return (
          <NotAvailableYetContent
            continue={() => setMode(AuthenticationFormState.COMPLETE)}
          />
        );
      case AuthenticationFormState.COMPLETE:
        return <LoadingContent />;
    }
  }, [
    isFormLoading,
    isSubmitting,
    mode,
    authenticatedEmail,
    schema,
    setSchema,
    backToCustomerType,
    trackEvent,
    setMode,
  ]);

  useEffect(() => {
    // Scroll the container up to the top when the mode changes
    const element = document.getElementById("authentication-modal-content");
    const parent = element?.parentElement;
    if (element && parent) {
      element.scrollTo({
        top: 0,
        left: 0,
      });
      parent.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [mode]);

  const isWider = mode === AuthenticationFormState.NOT_AVAILABLE_YET;
  window.location.pathname.includes("/start") ||
    window.location.pathname.includes("/login");

  const logoDisplay =
    mode === AuthenticationFormState.NOT_AVAILABLE_YET ? "none" : "flex";

  return (
    <ContentContainer isWider={isWider} id={"authentication-modal-content"}>
      <Logo
        style={{
          display: logoDisplay,
          margin: "32px auto 32px auto",
          height: "40px",
        }}
      />
      {content}
      {mode !== AuthenticationFormState.NOT_AVAILABLE_YET &&
        !isSubmitting &&
        !isFormLoading && (
          <LoggedInAs email={authenticatedEmail} logOut={logOutLead} />
        )}
    </ContentContainer>
  );
};

export default AuthenticationContent;
