import React, {useState} from "react";
import {BannerProps} from "./interface";
import Text from "../Text";
import {NatWarningCircle} from "../../../components/_shared/icon/icons";
import styled from "styled-components";
import chevron from "./chevron_down.svg";

const Banner = (props: BannerProps) => {
  const [expanded, setExpanded] = useState(props.defaultState === "expanded");
  return (
    <Container expanded={expanded}>
      <Label>
        <NatWarningCircle color={"#572D76"} size={24} />
        <P style={{fontWeight: "bold", margin: "0"}}>{props.label}</P>
        <ChevronContainer onClick={() => setExpanded((prev) => !prev)}>
          <Chevron src={chevron} expanded={expanded} />
        </ChevronContainer>
      </Label>
      <ContentContainer expanded={expanded}>
        <Text {...props.textProps} />
      </ContentContainer>
    </Container>
  );
};

const ContentContainer = styled.div<{expanded: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  max-height: ${(props) => (props.expanded ? "200px" : "0")};
  margin-top: ${(props) => (props.expanded ? "8px" : "0")};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;

const Container = styled.div<{expanded: boolean}>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 458px;

  // Styles
  border-radius: 10px;
  border: 1px solid #e6c7ff;
  background: #f9f1ff;

  span {
    color: #030712;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px; /* 142.857% */
  }

  a {
    color: #030712;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 20px;
    text-decoration-line: underline;
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: "#4B0082";
`;

const P = styled.p`
  color: #572d76;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
`;

const Chevron = styled.img<{expanded: boolean}>`
  transform: ${(props) => (props.expanded ? "rotate(0deg)" : "rotate(-90deg)")};
  transition: transform 0.3s ease-in-out;
  size: 24px;
  cursor: pointer;
`;

const ChevronContainer = styled.div`
  margin-left: auto;
`;

export default Banner;
