import React, {Fragment} from "react";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
// @ts-ignore
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {EMBEDDED_PROPOSAL} from "../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {DTCProposalPreview} from "./useProjectProposalDownload/DTCProposalPreview";
import {DTCPresentationView} from "../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/DTCPresentationView";
import {ProjectPriceMode} from "@natomas-org/core";

export const ProposalPreview = (props: {
  hidden?: boolean;
  zoomLevel?: number;
  embedded: boolean;
}) => {
  if (props.embedded) {
    return (
      <EMBEDDED_PROPOSAL.Provider value={props.embedded}>
        <DTCPresentationView hideClose={true} readyToPrint={false} />
      </EMBEDDED_PROPOSAL.Provider>
    );
  }
  return (
    <div
      id={"proposal-preview-internal"}
      hidden={props.hidden}
      style={{
        height: "fit-content",
        width: "fit-content",
        position: "absolute",
        top: 0,
        left: 0,
        // top: props.zoomLevel ? `${(props.zoomLevel / 4) * 100}` : undefined,
        transform: props.zoomLevel ? `scale(${props.zoomLevel})` : undefined,
      }}
    >
      <EMBEDDED_PROPOSAL.Provider value={props.embedded}>
        <DTCProposalPreview />
      </EMBEDDED_PROPOSAL.Provider>
    </div>
  );
};

export const ProposalViewerAndEditor = () => {
  const {projectPriceMode} = useActiveProject();
  const isCostPlus = projectPriceMode === ProjectPriceMode.CostPlus;

  if (isCostPlus) {
    return (
      <div style={{padding: "16px"}}>
        Proposal Viewer / Editor functionality for CostPlus customers has been
        deprecated.
      </div>
    );
  }

  return (
    <Fragment>
      <NatFlex COLUMN NOWRAP style={{padding: "2rem 2rem"}}>
        <ProposalPreview embedded={true} />
      </NatFlex>
    </Fragment>
  );
};
