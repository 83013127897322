import React, {useCallback, useEffect, useMemo, useState} from "react";
import {HeaderGroup, Row, useFilters, useSortBy, useTable} from "react-table";
import _ from "lodash";
import {InfoSetItem} from "../shared/interfaces";
import {NatButton} from "../../_shared/generics/button";
import {
  NatGoBackIcon,
  NatResetIcon,
  NatSaveIcon,
} from "../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../_shared/generics/_shared";
import {getHeaderProps, infoSetColumns} from "./structure";
import {
  databaseToTableRows,
  InfoSetItemTableRow,
  isProtectedField,
  tableRowsToDatabase,
  updateCell,
  updateCells,
} from "./table";
import {
  InfoSetItemContextMenu,
  InfoSetItemContextMenuProps,
} from "./components/RowContextMenu/InfoSetItemContextMenu";
import {
  InfoSetContainer,
  InfoSetControlContainer,
  InfoSetTableController,
  InfoSetTableElement,
  InfoSetTableRow,
  InfoSetTableRowCell,
  ReactTableHeaderRowCell,
  ReactTableHeaderRowCellContent,
} from "./styles";
import {
  getUpdatedFactoryLineItems,
  updateFactoryLineItems,
} from "../../../database/firebase/factoryLine/saving";
import {errorLogger} from "../../../developerSettings";
import {InfoSetEditableCell} from "./components/EditableCell";
import {addItemSet} from "./actions";
import {
  ARCHIVED_KEY,
  ASSEMBLY_KEY,
  CONDITIONS_KEY,
  DETAILS_KEY,
  EXTERNAL_CATEGORY_KEY,
  EXTERNAL_DESCRIPTION_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  INTERNAL_NOTES_KEY,
  LABEL_KEY,
  MULTI_OPTION_KEY,
  OMIT_KEY,
  PRICING_PACKAGE_KEY,
  SOURCE_KEY,
  VARIANTS_KEY,
} from "../shared/constants";
import {InfoSetColumnTextFilter} from "./components/ColumnTextFilter";
import {IProduct, Product} from "@natomas-org/core";
import {sortProducts} from "../../_shared/_legacy/logic/ProductGroupUtils";
import {NatMicroModal} from "../../_shared/generics/micro-modal";
import {getRowContextMenuActions} from "./components/RowContextMenu/helper";
import {ProductHelper} from "../ProductHelper";
import {IFactorySummary} from "../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";
import {AdminActionController} from "../../_shared/generics/admin-controller";
import {ReactTableColumnHeader} from "./components/ColumnHeader";
import ColumnController from "./components/_shared/ColumnController";
import InfoSetCellRichTextEditorModal from "./components/InfoSetCellRichTextEditorModal";
import {getInfoSetTableHeaderBackgroundColor} from "./helper";

export const InfoSetTable = (props: {factoryLine: IFactorySummary}) => {
  const {factoryLine} = props;
  const {
    categories,
    modifierGroups,
    modifiers,
    products,
    info,
    infoSet: initialData,
  } = factoryLine;
  const {to} = useNavigation();
  const columns = useMemo(() => infoSetColumns, []);
  const [data, setData] = useState<InfoSetItemTableRow[]>([]);
  const [event, setEvent] = useState<any>(undefined);
  const [originalData, setOriginalData] = useState(data);
  const [richTextEditorParameters, setRichTextEditorParameters] = useState<
    {key: string; index: number} | undefined
  >();
  const [targetRow, setTargetRow] = useState<
    Row<InfoSetItemTableRow> | undefined
  >();

  const productOptions: IProduct[] = useMemo(() => {
    if (!products) {
      return [];
    }
    return sortProducts(
      Object.values(products)?.filter(
        (p: IProduct) => !!p && !Product.isInventory(p)
      )
    );
  }, [products]);

  const modifierOptions: any[] = useMemo(() => {
    if (!modifiers) {
      return [];
    }
    return Object.values(modifiers)
      .filter((m: any) => !!m)
      ?.map((modifier: any) => {
        return {
          value: modifier.id ?? "",
          label:
            modifier.internal_title?.length > 0
              ? modifier.internal_title
              : modifier.title?.length > 0
              ? modifier.title
              : modifier.configurationButtonTitle?.length > 0
              ? modifier.configurationButtonTitle
              : "Unknown",
        };
      })
      .sort((a: any, b: any) => {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      });
  }, [modifiers]);

  // Everytime we get a new server update, update table + original data
  const initialDataArray: InfoSetItem[] = useMemo(() => {
    if (initialData) {
      return Object.values(initialData);
    }
    return [];
  }, [initialData]);

  useEffect(() => {
    if (initialDataArray) {
      console.log("InfoSetTable: Initializing table...");
      const _data: InfoSetItemTableRow[] =
        databaseToTableRows(initialDataArray);
      setData(_data);
      setOriginalData([..._data]);
    } else {
      setData([]);
      setOriginalData([]);
    }
  }, [initialDataArray]);

  // When our cell renderer calls updateCellValue, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateCellValue = (rowIndex: number, columnId: string, value: any) => {
    if (isProtectedField(columnId)) {
      setData((old) => {
        return updateCells(old, rowIndex, columnId, value);
      });
    } else {
      setData((old) => {
        return updateCell(old, rowIndex, columnId, value);
      });
    }
  };

  const dataToSave = useMemo(() => {
    if (!info?.id || !initialDataArray || !data) {
      errorLogger("FactoryLineInfoSet: Save failed, insufficient information");
      return;
    }
    const databaseObjects = tableRowsToDatabase(data);
    const updated = getUpdatedFactoryLineItems(
      databaseObjects,
      initialDataArray
    );
    return updated?.length > 0 ? updated : null;
  }, [data, info, initialDataArray]);

  const saveData = useCallback(() => {
    if (dataToSave && info?.id) {
      updateFactoryLineItems(info.id, dataToSave);
    }
  }, [dataToSave, info]);

  const resetData = () => setData(originalData);
  const resetMenu = () => {
    setEvent(undefined);
    setTargetRow(undefined);
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = useMemo(
    () => ({
      Cell: InfoSetEditableCell,
      Filter: InfoSetColumnTextFilter,
    }),
    []
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const initialState = {
    sortBy: [
      {
        id: INTERNAL_CATEGORY_KEY,
        desc: false,
      },
      {
        id: LABEL_KEY,
        desc: false,
      },
    ],
    hiddenColumns: [
      ID_KEY,
      OMIT_KEY,
      SOURCE_KEY,
      CONDITIONS_KEY,
      MULTI_OPTION_KEY,
      PRICING_PACKAGE_KEY,
      ASSEMBLY_KEY,
      INCLUDED_ORDER_FORM_KEY,
      DETAILS_KEY,
      VARIANTS_KEY,
      EXTERNAL_DESCRIPTION_KEY,
      EXTERNAL_CATEGORY_KEY,
      INTERNAL_NOTES_KEY,
      INTERNAL_CATEGORY_KEY,
    ],
  };

  // Render the UI for your table
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    allColumns,
    rows,
    prepareRow,
  } = useTable(
    {
      initialState,
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      // @ts-ignore
      updateCellValue,
      editingTargetId: targetRow?.original?.key,
      setRichTextEditorParameters,
      categories,
      modifierGroups,
      modifierOptions,
      productOptions,
    },
    useFilters,
    useSortBy
  );
  const contextMenuProps: InfoSetItemContextMenuProps = useMemo(() => {
    return {
      items: getRowContextMenuActions(
        setData,
        resetMenu,
        targetRow,
        originalData[targetRow?.index ?? -1]
      ),
      title: targetRow?.original?.[LABEL_KEY] ?? undefined,
    };
  }, [targetRow, originalData]);

  const onContextMenuRow = (row: Row<InfoSetItemTableRow>, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setEvent(e);
    setTargetRow(row);
  };

  const onLeftClick = (row: Row<InfoSetItemTableRow>, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setTargetRow(row);
  };

  const getBorderType = useCallback(
    (
      key: string,
      index: number,
      row: Row<InfoSetItemTableRow>,
      array: Row<InfoSetItemTableRow>[]
    ) => {
      if (array[index + 1]?.original?.[ID_KEY] === row.original[ID_KEY]) {
        if (isProtectedField(key)) {
          return "none";
        } else {
          return "dashed";
        }
      } else {
        return "solid";
      }
    },
    []
  );

  const isSiblingRow = useCallback(
    (
      key: string,
      index: number,
      row: Row<InfoSetItemTableRow>,
      array: Row<InfoSetItemTableRow>[]
    ) => {
      const previousRowKey = array?.[index - 1]?.original?.[ID_KEY];
      const currentRowKey = row?.original?.[ID_KEY];
      return isProtectedField(key) && currentRowKey === previousRowKey;
    },
    []
  );

  return (
    <>
      <NatMicroModal
        id={`context-menu`}
        event={event}
        additionalReset={resetMenu}
        draggable={true}
        title={contextMenuProps.title}
      >
        <InfoSetItemContextMenu items={contextMenuProps.items} />
      </NatMicroModal>
      <InfoSetCellRichTextEditorModal
        row={targetRow}
        originalRow={originalData.find(
          (r: InfoSetItemTableRow) => r.key === targetRow?.original.key
        )}
        field={richTextEditorParameters?.key}
        handleSave={(v: string | undefined) => {
          if (!richTextEditorParameters) {
            return;
          }
          updateCellValue(
            richTextEditorParameters?.index,
            richTextEditorParameters?.key,
            v
          );
          setTargetRow(undefined);
          setRichTextEditorParameters(undefined);
        }}
      />
      <InfoSetTableController>
        <ColumnController allColumns={allColumns} />
        <InfoSetControlContainer>
          <NatButton
            label={
              factoryLine?.details?.internal_title ??
              factoryLine?.details?.title ??
              "Unknown Factory"
            }
            icon={{
              icon: <NatGoBackIcon />,
              iconPosition: IconPosition.LEFT,
            }}
            size={NatSize.XSMALL}
            option={StyleOption.PRIMARY_BLACK}
            clickEvent={() => to(NavigationPaths.ADMIN)}
          />
          <AdminActionController hideLabels={true} />
          <ProductHelper
            infoSetData={data}
            products={productOptions}
            categories={categories}
            modifierGroups={modifierGroups}
            modifiers={modifiers ?? {}}
          />
          <NatButton
            label={"New Key"}
            clickEvent={() => addItemSet(setData)}
            size={NatSize.SMALL}
            option={StyleOption.PRIMARY_ALT}
          />
        </InfoSetControlContainer>
        {!!dataToSave && (
          <InfoSetControlContainer>
            <NatButton
              label={"Save Changes"}
              icon={{icon: <NatSaveIcon />, iconPosition: IconPosition.LEFT}}
              clickEvent={() => saveData()}
              hidden={!dataToSave}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY_ALT}
            />
            <NatButton
              label={"Abandon Changes"}
              icon={{icon: <NatResetIcon />, iconPosition: IconPosition.LEFT}}
              clickEvent={() => resetData()}
              hidden={!dataToSave}
              size={NatSize.SMALL}
              option={StyleOption.DESTRUCTIVE}
            />
          </InfoSetControlContainer>
        )}
      </InfoSetTableController>
      <InfoSetContainer>
        <InfoSetTableElement {...getTableProps()}>
          <thead style={{fontSize: "1.25rem"}}>
            {headerGroups.map(
              (
                headerGroup: HeaderGroup<InfoSetItemTableRow>,
                index: number
              ) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const headerProps = getHeaderProps(column);
                    return (
                      <ReactTableHeaderRowCell
                        {...headerProps}
                        sticky={index === 1}
                        onClick={undefined}
                        backgroundColor={getInfoSetTableHeaderBackgroundColor(
                          column.Header
                        )}
                      >
                        <ReactTableHeaderRowCellContent>
                          <ReactTableColumnHeader
                            onClick={headerProps?.onClick}
                            column={column}
                          />
                          {column.canFilter ? column.render("Filter") : null}
                        </ReactTableHeaderRowCellContent>
                      </ReactTableHeaderRowCell>
                    );
                  })}
                </tr>
              )
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index, array) => {
              prepareRow(row);
              const originalRowData: InfoSetItemTableRow | undefined =
                originalData.find(
                  (r: InfoSetItemTableRow) => r.key === row.original.key
                );
              return (
                <InfoSetTableRow
                  {...row.getRowProps()}
                  toBeArchived={row?.original?.[ARCHIVED_KEY]}
                  selected={targetRow?.[ID_KEY] === row?.[ID_KEY]}
                  onContextMenu={(e) => {
                    onContextMenuRow(row, e);
                  }}
                  onClick={(e) => {
                    if (targetRow?.original?.key !== row.original?.key) {
                      onLeftClick(row, e);
                    }
                  }}
                >
                  {row.cells.map((cell) => {
                    const key = cell?.column?.id as keyof InfoSetItemTableRow;
                    if (!key) {
                      return null;
                    }
                    const hasChanges = !originalRowData
                      ? true
                      : !_.isEqual(
                          cell.value,
                          originalRowData?.[key] ?? undefined
                        );
                    const borderType = getBorderType(key, index, row, array);
                    const hideField =
                      index > 0 && isSiblingRow(key, index, row, array);

                    return (
                      <InfoSetTableRowCell
                        changed={hasChanges}
                        borderType={borderType}
                        toBeArchived={row?.original?.[ARCHIVED_KEY]}
                        {...cell.getCellProps()}
                      >
                        {!hideField && cell.render("Cell")}
                      </InfoSetTableRowCell>
                    );
                  })}
                </InfoSetTableRow>
              );
            })}
          </tbody>
        </InfoSetTableElement>
      </InfoSetContainer>
    </>
  );
};
