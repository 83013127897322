import React from "react";
import {IProjectTeamMemberContactInfo} from "@natomas-org/core";
import {
  HCCardContainer,
  HCCardImage,
  HCCardInfoWrapper,
  HCCardSubTitle,
  HCFooter,
} from "./styles";
import logo from "../../../../../assets/logos/villa-logo-white.png";
import {VILLA_SLATE} from "../../../../_shared/colors";
import TeamMemberPhoto from "../../../../../0-app/1-container/team-member-photo";

export type TeamMemberCardLayoutStyle = "details" | "full-details" | "compact";

export const DefaultProfilePicture = (
  <img
    style={{
      backgroundColor: VILLA_SLATE,
      height: "100%",
      width: "100%",
      padding: "10%",
    }}
    src={logo}
    alt={"Villa Homes"}
  />
);
export const TeamMemberCard = (props: {
  shouldLookMobile: boolean;
  layout: TeamMemberCardLayoutStyle;
}) => {
  const {shouldLookMobile, layout} = props;

  if (layout === "compact") {
    return null;
  }
  return (
    <HCCardContainer shouldLookMobile={shouldLookMobile} layout={layout}>
      <HCCardImage>
        <TeamMemberPhoto email={undefined} />
      </HCCardImage>
      <HCCardInfoWrapper>
        <HCCardSubTitle>
          Your project is in the works! Your Villa representative will be in
          touch soon via email.
        </HCCardSubTitle>
        <HCFooter>
          {`Need assistance now?\nReach us at `}
          <a href="tel:415-968-1625">415-968-1625</a> or{" "}
          <a href="mailto:hello@villahomes.com">hello@villahomes.com</a>
        </HCFooter>
      </HCCardInfoWrapper>
    </HCCardContainer>
  );
};
