import React from "react";
import styled from "styled-components";

const BlueDiamondSvg = styled.svg`
  width: 16px; // Change the size as needed
  height: 16px; // Change the size as needed
`;

const BlueDiamond = () => {
  return (
    <BlueDiamondSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M8 0L16 8L8 16L0 8L8 0Z" fill="#0098C4" />
    </BlueDiamondSvg>
  );
};

const OurProcessBlueDiamond = styled(BlueDiamond)`
  svg {
    margin: auto 0 auto 0;
  }
`;

export default OurProcessBlueDiamond;
