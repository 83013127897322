import {usePage} from "../../../../components/_shared/hooks/usePage";
import React, {useEffect} from "react";
import styled from "styled-components";
import {IPopupMenu, PopupMenu, PopupMenuContainer} from "./menu_popup";
import {IAvatarProps} from "./useAvatarMenu";
import AvatarMenuContent from "./avatar-menu-content";
import HamburgerIcon from "./hamburger";
import Button from "../../../2-component/Button";
import {RiShieldFill} from "react-icons/ri";

export const AvatarSelector = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13343b;
  border-radius: 50%;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: p22-mackinac-pro, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  user-select: none;
  position: relative;

  span {
    background-color: #13343b;
  }

  cursor: pointer;
  &:hover {
    background-color: #0080a7;
  }
  transition: all 200ms ease;
`;

export interface IAvatarMenuProps extends IPopupMenu {
  avatar: IAvatarProps;
  signInAction: () => void;
  toggle: () => void;
}

export const AvatarMenu = (props: IAvatarMenuProps) => {
  const {avatar, toggle, visible, ...rest} = props;
  const {columnCount} = usePage();

  // If a scroll event occurs, the banner will be hidden
  // This is to prevent the banner from being visible when the user scrolls
  useEffect(() => {
    // Hide the banner when the user scrolls
    const handleScroll = () => {
      if (visible) toggle();
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [visible, toggle]);

  // Can add loading state later
  const selector_content = visible ? (
    <AvatarSelector
      onClick={toggle}
      style={{
        cursor: "pointer",
        backgroundColor: "#fff",
      }}
    >
      <HamburgerIcon close={true} />
    </AvatarSelector>
  ) : (
    <AvatarSelector onClick={toggle}>
      {avatar.is_user_an_admin ? (
        <RiShieldFill
          size={"32px"}
          color={"#0080a7"}
          style={{position: "absolute", zIndex: 0}}
        />
      ) : null}
      <div style={{zIndex: 1}}>{avatar.initials}</div>
    </AvatarSelector>
  );

  if (columnCount <= 8) {
    // Use mobile right sheet
    return <>{selector_content}</>;
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Backdrop onClick={toggle} visible={visible} />
        {avatar.header_cta && (
          <Button
            id={"header_avatar_menu_action"}
            data-cy={"header_avatar_menu_action"}
            label={avatar.header_cta?.label ?? ""}
            onClick={avatar.header_cta?.onClick}
            theme={"secondary"}
            size={"small"}
          />
        )}
        {selector_content}
        <PopupMenu visible={visible} {...rest}>
          <PopupMenuContainer
            style={{
              width: "400px",
            }}
          >
            <AvatarMenuContent avatar={avatar} />
          </PopupMenuContainer>
        </PopupMenu>
      </div>
    </>
  );
};

const Backdrop = styled.div<{visible: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  // add blur
  z-index: 100;
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  pointer-events: ${({visible}) => (visible ? "all" : "none")};
  transition: opacity 150ms cubic-bezier(0.85, 0.5, 0.85, 0.5);
`;
