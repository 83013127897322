import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IRegistrationState {
  visible: boolean;
  signUp: boolean;
  isForced?: boolean;
  redirectType?: string;
  registrationValues?: any;
}

interface ISessionState {
  registration: IRegistrationState;
  displayCompletedProjectsPrice: boolean;
}

const getInitialState = (): ISessionState => {
  return {
    registration: {
      visible: false,
      signUp: false,
      isForced: false,
    },
    displayCompletedProjectsPrice: false,
  };
};

const sessionSlice = createSlice({
  name: "session",
  initialState: getInitialState(),
  reducers: {
    setRegistrationModalVisible: (state, action: PayloadAction<boolean>) => {
      state.registration.visible = action.payload;
    },
    setRegistrationModalForced: (state, action: PayloadAction<boolean>) => {
      state.registration.isForced = action.payload;
    },
    setSignUp: (state, action: PayloadAction<boolean>) => {
      state.registration.signUp = action.payload;
    },
    setRegistrationModalState: (
      state,
      action: PayloadAction<IRegistrationState>
    ) => {
      state.registration.signUp = action.payload.signUp;
      state.registration.visible = action.payload.visible;
      state.registration.isForced = action.payload.isForced ?? false;
      // Action must set every time
      state.registration.redirectType = action.payload.redirectType;

      if (action.payload.registrationValues) {
        state.registration.registrationValues =
          action.payload.registrationValues;
      } else {
        state.registration.registrationValues = {};
      }
    },
    setShowCompletedProjectPricing: (state, action: PayloadAction<boolean>) => {
      state.displayCompletedProjectsPrice = action.payload;
    },
  },
});

export const sessionReducer = sessionSlice.reducer;
export const {
  setRegistrationModalVisible,
  setRegistrationModalState,
  setShowCompletedProjectPricing,
} = sessionSlice.actions;
