import {
  getCategoryRenderings,
  getFullModifierGroup,
  getOnlyActiveModifierGroupsFromValid,
  getOnlyValidModifierGroupsFromFull,
  isModifierValid,
} from "@natomas-org/service";
import {
  getImagesFromRenderings,
  ProjectPriceMode,
  SHOW_MULTI_SELECT_PROPERTY_KEY,
} from "@natomas-org/core";
import {setStudioSelection} from "../../../slices/StudioSlice";
import {batch} from "react-redux";
import {store} from "../../../../../store";

export const getFullStudioStructure = (
  categories: any[],
  modifierGroups: any[],
  modifiers: any[]
) => {
  return categories?.map((category: any) => {
    if (!category || !category?.modifierGroups) {
      return [];
    }

    const modifierGroupIds = category.modifierGroups;
    const fullModifierGroups = modifierGroupIds
      .map((id: string) => {
        const modifierGroup = modifierGroups?.find((g: any) => g?.id === id);
        return getFullModifierGroup(categories, modifierGroup, modifiers);
      })
      .filter((modifierGroup: any) => !!modifierGroup);
    return {
      ...category,
      fullModifierGroups: fullModifierGroups ?? [],
    };
  });
};

export const getActiveStudioStructure = (
  fullStudioStructure: any[],
  selections: any,
  priceMode: ProjectPriceMode
) => {
  const activeStructure = fullStudioStructure?.map((category: any) => {
    const validModifierGroups = getOnlyValidModifierGroupsFromFull(
      fullStudioStructure,
      category,
      selections
    );
    const selectedModifierGroups = getOnlyActiveModifierGroupsFromValid(
      fullStudioStructure,
      validModifierGroups,
      selections
    );
    return {
      ...category,
      selectedModifierGroups: selectedModifierGroups,
    };
  });
  return activeStructure?.map((category: any) => {
    const renderings = getCategoryRenderings(
      category,
      activeStructure,
      selections
    );
    const images = getImagesFromRenderings(renderings);
    return {
      ...category,
      activeImages: images,
    };
  });
};

export const getSnapshotStudioStructure = (
  snapshot: {
    structure: any;
    images: any;
  },
  selections: any,
  priceMode: ProjectPriceMode
) => {
  const {structure, images: allImages} = snapshot;
  if (allImages) {
    return structure?.map((category: any) => {
      // console.log(category);

      const images = allImages?.filter(
        (image: any) => image?.configurationPageId === category?.id
      );
      return {
        ...category,
        activeImages: images,
      };
    });
  } else {
    return structure?.map((category: any) => {
      const renderings = getCategoryRenderings(category, structure, selections);
      const images = getImagesFromRenderings(renderings);
      return {
        ...category,
        activeImages: images,
      };
    });
  }
};

interface ISelectModifierInfo {
  modifier: any;
  modifierGroup: any;
  status: true | null;
}

export const validateSelections = (selections: any, structure: any) => {
  const batchedUpdates: ISelectModifierInfo[] = [];
  structure?.forEach((category: any) => {
    category?.fullModifierGroups?.forEach((modifierGroup: any) => {
      // Get modifier group modifiers
      const modifiers = modifierGroup?.fullModifiers;

      // Determine if group is admin only
      const isAdminOnly = category?.isAdminSelection === true;

      if (isAdminOnly) {
        return;
      }
      // Determine if group is multi select
      const isMultiSelect =
        modifierGroup?.properties?.[SHOW_MULTI_SELECT_PROPERTY_KEY] === true;

      // Currently selected modifiers
      const selectedModifiers = modifiers?.filter((modifier: any) => {
        return selections?.[modifier?.id] === true;
      });

      if (isMultiSelect) {
        // Reset multi select options if they are not valid
        const invalidModifiers = selectedModifiers?.filter((modifier: any) => {
          return !isModifierValid(
            structure,
            modifierGroup,
            modifier,
            selections
          );
        });
        if (invalidModifiers?.length > 0) {
          console.log("multi-invalidModifiers", invalidModifiers);
          invalidModifiers.forEach((modifier: any) => {
            batchedUpdates.push({
              modifier: modifier,
              modifierGroup: modifierGroup,
              status: null,
            });
          });
        }
      } else {
        const noSelectedModifiers = modifiers?.every((modifier: any) => {
          return selections?.[modifier?.id] !== true;
        });

        if (noSelectedModifiers) {
          // Select the first one if none are selected only if it is valid
          const defaultModifier = modifiers?.[0];
          if (defaultModifier) {
            const isValid = isModifierValid(
              structure,
              modifierGroup,
              defaultModifier,
              selections
            );
            if (isValid) {
              console.log("single-default-select");
              batchedUpdates.push({
                modifier: modifiers[0],
                modifierGroup: modifierGroup,
                status: true,
              });
            }
          }
        } else {
          // Reset if any of the selected modifiers are invalid
          const invalidModifiers = selectedModifiers?.filter(
            (modifier: any) => {
              return !isModifierValid(
                structure,
                modifierGroup,
                modifier,
                selections
              );
            }
          );
          if (invalidModifiers?.length > 0) {
            console.log("single-invalidModifiers", invalidModifiers);
            invalidModifiers.forEach((modifier: any) => {
              batchedUpdates.push({
                modifier: modifier,
                modifierGroup: modifierGroup,
                status: null,
              });
            });
          }
        }
      }
    });
    if (batchedUpdates?.length > 0) {
      batch(() => {
        batchedUpdates.forEach((info: ISelectModifierInfo) => {
          const payload = {
            ...info,
            isValidating: true,
          };
          console.log("payload", payload);
          store.dispatch(setStudioSelection(payload));
        });
      });
    }
  });
};

// if (modifiers != null && modifiers.length > 0) {
//   // Reset if no modifiers are selected
//   const noSelectedModifiers = modifiers.every((modifier: any) => {
//     return selections?.[modifier?.id] !== true;
//   });
//
//   let needsToReset = noSelectedModifiers && !category?.isAdminSelection;
//
//   if (!needsToReset) {
//     // Reset if any of the selected modifiers are invalid
//     needsToReset = modifiers.some((modifier: any) => {
//       return (
//         selections?.[modifier?.id] === true &&
//         !isModifierValid(structure, modifierGroup, modifier, selections)
//       );
//     });
//   }
//
//   if (needsToReset) {
//     let reset = false;
//     for (const modifier of modifiers) {
//       if (
//         isModifierValid(structure, modifierGroup, modifier, selections)
//       ) {
//         batchedUpdates.push(
//           setStudioSelection({
//             modifier: modifier,
//             modifierGroup: modifierGroup,
//             reset: true,
//           })
//         );
//         reset = true;
//         break;
//       }
//     }
//
//     // In the case that all modifiers are invalid, just select the first one.
//     if (!reset && modifiers.length > 0 && noSelectedModifiers) {
//       batchedUpdates.push(
//         setStudioSelection({
//           modifier: modifiers[0],
//           modifierGroup: modifierGroup,
//           reset: true,
//         })
//       );
//     }
//   }
// }
