import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@natomas-org/villa-component-library/dist/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import {store} from "./store";
import history from "./components/_shared/navigation/history";
import {Router} from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {HelmetProvider} from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {
  disableAnalytics,
  enableAnalytics,
} from "./components/_shared/generics/DataManager";
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
enum CookieType {
  Necessary = "necessary",
  Analytics = "analytics",
  Advertisement = "advertisement",
  Functional = "functional",
  Performance = "performance",
  Other = "other",
}
type CookieYesEvent = CustomEvent<{
  accepted: CookieType[];
  rejected: CookieType[];
}>;

const processServices = (accepted: CookieType[]) => {
  // If analytics and advertisement are enabled, initialize analytics tracking
  if (
    accepted.includes(CookieType.Advertisement) ||
    accepted.includes(CookieType.Analytics)
  ) {
    enableAnalytics();
  } else {
    disableAnalytics();
  }
};

// Custom provider component
const useCookieProvider = () => {
  useEffect(() => {
    const handleCookieUpdate = (event: any) => {
      const cookieYes: CookieYesEvent = event as CookieYesEvent;
      const {detail} = cookieYes;
      const {accepted} = detail;
      // Process accepted services
      processServices(accepted);
    };

    document.addEventListener("cookieyes_consent_update", handleCookieUpdate);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener(
        "cookieyes_consent_update",
        handleCookieUpdate
      );
    };
  }, []); // Empty dependency array ensures the effect runs only once
  return null;
};
const CookieYes = () => {
  return useCookieProvider();
};

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Provider store={store}>
        <HelmetProvider>
          <CookieYes />
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </HelmetProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("natomas-web-app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate: (registration: ServiceWorkerRegistration) => {
//     if (registration && registration.waiting) {
//       registration.waiting.postMessage({type: "SKIP_WAITING"});
//     }
//     window.location.reload();
//   },
// });

serviceWorkerRegistration.unregister();
