import {StudioController} from "../../../_shared/components/StudioController";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import React from "react";
import {
  CustomerJourneyStepName,
  useTracking,
} from "../../../../_shared/hooks/useTracking";
import {IStudioOptions} from "../../../_shared/components/StudioContent";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {useDispatch} from "react-redux";
import {setRegistrationModalState} from "../../../../_shared/slices/SessionSlice/SessionSlice";
import {buildConfigurationObject} from "../../helper";
import useLeadExists from "../../../../../api/Lead/useLeadExists";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../../../intake-form/constants";
import {ButtonProps} from "../../../../../0-app/2-component/Button/interface";

export interface DesignerControllerProps {
  formik: any;
  summary: any;
  minimize: boolean;
  canSave: boolean;
  save: any;
  reset: any;
  options?: IStudioOptions;
}

export const DesignerController = (props: DesignerControllerProps) => {
  const {formik, summary, save, canSave, reset} = props;
  const {loggedInUser, isAdmin, uid} = useCurrentUser();
  const {data: lead_exists} = useLeadExists(uid);
  const {isCustomerLoaded} = useCustomerStatus();
  const {isEditing, isSaving} = useStudioSettings();
  const {to} = useNavigation();
  const {trackJourneyStep} = useTracking();
  const isPublicApp = useIsPublicApp();
  const dispatch = useDispatch();
  const saveAction = () => {
    // If no user logged in or the user is an admin (
    // Only save if a customer is loaded and the user is logged in and not on the public app
    if (!!loggedInUser && isCustomerLoaded && !isPublicApp) {
      if (canSave && !!save) {
        trackJourneyStep(CustomerJourneyStepName.UNIT_SELECTION_COMPLETED);
        return save(formik, summary);
      } else {
        to(NavigationPaths.PORTAL);
      }
    } else {
      const configuration = buildConfigurationObject(summary);
      dispatch(
        setRegistrationModalState({
          visible: true,
          signUp: true,
          registrationValues: {
            configuration: configuration,
            unitPlacement: undefined,
          },
          redirectType: INTAKE_FORM_REDIRECT_TYPE.INSTANT_ESTIMATE,
        })
      );
    }
  };

  const attemptSave = (event: any) => {
    event?.preventDefault();
    saveAction();
  };

  const attemptReset = (event: any) => {
    event?.preventDefault();
    reset();
  };

  const saveButtonId = !canSave
    ? "studio-continue"
    : isPublicApp && !loggedInUser
    ? `studio-create`
    : !lead_exists && !isAdmin
    ? `studio-complete`
    : `studio-save`;
  const saveDesignButton: ButtonProps = {
    hidden: !isEditing || (isPublicApp && !!loggedInUser),
    onClick: attemptSave,
    disabled: isPublicApp && !!loggedInUser,
    label: !canSave
      ? "Continue"
      : isPublicApp && !loggedInUser
      ? `Create account to save design`
      : !lead_exists && !isAdmin
      ? `Complete account to save design`
      : `Save design`,
    id: saveButtonId,
    theme: saveButtonId === "studio-save" ? "primary" : "secondary",
    containerStyle: saveButtonId === "studio-save" ? undefined : {flexGrow: 1},
  };

  const designResetButton: ButtonProps = {
    hidden: saveButtonId !== "studio-save",
    disabled: isSaving,
    onClick: attemptReset,
    theme: "secondary",
    id: "reset-design",
    label: "Revert changes",
  };

  return <StudioController buttons={[designResetButton, saveDesignButton]} />;
};
