import React from "react";
import useActivitySlice from "../../../../../../_shared/hooks/useActivity/useActivitySlice";
import styled from "styled-components";
import {isProd, isStaging} from "../../../../../../_shared/application";

const LatestEstimateLink = () => {
  const {activeProjectId} = useActivitySlice();
  if (!activeProjectId) {
    return <p>No active project id</p>;
  }

  let href = `https://villa-admin.villa-app-development.com/project/${activeProjectId}/estimate`;
  if (isProd) {
    href = `https://admin.villahomes.com/project/${activeProjectId}/estimate`;
  } else if (isStaging) {
    href = `https://villa-admin.villa-app-staging.com/project/${activeProjectId}/estimate`;
  }

  return (
    <span
      style={{
        borderRadius: "4px",
        border: `1px solid #e0e0e0`,
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "8px",
      }}
    >
      <StyledA href={href}>Estimate Manager</StyledA>
      <StyledUl>
        <StyledLi>View, create estimates</StyledLi>
        <StyledLi>Add / remove discounts</StyledLi>
      </StyledUl>
    </span>
  );
};

const StyledA = styled.a`
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #0056b3;
  }
`;

const StyledUl = styled.ul`
  list-style: none; /* Remove default bullets */
  margin: 0;
  padding: 0;
  max-width: 400px; /* Optional: set a max width */
`;

const StyledLi = styled.li`
  cursor: default;
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: transparent;
  transition: background-color 0.3s ease;

  /* Create a custom bullet using the ::before pseudo-element */
  &::before {
    content: "•"; /* Or use an icon font, e.g. Font Awesome */
    position: absolute;
    left: 0.5rem;
    font-size: 1rem;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);
  }

  /* Optional: hover effect */
  &:hover {
    background-color: #e0e0e0;
  }
`;

export default LatestEstimateLink;
