import styled from "styled-components";
import {HEADER_Z_INDEX, MODAL_Z_INDEX} from "../../../../_shared/styles";
import {DIVIDER_COLOR, WHITE} from "../../../../_shared/colors";

export const DesktopFixedAdminContainer = styled.div`
  position: fixed;
  top: 108px;
  left: 8px;
  z-index: ${HEADER_Z_INDEX - 2000};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DesktopRenderingContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: white;
  z-index: 100;
  width: calc(100% - 469px);
`;

export const DesktopStickyMenu = styled.div<{hasBackButton: boolean}>`
  position: absolute;
  width: 469px;
  height: ${(props) => (props?.hasBackButton ? 98 : 43)}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${(props) => (props?.hasBackButton ? 1 : 0)}rem;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  right: 0;
`;

export const InformationIconContainer = styled.div<{
  top: string;
  right?: string;
  left?: string;
}>`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 24px;
  border-radius: 12px;
  background-color: ${WHITE};
  display: flex;
  top: ${(props) => props.top};
  left: ${(props) => props.left ?? undefined};
  right: ${(props) => props.right ?? undefined};
  z-index: ${MODAL_Z_INDEX};
  &:hover {
    background-color: ${DIVIDER_COLOR};
    cursor: help;
  }
`;

export const ScrollableContainer = styled.div<{
  stackedMode?: boolean;
  hideBack?: boolean;
}>`
  overflow-x: hidden;
  overflow-y: auto;
  ${(props) =>
    !props.stackedMode &&
    `
    width = "469px";
    position = "absolute";
    right = 0;
    bottom = "0px";
    overflow = "hidden auto";
    `}
  margin-top: ${(props) => props.stackedMode && "30vh"};
`;

export const StudioContentContainer = styled.div<{
  height?: string;
  stackedMode?: boolean;
}>`
  width: 100%;
  height: ${(props) => props.height};
  position: relative;
  ${(props) =>
    props.stackedMode &&
    `
      height: unset;
      min-height: calc(100vh - 120px);
      margin: 0;
      padding: 0;
    `}
`;
