import React from "react";
import {NatomasModalFooterButtonContainer} from "./styles";
import Button from "../../../../0-app/2-component/Button";
import {NatomasButtonProps} from "../button";
import {StyleOption} from "../_shared";
import {ButtonTheme} from "../../../../0-app/2-component/Button/interface";

export const FooterButtons = (props: {
  footerButtons: NatomasButtonProps[];
  columned: boolean;
}) => {
  const {footerButtons, columned} = props;
  return (
    <NatomasModalFooterButtonContainer columnButtons={columned}>
      {footerButtons?.map((button: NatomasButtonProps, index: number) => {
        const {id, trackingId, label, clickEvent, option} = button;
        const key = "nat-modal-footer-button-" + index;
        let theme: ButtonTheme = "primary";
        switch (option) {
          case StyleOption.SECONDARY:
          case StyleOption.SECONDARY_ALT:
            theme = "secondary";
            break;
          case StyleOption.DESTRUCTIVE:
            theme = "danger";
            break;
          default:
            theme = "primary";
        }
        return (
          <Button
            key={key}
            id={id ?? trackingId ?? key}
            label={label}
            onClick={clickEvent}
            theme={theme}
            containerStyle={{width: "fit-content"}}
          />
        );
      })}
    </NatomasModalFooterButtonContainer>
  );
};
