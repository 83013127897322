import React from "react";
import styled, {keyframes} from "styled-components";

// Spinner animation
const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

// Styled components
const PlaceholderContainer = styled.div`
  width: 100%; /* Adjust width */
  padding: 16px; /* Padding */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Positioning for the spinner */
  border: 2px dashed #ccc; /* Dashed border */
`;

const Spinner = styled.div`
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px; /* Spinner size */
  height: 40px; /* Spinner size */
  animation: ${spin} 1s linear infinite; /* Spin animation */
  position: absolute; /* Positioning on top of the placeholder */
`;

// React component
const ImagePlaceholder = () => {
  return (
    <PlaceholderContainer>
      <Spinner />
    </PlaceholderContainer>
  );
};

export default ImagePlaceholder;
