import {
  IRequestHandlerResult,
  useRequestHandler,
} from "../admin/NexusDemoView/useRequestDebugger";
import {
  getEstimateService,
  IEstimateProjectRange,
  IEstimateProjectRangeProperties,
} from "../../api/Estimate/EstimateService";
import {useCallback, useEffect, useState} from "react";
import {auth} from "../../database/firebase";
import useActiveProject from "../_shared/hooks/useProject/useActiveProject";
import {convertRawRangeToAppRange} from "./_shared/convert";

const getLatestEstimateWithProjectId = async (
  pid: string,
  properties?: IEstimateProjectRangeProperties
) => {
  const e = await getEstimateService();
  return e.getLatestEstimate(pid, properties);
};

const useLatestEstimate = (
  props?: IEstimateProjectRangeProperties
): {
  response: IEstimateProjectRange | undefined;
  error: any;
  status: {
    fetching: boolean;
    fetched: boolean;
  };
} => {
  const [fetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState(false);
  const {id} = useActiveProject();
  const [result, setResult] = useState<IEstimateProjectRange | undefined>(
    undefined
  );
  const [response, setResponse] = useState<IRequestHandlerResult | undefined>(
    undefined
  );

  const {fetch} = useRequestHandler({
    method: getLatestEstimateWithProjectId,
    maxTry: 1,
  });

  const attemptFetch = useCallback(async () => {
    setFetched(true);
    setFetching(true);
    return fetch(id, props).then((r: IRequestHandlerResult) => {
      setFetching(false);
      setFetched(true);
      return r;
    });
  }, [fetch, id, props]);

  useEffect(() => {
    if (!auth.currentUser) {
      console.log("useLatestEstimate - no current user");
      return;
    }
    if (id && !fetched) {
      console.log("useLatestEstimate", id, fetched, auth.currentUser);
      attemptFetch().then((r) => {
        setResponse(r);
        setResult(convertRawRangeToAppRange(r?.response));
      });
    }
  }, [auth.currentUser, id, attemptFetch, fetch, fetched]);

  return {
    response: result,
    error: response?.error?.response?.data?.details as string,
    status: {
      fetching,
      fetched,
    },
  };
};

export default useLatestEstimate;
