import {useEffect, useState} from "react";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import {getProjectProposal} from "../../../../database/firebase/api/project/pdf";
import {PDFProposal} from "@natomas-org/core";

export interface B2BProposalInfoResponse {
  data: PDFProposal.IProjectProposal | null | undefined;
  fetching: boolean;
}

const useProjectProposalInfo = (): B2BProposalInfoResponse => {
  const {id} = useActiveProject();
  const [data, setData] = useState<
    PDFProposal.IProjectProposal | null | undefined
  >();
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    // Do not do this unless we are in cost plus mode
    if (!id) {
      console.log(
        "useCostPlusProposalInfo - skipping fetch since project id is not available"
      );
      return;
    }
    setFetching(true);
    getProjectProposal(id)
      .then((res: PDFProposal.IProjectProposal | null) => {
        console.log("useCostPlusProposalInfo", res);
        if (!res) {
          setData(null);
        } else {
          setData(res ?? null);
        }
      })
      .finally(() => {
        setFetching(false);
      });
  }, [id]);

  return {data, fetching};
};
export default useProjectProposalInfo;
