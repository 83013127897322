import {
  CreateLeadSchema,
  LeadsApi,
  PatchSalesforceLeadSchema,
  UpdateLeadQuestionsSchema,
} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../setup";
import LogRocket from "logrocket";

const getPath = (): string => {
  const path = window.location.pathname;
  const replacement = path.replace(/\//g, "");
  if (replacement.length > 0) {
    return replacement;
  } else {
    return "/";
  }
};

const getCreatedFromKey = (): string => {
  return getPath();
};

export class LeadService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new LeadsApi(undefined, undefined, this.axiosInstance);
  }

  async getLeadExists() {
    try {
      // Will error with status 409 if lead exists
      await this.client.checkIfLeadExists();
      return false;
    } catch (error) {
      // @ts-ignore - Lead exists with collision check returns a 409 status code
      if (error?.response?.status === 409) {
        return true;
        // @ts-ignore - Failed authenticated
      } else if (error?.response?.status === 401) {
        LogRocket.error("LeadServiceError", error);
      }
      return false;
    }
  }

  async create(schema: CreateLeadSchema) {
    if (!schema.auth_user_id) {
      throw new Error("createLead requires auth_user_id");
    }

    const lead: CreateLeadSchema = {
      ...schema,
      lead_source: "Villa Homes Intake",
      created_from: getCreatedFromKey(),
    };
    return this.client.createLead(lead);
  }

  async update(schema: UpdateLeadQuestionsSchema) {
    return this.client.updateLeadQuestions(schema);
  }
  async updateMarketingChannel(schema: PatchSalesforceLeadSchema) {
    return this.client.updateSalesforceLead(schema);
  }
}

export const getLeadService = async () => {
  // LeadService does not require authentication to use
  const axiosInstance = await getAxiosInstance({skipAuth: false});
  return new LeadService(axiosInstance);
};
