import {
  IEstimateProjectRange,
  IEstimateProjectRangeSummary,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";
import {TableRowProps} from "../StaticTable";
import {fullPriceTextFromMicros} from "@natomas-org/core";
import {getADURows} from "./getADURows";

export const convertEstimateRangeItemToTableRow = (
  lineItem: IEstimateRangeItem
): TableRowProps<any> => {
  return {
    bucket: lineItem.bucket,
    title: lineItem.name,
    priceLow: fullPriceTextFromMicros(lineItem.price_low),
    priceHigh: fullPriceTextFromMicros(lineItem.price_high),
    costLow: fullPriceTextFromMicros(lineItem?.cost_low ?? 0),
    costHigh: fullPriceTextFromMicros(lineItem?.cost_high ?? 0),
    gpLow: fullPriceTextFromMicros(lineItem?.gross_profit_low ?? 0),
    gpHigh: fullPriceTextFromMicros(lineItem?.gross_profit_high ?? 0),
    gmLow: convertGrossMarginPercentage(lineItem?.gross_margin_low),
    gmHigh: convertGrossMarginPercentage(lineItem?.gross_margin_high),
    note: lineItem.notes ?? "",
  };
};

export const convertEstimateAduItemToTableRow = (
  adu: any,
  options?: {hideEmptyPrices: boolean; hideSelections: boolean}
): TableRowProps<any>[] => {
  return getADURows(adu, options);
};

export const convertEstimateSummaryToTableRow = (
  summary: IEstimateProjectRangeSummary,
  label: string
): TableRowProps<any> => {
  // @ts-ignore
  return {
    title: `${label} Subtotal`,
    priceLow: fullPriceTextFromMicros(summary?.total_price_low),
    priceHigh: fullPriceTextFromMicros(summary?.total_price_high),
    costLow: fullPriceTextFromMicros(summary?.total_cost_low ?? 0),
    costHigh: fullPriceTextFromMicros(summary?.total_cost_high ?? 0),
    gpLow: fullPriceTextFromMicros(summary?.total_gross_profit_low ?? 0),
    gpHigh: fullPriceTextFromMicros(summary?.total_gross_profit_high ?? 0),
    gmLow: convertGrossMarginPercentage(summary?.total_gross_margin_low),
    gmHigh: convertGrossMarginPercentage(summary?.total_gross_margin_high),
    _style: {
      fontWeight: "700",
      backgroundColor: "#EAF0E9",
    },
  };
};

const convertGrossMarginPercentage = (percentage?: number): string => {
  if (!percentage || percentage === 0) {
    return "0%";
  } else if (percentage < 1) {
    // FIXME: Dec. 2 2024 - Short term fix for gross margin percentages
    return `${percentage * 100}%`;
  } else {
    return `${percentage}%`;
  }
};

export const convertRawRangeToAppRange = (
  range: IEstimateProjectRange | undefined
): IEstimateProjectRange | undefined => {
  if (range) {
    // Get buckets as a dictionary (key = bucket_name, value = bucket object)
    const buckets = Object.entries(range?.buckets ?? {}).reduce(
      (acc, [bucketName, bucket]) => {
        const _items = bucket?.line_items ?? [];
        // Update line items with the corresponding bucket_name
        const items = _items.map((item) => ({
          ...item,
          bucket: bucketName, // Set bucket_name to each line item
        }));
        // Add the modified bucket with updated line_items to the accumulator
        // @ts-ignore
        acc[bucketName] = {...bucket, line_items: items};
        return acc;
      },
      {}
    );

    // Return the range object with the updated buckets
    return {...range, buckets};
  } else {
    return undefined;
  }
};
