import {Subtitle} from "../../../SSDDashboardView/styles/preDeposit";
import React, {useMemo} from "react";
import {Product} from "@natomas-org/core";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import {
  getLengthAndWidthString,
  getSqFtString,
} from "../../../../../_shared/_legacy/logic/ProductUtils";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {NatDropdown} from "../../../../../_shared/generics/popup/dropdown/NatDropdown";
import {
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../../_shared/generics/popup/dropdown/constants";
import {NatPopupItemText} from "../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {useDesigns} from "../../../../../_shared/hooks/useDesign/useDesigns";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../_shared/generics/label/NatLabel";
import useDesign from "../../../../../_shared/hooks/useDesign/useDesign";
import useActivitySliceDispatcher from "../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import Button from "../../../../../../0-app/2-component/Button";

export const UnitTitle = (props: {
  designId: string;
  setDesignId?: (designId: string) => void;
  cartItemId?: string;
  isProjectLocked?: boolean;
}) => {
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {designId, setDesignId} = props;
  const activeDesign = useDesign(designId);
  const {configuration} = activeDesign;
  const {product} = configuration ?? {};
  const size = useDynamicValue({
    forFour: "0.875rem",
    forEight: "1rem",
    forTwelve: "1rem",
    forSixteen: "1rem",
  });
  const {to} = useNavigation();
  const details = useMemo(() => {
    if (!product) {
      return "Select a home for your project";
    }
    return (
      product?.productDetails?.bedrooms +
      " bed | " +
      product?.productDetails?.bathrooms +
      " bath | " +
      getSqFtString(product) +
      " | " +
      getLengthAndWidthString(product)
    );
  }, [product]);

  return (
    <Subtitle fontSize={size}>
      {product && (
        <TitleDropdownSelector designId={designId} setDesignId={setDesignId} />
      )}
      <div>{details}</div>
      {!props?.isProjectLocked ? (
        <Button
          id={"design-selections-catalog-cta"}
          label={"Change home"}
          size={"small"}
          theme={"secondary"}
          containerStyle={{width: "fit-content"}}
          onClick={() => to(NavigationPaths.CATALOG, {})}
        />
      ) : (
        <Button
          id={"design-selections-catalog-cta"}
          label={"View design"}
          size={"small"}
          theme={"secondary"}
          containerStyle={{width: "fit-content"}}
          onClick={() => {
            setActiveCartItemById(props.cartItemId);
            to(NavigationPaths.STUDIO, {
              productId: product?.id,
              productGroupId: product?.productGroupId,
              cartItemId: props.cartItemId,
            });
          }}
        />
      )}
    </Subtitle>
  );
};

const TitleDropdownSelector = (props: {
  designId: string;
  setDesignId?: (designId: string) => void;
}) => {
  const {cartItemIds} = useActiveProject();
  const {setDesignId, designId} = props;
  const designSelected = useDesign(designId);
  const designs = useDesigns(cartItemIds);
  const toggleButtonText = useMemo(() => {
    return Product.getTitle(designSelected?.configuration?.product);
  }, [designSelected?.configuration?.product]);
  const contentItemArray: NatPopupItemText[] = useMemo(() => {
    const toReturn: NatPopupItemText[] = [];
    Object.values(designs)?.forEach((design) => {
      const id = design?.id;
      const label = design?.configuration?.product?.title;
      if (!id || !label || !setDesignId) {
        return;
      }
      toReturn.push({
        id: id,
        label: label,
        callback: () => {
          setDesignId(design.id);
        },
      });
    });
    return toReturn;
  }, [designs, setDesignId]);
  if (cartItemIds.length <= 1 || !setDesignId) {
    return (
      <NatLabel
        style={{margin: "0 2rem 0 0"}}
        type={NatLabelType.P2}
        weight={NatLabelWeight.MEDIUM}
        label={toggleButtonText}
      />
    );
  }
  return (
    <NatDropdown
      style={{
        TYPE: NatPopupType.HIDE,
        ADDITIONAL_STYLE_OPTIONS: [NatPopupStyleOptions.CLOSE_ON_SELECT],
      }}
      contentItemArray={contentItemArray}
      toggleButtonOrLabel={
        <NatLabel
          style={{margin: "0 0"}}
          type={NatLabelType.P3}
          weight={NatLabelWeight.MEDIUM}
          label={toggleButtonText}
        />
      }
    />
  );
};
