import React, {Fragment, useEffect, useMemo} from "react";
import {
  ImageCategory,
  IProduct,
  IProductImage,
  Product,
  ProjectPriceMode,
} from "@natomas-org/core";
//Shared
//Hooks
import {usePage} from "../../../_shared/hooks/usePage";
//Styles
import {
  PriceContainerSticky,
  PriceModel,
  PriceModelContainer,
  PriceModelTag,
  ProductDescriptionContainer,
  ProductDescriptionContainerWithStickyPriceCard,
  ProductHighlight,
  ProductInfo,
  ProductInfoContainer,
  ProductInfoTitle,
  ProductInfoTitleContainer,
  SelectionContainer,
  StickyCard,
} from "./styles";
//Icons
import {AiOutlineStar as Star} from "react-icons/ai";
//Dev
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {Helmet} from "react-helmet-async";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {scrollToTop} from "../../../_shared/navigation/_helpers";
import {InventoryUpgradeDetails} from "./components/InventoryUpgradeDetails";
import {useProductImages} from "../../../_shared/hooks/useImageCarousel/useProductImages";
import {RelatedProducts} from "../../_shared/components/RelatedProducts";
import {
  RelatedProjects,
  RelatedProjectsCategory,
} from "../../_shared/components/RelatedProjects";
import {useAddress} from "../../../_shared/hooks/useAddress";
import {priceTextFromMicros} from "../../../studio/_shared/helper";
import {
  JUST_UNIT_COST_DESCRIPTOR,
  JUST_UNIT_PRICE_DESCRIPTOR,
} from "../../_shared/components/TotalEstimateToggle/constants";
import {useSelectedFactoryLine} from "../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {useCatalogSession} from "../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {ImageCarousel} from "../../../_shared/catalog/views/product/carousel";
import {CatalogLabel} from "../../../_shared/catalog/views/product/label";
import CatalogUnitDetails from "../../../_shared/catalog/views/product/details";
import {getInventoryProductionTimelineTotal} from "../../_shared/utilities";
import styled from "styled-components";
import {useMapping} from "../../../mapping/views/UseMapping";
import {MobilePriceCard} from "../../../portal/_shared/components/AnimatedSnapBottomSheet/MobilePriceCard";
import {useCatalogProductSelectionButtons} from "../../../_shared/hooks/useCatalogProductSelectionButtons";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import useActiveDesign from "../../../_shared/hooks/useDesign/useActiveDesign";
import {ProductViewTags} from "./components/ProductTags/ProductViewTags";
import {getProductionTimelineInfo} from "../../../portal/views/ProposalView/constants/timelineHelpers";
import InteriorSection from "./components/InteriorSection";
import NatIFrameContainer from "./components/Matterport";
import {COMPANY_NAME} from "../../../_shared/strings/company-info";
import {useUrl} from "../../../_shared/hooks/useUrl";
import VCL from "@natomas-org/villa-component-library";
import {StudioDisclaimerContent} from "../../../studio/_shared/components/StudioDisclaimerModal/constant";
import {GRANITE} from "../../../_shared/colors";
import BuildingMultipleUnitsBanner from "../../../../0-app/1-container/building-multiple-units-cta";
import {useSelectedProduct} from "src/components/_shared/hooks/useProductCatalog/useSelectedProduct";

export const ProductView = (props: {
  JUST_CONTENT?: boolean;
  propertyMap?: JSX.Element;
}) => {
  const {customer} = useCurrentCustomer();
  const {loggedInUser} = useCurrentUser();
  const {catalogAddress} = useAddress();
  const product = useSelectedProduct();
  const {eligibility, priceMode} = useCatalogSession();
  const {details} = useSelectedFactoryLine();
  const {columnCount, isNatMobile} = usePage();
  const {map} = useActiveDesign();
  const {designButton, selectButton, modals} =
    useCatalogProductSelectionButtons();
  const {pathRoot} = useUrl();
  const {propertyMap} = useMapping({
    address: catalogAddress,
    displayProps: {
      readOnly: false,
      minimal: false,
      showSideBar: false,
      showSaveButton: !!loggedInUser && !!customer,
      hideDisclaimer: false,
      hideAHJ: isNatMobile,
      panningDisabled: isNatMobile,
    },
    product: product,
    unitPlacement: map?.unitPlacement,
  });

  useEffect(() => {
    if (product) {
      scrollToTop(false);
    }
  }, [product]);

  const {images, photoModal, hidePhotoModal, showSelectedImage} =
    useProductImages(product);
  const unitPriceInMicros = Product.getUnitPrice(product) as number;
  const unitCostInMicros: number = Product.getUnitCost(product) as number;
  const totalPriceCostMicros: number = useMemo(() => {
    if (priceMode === ProjectPriceMode.CostPlus) {
      return unitCostInMicros;
    } else {
      return unitPriceInMicros;
    }
  }, [priceMode, unitCostInMicros, unitPriceInMicros]);

  const pricingTag = useMemo(() => {
    if (priceMode === ProjectPriceMode.CostPlus) {
      return JUST_UNIT_COST_DESCRIPTOR;
    } else {
      return JUST_UNIT_PRICE_DESCRIPTOR;
    }
  }, [priceMode]);
  if (!product) {
    return null;
  }

  const getImagesByCategory = (category: ImageCategory) => {
    const filteredImages = images.filter(
      (image: IProductImage) => image.categories?.includes(category) ?? false
    );
    if (!product || !filteredImages.length) {
      return null;
    }
    return (
      <ImageCarousel
        hideFullScreenModal={hidePhotoModal}
        setFullScreenImage={showSelectedImage}
        images={filteredImages}
      />
    );
  };

  const hasImagesInCategory = (category: ImageCategory) => {
    if (!product) {
      return;
    }
    return Product.getImageDetailsByCategory(product, category).length > 0;
  };

  const getBodyElement = () => {
    return (
      <Fragment>
        {/*<WarningTag*/}
        {/*  show={*/}
        {/*    !Product.isInventory(product) &&*/}
        {/*    !isProductEligible(*/}
        {/*      eligibility,*/}
        {/*      product?.productGroupId,*/}
        {/*      product?.id*/}
        {/*    )*/}
        {/*  }*/}
        {/*  title={*/}
        {/*    <>*/}
        {/*      <IoWarning />*/}
        {/*      {"   "}*/}
        {/*      This product is unavailable in your area. Questions? Email us at*/}
        {/*      {"   "}*/}
        {/*      <a href={"mailto:hello@villahomes.com"}>hello@villahomes.com</a>*/}
        {/*    </>*/}
        {/*  }*/}
        {/*/>*/}
        <ProductDescriptionContainerWithStickyPriceCard
          mobile={columnCount === 4}
        >
          <ProductDescriptionContainer>
            <ProductInfoTitleContainer>
              <CatalogLabel label={product?.title} showAdminController={true} />
              {details && (
                <CatalogUnitDetails
                  details={{
                    bedroomCount: product?.productDetails?.bedrooms,
                    bathroomCount: product?.productDetails?.bathrooms,
                    factoryTimelineTotal:
                      getProductionTimelineInfo(details)?.totalMonths,
                    dimensions: Product.getLengthAndWidthString(product),
                    squareFootage: product?.productDetails?.squareFeet,
                    unitTimelineTotal: Product.isInventory(product)
                      ? getInventoryProductionTimelineTotal(product)
                      : undefined,
                  }}
                  showIcons={false}
                  showTimeline={true}
                />
              )}
              <ProductViewTags product={product} />
            </ProductInfoTitleContainer>
            <MobilePriceCard
              product={product}
              address={catalogAddress ?? undefined}
            />
            {product?.inventory_info && (
              <InventoryUpgradeDetails priceMode={priceMode} />
            )}
            {product?.highlights && (
              <ProductInfoContainer>
                <ProductInfo>
                  {product?.highlights.map((highlight: string) => {
                    return (
                      <ProductHighlight>
                        <Star /> {" " + highlight}
                      </ProductHighlight>
                    );
                  })}
                </ProductInfo>
              </ProductInfoContainer>
            )}
            <ProductInfoContainer>
              <ProductInfoTitle>Floor Plan</ProductInfoTitle>
              <ProductInfo>{product?.displayDescription}</ProductInfo>
              {getImagesByCategory(ImageCategory.FLOOR_PLAN)}
              <EmbeddedMapContainer hidden={!propertyMap}>
                {propertyMap}
              </EmbeddedMapContainer>
              <VCL.Components.Atoms.Text
                text={StudioDisclaimerContent}
                style={{color: GRANITE, cursor: "default"}}
              />
            </ProductInfoContainer>

            {(product?.exteriorDescription ||
              hasImagesInCategory(ImageCategory.EXTERIOR)) && (
              <ProductInfoContainer>
                <ProductInfoTitle>Exterior Features</ProductInfoTitle>
                <ProductInfo>{product?.exteriorDescription}</ProductInfo>
                {getImagesByCategory(ImageCategory.EXTERIOR)}
              </ProductInfoContainer>
            )}
            <InteriorSection
              description={product?.interiorDescription}
              matterportUrl={product?.matterport}
            >
              {getImagesByCategory(ImageCategory.INTERIOR)}
            </InteriorSection>
            {product.video && (
              <ProductInfoContainer>
                <ProductInfoTitle>Video Tour</ProductInfoTitle>
                <ProductInfo>Take a closer look at the details.</ProductInfo>
                <NatIFrameContainer
                  title={`${COMPANY_NAME} x Explore by Video`}
                  url={product.video}
                />
              </ProductInfoContainer>
            )}
          </ProductDescriptionContainer>
          <PriceContainerSticky>
            <StickyCard>
              <PriceModelContainer>
                <PriceModel>
                  {priceTextFromMicros(totalPriceCostMicros, "accurate")}
                </PriceModel>
                <PriceModelTag data-cy="price-card-component-label">
                  {pricingTag}
                </PriceModelTag>
              </PriceModelContainer>
              <SelectionContainer>
                {designButton}
                {selectButton}
                <BuildingMultipleUnitsBanner />
              </SelectionContainer>
            </StickyCard>
            <VCL.Components.Atoms.Disclaimer
              html={
                [
                  "This pricing is an estimate only for the cost of the home. " +
                    "Additional fees and costs for site work and other services will vary based on further inspection.",
                ] as unknown as TrustedHTML[]
              }
            />
          </PriceContainerSticky>
        </ProductDescriptionContainerWithStickyPriceCard>
        <RelatedProducts product={product} />
        <RelatedProjects
          title={`Completed Villa homes with similar square footage`}
          description={`We've built over 175 homes across California.`}
          product={product}
          findRelatedMethod={RelatedProjectsCategory.SQFT}
          includeHorizontalRule={true}
        />
        {modals}
        {photoModal}
      </Fragment>
    );
  };
  const getProductContent = (embedInPageContainer: boolean) => {
    if (embedInPageContainer) {
      return <PageContainer>{getBodyElement()}</PageContainer>;
    }
    return getBodyElement();
  };

  return (
    <div>
      <Fragment>
        <Helmet>
          <title>Villa | {product?.title ?? ""}</title>
          <meta name="description" content={product?.displayDescription} />
        </Helmet>
        <PageContainer overridePadding={pathRoot === "map"}>
          <ImageCarousel
            images={images}
            setFullScreenImage={showSelectedImage}
            hideFullScreenModal={hidePhotoModal}
          />
        </PageContainer>
      </Fragment>
      {getProductContent(!props.JUST_CONTENT)}
    </div>
  );
};

const EmbeddedMapContainer = styled.div`
  margin-top: 40px;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  display: ${(props) =>
    props.theme?.ProductViewContent?.showEmbeddedMap ? undefined : "none"};
  height: ${(props) =>
    props.theme?.Page?.columnCount === 4 ? "40vh" : "480px"};
`;
