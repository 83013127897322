import React from "react";

import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {RecommendationSubtitle, RecommendationTitle} from "./styles";
import {RecommendedProductCards} from "../../../_shared/components/RecommendedProductCards";
import {useCatalogProducts} from "../../../../_shared/hooks/useProductCatalog/useProducts";

export const ProductRecommendations = (props: {
  completeDiscovery: () => void;
}) => {
  const {completeDiscovery} = props;
  const filteredProducts = useCatalogProducts();
  const size = useDynamicValue(fullColumnWidths);

  return (
    <>
      <PageElement size={size} height={"auto"}>
        <RecommendationTitle>Your recommended products</RecommendationTitle>
      </PageElement>
      <PageElement size={size} height={"auto"}>
        <RecommendationSubtitle>
          {filteredProducts?.length} homes available
        </RecommendationSubtitle>
      </PageElement>
      <RecommendedProductCards
        completeDiscovery={completeDiscovery}
        products={filteredProducts ?? []}
      />
    </>
  );
};
