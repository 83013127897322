import React, {useCallback} from "react";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {StudioController} from "../../../_shared/components/StudioController";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {setRegistrationModalState} from "../../../../_shared/slices/SessionSlice/SessionSlice";
import {IProduct} from "@natomas-org/core";
import {useDispatch} from "react-redux";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../../../intake-form/constants";
import {ButtonProps} from "../../../../../0-app/2-component/Button/interface";

export interface ShowcaseFooterProps {
  title: string;
  canSave: boolean;
  save: any;
  product?: IProduct;
}

export const ShowcaseController = (props: ShowcaseFooterProps) => {
  const {save, canSave} = props;
  const {loggedInUser, uid} = useCurrentUser();
  const {isCustomerLoaded} = useCustomerStatus();
  const {isCartEnabled} = useActiveProject();
  const {to} = useNavigation();
  const isPublicApp = useIsPublicApp();
  const dispatch = useDispatch();

  const saveAction = useCallback(() => {
    // Only save if a customer is loaded and the user is logged in
    if (!!loggedInUser && isCustomerLoaded && !isPublicApp) {
      if (canSave) {
        save();
      } else {
        if (isCartEnabled) {
          to(NavigationPaths.CART);
        } else {
          to(NavigationPaths.PORTAL);
        }
      }
    } else {
      // If no user logged in and a public app save is attempted, present the registration modal
      dispatch(
        setRegistrationModalState({
          visible: true,
          signUp: true,
          registrationValues: {
            product: props.product,
          },
          redirectType: INTAKE_FORM_REDIRECT_TYPE.INSTANT_ESTIMATE,
        })
      );
    }
  }, [
    canSave,
    dispatch,
    isCartEnabled,
    isCustomerLoaded,
    isPublicApp,
    loggedInUser,
    props.product,
    save,
    to,
  ]);

  const attemptSave = useCallback(
    (event: any) => {
      event?.preventDefault();
      saveAction();
    },
    [saveAction]
  );

  const saveDesignButton: ButtonProps = {
    id: "showcase-next",
    onClick: attemptSave,
    theme: canSave ? "primary" : "secondary",
    label: canSave ? `Select this home` : "Continue",
    disabled: isPublicApp && !!loggedInUser,
    hidden: isPublicApp && !!loggedInUser,
  };

  return <StudioController buttons={[saveDesignButton]} hideEdit={true} />;
};
