import {setURLQueryKeyValue} from "../../../_shared/navigation/_helpers";
import React from "react";
import {AccountView} from "../../views/AccountView";
import {MdOutlineMonetizationOn} from "react-icons/md";
import {RiBuilding3Line} from "react-icons/ri";
import {InstantQuoteView} from "../../views/InstantQuoteView";
import {
  CART_PAGE_ID,
  DESIGN_PAGE_ID,
  HOME_PAGE_ID,
  INSTANT_ESTIMATE_PAGE_ID,
  OVERVIEW_ASSESSMENT_PAGE_ID,
  PROFILE_PAGE_ID,
  PROPOSAL_PAGE_ID,
  THANK_YOU_PAGE_ID,
} from "./constants";
import {SITE_WORK_PHRASE_CAPITALIZED} from "../../../_shared/constants/labels";
import {SSDDashboardView} from "../../views/SSDDashboardView/components/ANDY-components/SSDDashboardView";
import {NatHouseIcon, NatProjectIcon} from "../../../_shared/icon/icons";
import {ThankYouPage} from "../../views/SSDDashboardView/components/ANDY-components/ThankYouPage/ThankYouPage";
import CartView from "../../views/CartView";

const instantQuoteTab = {
  title: SITE_WORK_PHRASE_CAPITALIZED,
  pageId: INSTANT_ESTIMATE_PAGE_ID,
  icon: <MdOutlineMonetizationOn />,
  component: <InstantQuoteView />,
  natPage: true,
};

const homeTab = {
  title: "Dashboard",
  pageId: HOME_PAGE_ID,
  icon: <NatProjectIcon />,
  component: <SSDDashboardView />,
};

export const TABS = {
  [HOME_PAGE_ID]: homeTab,
  [OVERVIEW_ASSESSMENT_PAGE_ID]: instantQuoteTab,
  [DESIGN_PAGE_ID]: instantQuoteTab,
  [PROPOSAL_PAGE_ID]: homeTab,
  [INSTANT_ESTIMATE_PAGE_ID]: instantQuoteTab,
  [PROFILE_PAGE_ID]: {
    title: "Profile",
    pageId: PROFILE_PAGE_ID,
    component: <AccountView />,
  },
  [THANK_YOU_PAGE_ID]: {
    title: "Start Project",
    pageId: THANK_YOU_PAGE_ID,
    icon: <RiBuilding3Line />,
    component: <ThankYouPage />,
    natPage: true,
  },
  [CART_PAGE_ID]: {
    title: "Cart",
    pageId: CART_PAGE_ID,
    icon: <NatHouseIcon />,
    component: <CartView />,
    natPage: true,
  },
};

export const getTitleForPageId = (pageId) => {
  if (pageId) return TABS[pageId]?.title;
  else return TABS[HOME_PAGE_ID].title;
};

export const getPageComponent = (pageId) => {
  if (pageId) return TABS[pageId]?.component;
  else return TABS[HOME_PAGE_ID].component;
};

export const toPage = (pageId) => {
  setURLQueryKeyValue("page", pageId);
};
