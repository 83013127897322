export const CART_PAGE_ID = "cart";
export const DESIGN_PAGE_ID = "design";
export const HOME_PAGE_ID = "home";
export const INSTANT_ESTIMATE_PAGE_ID = "estimate";
export const OVERVIEW_ASSESSMENT_PAGE_ID = "assessment";
export const PRESENTATION_PAGE_ID = "presentation";
export const PROFILE_PAGE_ID = "profile";
export const PROPOSAL_PAGE_ID = "proposal";
export const SITE_PAGE_ID = "site";
export const THANK_YOU_PAGE_ID = "thankyou";
export const UNIT_PAGE_ID = "unit";
