import {FilterDropdownDisplayType} from "../../../../_shared/catalog/filter/types";
import React, {useMemo} from "react";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {GRANITE, VILLA_FOREST_GREEN} from "../../../../_shared/colors";
import {IconPosition} from "../../../../_shared/generics/_shared";
import {store} from "../../../../../store";
import {setShowFiltersModal} from "../../../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {FilterInlineDropdown} from "../../../../_shared/generics/popup/dropdown/specializedDropdowns/filter/FilterInlineDropdown";
import {FiltersContainer} from "./styles";
import styled from "styled-components";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {DropdownButton} from "../../../../_shared/generics/popup/dropdown/components/DropdownButton";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {BsFilter} from "react-icons/bs";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";

const MobileFiltersComponent = () => {
  const {showFiltersModal} = useCatalogSession();
  const {filters, adminCatalogMode} = useCatalogSession();
  const label = useMemo(() => {
    if (filters?.length > 0) {
      return (
        <span>
          Filters
          <span
            style={{color: VILLA_FOREST_GREEN, fontFamily: FONT_FAMILY_BOLD}}
          >{` (${filters.length})`}</span>
        </span>
      );
    }
    return "Filters";
  }, [filters.length]);
  return (
    <DropdownButton
      show={showFiltersModal}
      contentItemArray={[]}
      icon={{icon: <BsFilter />, iconPosition: IconPosition.LEFT}}
      toggleButtonOrLabel={label}
      setShow={() => {
        store.dispatch(setShowFiltersModal(true));
      }}
      style={
        filters?.length > 0 || adminCatalogMode
          ? NatDropdownStyles[DropdownStyles.SORT_AND_FILTER_ACTIVE].SMALL
          : NatDropdownStyles[DropdownStyles.SORT_AND_FILTER].SMALL
      }
    />
  );
};

export const FiltersRow = React.memo(
  (props: {
    filterTypes: FilterDropdownDisplayType[];
    filtersCountToDisplay?: number;
    rightAlignLastFilter?: boolean;
    spaceBetweenFiltersRem?: string;
  }) => {
    const {isNatMobile} = usePage();
    const {getURLPath} = useUrl();
    let filtersCountToDisplay = useDynamicValue({
      forFour: 0,
      forEight: 2,
      forTwelve: 4,
      forSixteen: 4,
    });
    if (props.filtersCountToDisplay != null) {
      filtersCountToDisplay = props.filtersCountToDisplay;
    }
    const combinedFilters: FilterDropdownDisplayType[] | null = useMemo(() => {
      if (filtersCountToDisplay < props.filterTypes?.length) {
        return props.filterTypes.slice(filtersCountToDisplay);
      }
      return null;
    }, [filtersCountToDisplay, props.filterTypes]);
    const FiltersComponent = () => {
      if (isNatMobile) {
        return <MobileFiltersComponent />;
      }
      return (
        <FiltersContainer>
          {props.filterTypes
            ?.slice(0, filtersCountToDisplay)
            ?.map((filter, idx) => {
              return (
                <FilterInlineDropdown
                  key={`${filter}-${idx}`}
                  filterDisplayTypes={[filter]}
                />
              );
            })}
          {combinedFilters && (
            <FilterInlineDropdown
              filterDisplayTypes={combinedFilters}
              title={filtersCountToDisplay === 0 ? "Filters" : undefined}
              rightAlign={props.rightAlignLastFilter}
            />
          )}
        </FiltersContainer>
      );
    };
    return (
      <div style={{display: "flex", height: "inherit", width: "100%"}}>
        <SortFilterDivider />
        <FiltersComponent />
      </div>
    );
  }
);

const SortFilterDivider = styled.div`
  font-size: 1.5rem;
  color: ${GRANITE};
  padding-right: 0;
  border-left: 1px solid ${GRANITE};
  height: inherit;
  color: ${GRANITE};
  padding-left: ${(props) =>
    props.theme?.Filters?.gapBetweenFilters ?? "0.5rem"};
  display: ${(props) =>
    !props.theme?.Filters?.showVerticalLine ? "none" : undefined};
`;
