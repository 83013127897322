import {createSlice} from "@reduxjs/toolkit";
import {
  ICustomerInfo,
  resetFetchesForUser,
  STRIPE_PHASE_1_COMPLEX_PRICE_ID,
  STRIPE_PHASE_1_COMPLEX_PRICE_ID_TEST,
} from "@natomas-org/core";
import {IGlobalSlice} from "./types/Store";
import {getStripeAssessmentFeeProduct, getStripePhase1Product} from "../stripe";
import {isDev, isProd} from "../application";

export enum PricingMode {
  UNIT,
  BASE,
}

let overrideConfig: any = {};

export interface IGlobalConfig {}

const CONFIG_CONSTANTS = {
  // App
  IS_CUSTOMER_DOWN_FOR_MAINTENANCE: false,

  // Sign Up
  submit_button_title: "Start Project",
  submit_page_title: "Let's begin your project!",
  submit_password_reveal_enabled: true,

  // Generic
  IS_REFERRAL_ENABLED: true,
  IS_PROJECT_SWITCHING_LIVE: true,
  IS_WATCH_WEBINAR_LIVE: true,
  SHOW_FAQ_EVERYWHERE: true,
  phase1PriceSKU: getStripePhase1Product(),
  phase1PriceSKUComplex: isProd
    ? STRIPE_PHASE_1_COMPLEX_PRICE_ID
    : STRIPE_PHASE_1_COMPLEX_PRICE_ID_TEST,
  assessmentPriceSKU: getStripeAssessmentFeeProduct(),
  SHOW_MARKETING_CHANNEL_SURVEY: true,

  // SSD
  WELCOME_VIDEO_URL: "https://vimeo.com/664004858",
  WELCOME_INTERCOM_CHAT_ENABLED: true,
  IS_INTERCOM_LIVE: false,
  IS_NEXUS_LIVE: false,
  MODEL_HOME_TOUR_ENABLED: true,
  SHOW_POST_SSD_SURVEY: true,
  PRICING_MODE: PricingMode.UNIT,
  INVENTORY_PROGRAM: {
    IS_LIVE: true,
    DELIVERY_MONTHS: 2,
  },

  // HCP
  HCP_PRESENTATION_LIVE: true,

  // Versioning
  ALLOW_CATALOG_MIGRATION: false,

  // Routing
  USE_FACTORY_ROUTING: isDev ?? false,

  // Catalog
  ALL_IN_PRICING_ON_CATALOG_ENABLED: true,

  // Docusign
  IS_DOCUSIGN_LIVE: false,
};

const getInitialState = (): IGlobalSlice => {
  return {
    app_version: null,
    firebaseInitialized: false,
    url: window.location.href,
    user: null,
    userInitialized: null,
    users: {},
    images: {},
    imagesFetched: {},
    config: CONFIG_CONSTANTS,
    windowWidth: 0,
    windowHeight: 0,
  };
};

const globalSlice = createSlice({
  name: "global",
  initialState: getInitialState(),
  reducers: {
    resetUser: (state) => {
      resetFetchesForUser(state.user?.uid ?? null);
      state.users[state.user?.uid ?? ""] = null;
      state.user = null;
      state.userInitialized = false;
    },
    initializeFirebase: (state) => {
      state.firebaseInitialized = true;
    },
    updateURL: (state, action) => {
      state.url = action.payload;
    },
    currentUser: (state, action) => {
      state.user = action.payload;
      state.userInitialized = !!action.payload;
    },
    userLoaded: (state, action) => {
      const payload: {key: string, customer_info: ICustomerInfo | null} = action.payload;
      state.users[payload.key] = payload.customer_info;
    },
    imageDataLoaded: (state, action) => {
      const image = action.payload;
      state.images[image.id] = image;
    },
    enrichConfig: (state, action) => {
      const remoteConfig = action.payload;

      // If the override config is override the remoteConfig values
      const enrichedConfig = Object.assign({}, remoteConfig, overrideConfig);

      Object.keys(enrichedConfig).forEach((key) => {
        state.config[key] = enrichedConfig[key];
      });
    },
    setWidth: (state, action) => {
      state.windowWidth = action.payload;
    },
    setHeight: (state, action) => {
      state.windowHeight = action.payload;
    },
    setAppVersion: (state, action) => {
      state.app_version = action.payload;
    },
  },
});

export const globalReducer = globalSlice.reducer;
export const {
  setAppVersion,
  resetUser,
  currentUser,
  imageDataLoaded,
  initializeFirebase,
  updateURL,
  enrichConfig,
  userLoaded,
  setHeight,
  setWidth,
} = globalSlice.actions;
