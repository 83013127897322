import React, {useEffect, useMemo, useRef} from "react";
import {Address, Area, IAddressDetails, IAreaDetails} from "@natomas-org/core";
import {CheckMark} from "../CheckMark";
import {
  Description,
  ResolutionDescription,
  StyledAddressInput,
  StyledNatAddressInput,
} from "./styles";
import {AiFillCheckCircle, AiFillExclamationCircle} from "react-icons/ai";
import {VILLA_WARNING_YELLOW} from "../../../map/constants";
import {usePage} from "../../hooks/usePage";
import {NatErrorCircle} from "../../icon/icons";
import {useDeepEffect} from "../../hooks/useDeepEffect";
import {ERROR_COLOR, VILLA_APPLE_GREEN} from "../../colors";
import styled from "styled-components";

export const NatFormErrorMessage = styled.div`
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${ERROR_COLOR};
  gap: 4px;
`;

let autoComplete: {
  addListener: (arg0: string, arg1: () => void) => void;
  getPlace: () => any;
};

//handle when the script is loaded we will assign autoCompleteRef with google maps autocomplete
//were passing in setQuery as updateQuery, the ref of the input and passing in dispatch function, also passing setAddressError so if incomplete address is sent, we can display error message
//passing in nextStep to handle the next step when somone chooses a verified address
function handleScriptLoad(
  autoCompleteRef: React.MutableRefObject<null>,
  setAddress: (address: string) => void,
  setAddressObject: (addressObj: IAddressDetails | null) => void
) {
  //assign autoComplete with google maps place one time
  // @ts-ignore
  autoComplete = new window.google.maps.places.Autocomplete(
    //set the type of data we want and the fields associated with that type
    // @ts-ignore
    autoCompleteRef.current,
    {
      types: ["address"],
      componentRestrictions: {country: "us"},
    }
  );

  //Everytime user add/remove a character, call handlePlaceSelect to update predictions
  autoComplete.addListener("place_changed", () => {
    //when looking for address, set to false
    /*  setVerifiedAddress(false) */

    //continues to pass on setQuery function and dispatch function and setAddressError to finally use them in the next function
    handlePlaceSelect(setAddress, setAddressObject);
  });
}

function handlePlaceSelect(setAddress: any, setAddressObject: any) {
  //when user selects a prediction, grab the data from that selection and save it
  const addressObject = autoComplete.getPlace();
  let address: IAddressDetails | null =
    Address.autoCompleteToPropertyAddressDetails(addressObject);
  if (!address) {
    const areaDetails: IAreaDetails | null =
      Area.autoCompleteToAreaDetails(addressObject);
    if (areaDetails) {
      address = areaDetails.address;
    }
  }
  setAddressObject(address);
  setAddress(Address.getFullAddress(address ?? Address.dataToAddress()));
}

export interface AddressInputProps {
  setAddressObject: (addressObj: IAddressDetails | null) => void;
  setAddress: any;
  placeholder: string;
  value: IAddressDetails;
  formikStyle?: boolean;
  onBlur?: (e: React.FocusEvent<any>) => void;
}

export const AddressInput = (props: AddressInputProps) => {
  // Touched means a place has been clicked for AddressInput
  const [touched, setTouched] = React.useState(false);
  const autoCompleteRef = useRef(null);
  const {isNatMobile} = usePage();
  const verifiedAddress = useMemo(() => {
    return !Address.isInvalidAddress(props.value);
  }, [props.value]);

  const verifiedIcon = (
    <ResolutionDescription id={"verified-icon"} status={"valid"}>
      <CheckMark size={18} />
      <p>Valid address.</p>
    </ResolutionDescription>
  );

  const errorIcon = (
    <ResolutionDescription status={"error"}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/intake-forms%2Ficons%2FAlert.svg?alt=media&token=0ccb15d9-7f9b-4d8d-9791-44e17df3af2f"
        alt="error icon"
      />
      <p>Invalid address.</p>
    </ResolutionDescription>
  );

  useEffect(() => {
    handleScriptLoad(autoCompleteRef, props.setAddress, props.setAddressObject);
    props.setAddress(Address.getFullAddress(props.value));
  }, []);

  const getValue = () => {
    if (!props?.value) {
      return "";
    } else {
      return isNatMobile && !Address.isInvalidAddress(props.value)
        ? Address.getStreetAndCity(props.value)
        : Address.getFullAddress(props.value);
    }
  };

  useDeepEffect(() => {
    if (props.value && autoCompleteRef.current) {
      // @ts-ignore
      const input = autoCompleteRef.current as HTMLInputElement;
      input.value = getValue();
    }
  }, [props.value]);

  if (props.formikStyle) {
    const validationIconNoText = (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: "100%",
          alignItems: "center",
          paddingRight: "6px",
          pointerEvents: "none",
        }}
      >
        {verifiedAddress ? (
          <AiFillCheckCircle size={"24px"} color={VILLA_APPLE_GREEN} />
        ) : (
          touched && (
            <>
              <AiFillExclamationCircle
                size={"24px"}
                color={VILLA_WARNING_YELLOW}
              />
            </>
          )
        )}
      </div>
    );

    return (
      <div
        style={{
          display: "flex",
          alignContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{display: "grid"}}>
          <div style={{gridColumn: "1", gridRow: "1"}}>
            <StyledNatAddressInput
              name={"search"}
              type={"search"}
              ref={autoCompleteRef}
              autoComplete={"do-not-autofill"}
              spellCheck={false}
              placeholder={props.placeholder}
              defaultValue={getValue()}
              id="input1"
              onBlur={(e) => {
                !touched && setTouched(true);
                props.onBlur && props.onBlur(e);
              }}
              autoFocus={false}
              className={"formik-input"}
              hasError={!verifiedAddress && touched}
            />
          </div>
          <div style={{gridColumn: "1", gridRow: "1", pointerEvents: "none"}}>
            {validationIconNoText}
          </div>
        </div>
        {!verifiedAddress && touched && (
          <div
            style={{
              position: "relative",
              flexGrow: 1,
            }}
          >
            <NatFormErrorMessage style={{position: "absolute", width: "100%"}}>
              <NatErrorCircle />
              Invalid address
            </NatFormErrorMessage>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <StyledAddressInput
        autoComplete={"chrome-off"}
        name={"search"}
        type={"search"}
        ref={autoCompleteRef}
        spellCheck={false}
        placeholder={props.placeholder}
        defaultValue={getValue()}
        id="input1"
        autoFocus={true}
        hasError={!verifiedAddress && touched}
        className={"formik"}
      />
      <Description show={verifiedAddress}>{verifiedIcon}</Description>
      <Description show={!verifiedAddress}>{errorIcon}</Description>
    </>
  );
};
