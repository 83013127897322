import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import React, {useCallback, useMemo} from "react";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {
  CallToActionSection,
  CallToActionSectionProps,
} from "../../SSDDashboardView/components/ANDY-components/CallToActionSection";
import {Project, TeamMemberRole} from "@natomas-org/core";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {PageElementWrapper} from "../../../../_shared/generics/page/components/PageElementWrapper";
import {determineCTAContent} from "./logic";
import {useCalendly} from "../../../../_shared/calendly/useCalendly";
import {fetchAndSaveCalendlyEvent} from "../../../../../database/firebase/api/event";
import {PORTAL_GREETING_ID} from "../HCPTitleNav/navigation_ids";
import {TeamMemberCard} from "../../../_shared/components/TeamContactCard/TeamMemberCard";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useActiveProjectDocuments} from "../../../../_shared/hooks/useProject/useActiveProjectDocuments";
import useLatestEstimate from "../../../../estimate/useLatestEstimate";

export const HCPGreeting = () => {
  const {to} = useNavigation();

  const CTAWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 8,
    forSixteen: 8,
  });
  const teamMemberWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 4,
    forSixteen: 4,
  });
  const {customer} = useCurrentCustomer();
  const {info, site, id, budgetHidden} = useActiveProject();
  const {response} = useLatestEstimate();
  const budgetNotAvailable = budgetHidden ? true : !response;
  console.log("greeting", budgetNotAvailable, response);
  const {configuration, snapshot} = useActiveDesign();
  const globalConfig = useGlobalConfiguration();
  const {documentDictionary} = useActiveProjectDocuments();
  const {isNatMobile} = usePage();
  const teamMember = Project.getTeamMemberForRole(info, TeamMemberRole.HC);
  const generalMeetingLink = Project.getTeamMemberLink(info, "general-meeting");
  const kickOffMeetingLink = Project.getTeamMemberLink(info, "kick-off");

  const {setCalendlyEventInfo, calendlyWidget} = useCalendly({
    projectId: id,
  });

  const launchCalendly = useCallback(
    (url: string | null, eventId: string) => {
      if (url) {
        setCalendlyEventInfo({
          url: url,
          eventScheduled: (e, eventURI, inviteeURI, originalCalendlyLink) => {
            return fetchAndSaveCalendlyEvent(
              eventId,
              eventURI,
              id,
              originalCalendlyLink
            );
          },
        });
      }
    },
    [id, setCalendlyEventInfo]
  );

  const launchKickOffCalendly = useCallback(() => {
    launchCalendly(kickOffMeetingLink, "kick-off");
  }, [kickOffMeetingLink, launchCalendly]);

  const greeting = useMemo((): CallToActionSectionProps => {
    return determineCTAContent(
      customer,
      info,
      configuration,
      snapshot,
      launchKickOffCalendly,
      site,
      to,
      globalConfig,
      isNatMobile,
      budgetNotAvailable,
      documentDictionary
    );
  }, [
    customer,
    configuration,
    snapshot,
    launchKickOffCalendly,
    site,
    to,
    globalConfig,
    isNatMobile,
    budgetNotAvailable,
    documentDictionary,
  ]);

  const shouldLookMobile = useDynamicValue({
    forFour: true,
    forEight: false,
    forTwelve: false,
    forSixteen: false,
  });

  return (
    <PageContainer>
      <div id={PORTAL_GREETING_ID} />
      <PageElementWrapper justifyContent={"space-between"}>
        <PageElement size={CTAWidth} height={"auto"}>
          <CallToActionSection {...greeting} orientation={"left"} />
        </PageElement>
        <PageElement size={teamMemberWidth} height={"auto"}>
          <TeamMemberCard
            layout={"full-details"}
            shouldLookMobile={shouldLookMobile}
          />
        </PageElement>
      </PageElementWrapper>
      {calendlyWidget}
    </PageContainer>
  );
};
