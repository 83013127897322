import React, {useEffect} from "react";
import styled from "styled-components";
import {HEADER_Z_INDEX} from "../../../../components/_shared/styles";

export const MenuBanner = (props: {
  children: React.ReactNode;
  show: boolean;
  top: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  // If a scroll event occurs, the banner will be hidden
  // This is to prevent the banner from being visible when the user scrolls
  useEffect(() => {
    // Hide the banner when the user scrolls
    const handleScroll = () => {
      props.onMouseLeave();
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [props.onMouseLeave]);

  const {children, ...rest} = props;
  return <Container {...rest}>{props.children}</Container>;
};

const Container = styled.div<{show: boolean; top: number}>`
  display: flex;
  width: 100%;
  position: fixed;
  top: ${(props) => (props.show ? props.top : -100)}px;
  left: 0;
  right: 0;
  opacity: ${({show}) => (show ? "1" : "0")};
  pointer-events: ${({show}) => (show ? "all" : "none")};
  transition: opacity 200ms cubic-bezier(0.85, 0.5, 0.85, 0.5), top 200ms ease;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: white; // #f7f5f2;
  z-index: ${HEADER_Z_INDEX - 100}; // Ensure it sits above other content
  cursor: pointer;
  padding-top: 100px;
`;

export default MenuBanner;
