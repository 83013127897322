import React from "react";
import {
  AccountInfoText,
  FullScreenCenteredContent,
  LandingText,
  LogoutButtonContainer,
} from "../styles";
import {AdminViewCard, AdminViewCardSize} from "../_shared/AdminViewCard";
import {NatButton} from "../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../_shared/generics/_shared";
import {store} from "../../../../store";
import {resetConfiguration} from "../../../_shared/slices/ConfigurationSlice";
import {AdminHelpIcon} from "../../_shared/components/AdminHelpIcon";
import {DOC_LINK_ROOT} from "../../_shared/constants/links";
import {BLACK} from "../../../_shared/colors";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {usePermissions} from "../../../_shared/hooks/usePermissions/usePermissions";
import {getWelcomeMessage} from "./_helper";
import {
  NatCompletedProjectsIcon,
  NatCustomerDatabaseIcon,
  NatDataIcon,
  NatDeveloper,
  NatEmployeeDatabaseIcon,
  NatHouseCity,
  NatInventoryDatabaseIcon,
  NatMapFeatureIcon,
  NatProductDatabaseIcon,
} from "../../../_shared/icon/icons";
import {AdminCardContainer} from "./styles";
import {useHistory} from "react-router-dom";
import {ADMIN_PRODUCT_PATH} from "../../../../paths";
import {logOut} from "../../../../database/firebase";

export const AdminLandingView = (props: {contentElement?: boolean}) => {
  const {to} = useNavigation();
  const {loggedInUser} = useCurrentUser();
  const {isAdmin} = useCurrentUser();
  const {isFetching, access, anyAccess, fullAccess} = usePermissions();
  const history = useHistory();
  if (!isAdmin) {
    return null;
  }

  const MainContent = () => {
    return (
      <AdminCardContainer>
        {access?.productLines && (
          <AdminViewCard
            title={"Factories"}
            icon={<NatProductDatabaseIcon />}
            clickEvent={() => to(NavigationPaths.ADMIN_PRODUCTS)}
            size={AdminViewCardSize.NORMAL}
          />
        )}
        {access?.productLines && (
          <AdminViewCard
            title={"Routing"}
            icon={<NatMapFeatureIcon />}
            clickEvent={() => to(NavigationPaths.ADMIN_PRODUCT_ROUTING)}
            size={AdminViewCardSize.NORMAL}
          />
        )}
        <AdminViewCard
          title={"Inventory"}
          icon={<NatInventoryDatabaseIcon />}
          clickEvent={() => to(NavigationPaths.ADMIN_INVENTORY)}
          size={AdminViewCardSize.NORMAL}
        />
        {access?.customers && (
          <AdminViewCard
            title={"Customers"}
            icon={<NatCustomerDatabaseIcon />}
            clickEvent={() => {
              to(NavigationPaths.ADMIN_CUSTOMER_DATABASE);
              store.dispatch(resetConfiguration());
            }}
            size={AdminViewCardSize.NORMAL}
          />
        )}
        <AdminViewCard
          title={"Completed Projects"}
          icon={<NatCompletedProjectsIcon />}
          clickEvent={() => to(NavigationPaths.ADMIN_COMPLETED_PROJECTS)}
          size={AdminViewCardSize.NORMAL}
        />
        {fullAccess && (
          <AdminViewCard
            title={"Employees"}
            icon={<NatEmployeeDatabaseIcon />}
            clickEvent={() => to(NavigationPaths.ADMIN_EMPLOYEE_MANAGEMENT)}
            size={AdminViewCardSize.NORMAL}
          />
        )}
        {access?.customers && (
          <AdminViewCard
            title={"Data"}
            icon={<NatDataIcon />}
            clickEvent={() => to(NavigationPaths.ADMIN_DATA)}
            size={AdminViewCardSize.NORMAL}
          />
        )}
        {access?.developer && (
          <AdminViewCard
            title={"Developer"}
            icon={<NatDeveloper />}
            clickEvent={() => to(NavigationPaths.DEVELOPER)}
            size={AdminViewCardSize.NORMAL}
          />
        )}
        {access?.developer && (
          <AdminViewCard
            title={"Product"}
            icon={<NatHouseCity />}
            clickEvent={() => history.push(ADMIN_PRODUCT_PATH)}
            size={AdminViewCardSize.NORMAL}
          />
        )}
      </AdminCardContainer>
    );
  };

  if (props.contentElement) {
    return (
      <div style={{padding: "4rem 0"}}>
        <LandingText>
          {getWelcomeMessage(loggedInUser, isFetching, anyAccess, fullAccess)}
        </LandingText>
        <MainContent />
      </div>
    );
  }
  return (
    <>
      <FullScreenCenteredContent>
        <LandingText>
          {getWelcomeMessage(loggedInUser, isFetching, anyAccess, fullAccess)}
        </LandingText>
        <MainContent />
      </FullScreenCenteredContent>
      <LogoutButtonContainer>
        <NatButton
          size={NatSize.SMALL}
          label={"Logout"}
          type={"button"}
          option={StyleOption.PRIMARY_ALT}
          clickEvent={() => logOut().then(() => to(NavigationPaths.LOGIN))}
        />
        <AccountInfoText>{loggedInUser?.email}</AccountInfoText>
        <AdminHelpIcon link={DOC_LINK_ROOT} color={BLACK} size={30} />
      </LogoutButtonContainer>
    </>
  );
};
