import {
  getTextFromPriceMicros,
  IConfiguration,
  IProjectInfo,
  Product,
  UpdateProjectInfoRequest,
} from "@natomas-org/core";
import {
  CART_ENABLED_KEY,
  LOCK_DESIGN_KEY,
  PRIMARY_CONTACT_EMAIL_KEY,
  PROJECT_STAGE_KEY,
  UNIT_PRICING_KEY,
} from "./project_model";
import {updateProjectInfoAction} from "../../../../../../../database/firebase/api/project";

export const getInitialControlPanelTabValues = (
  configuration: IConfiguration | null | undefined,
  projectSummary: IProjectInfo | null | undefined,
  defaultValues: any
) => {
  let values = defaultValues;
  values[UNIT_PRICING_KEY] = getTextFromPriceMicros(
    Product.getUnitPrice(configuration?.product, {
      excludeInventoryUpgrades: true,
    })
  );
  values[LOCK_DESIGN_KEY] =
    projectSummary?.settings?.design_lock?.is_locked ?? false;
  values[CART_ENABLED_KEY] = projectSummary?.settings?.cart_enabled ?? false;
  values[PRIMARY_CONTACT_EMAIL_KEY] =
    projectSummary?.metadata.assignedPrimaryTeamContactEmail ?? undefined;
  return values;
};

export const saveProjectChanges = (
  projectSummary: IProjectInfo | null | undefined,
  newValues: any
) => {
  if (!projectSummary?.id) {
    return;
  }
  const request: UpdateProjectInfoRequest = {projectId: projectSummary?.id};
  if (
    typeof newValues[CART_ENABLED_KEY] === "boolean" &&
    projectSummary?.settings?.cart_enabled !== newValues[CART_ENABLED_KEY]
  ) {
    request.cartEnabled = newValues[CART_ENABLED_KEY];
  }

  console.log("Lock value", newValues[LOCK_DESIGN_KEY]);
  console.log("Lock type", typeof newValues[LOCK_DESIGN_KEY]);

  if (typeof newValues[LOCK_DESIGN_KEY] === "boolean") {
    request.designLocked = newValues[LOCK_DESIGN_KEY] ?? null;
  }

  const primaryContactEmail = newValues[PRIMARY_CONTACT_EMAIL_KEY];
  if (
    typeof primaryContactEmail === "string" &&
    projectSummary?.metadata.assignedPrimaryTeamContactEmail !==
      primaryContactEmail
  ) {
    request.primaryContactEmail = primaryContactEmail;
  }

  const projectStage = newValues[PROJECT_STAGE_KEY];
  if (projectSummary?.status_details.stage !== projectStage && projectStage) {
    request.stage = projectStage;
  }

  if (Object.keys(request).length > 1) {
    // > 1 because projectId is always included
    return updateProjectInfoAction(request);
  }
  return;
};
