import styled from "styled-components";
import {ButtonSize, ButtonTheme} from "./interface";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: inherit;
  row-gap: 8px;
`;

const Button = styled.button<{
  theme?: ButtonTheme;
  size?: ButtonSize;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-feature-settings: "liga" off, "clig" off;
  /* body/body-md/medium */
  font-family: "Inter-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: 0;
  outline: 0;
  border-radius: 6px;
  width: 100%;
  white-space: nowrap;
  ${(props) => {
    switch (props.theme) {
      case "danger":
        return `
          outline: 0;
          background-color: #FF0000;
          color: #FFFFFF;
          `;
      case "secondary":
        return `
          outline: 1px solid #ADB5BD;
          background: rgba(255, 255, 255, 0);
          color: #04171B;
        `;
      case "primary":
      default:
        return `
          outline: 0;
          background-color: #0080A7;
          color: #FFFFFF;
        `;
    }
  }}
  ${(props) => {
    switch (props.size) {
      case "small":
        return `
          padding: 8px 16px;
        `;
      case "large":
        return `
          padding: 16px 32px;
          `;
      case "medium":
      default:
        return `
          padding: 12px 24px;
        `;
    }
  }}
  &:hover {
    ${(props) => {
      switch (props.theme) {
        case "danger":
          return `
          background-color: #C92A2A;
        `;
        case "secondary":
          return `
          background: #F1F3F5;
        `;
        case "primary":
        default:
          return `
          background-color: #0B617F;
        `;
      }
    }}
  }

  // Disabled Override
  &:disabled {
    outline: 1px solid #adb5bd;
    opacity: 0.9;
    background: #f8f9fa;
    color: #868e96;
    cursor: not-allowed;
  }

  // Focus Override - Accessibility
  &:focus {
    outline: 2px solid #000;
    box-shadow: none;
  }

  // Transition
  transition: color 175ms, font-size 175ms, background-color 175ms;
`;

const Error = styled.p`
  color: orangered;
  font-size: 12px;
  font-family: Inter-Regular, serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export {Container, Button, Error};
