import React, {useMemo, useState} from "react";
import {FilterDropdownDisplayType} from "../../../../../catalog/filter/types";
import {
  NatPopupItemElement,
  NatPopupItemText,
} from "../../components/NatPopup/NatPopup";
import {PriceSlider} from "../../../../../../catalog/_shared/components/Filters/PriceFilterSlider";
import {NatLink} from "../../../../button";
import {NatSize, NatSizeType, StyleOption} from "../../../../_shared";
import {SquareFootageFilterSlider} from "../../../../../../catalog/_shared/components/Filters/SquareFootageFilterSlider";
import {PriceAndFitFilters} from "../../../../../../catalog/_shared/components/Filters/PriceAndFitFilters";
import {
  FilterModalTitleContainer,
  FilterModalTitleWrapper,
  IndividualFilterTitle,
} from "../../../../../../catalog/_shared/components/CatalogBar/styles";
import {
  FilterDisplayAttributes,
  IFilterDisplayAttributes,
  resetFiltersByDisplayType,
} from "./constants";
import {BedAndBathFilterContent} from "../../../../../../catalog/_shared/components/Filters/BedAndBathFilterContent/BedAndBathFilterContent";
import {useDropdownFiltersState} from "../../../../../hooks/useProductCatalog/useCatalogSession/useCatalogFilters";
import {usePage} from "../../../../../hooks/usePage";
import {useCatalogProducts} from "../../../../../hooks/useProductCatalog/useProducts";
import {useCatalogFilteredProducts} from "../../../../../hooks/useProductCatalog/useCatalogSession/useCatalogFilteredProducts";
import {useNatDropdown} from "../../NatDropdown";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../constants";
import {useCatalogSession} from "../../../../../hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {setShowFiltersModal} from "../../../../../slices/CatalogSlice/CatalogSessionSlice";
import {store} from "../../../../../../../store";
import {IoMdClose} from "react-icons/io";
import {NatomasFilterModalClose} from "../../../../modal/styles";
import Button from "../../../../../../../0-app/2-component/Button";

const filterDisplayStyle: React.CSSProperties = {
  borderTop: 0,
  width: "100%",
  pointerEvents: "all",
  display: "inherit",
  flexDirection: "inherit",
  gap: "inherit",
};

const useFilterInlineDropdown = (props: {
  filterDisplayTypes: FilterDropdownDisplayType[];
  title?: string;
  rightAlign?: boolean;
  showCloseButton?: boolean;
}) => {
  const filteredProducts = useCatalogFilteredProducts(useCatalogProducts());
  const {isNatMobile} = usePage();
  const {showFiltersModal} = useCatalogSession();
  const dropdownFiltersState = useDropdownFiltersState();
  const [show, setShow] = useState(false);
  const contentArray: (NatPopupItemText | NatPopupItemElement)[] =
    useMemo(() => {
      const contentArray: (NatPopupItemText | NatPopupItemElement)[] = [];
      props.filterDisplayTypes?.forEach((filterDisplayType) => {
        let contentElement = <></>;
        switch (filterDisplayType) {
          case FilterDropdownDisplayType.Toggles:
            contentElement = <PriceAndFitFilters show={show || isNatMobile} />;
            break;
          case FilterDropdownDisplayType.SquareFoot:
            contentElement = <SquareFootageFilterSlider />;
            break;
          case FilterDropdownDisplayType.UnitPrice:
            contentElement = <PriceSlider />;
            break;
          case FilterDropdownDisplayType.BedAndBath:
            contentElement = <BedAndBathFilterContent />;
            break;
          default:
            break;
        }
        if (
          props.filterDisplayTypes?.length > 1 &&
          filterDisplayType !== FilterDropdownDisplayType.BedAndBath
        ) {
          contentElement = (
            <div
              style={
                isNatMobile
                  ? {padding: "0.5rem 0.75rem", width: "100%"}
                  : {padding: "1rem 1rem", width: "100%"}
              }
            >
              <IndividualFilterTitle>
                {FilterDisplayAttributes[filterDisplayType]?.contentTitle}
              </IndividualFilterTitle>
              <>{contentElement}</>
            </div>
          );
        } else {
          contentElement = (
            <div style={{padding: "1rem 1rem", width: "100%"}}>
              {contentElement}
            </div>
          );
        }
        contentArray.push({
          id: FilterDisplayAttributes[filterDisplayType]?.contentTitle,
          label: FilterDisplayAttributes[filterDisplayType]?.unsetLabel ?? "",
          component: contentElement,
          style: filterDisplayStyle,
        });
      });
      return contentArray;
    }, [isNatMobile, props.filterDisplayTypes, show]);

  const handleClose = () => {
    if (isNatMobile) {
      store.dispatch(setShowFiltersModal(false));
    }
    setShow(false);
  };
  const filterDisplayAttributes: IFilterDisplayAttributes | null =
    useMemo(() => {
      if (props.filterDisplayTypes?.length < 2) {
        return FilterDisplayAttributes[props.filterDisplayTypes[0]];
      }
      return null;
    }, [props.filterDisplayTypes]);
  let resetLabel = "Reset Filter";
  if (
    contentArray?.length > 1 ||
    props.filterDisplayTypes[0] === FilterDropdownDisplayType.Toggles
  ) {
    resetLabel = "Reset Filters";
  }
  let closeButtonLabel = "Done";
  if (isNatMobile) {
    if (filteredProducts.length === 0) {
      closeButtonLabel = "No exact matches";
    } else {
      closeButtonLabel = `Show ${filteredProducts.length} homes`;
    }
  }
  const toggleTitle = useMemo(() => {
    if (props.filterDisplayTypes?.length > 1) {
      if (isNatMobile) {
        return "Filters";
      }
      return "More";
    }

    if (dropdownFiltersState[props.filterDisplayTypes[0]]?.title) {
      return dropdownFiltersState[props.filterDisplayTypes[0]]?.title;
    }
    return filterDisplayAttributes?.contentTitle ?? "";
  }, [
    dropdownFiltersState,
    filterDisplayAttributes?.contentTitle,
    isNatMobile,
    props.filterDisplayTypes,
  ]);

  const toggleActive: boolean = useMemo(() => {
    let active = false;
    props.filterDisplayTypes?.forEach((filterDisplayType) => {
      if (dropdownFiltersState[filterDisplayType]?.active) {
        active = true;
      }
    });
    return active;
  }, [dropdownFiltersState, props.filterDisplayTypes]);
  let additionalStyleOptions: NatPopupStyleOptions[] = [
    NatPopupStyleOptions.NO_ITEMS_BORDER,
  ];
  additionalStyleOptions.push(NatPopupStyleOptions.NO_ITEMS_BORDER);
  if (props.rightAlign) {
    additionalStyleOptions.push(NatPopupStyleOptions.RIGHT_ALIGNED);
  }
  return useNatDropdown({
    style: {
      ...NatDropdownStyles[DropdownStyles.SORT_AND_FILTER][NatSizeType.NORMAL],
      TYPE: NatPopupType.HIDE,
      ADDITIONAL_STYLE_OPTIONS: [
        NatPopupStyleOptions.HIDE_CLOSE_BUTTON,
        NatPopupStyleOptions.NO_ITEMS_BORDER,
        NatPopupStyleOptions.VERTICAL_SPACING_MEDIUM,
        NatPopupStyleOptions.NO_PADDING,
        NatPopupStyleOptions.BOTTOM_SHEET_MOBILE,
        ...additionalStyleOptions,
      ],
      DROPDOWN_BUTTON_SIZE: NatSize.SMALL,
      DROPDOWN_BUTTON_STYLE: toggleActive
        ? StyleOption.FILTERS_ACTIVE
        : undefined,
      POPUP_ITEM_WIDTH: "100%",
      popupCSS: {
        top: "8px",
        width: isNatMobile ? "100%" : "max-content",
        borderRadius: "4px",
        borderTop: "0px solid white",
        maxWidth: isNatMobile ? undefined : "19rem",
        minWidth: "19rem",
        justifyContent: isNatMobile ? "center" : undefined,
        display: isNatMobile ? "flex" : undefined,
        flexDirection: "column",
        paddingTop: "0.5rem",
        ...filterDisplayAttributes?.style,
        gap: isNatMobile ? "0 0" : "inherit",
        padding: isNatMobile ? "0 0" : "0 1rem",
      },
    },
    contentItemArray: [
      {
        component: (
          <FilterModalTitleWrapper>
            <FilterModalTitleContainer>
              {props.title
                ? props.title
                : filterDisplayAttributes?.contentTitle ?? "More Filters"}
            </FilterModalTitleContainer>
            <NatomasFilterModalClose
              style={{position: "sticky"}}
              sticky={true}
              onClick={() => {
                store.dispatch(setShowFiltersModal(false));
              }}
              hidden={!props.showCloseButton}
            >
              <IoMdClose />
            </NatomasFilterModalClose>
          </FilterModalTitleWrapper>
        ),
        id: props.title ?? "title",
        label: props.title ?? "",
        style: {
          position: isNatMobile ? "sticky" : undefined,
          top: isNatMobile ? "0" : undefined,
          backgroundColor: isNatMobile ? "white" : undefined,
          borderBottom: isNatMobile ? "1px solid lightgray" : "0px solid white",
          height: isNatMobile ? "100%" : undefined,
          zIndex: isNatMobile ? "9999" : undefined,
          padding: isNatMobile ? "0 0" : undefined,
        },
      },
      ...contentArray,
      {
        id: "reset-filter",
        component: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              border: "none",
              borderTop: isNatMobile
                ? "1px solid lightgray"
                : "0px solid transparent",
              padding: isNatMobile ? "1rem 1rem" : "0.25rem 1rem",
              paddingBottom: "1rem",
              alignItems: isNatMobile ? "center" : undefined,
              height: "inherit",
            }}
          >
            <NatLink
              label={resetLabel}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY_LINK_ALT}
              clickEvent={() => {
                props.filterDisplayTypes?.forEach((filterDisplayType) => {
                  resetFiltersByDisplayType(filterDisplayType);
                });
              }}
            />
            <Button
              label={closeButtonLabel}
              size={"small"}
              disabled={isNatMobile && filteredProducts.length === 0}
              theme={"secondary"}
              onClick={handleClose}
              id={"close-filter-modal"}
              containerStyle={{width: "fit-content"}}
            />
          </div>
        ),
        label: "Reset Filter",
        style: {
          position: isNatMobile ? "sticky" : undefined,
          bottom: isNatMobile ? "0" : undefined,
          backgroundColor: isNatMobile ? "white" : undefined,
          height: isNatMobile ? "100%" : undefined,
          padding: isNatMobile ? "0 0" : undefined,
        },
      },
    ],
    show: show || (isNatMobile && showFiltersModal),
    setShow: isNatMobile ? setShowFiltersModal : setShow,
    handleClose: handleClose,
    toggleButtonOrLabel: toggleTitle,
  });
};
export const FilterInlineDropdown = (props: {
  filterDisplayTypes: FilterDropdownDisplayType[];
  title?: string;
  rightAlign?: boolean;
}) => {
  return useFilterInlineDropdown({...props})?.dropdown;
};

export const FilterInlineModal = (props: {
  filterDisplayTypes: FilterDropdownDisplayType[];
  title?: string;
  rightAlign?: boolean;
}) => {
  return useFilterInlineDropdown({...props, showCloseButton: true})?.modal;
};
