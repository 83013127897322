import React from "react";
import {usePage} from "../../../hooks/usePage";
import {Label, LabelContainer} from "./styles";
import {AdminActionController} from "../../../generics/admin-controller";

export const CatalogLabel = (props: {
  label?: string;
  showAdminController?: boolean;
}) => {
  const {label, showAdminController} = props;
  const {isNatMobile} = usePage();
  return (
    <LabelContainer>
      <Label data-cy={"catalog-product-view-title"} isMobile={isNatMobile}>
        {label}
      </Label>
      {showAdminController && <AdminActionController hideLabels={true} />}
    </LabelContainer>
  );
};
