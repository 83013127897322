import React, {useMemo} from "react";
import TextInput from "./TextInput";
import TextBoxInput from "./TextBoxInput";
import DimensionsInput from "./DimensionsInput";
import NumberInput from "./NumberInput";
import TimelineInput from "./TimelineInput";
import ImagesInput from "./ImagesInput";
import SingleImageInput from "./SingleImageInput";
import {NatButton} from "../../../../../../../../../_shared/generics/button";
import {NatResetIcon} from "../../../../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../_shared/generics/_shared";
import _ from "lodash";
import ColorInput from "./ColorInput";
import {
  InputContainer,
  InputDescription,
  InputLabel,
  InputLabelContainer,
} from "./styles";
import ListingStatusInput from "./ListingStatusInput";
import PriceInput from "./PriceInput";
import DateTimeInput from "./DateTimeInput";
import LinkInput from "./LinkInput";

export enum FactoryInventoryInputType {
  TEXT = "text",
  TEXT_BOX = "text-box",
  SINGLE_IMAGE = "single-image",
  MULTI_GROUP_IMAGES = "multi-group-images",
  NUMBER = "number",
  DIMENSIONS = "dimensions",
  TIMELINE = "timeline",
  COLOR = "color",
  LISTING_STATUS = "listing-status",
  PRICE = "price",
  DATE_TIME = "date-time",
  URL = "url",
}

export interface FactoryInventoryInputProps {
  id: string;
  label: string;
  description?: string;
  type: FactoryInventoryInputType;
  onChange: (values: any) => void;
  resetChange: () => void;
  initialValue: any;
  value: any;
}
export const FactoryInventoryInput = (props: FactoryInventoryInputProps) => {
  const {
    id,
    type,
    label,
    description,
    onChange,
    resetChange,
    initialValue,
    value,
  } = props;

  const inputProps = useMemo(() => {
    return {
      id,
      onChange,
      initialValue: value,
    };
  }, [id, onChange, value]);

  const isEdited = useMemo(() => {
    return !_.isEqual(value, initialValue);
  }, [value, initialValue]);

  const input: JSX.Element | null = useMemo(() => {
    if (type === FactoryInventoryInputType.TEXT) {
      return <TextInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.NUMBER) {
      return <NumberInput {...inputProps} range={[0, Infinity]} />;
    }
    if (type === FactoryInventoryInputType.TEXT_BOX) {
      return <TextBoxInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.DIMENSIONS) {
      return <DimensionsInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.TIMELINE) {
      return <TimelineInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.MULTI_GROUP_IMAGES) {
      return <ImagesInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.SINGLE_IMAGE) {
      return <SingleImageInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.COLOR) {
      return <ColorInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.LISTING_STATUS) {
      return <ListingStatusInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.PRICE) {
      return <PriceInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.DATE_TIME) {
      return <DateTimeInput {...inputProps} />;
    }
    if (type === FactoryInventoryInputType.URL) {
      return <LinkInput {...inputProps} />;
    }
    return null;
  }, [inputProps, type]);

  return (
    <InputContainer key={id} isEdited={isEdited}>
      <InputLabelContainer>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <NatButton
          hidden={!isEdited}
          label={"reset"}
          clickEvent={resetChange}
          icon={{icon: <NatResetIcon />, iconPosition: IconPosition.ONLY}}
          size={NatSize.XSMALL}
          option={StyleOption.PRIMARY_BLACK}
        />
      </InputLabelContainer>
      {!!description && <InputDescription>{description}</InputDescription>}
      {input}
    </InputContainer>
  );
};
