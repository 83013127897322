import {
  BuildInfoModalContainer,
  BuildInfoModalHeader,
  ColumnFlex,
  RowFlex,
  WhatHappensNextContainer,
} from "./styles";
import {VILLA_MAP} from "../../../_shared/constants/labels";
import React from "react";
import Logo from "../../../../assets/logos/villa-logo-slate.png";
import {PublicAppSearchBar} from "../../_shared/components/PublicAppSearchBar";
import {Address} from "@natomas-org/core";
import {useAddress} from "../../../_shared/hooks/useAddress";
import {LOCATION_SEARCH_AUTOCOMPLETE_TYPE} from "../../_shared/components/PublicAppSearchBar/helpers";
import {GRAY_BORDER, VILLA_APPLE_GREEN} from "../../../_shared/colors";
import {StepCard} from "./components/StepCard";
import {ADDRESS_BAR_STYLE} from "../../_shared/components/PublicAppSearchBar/constants";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import Button from "../../../../0-app/2-component/Button";

export const MapInfoModal = (props: {onClose: () => void}) => {
  const {onClose} = props;
  const {propertySearch: address} = useAddress();
  const {loggedInUser} = useCurrentUser();
  return (
    <BuildInfoModalContainer>
      <img src={Logo} style={{width: "4.75rem"}} alt={"villa-logo"} />
      <BuildInfoModalHeader>
        Welcome to {VILLA_MAP}! Enter an address to get started.
      </BuildInfoModalHeader>
      <div style={{width: "100%", maxWidth: "34rem"}}>
        <RowFlex>
          <ColumnFlex style={{rowGap: "0.5rem", alignItems: "center"}}>
            <div style={{width: "100%"}}>
              <PublicAppSearchBar
                autoFocus={false}
                searchType={LOCATION_SEARCH_AUTOCOMPLETE_TYPE.STREET_ADDRESS}
                addressBarStyle={ADDRESS_BAR_STYLE.SEARCH_BAR}
                hollowVerifiedIcon={true}
                hideVerification={false}
                iconColor={VILLA_APPLE_GREEN}
                style={{padding: "0 0", width: "100%"}}
              />
            </div>
            <hr
              style={{width: "100%", borderTop: `1px solid ${GRAY_BORDER}`}}
            />
            <WhatHappensNextContainer>
              What happens next?
            </WhatHappensNextContainer>
            <ColumnFlex
              style={{
                gap: "1rem",
                width: "100%",
              }}
            >
              {[
                {
                  text: "First, select an ADU to see how it fits on your property.",
                },
                {
                  text: "Next, customize your ADU or continue with the base model.",
                },
              ].map((info, idx) => {
                return (
                  <StepCard
                    key={`step-card-${idx}`}
                    index={idx + 1}
                    text={info.text}
                  />
                );
              })}
            </ColumnFlex>
          </ColumnFlex>
        </RowFlex>
      </div>
      <Button
        id={
          !!loggedInUser
            ? "account-map-info-modal-start"
            : "pa-map-info-modal-start"
        }
        label={"Get started"}
        onClick={onClose}
        disabled={Address.isInvalidAddress(address)}
      />
    </BuildInfoModalContainer>
  );
};
