import React, {useMemo, useState} from "react";
import {toggleProductParcelFitFilter} from "./helpers";
import {FilterType} from "../../../../_shared/catalog/filter/types";
import {FITS_ON_LOT} from "../../../../_shared/constants/labels";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {
  setAdminCatalogMode,
  setAllInEstimateCatalog,
} from "../../../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {useDispatch, useSelector} from "react-redux";
import {NatomasPopupChevronContainer} from "../../../../_shared/generics/popup/dropdown/components/NatPopup/styles";
import {FiChevronDown} from "react-icons/fi";
import {POPUP_ANIMATION_SPEED_MS} from "../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {NatLink} from "../../../../_shared/generics/button";
import {NatSize} from "../../../../_shared/generics/_shared";
import {GRANITE, VILLA_APPLE_GREEN} from "../../../../_shared/colors";
import styled from "styled-components";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {IStore} from "../../../../_shared/slices/types/Store";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {INSTANT_QUOTE_DESCRIPTOR} from "../TotalEstimateToggle/constants";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {OVERVIEW_ASSESSMENT_PAGE_ID} from "../../../../portal/_shared/navigation/constants";
import {MicroSwitch} from "../../../../_shared/generics/switch/microSwitch";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {ProjectPriceMode} from "@natomas-org/core";

export const PriceAndFitFilters = (props: {show: boolean}) => {
  const {filters, allInEstimateCatalog, priceMode} = useCatalogSession();
  const {isAdmin} = useCurrentUser();
  const {isNatMobile} = usePage();
  const dispatch = useDispatch();
  const filterOn: boolean = useMemo(() => {
    return filters?.find((f: any) => f.type === FilterType.ProductParcelFit);
  }, [filters]);
  const {to} = useNavigation();
  const isAdminViewMode = useSelector(
    (state: IStore) => state.catalogSession.adminCatalogMode
  );
  const pricingMode = useSelector(
    (state: IStore) => state.catalogSession.priceMode
  );
  const {customer} = useCurrentCustomer();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const [expandedIndex, setExpandedIndex] = useState(0);
  const InitialCostProjectionFilter = (
    <SwitchFilter
      show={props.show && pricingMode !== ProjectPriceMode.CostPlus}
      expanded={expandedIndex === 2}
      setExpanded={(on) => {
        setExpandedIndex(on ? 2 : -1);
      }}
      toggleAction={() => {
        dispatch(setAllInEstimateCatalog(!allInEstimateCatalog));
        setExpandedIndex(2);
      }}
      toggled={allInEstimateCatalog}
      description={
        isCustomerPreDeposit || !customer
          ? `Show initial total cost projection`
          : `Show ${INSTANT_QUOTE_DESCRIPTOR}`
      }
      expandableContent={
        isCustomerPreDeposit ? (
          <>
            The initial total cost projection is a high-level first look of the
            total project cost. It takes into account the base unit price and
            standard cost associated with site work (i.e. permitting, site
            preparation etc.) It <b>does not</b> take into account your unique
            property characteristics (i.e. sewer or slope) or upgrades to your
            model.
          </>
        ) : (
          <>
            The instant quote is a high-level estimate for your ADU project. It
            takes into account the base unit price and standard site work scope
            based on your site work quiz results. To get started, submit a
            fully-refundable deposit to receive a{" "}
            <span
              onClick={() => {
                to(NavigationPaths.PORTAL, {
                  page: OVERVIEW_ASSESSMENT_PAGE_ID,
                });
              }}
            >
              custom project proposal
            </span>
            with one our ADU experts.
          </>
        )
      }
      expandedHeight={"10rem"}
    />
  );
  const FitsOnLotFilter = (
    <SwitchFilter
      show={props.show}
      expanded={expandedIndex === 1}
      setExpanded={(on) => {
        setExpandedIndex(on ? 1 : -1);
      }}
      toggleAction={() => {
        toggleProductParcelFitFilter(filters, FITS_ON_LOT);
        setExpandedIndex(1);
      }}
      toggled={filterOn}
      description={"Only show units that fit on lot"}
      expandableContent={
        <>Only show ADUs that are guaranteed to fit on your property.</>
      }
      expandedHeight={"2.5rem"}
    />
  );
  const RevealAllHomesFilter = isAdmin ? (
    <SwitchFilter
      expanded={expandedIndex === 3}
      setExpanded={(on) => {
        setExpandedIndex(on ? 3 : -1);
      }}
      show={props.show && isAdmin}
      toggleAction={() => {
        dispatch(setAdminCatalogMode(!isAdminViewMode));
        setExpandedIndex(3);
      }}
      toggled={isAdminViewMode}
      description={"Reveal all homes (admin)"}
      expandableContent={
        <>See all homes, regardless of listing and routing status.</>
      }
      expandedHeight={"2rem"}
    />
  ) : null;

  return (
    <div
      style={{
        width: "100%",
        rowGap: isNatMobile ? "3rem" : "0",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-around",
        minHeight: isNatMobile ? "4rem" : "10rem",
        flexGrow: 1,
      }}
    >
      {FitsOnLotFilter}
      {RevealAllHomesFilter}
      <div>
        <NatLink
          label={"Have questions? Schedule a call with us."}
          size={NatSize.SMALL}
          clickEvent={() => {
            window.open("https://villahomes.com/contact-us", "_blank");
          }}
        />
      </div>
    </div>
  );
};

const SwitchFilter = (props: {
  show: boolean;
  toggleAction: () => void;
  toggled: boolean;
  description: string;
  expandableContent?: JSX.Element;
  expandedHeight?: string;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  style?: React.CSSProperties;
}) => {
  const {isNatMobile} = usePage();
  if (!props.show) return null;
  return (
    <div style={{cursor: "pointer", ...props.style}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "0.5rem",
          width: "100%",
        }}
      >
        <div style={{display: "flex", alignItems: "flex-end"}}>
          <MicroSwitch
            isChecked={props.toggled}
            setChecked={props.toggleAction}
            displayLarge={false}
            color={VILLA_APPLE_GREEN}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
          onClick={() => {
            props.setExpanded(!props.expanded);
          }}
        >
          <div style={{fontSize: "0.85rem"}}>{props.description}</div>
          <NatomasPopupChevronContainer
            expanded={!props.expanded}
            animationSpeed={POPUP_ANIMATION_SPEED_MS * 0.25}
          >
            <FiChevronDown size={"1.5rem"} />
          </NatomasPopupChevronContainer>
        </div>
      </div>
      <ExpandedContentContainer
        animationSpeed={POPUP_ANIMATION_SPEED_MS * 0.25}
        expanded={props.expanded}
        expandedHeight={props.expandedHeight ?? "100%"}
        isNatMobile={isNatMobile}
      >
        <div
          style={{
            fontSize: "0.75rem",
            color: GRANITE,
            alignItems: "flex-start",
          }}
          onClick={() => {
            props.setExpanded(!props.expanded);
          }}
        >
          {props.expandableContent}
        </div>
      </ExpandedContentContainer>
    </div>
  );
};

export const ExpandedContentContainer = styled.div<{
  animationSpeed: number;
  expanded: boolean;
  expandedHeight: string;
  isNatMobile: boolean;
}>`
  display: flex;
  cursor: pointer;
  margin-bottom: ${(props) => (props.isNatMobile ? "0" : "1rem")};
  margin-top: ${(props) => (props.isNatMobile ? "0.5rem" : "0.5rem")};
  transition: height ${(props) => props.animationSpeed}ms ease-in-out;
  height: ${(props) => (props.expanded ? props.expandedHeight : "0")};
  max-height: ${(props) => (props.isNatMobile ? "fit-content" : "max-content")};
  overflow: hidden;
  align-items: flex-start;
`;
