import React, {useState} from "react";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../../../../../../_shared/constants/labels";
import {
  StudioEditorCollapseButtonContainer,
  StudioEditorHeaderLabelContainer,
  StudioEditorWarningContainer,
} from "./styles";
import {NatRightChevron} from "../../../../../../../../../../../_shared/icon/icons";

const StudioEditorWarning = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <StudioEditorWarningContainer
      collapsed={collapsed}
      onClick={() => setCollapsed((current) => !current)}
    >
      <StudioEditorHeaderLabelContainer>
        <div>Warning - {INVENTORY_UNIT_PREFIX} is out-of-date.</div>
        <StudioEditorCollapseButtonContainer collapsed={collapsed}>
          <NatRightChevron size={24} />
        </StudioEditorCollapseButtonContainer>
      </StudioEditorHeaderLabelContainer>
      <div>
        This unit possess 'extra' information, as it was designed in the older
        (v1) {INVENTORY_UNIT_PREFIX} Studio Editor.
      </div>
      <div>
        Everything present in this editor will be visible in the Studio, and
        saved to the unit. If there are any renderings, categories, modifier
        groups, or modifiers, that should not included with this unit, delete
        them.
      </div>
    </StudioEditorWarningContainer>
  );
};

export default StudioEditorWarning;
