import React, {useState} from "react";
import LoginWithEmailForm from "./LoginWithEmailForm";
import {
  handleCredential,
  ISetSchema,
} from "../../../api/Auth/Login/handleCredential";
import Button from "../../2-component/Button";
import {ContentWrapper} from "../../0-page/auth-content/style";
import Text from "../../2-component/Text";
import {DefaultError} from "../../2-component/Input/generic/DefaultError";
import firebase from "firebase";
import Google from "../auth-button/Google";
import {Divider} from "../../../components/_shared/generics/divider";
import styled from "styled-components";
import {
  EventType,
  useTracking,
} from "../../../components/_shared/hooks/useTracking";

const LoginForm = (props: {
  setSchema?: ISetSchema;
  resetPassword?: () => void;
  toSignUp?: () => void;
}) => {
  const [showLoginWithEmail, setShowLoginWithEmail] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string>();
  const {trackEvent} = useTracking();
  const handleSubmit = async (values: any) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(values["email"], values["password"])
      .then((result) => {
        const cred = handleCredential(result);
        trackEvent(EventType.LOGIN, {
          provider: "email-pass",
          auth_user_id: cred.auth_user_id,
        });
        if (props.setSchema) {
          props.setSchema(cred);
        }
      })
      .catch((error) => {
        setLocalError(
          "Oops! There's a problem. It might be one of the following:\n" +
            "- Your password or email doesn't match what we have for you.\n" +
            "- You haven't yet signed up for an account."
        );
      });
  };

  if (showLoginWithEmail) {
    return (
      <ContentWrapper>
        <Text
          text={`Sign in to continue designing your villa, see how it fits on your lot, and get an instant quote.`}
          style={{
            textAlign: "center",
            fontSize: "14px !important",
            lineHeight: "18px !important",
          }}
        />
        <DefaultError id={"login-by-email-error"} error={localError} />
        <LoginWithEmailForm
          resetPassword={props.resetPassword}
          buttons={[
            {
              id: "back-to-login-options",
              label: "Go back",
              theme: "secondary",
              type: "button",
              onClick: () => setShowLoginWithEmail(false),
            },
            {
              id: "login-by-email-button",
              label: "Login",
              type: "submit",
            },
          ]}
          onSubmit={handleSubmit}
        />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Text
        text={`Sign in to continue designing your villa, see how it fits on your lot, and get an instant quote.`}
        style={{
          textAlign: "center",
          fontSize: "14px !important",
          lineHeight: "18px !important",
        }}
      />
      <ContentContainer>
        <Google
          id={"login-google-button"}
          label={"Login with Google"}
          onSubmit={(values: any) => {
            const cred = handleCredential(values);
            if (props.setSchema) {
              props.setSchema(cred);
            }
          }}
        />
        <Text
          text={
            "By signing in, you agree that you have read, understood and agree to the Terms of Use and have read and acknowledge the Privacy Policy."
          }
          links={[
            {
              replacementKey: "Privacy Policy",
              target: "_blank",
              theme: "bold",
              href: "https://villahomes.com/privacy-policy/",
            },
            {
              replacementKey: "Terms of Use",
              target: "_blank",
              theme: "bold",
              href: "https://villahomes.com/terms-of-use/",
            },
          ]}
        />
      </ContentContainer>
      <Divider color={"#D0D5DD"} />
      <ContentContainer>
        <Button
          id={"login-email-button"}
          label={"Log in to existing account"}
          theme={"primary"}
          containerStyle={{width: "100%"}}
          onClick={() => setShowLoginWithEmail(true)}
        />
        <Button
          id={"sign-up-email-button"}
          label={"Sign up for an account"}
          theme={"secondary"}
          type={"button"}
          containerStyle={{width: "100%"}}
          onClick={props.toSignUp}
        />
      </ContentContainer>
    </ContentWrapper>
  );
};

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  a {
    font-size: 12px !important;
  }
  span {
    font-size: 12px !important;
  }
`;

export default LoginForm;
