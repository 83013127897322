import React, {useMemo} from "react";
import {Helmet} from "react-helmet-async";
import {Product} from "@natomas-org/core";
import ConstructionSpecifications from "./components/ConstructionSpecifications";
import SeriesBanner from "./components/SeriesBanner";
import SeriesComparisons from "./components/SeriesComparsions";
import SeriesSpecifications from "./components/SeriesSpecifications";
import {AdminActionController} from "../../../_shared/generics/admin-controller";
import {NoHomesResetFiltersCTA} from "../MainView/components/NoHomesResetFiltersCTA";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../_shared/generics/page/components/PageContainer";
import {ProductList} from "../../_shared/components/ProductList";
// import {WarningTag} from "../../_shared/components/WarningTag";
// import {isFactoryEligible} from "../../../_shared/hooks/useMappingFeatures/helper";
import {useAllPublishedAndRoutedProducts} from "../../../_shared/hooks/useProductCatalog/useProducts";
import {useCatalogFilteredProducts} from "../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogFilteredProducts";
// import {useCatalogSession} from "../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useProgressBar} from "../../../_shared/hooks/useProgressBar";
import {useSelectedFactoryLine} from "../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {Divider} from "../../../_shared/generics/divider";
import {
  INVENTORY_UNIT_DESCRIPTION,
  INVENTORY_UNIT_PREFIX,
} from "../../../_shared/constants/labels";
import {PROGRESS_BAR_HEIGHT} from "../../../_shared/generics/progress-bar/ProgressBar";
import {HEADER_Z_INDEX} from "../../../_shared/styles";
import {Filters} from "../../_shared/components/CatalogBar/MapCatalogBar";

export const ProductLineView = () => {
  // const {eligibility} = useCatalogSession();
  const {info, details, infoSet} = useSelectedFactoryLine();
  const allProducts = useAllPublishedAndRoutedProducts({onlyFactory: info?.id});
  const filteredProducts = useCatalogFilteredProducts(allProducts);
  const {showProgressBar} = useProgressBar();

  const getOffset = (): string => {
    let total = 103;
    if (showProgressBar) {
      total += PROGRESS_BAR_HEIGHT;
    }
    return total + "px";
  };
  const nonInventoryProducts = useMemo(() => {
    return filteredProducts?.filter((product) => !Product.isInventory(product));
  }, [filteredProducts]);
  const inventoryProducts = useMemo(() => {
    return filteredProducts?.filter((product) => Product.isInventory(product));
  }, [filteredProducts]);

  return (
    <>
      <Helmet>
        <title>Villa | {details?.title ?? ""}</title>
      </Helmet>
      <AdminActionController />
      <SeriesBanner
        details={details}
        numberOfProducts={
          allProducts?.filter((product) => !Product.isInventory(product))
            ?.length ?? 0
        }
      />
      <PageContainer
        stickyOptions={{
          offset: "0",
          mode: PageContainerStickyMode.MODAL,
          zIndex: HEADER_Z_INDEX - 2000,
        }}
        backgroundColor={"#fff"}
      >
        <Filters />
      </PageContainer>
      {/*{!isFactoryEligible(eligibility, info?.id) && (*/}
      {/*  <PageContainer>*/}
      {/*    <br />*/}
      {/*    <WarningTag*/}
      {/*      show={!isFactoryEligible(eligibility, info?.id)}*/}
      {/*      title={*/}
      {/*        <>*/}
      {/*          This product is unavailable in your area. Questions? Email us at{" "}*/}
      {/*          <a href={"mailto:hello@villahomes.com"}>hello@villahomes.com</a>*/}
      {/*        </>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </PageContainer>*/}
      {/*)}*/}
      <PageContainer>
        <NoHomesResetFiltersCTA factoryId={info?.id} />
        <ProductList
          products={nonInventoryProducts}
          title={details?.title}
          hideTitle={!details?.title}
          subtitle={`Choose one of ${details?.title} homes to get your instant quote.`}
          removeBottomMargin={inventoryProducts?.length > 0}
        />
        {inventoryProducts && inventoryProducts.length > 0 && (
          <ProductList
            products={inventoryProducts}
            title={`${details?.title} ${INVENTORY_UNIT_PREFIX}s`}
            hideTitle={!details?.title}
            subtitle={INVENTORY_UNIT_DESCRIPTION}
          />
        )}
      </PageContainer>
      <Divider />
      <SeriesSpecifications marketing={info?.marketing} />
      <Divider />
      <ConstructionSpecifications infoSet={infoSet} />
      <Divider />
      <SeriesComparisons />
    </>
  );
};
