import React, {useCallback, useMemo} from "react";
import CategoryFieldEditor from "./Category/CategoryFieldEditor";
import ModifierFieldEditor from "./Modifier/ModifierFieldEditor";
import ModifierGroupFieldEditor from "./ModifierGroup/ModifierGroupFieldEditor";
import RenderingFieldEditor from "./Rendering";
import {IFactoryInventoryStudioEditor} from "../../../index";
import {StudioEditorFieldType} from "../_shared/StudioEditorFieldAddButton";
import {getFormikPath, getValue} from "./helper";
import {IFactoryInventoryStudioEditorPopupPathOptions} from "./interface";

interface FieldEditorProps {
  initialValues: IFactoryInventoryStudioEditor;
  reset: () => void;
  path?: IFactoryInventoryStudioEditorPopupPathOptions;
  type?: StudioEditorFieldType;
  formik?: any;
}

export interface DefaultFieldEditorProps {
  reset: () => void;
  setValue: (value: any) => void;
}

const FieldEditor = (props: FieldEditorProps) => {
  const {initialValues, type, formik, path, reset} = props;
  const initialValue: any = useMemo(() => {
    return getValue(initialValues, path);
  }, [initialValues, path]);
  const currentValue: any = useMemo(() => {
    return getValue(formik.values, path);
  }, [formik.values, path]);
  const setCurrentValue = useCallback(
    (value: any) => {
      const currentPath = getFormikPath(path);
      formik.setFieldValue(currentPath, value);
    },
    [formik, path]
  );

  return useMemo(() => {
    if (!initialValue && !currentValue) {
      return null;
    }
    if (type === StudioEditorFieldType.MODIFIER && path) {
      return (
        <>
          <ModifierFieldEditor
            initialValue={initialValue}
            currentValue={currentValue}
            path={path}
            reset={reset}
            setValue={setCurrentValue}
          />
        </>
      );
    }
    if (type === StudioEditorFieldType.MODIFIER_GROUP && path) {
      return (
        <>
          <ModifierGroupFieldEditor
            initialValue={initialValue}
            currentValue={currentValue}
            path={path}
            reset={reset}
            setValue={setCurrentValue}
          />
        </>
      );
    }
    if (type === StudioEditorFieldType.CATEGORY) {
      return (
        <>
          <CategoryFieldEditor
            initialValue={initialValue}
            currentValue={currentValue}
            reset={reset}
            setValue={setCurrentValue}
          />
        </>
      );
    }
    if (type === StudioEditorFieldType.RENDERING) {
      return (
        <>
          <RenderingFieldEditor
            initialValue={initialValue}
            currentValue={currentValue}
            reset={reset}
            setValue={setCurrentValue}
          />
        </>
      );
    }
    return null;
  }, [initialValue, currentValue, type, path, reset, setCurrentValue]);
};

export default FieldEditor;
