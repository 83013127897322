import React, {useCallback, useEffect, useMemo} from "react";
import {isDeepEqual} from "../../../../../ProductLineView/logic/AdminEditorHelper";
import {useFormik} from "formik";
import {getFormInitialValues} from "../../../../../../_shared/application";
import {PRIMARY_CONTACT_EMAIL_KEY, ProjectStructure} from "./project_model";
import {CustomerProjectTabHeader} from "../../_shared/CustomerProjectTabHeader";
import {AdminProjectActions} from "./AdminProjectActions";
import {ProjectTeamMemberManager} from "./ProjectTeamManager/ProjectTeamMemberManager";
import {DangerZoneControlPanelSection} from "./DangerZoneControlPanelSection/DangerZoneControlPanelSection";
import {ProjectSettingsSection} from "./ProjectSettingsSection";
import {NatMasonryLayout} from "../../../../../../_shared/generics/layout/masonry/NatMasonryLayout";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {getInitialControlPanelTabValues, saveProjectChanges} from "./helpers";
import {Project} from "@natomas-org/core";

// TODO B2B Add popup warning for changing pricing mode

export const ControlPanelTab = () => {
  const {info, site} = useActiveProject();
  const {configuration} = useActiveDesign();
  const initialValues = useMemo(() => {
    return getInitialControlPanelTabValues(
      configuration,
      info,
      getFormInitialValues(ProjectStructure)
    );
  }, [configuration, site, info]);

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {},
  });
  // A string or null that represents the error message for the invalid settings submission
  //  If null, then the submission is valid
  const invalidSettingsWarning = useMemo(() => {
    let toReturn: string | null = null;
    return toReturn;
  }, []);
  const additionalActionsAfterSave = useCallback(async () => {}, []);

  let items: (JSX.Element | null)[] = [
    <ProjectSettingsSection formik={formik} initialValues={initialValues} />,
    <AdminProjectActions formik={formik} initialValues={initialValues} />,
    <ProjectTeamMemberManager
      projectSummary={info}
      newAssignedPrimaryContactEmail={formik.values[PRIMARY_CONTACT_EMAIL_KEY]}
      onAssignmentChange={(email) => {
        formik.setFieldValue(PRIMARY_CONTACT_EMAIL_KEY, email);
      }}
    />,
    <DangerZoneControlPanelSection />,
  ];
  const teamMembers = info ? Project.getTeamMembers(info) : undefined;
  if ((!teamMembers || teamMembers?.length < 1) && items) {
    items = [...items.slice(0, 2), ...items.slice(3, items.length)];
  }

  return (
    <div style={{flexGrow: 1}}>
      <CustomerProjectTabHeader
        isUpToDate={isDeepEqual(formik.values, initialValues)}
        invalidSettingsWarning={invalidSettingsWarning}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        saveAction={() => {
          const infoToSave = Object.assign({}, formik.values);
          return saveProjectChanges(info, infoToSave)?.then(() => {
            console.log("Saved project changes");
            return additionalActionsAfterSave().then(() => {
              console.log("Completed additional actions after save");
            });
          });
        }}
      />
      <NatMasonryLayout componentWidthPx={400} components={items} />;
    </div>
  );
};
