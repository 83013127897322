import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {MapMainView} from "../components/map/views/MapMainView";
import {useMap} from "../components/_shared/hooks/useMap";
import {useHistory} from "react-router-dom";
import {usePage, usePageManager} from "../components/_shared/hooks/usePage";
import {getURLSearchParamsFromAddressDetailsAndGLMapState} from "../components/_shared/hooks/useNavigation/PublicMap/helpers";
import {LoadingPanel} from "../components/_shared/generics/loading-panel";
import {MAP_MAX_LOAD_DURATION_MS} from "../components/map/constants";
import {useAddress} from "../components/_shared/hooks/useAddress";
import {MapInfoModal} from "../components/map/views/MapInfoModal";
import {NatModal} from "../components/_shared/generics/modal/NatModal";
import {Address, shallowEquals} from "@natomas-org/core";
import {
  MapViewMode,
  URL_PARAM_KEY_MAP_VIEW_MODE_ID,
  URL_PARAM_KEY_PRODUCT_GROUP_ID,
  URL_PARAM_KEY_UNIT_ID,
} from "@natomas-org/service";
import {formNewURL} from "../components/_shared/navigation/_helpers";
import {getParameter} from "../components/_shared/cookies";
import {MAP_PATH} from "../paths";
import {navigateTo} from "../components/_shared/hooks/useNavigation/navigation";
import {NavigationPaths} from "../components/_shared/hooks/useNavigation/paths";
import {useNavigation} from "../components/_shared/hooks/useNavigation";
import {
  setMapInfoModalViewed,
  setMapStateParcelView,
} from "../components/map/slices/mapSlice";
import {useDispatch} from "react-redux";
import {useCompletedProjects} from "../components/_shared/hooks/useCompletedProjects";

const MapLayout = () => {
  const {glMapState, parcelView, infoModalViewed} = useMap();
  const {completedProjects} = useCompletedProjects();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const lastAddress = useRef(Address.dataToAddress());
  const history = useHistory();
  const {to} = useNavigation();
  const {propertySearch: address} = useAddress();
  const {isNatMobile} = usePage();
  const dispatch = useDispatch();
  usePageManager();
  useEffect(() => {
    if (!!completedProjects) {
      setIsLoaded(true);
    }
  }, [completedProjects]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, MAP_MAX_LOAD_DURATION_MS);
  });
  useEffect(() => {
    if (
      address &&
      !Address.isInvalidAddress(address) &&
      !shallowEquals(lastAddress.current, address)
    ) {
      // If a new address search is warranted, make the search
      lastAddress.current = address;
      const params = getURLSearchParamsFromAddressDetailsAndGLMapState(
        address,
        glMapState
      );
      history.replace({search: params.toString()});
    }
  }, [address]);

  useEffect(() => {
    const completedProjectsParam = getParameter(URL_PARAM_KEY_MAP_VIEW_MODE_ID);

    if (completedProjectsParam === MapViewMode.COMPLETED_PROJECTS) {
      const newURL = formNewURL({
        pathName: MAP_PATH,
        params: [
          {key: URL_PARAM_KEY_UNIT_ID, value: null},
          {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
          {
            key: URL_PARAM_KEY_MAP_VIEW_MODE_ID,
            value: MapViewMode.COMPLETED_PROJECTS,
          },
        ],
      });
      navigateTo(newURL);
    } else if (completedProjectsParam === MapViewMode.PROPERTY_MAP) {
      const newURL = formNewURL({
        pathName: MAP_PATH,
        params: [
          {
            key: URL_PARAM_KEY_MAP_VIEW_MODE_ID,
            value: MapViewMode.PROPERTY_MAP,
          },
        ],
      });
      navigateTo(newURL);
    }
  }, [parcelView]);

  const closePropertyMapModal = () => {
    if (Address.isInvalidAddress(address ?? undefined)) {
      to(NavigationPaths.PROPERTY_MAP);
      dispatch(setMapStateParcelView(false));
    } else {
      dispatch(setMapInfoModalViewed(true));
    }
  };
  return (
    <>
      <Helmet>
        <title>Villa | Map</title>
        <meta name="description" content="Map with Villa" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
      </Helmet>
      <LoadingPanel show={!isLoaded} />
      <NatModal
        customSize={{
          height: isNatMobile ? "16rem" : "30rem",
          width: isNatMobile ? "100%" : "40rem",
        }}
        content={<MapInfoModal onClose={closePropertyMapModal} />}
        onlyShowContent={true}
        show={parcelView && !infoModalViewed}
        handleClose={closePropertyMapModal}
      />
      <MapMainView />
    </>
  );
};

export default MapLayout;
