import React, {useRef, useState} from "react";
import {
  HeaderContainer,
  HeaderLink,
  HeaderLinkContainer,
  HeaderLinksContainer,
  HeaderSection,
  MobileAvatarMenuSheet,
  MobileContainerGroup,
  MobileHeaderContainer,
  MobileHeaderLinksContainer,
  MobileHeaderMenuSheet,
  MobileMenuLink,
  Rotator,
} from "./styles";
import Logo from "../images/Logo";
import ChevronDown from "./assets/chevron_down";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../components/_shared/generics/page/components/PageContainer";
import MenuBanner from "./subcomponents/menu_banner";
import {OurProcess} from "./our_process";
import styled from "styled-components";
import {AboutUsMenu} from "./subcomponents/about-us-menu";
import {usePage} from "../../../components/_shared/hooks/usePage";
import Button from "../../2-component/Button";
import HamburgerIcon from "./subcomponents/hamburger";
import {HeaderButtons} from "./subcomponents/header-buttons";
import {MobileHeaderButtons} from "./subcomponents/mobile-header-buttons";
import {about_me_menu_links, all_links} from "./links";
import AvatarMenuContent from "./subcomponents/avatar-menu-content";
import {useAvatarMenu} from "./subcomponents/useAvatarMenu";
import {setRegistrationModalState} from "../../../components/_shared/slices/SessionSlice/SessionSlice";
import {useDispatch} from "react-redux";

export interface HeaderProps {
  custom_mode?: "studio";
  navigation_override?: React.ReactNode;
}

export const Header = (props: HeaderProps) => {
  const avatar = useAvatarMenu();
  const dispatch = useDispatch();
  const {columnCount} = usePage();
  const [show, setShow] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showNavigationMenu, setShowNavigationMenu] = useState(false);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);

  const bannerTouchedRef = useRef(false);
  const aboutTouchedRef = useRef(false);

  const closeOurProcess = () => {
    setShow(false);
    bannerTouchedRef.current = false;
  };

  const closeAboutVilla = () => {
    setShowAbout(false);
    aboutTouchedRef.current = false;
  };

  const attemptCloseOurProcess = () => {
    if (!bannerTouchedRef.current) {
      closeOurProcess();
    }
  };

  const attemptCloseAboutVilla = () => {
    if (!aboutTouchedRef.current) {
      closeAboutVilla();
    }
  };

  const scroll = (opening: boolean) => {
    window.scrollTo(0, 0);
    if (opening) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const toggleNavigationMenu = () => {
    setShowNavigationMenu((prev) => {
      const newState = !prev;
      if (newState) {
        setShowAvatarMenu(false);
      }
      scroll(newState);
      return newState;
    });
  };

  const toggleAvatarMenu = () => {
    setShowAvatarMenu((prev) => {
      const newState = !prev;
      if (newState) {
        setShowNavigationMenu(false);
      }
      scroll(newState);
      return newState;
    });
  };

  const showAuthModal = () => {
    dispatch(
      setRegistrationModalState({
        visible: true,
        signUp: true,
        redirectType: undefined,
      })
    );
  };

  if (columnCount <= 8) {
    const mobile_header_content = props?.navigation_override ?? (
      <MobileHeaderLinksContainer>
        <HeaderLinkContainer
          style={{width: "100%", justifyContent: "flex-start"}}
        >
          <HeaderLink
            to={{pathname: all_links.header_browse_homes.pathname ?? undefined}}
            id={all_links.header_browse_homes.id}
            selected={all_links.header_browse_homes.is_selected?.()}
          >
            {all_links.header_browse_homes.label}
          </HeaderLink>
        </HeaderLinkContainer>
        <HeaderLinkContainer
          style={{width: "100%", justifyContent: "flex-start"}}
        >
          <HeaderLink
            to={{
              pathname: all_links.header_for_homeowners.pathname ?? undefined,
            }}
            id={all_links.header_for_homeowners.id}
          >
            {all_links.header_for_homeowners.label}
          </HeaderLink>
        </HeaderLinkContainer>
        <HeaderLinkContainer
          style={{width: "100%", justifyContent: "flex-start"}}
        >
          <HeaderLink
            to={{
              pathname: all_links.header_for_multifamily.pathname ?? undefined,
            }}
            id={all_links.header_for_multifamily.id}
          >
            {all_links.header_for_multifamily.label}
          </HeaderLink>
        </HeaderLinkContainer>
        <HeaderLinkContainer
          style={{width: "100%", justifyContent: "flex-start"}}
        >
          <HeaderLink
            to={{
              pathname: all_links.header_for_developers.pathname ?? undefined,
            }}
            id={all_links.header_for_developers.id}
          >
            {all_links.header_for_developers.label}
          </HeaderLink>
        </HeaderLinkContainer>
        <HeaderLinkContainer
          onClick={() => setShowAbout((prev) => !prev)}
          style={{width: "100%", justifyContent: "flex-start"}}
        >
          <HeaderLink id={all_links.header_resources.id} to={{}}>
            {all_links.header_resources.label}
          </HeaderLink>
          <Rotator rotate={showAbout}>
            <ChevronDown />
          </Rotator>
        </HeaderLinkContainer>
        <MobileContainerGroup
          style={{
            opacity: showAbout ? 1 : 0,
            maxHeight: showAbout ? "500px" : "0",
            overflow: "hidden",
          }}
        >
          {about_me_menu_links.map((link) => (
            <MobileMenuLink
              id={link.id}
              to={{pathname: link.pathname ?? undefined}}
              key={link.id}
            >
              {link.label}
            </MobileMenuLink>
          ))}
        </MobileContainerGroup>
      </MobileHeaderLinksContainer>
    );

    return (
      <>
        <MobileHeaderContainer custom_mode={props.custom_mode}>
          <div onClick={toggleNavigationMenu} style={{marginLeft: "16px"}}>
            <HamburgerIcon close={showNavigationMenu} />
          </div>
          <MobileHeaderLogo onClick={() => (window.location.pathname = "/")}>
            <Logo
              style={{
                cursor: "pointer",
                margin: "0",
                width: "95px",
                display: "block",
              }}
            />
          </MobileHeaderLogo>
          <MobileHeaderButtons
            avatar={avatar}
            signInAction={showAuthModal}
            toggle={toggleAvatarMenu}
            visible={showAvatarMenu}
          />
        </MobileHeaderContainer>
        <MobileHeaderMenuSheet show={showNavigationMenu}>
          <MobileContainerGroup>
            <Button
              id={"header_contact_us"}
              label={"Contact us"}
              onClick={() => (window.location.pathname = "/contact-us")}
              size={"large"}
            />
            {/* Hide when a user is signed in */}
            {avatar.is_user_signed_in ? null : (
              <Button
                id={"header_sign_in"}
                label={"Sign in"}
                theme={"secondary"}
                onClick={() => (window.location.pathname = "/login")}
                size={"large"}
              />
            )}
          </MobileContainerGroup>
          <MobileContainerGroup>{mobile_header_content}</MobileContainerGroup>
        </MobileHeaderMenuSheet>
        <MobileAvatarMenuSheet show={showAvatarMenu}>
          <MobileContainerGroup>
            <AvatarMenuContent avatar={avatar} />
          </MobileContainerGroup>
        </MobileAvatarMenuSheet>
      </>
    );
  }

  const is_browse_homes_selected =
    all_links.header_browse_homes.is_selected?.() ?? false;

  const header_content = props?.navigation_override ?? (
    <HeaderLinksContainer>
      <HeaderLinkContainer>
        <HeaderLink
          to={{pathname: all_links.header_browse_homes.pathname ?? undefined}}
          id={all_links.header_browse_homes.id}
          selected={is_browse_homes_selected}
        >
          {all_links.header_browse_homes.label}
        </HeaderLink>
      </HeaderLinkContainer>
      <HeaderLinkContainer
        onClick={() => {
          setShow(true);
        }}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => {
          setTimeout(attemptCloseOurProcess, 200);
        }}
      >
        <HeaderLink
          id={all_links.header_what_we_do.id}
          selected={false}
          to={{}}
        >
          {all_links.header_what_we_do.label}
        </HeaderLink>
        <ChevronDown />
      </HeaderLinkContainer>
      <HeaderLinkContainer
        onClick={() => {
          setShowAbout(true);
        }}
        onMouseEnter={() => setShowAbout(true)}
        onMouseLeave={() => {
          setTimeout(attemptCloseAboutVilla, 100);
        }}
      >
        <HeaderLink id={all_links.header_resources.id} selected={false} to={{}}>
          {all_links.header_resources.label}
        </HeaderLink>
        <ChevronDown />
        <AboutUsMenu
          visible={showAbout}
          onMouseEnter={() => (aboutTouchedRef.current = true)}
          onMouseLeave={closeAboutVilla}
          style={{
            padding: "20px",
          }}
        />
      </HeaderLinkContainer>
    </HeaderLinksContainer>
  );

  const containerProps =
    props?.custom_mode === "studio"
      ? {
          stickyOptions: {
            offset: "0",
            mode: PageContainerStickyMode.HEADER,
          },
          border: true,
        }
      : {};

  return (
    <>
      {/* TODO Remove Page Container system*/}
      <PageContainer backgroundColor={"#FFF"} {...containerProps}>
        <HeaderContainer>
          <HeaderSection style={{gap: "44px"}}>
            <HeaderLogo onClick={() => (window.location.href = "/")}>
              <Logo
                style={{
                  cursor: "pointer",
                  width: "111px",
                  height: "46px",
                  margin: "0",
                  display: "block",
                }}
              />
            </HeaderLogo>
            {header_content}
          </HeaderSection>
          <HeaderSection>
            <HeaderButtons
              avatar={avatar}
              toggle={() => setShowAvatarMenu((prev) => !prev)}
              visible={showAvatarMenu}
              style={{
                left: "unset",
                right: 0,
                top: "60px",
              }}
              signInAction={showAuthModal}
            />
          </HeaderSection>
        </HeaderContainer>
      </PageContainer>
      <MenuBanner
        show={show}
        top={0}
        onMouseEnter={() => (bannerTouchedRef.current = true)}
        onMouseLeave={closeOurProcess}
      >
        {/* Remove Page Container system*/}
        <PageContainer backgroundColor={"#FFF"}>
          <OurProcess />
        </PageContainer>
      </MenuBanner>
    </>
  );
};

const HeaderLogo = styled.div`
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileHeaderLogo = styled.div`
  position: absolute;
  top: 16px;
  left: calc(50% - 50px);
  right: calc(50% - 50px);
  cursor: pointer;
  width: 100px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
