import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../slices/types/Store";
import {
  IProduct,
  NatomasMapFeature,
  NatomasMapFeatureType,
} from "@natomas-org/core";
import {useEffect, useMemo} from "react";
import {queryFullMapFeatures} from "../../../../../database/firebase/mapping/query";
import {storeMapFeatures} from "../../../slices/NatomasMapSlice/NatomasMapSlice";
import {useAllProducts} from "../../useProductCatalog/useProducts";

export interface IAdminProduct {
  id: string;
  details: IProduct;
  mapping: NatomasMapFeature | null;
}
export const useAdminProducts = () => {
  const {products} = useAllProducts({
    listingStatusFilter: undefined,
  });
  const dispatch = useDispatch();
  const features = useSelector((state: IStore) => state.natomasMap.features);

  useEffect(() => {
    queryFullMapFeatures({
      collectionId: "mapping",
      types: [NatomasMapFeatureType.PRODUCT],
    }).then((r: NatomasMapFeature[]) => {
      if (r?.length > 0) {
        dispatch(storeMapFeatures(r));
      }
    });
  }, [dispatch]);

  return useMemo((): IAdminProduct[] => {
    return products?.map((product: any): IAdminProduct => {
      const id = product?.id;
      return {
        id: id,
        details: product,
        mapping: features?.[id] ?? null,
      };
    });
  }, [features, products]);
};
