import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import useAnalytics from "src/components/_shared/hooks/useAnalytics";
import {AuthenticationFormState} from "./interface";
import {validateAuthenticationFormState} from "./validateFormState";
import {
  EventType,
  useTracking,
} from "../../../components/_shared/hooks/useTracking";
import {getLeadService} from "../../../api/Lead";
import {
  CreateLeadSchema,
  Latitude,
  Longitude,
} from "@natomas-org/villa-nexus-client";
import {useCurrentUser} from "../../../components/_shared/hooks/useCurrentUser";
import {useRegistration} from "../../../components/_shared/hooks/useRegistration";
import useLeadExists from "../../../api/Lead/useLeadExists";
import {useDeepEffect} from "../../../components/_shared/hooks/useDeepEffect";
import {setRegistrationModalVisible} from "../../../components/_shared/slices/SessionSlice/SessionSlice";
import {createUserAccount} from "./createUserAccount";
import {logOut, Utilities} from "../../../database/firebase";
import firebase from "firebase";
import {AuthenticationContentProps} from "./index";
import useActivitySliceDispatcher from "../../../components/_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {useLocation} from "react-router-dom";
import {setURLPathAndQuery} from "../../../components/_shared/navigation/_helpers";
import {ADMIN_ROOT_PATH} from "../../../paths";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../../components/intake-form/constants";
import {
  updateUserDesignConfiguration,
  updateUserProductSelection,
} from "../../../database/firebase/api/user";
import {NavigationPaths} from "../../../components/_shared/hooks/useNavigation/paths";
import {HOME_PAGE_ID, INSTANT_ESTIMATE_PAGE_ID} from "../../../components/portal/_shared/navigation/constants";
import {useNavigation} from "../../../components/_shared/hooks/useNavigation";
import {captureFormComplete, captureFormView} from "./gtm";
import {useSelectedProduct} from "../../../components/_shared/hooks/useProductCatalog/useSelectedProduct";
import {isLeadTest} from "./_is_test";
import {IAddressDetails} from "@natomas-org/core";

const submit = async (
  schema: CreateLeadSchema,
  addressDetails: IAddressDetails
): Promise<any> => {
  if (!schema) {
    return new Error("No schema to submit");
  }
  if (!addressDetails) {
    return new Error("No address to submit");
  }

  const formatPhoneNumber = (preformatted: string): string => {
    // Remove all non-numeric characters
    const cleanedPhoneNumber = preformatted.replace(/\D/g, "");
    // Get the last 10 digits (remove the country code)
    const phoneNumber = cleanedPhoneNumber.substring(
      cleanedPhoneNumber.length - 10
    );
    // Apply the format
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };

  const payload: CreateLeadSchema = {
    first_name: schema.first_name,
    last_name: schema.last_name,
    email: schema.email,
    auth_user_id: schema.auth_user_id,
    lead_segment: schema.lead_segment,
    phone_number: formatPhoneNumber(schema.phone_number as string),
    latitude: addressDetails.latitude as Latitude,
    longitude: addressDetails.longitude as Longitude,
    street:
      String(addressDetails.street_number) +
      " " +
      String(addressDetails.street),
    city: String(addressDetails.city),
    county: String(addressDetails.county),
    state: String(addressDetails.state),
    postal_code: String(addressDetails.zip),
    country: String(addressDetails.country),
    company: schema.company,
    property_type: schema.property_type,
    marketing_opt_in: schema.marketing_opt_in ?? false,
  };
  try {
    return getLeadService().then(async (service) => {
      return service.create(payload);
    });
  } catch (error) {
    console.error("fetchLeadByAuthId", error);
    return null;
  }
};

// const submitMarketingChannel = async (
//   schema: PatchSalesforceLeadSchema
// ): Promise<any> => {
//   try {
//     if (!schema) {
//       return new Error("No schema to submit");
//     }
//     return getLeadService().then(async (service) => {
//       return service.updateMarketingChannel(schema);
//     });
//   } catch (error) {
//     console.error("submitMarketingChannel", error);
//     return null;
//   }
// };

export const useFormState = (props: AuthenticationContentProps) => {
  const {reportEvent} = useAnalytics();
  const {trackEvent} = useTracking();
  const {pathname} = useLocation();
  const {to} = useNavigation();
  // Redux state
  const {setActiveProjectById} = useActivitySliceDispatcher();
  const {isAdmin, loggedInUser, uid, user} = useCurrentUser();
  const {visible, signUp, redirectType, registrationValues} = useRegistration();
  // Local state
  const activeProduct = useSelectedProduct();
  const [schemaCache, setSchemaCache] = useState<
    CreateLeadSchema | undefined
  >();
  // const [marketingChannel, setMarketingChannel] = useState<
  //   string | undefined
  // >();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const getInitialMode = () =>
    pathname.includes("login")
      ? AuthenticationFormState.LOGIN_LANDING
      : pathname.includes("start")
      ? AuthenticationFormState.SIGN_UP_LANDING
      : signUp
      ? AuthenticationFormState.SIGN_UP_LANDING
      : AuthenticationFormState.LOGIN_LANDING;
  const [mode, setMode] = useState<AuthenticationFormState>(getInitialMode());
  useEffect(() => {
    if (visible) {
      setMode(getInitialMode());
    }
  }, [signUp, visible]);
  // Processed state
  const {data: leadExists, refetch, isFetching} = useLeadExists(uid);
  const isUserFound = user;
  const has_project_ids = !!user?.project_ids && user.project_ids.length > 0;
  const dispatch = useDispatch();

  useDeepEffect(() => {
    if (pathname.includes("login") || pathname.includes("start")) {
      if (isAdmin) {
        setURLPathAndQuery(ADMIN_ROOT_PATH);
      }
    }
  }, [pathname, isAdmin]);

  useDeepEffect(() => {
    if (loggedInUser && !schemaCache) {
      setSchemaCache({
        auth_user_id: loggedInUser.uid,
        email: loggedInUser.email,
        first_name: loggedInUser.firstName,
        last_name: loggedInUser.lastName,
      });
    } else if (!loggedInUser && schemaCache) {
      setSchemaCache(undefined);
    }
  }, [loggedInUser, schemaCache]);

  const saveStudio = async (uid: string, pid: string) => {
    if (!registrationValues) {
      console.log("No registration values");
      return;
    }
    // Design Studio
    if (registrationValues?.configuration) {
      console.log("Saving configuration", registrationValues?.configuration);
      const updateDesign = await updateUserDesignConfiguration(pid, {
        ...registrationValues?.configuration,
        configurationId: pid,
      });
      if (updateDesign) {
        console.log("Design configuration saved", pid);
        return Promise.resolve();
      } else {
        console.error("Error updating design configuration");
        return Promise.resolve();
      }
    } else if (registrationValues?.product) {
      console.log("Saving configuration", registrationValues?.product);
      // Save the studio product, and configuration
      // Note: pid and cartItemId are the same as we just created them in createUser
      const updateProduct = await updateUserProductSelection({
        customerId: uid,
        projectId: pid,
        cartItemId: pid,
        product: registrationValues?.product,
      });
      if (updateProduct) {
        console.log("Product configuration saved", pid);
        return Promise.resolve();
      } else {
        console.error("Error updating product configuration");
        return Promise.resolve();
      }
    } else {
      console.error("No product or configuration to save");
      return Promise.resolve();
    }
  };

  const createLead = useCallback(
    (schema: CreateLeadSchema, addressDetails: IAddressDetails) => {
      if (
        !has_project_ids &&
        schema &&
        addressDetails?.latitude &&
        addressDetails?.longitude
      ) {
        // Evoke lead creation, then refetch lead by auth_user_id
        setSubmitting(true);
        saveBackup(schema);
        submit(schema, addressDetails).then((r) => {
          if (!r) {
            window.alert("Error creating account");
            window.location.reload();
          }
          const {status, data: created} = r;
          if (status === 200) {
            refetch();
            // Capture backup
            const nexusId = created?.uuid ?? undefined;

            createUserAccount(schema, addressDetails)
              .then((project_id: string) => {
                if (project_id) {
                  setActiveProjectById(project_id);
                }
                captureFormComplete(
                  {
                    id: schema.auth_user_id as string,
                    email: schema.email,
                    phone: schema.phone_number as string,
                    customer_segment: schema.lead_segment as string,
                  },
                  addressDetails,
                  activeProduct ??
                    registrationValues?.configuration?.product ??
                    registrationValues?.product
                );
                trackEvent(EventType.REGISTRATION_SUBMIT, {
                  type: schema?.lead_segment,
                  auth_user_id: schema?.auth_user_id,
                  nexus_id: nexusId,
                  is_test: isLeadTest(schema),
                });
                reportEvent({
                  category: "Button",
                  action: "lead_captured",
                  label: "Registration",
                });
                // Set mode after registration
                saveStudio(schema.auth_user_id as string, project_id);
                if (created?.lead_segment === "Fee Building Development") {
                  window.location.pathname = "/for-developers";
                } else if (created?.is_serviceable === false) {
                  setSubmitting(false);
                  setMode(AuthenticationFormState.NOT_AVAILABLE_YET);
                } else {
                  setSubmitting(false);
                  setMode(AuthenticationFormState.COMPLETE);
                }
              })
              .catch((e: any) => {
                setSubmitting(false);
                trackEvent(EventType.REGISTRATION_ERROR, {
                  type: "account creation failed",
                  auth_user_id: schema?.auth_user_id,
                  nexus_id: nexusId,
                  is_test: isLeadTest(schema),
                });
                console.error("Error creating account", e);
              });
          } else {
            setSubmitting(false);
            trackEvent(EventType.REGISTRATION_ERROR, {
              type: "lead creation failed",
              auth_user_id: schema?.auth_user_id,
              nexus_id: "unknown",
              is_test: isLeadTest(schema),
            });
            console.error("Error creating lead", r);
          }
        });
      } else {
        console.error("Lead already exists", schema);
        window.location.reload();
      }
    }, [schemaCache, activeProduct, registrationValues, has_project_ids]);

  const logOutLead = () => {
    console.log("Logging out lead");
    logOut({preventPageClearOut: true});
    setSchemaCache(undefined);
    setMode(AuthenticationFormState.LOGIN_LANDING);
  };

  // Callback for validating the mode
  const revalidateMode = useCallback(
    (target?: AuthenticationFormState) => {
      validateAuthenticationFormState({
        currentMode: mode,
        targetMode: target,
        hasLead: !!leadExists,
        hasProjectIds: has_project_ids,
        hasEmail: !!leadExists ? true : !!schemaCache?.email,
        hasLeadSegment: !!leadExists ? true : !!schemaCache?.lead_segment,
        hasAuthId: !!leadExists ? true : !!schemaCache?.auth_user_id,
        reset: () => {
          console.log("Resetting lead");
          logOutLead();
        },
      }).then((result) => {
        if (result !== mode && result) {
          console.log(`Validated mode ${mode} is different, setting to ${result}`);
          setMode(result);
          if (!visible && result === AuthenticationFormState.SELECT_CUSTOMER_TYPE ) {
            dispatch(setRegistrationModalVisible(true));
          }
        }
      });
    },
    [schemaCache, mode, leadExists, has_project_ids]
  );

  // Revalidate the mode when the schema updates
  useEffect(() => {
    revalidateMode();
  }, [revalidateMode]);

  const redirect = () => {
    if (!!props.redirect) {
      props.redirect();
    } else if (redirectType) {
      switch (redirectType) {
        case INTAKE_FORM_REDIRECT_TYPE.INSTANT_ESTIMATE:
          to(NavigationPaths.PORTAL, {
            page: INSTANT_ESTIMATE_PAGE_ID,
          });
          break;
        default:
          to(NavigationPaths.PORTAL, {
            page: HOME_PAGE_ID,
          });
      }
    }
  };

  const modeRef = useRef(mode);

  // useEffect(() => {
  //   console.log("loggedInUser", loggedInUser);
  //   console.log("isUserFound", isUserFound);
  //   console.log("has_project_ids", has_project_ids);
  //   console.log("visible", visible);
  // }, [loggedInUser, isUserFound, has_project_ids, visible]);

  useDeepEffect(() => {
    const current = modeRef.current;
    if (current !== mode) {
      switch (mode) {
        case AuthenticationFormState.CONFIRM_CUSTOMER:
        case AuthenticationFormState.CONFIRM_BUSINESS:
        case AuthenticationFormState.CONFIRM_DEVELOPMENT:
          captureFormView(
            {
              id: schemaCache?.auth_user_id as string,
              email: schemaCache?.email ?? "",
              customer_segment: schemaCache?.lead_segment as string,
            },
            undefined,
            activeProduct ??
              registrationValues?.configuration?.product ??
              registrationValues?.product
          );
          break;
        case AuthenticationFormState.COMPLETE:
          redirect();
          dispatch(setRegistrationModalVisible(false));
          break;
        default:
          break;
      }
    }
    // Update the ref with current values
    modeRef.current = mode;
  }, [mode, schemaCache, activeProduct, registrationValues]);

  return {
    authenticatedEmail: loggedInUser?.email,
    schema: schemaCache,
    setSchema: setSchemaCache,
    mode,
    logOutLead,
    isSubmitting: submitting,
    createLead,
    // Validate the mode before assignment
    setMode: (target: AuthenticationFormState) => revalidateMode(target),
    isFormLoading: (loggedInUser && isFetching) || (loggedInUser && leadExists && isUserFound === undefined && isUserFound !== null),
  };
};

const saveBackup = (schema: any) => {
  const submissionPayload = {...schema};
  submissionPayload.timestamp = firebase.firestore.FieldValue.serverTimestamp();
  Utilities.collection("intake_form_submissions").doc().set(submissionPayload);
};
