import {
  fetchFactoryLineCategories,
  fetchFactoryLineDetails,
  fetchFactoryLineInfo,
  fetchFactoryLineInfoSet,
  fetchFactoryLineInventory,
  fetchFactoryLineModifierGroups,
  fetchFactoryLineModifiers,
  fetchFactoryLineProducts,
} from "../../database/firebase/catalog/fetching";
import {
  FactoryField,
  FactoryVersionField,
  ISetFactoryPayloadOptions,
} from "../../components/_shared/slices/FactorySlice";
import {getFactoryLinesRef} from "../../database/firebase/catalog/refs";
import {processOptions} from "../../components/_shared/slices/FactorySlice/helpers";
import {store} from "../../store";
import {
  hasActiveSubscription,
  IFetchingReturn,
  processFetchingReturn,
  setSubscribingAttempt,
  unsubscribeFromSubscription,
} from "../subscriptions";

export const tryFetchingAllCatalogFactories = async (
  options?: ISetFactoryPayloadOptions
) => {
  const docs = await getFactoryLinesRef()?.get();
  if (docs) {
    docs.forEach((d) => {
      if (d?.id) {
        tryFetchingCatalogFactory(d.id, options);
      }
    });
  }
};

export const tryFetchingCatalogFactory = (
  id?: string,
  options?: ISetFactoryPayloadOptions
): Promise<IFetchingReturn> | null => {
  if (!id) {
    return null;
  }
  const compoundId = getCompoundId(id, FactoryField.INFO);
  if (options?.force) {
    unsubscribeFromSubscription(compoundId);
  }
  // Re-fetch info
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineInfo(id, options).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  } else if (options?.fullFetch) {
    const info = store?.getState()?.factory?.[id]?.info;
    if (info) {
      processOptions(info, options);
    }
  }
  return null;
};

export const tryFetchingCatalogFactoryInfoSet = (
  id: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(id, FactoryField.INFO_SET);
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineInfoSet(id)?.then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};

export const tryFetchingCatalogFactoryDetails = (
  id: string,
  versionId: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(id, versionId, FactoryVersionField.DETAILS);
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineDetails(id, versionId).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};

export const tryFetchingCatalogFactoryProducts = (
  id: string,
  versionId: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(id, versionId, FactoryVersionField.PRODUCTS);
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineProducts(id, versionId).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};

export const tryFetchingCatalogFactoryInventory = (
  id: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(id, FactoryField.INVENTORY);
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineInventory(id).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};

export const tryFetchingCatalogFactoryCategories = (
  id: string,
  versionId: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(
    id,
    versionId,
    FactoryVersionField.CATEGORIES
  );
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineCategories(id, versionId).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};

export const tryFetchingCatalogFactoryModifierGroups = (
  id: string,
  versionId: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(
    id,
    versionId,
    FactoryVersionField.MODIFIER_GROUPS
  );
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineModifierGroups(id, versionId).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};
export const tryFetchingCatalogFactoryModifiers = (
  id: string,
  versionId: string
): Promise<IFetchingReturn> | null => {
  const compoundId = getCompoundId(
    id,
    versionId,
    FactoryVersionField.MODIFIERS
  );
  if (!hasActiveSubscription(compoundId)) {
    setSubscribingAttempt(compoundId, true);
    return fetchFactoryLineModifiers(id, versionId).then((r) =>
      processFetchingReturn(compoundId, r)
    );
  }
  return null;
};

export const getCompoundId = (...arg: string[]): string => {
  return arg.join("-");
};
