import {IProductGroupDetails} from "@natomas-org/core";
import React from "react";
import {Card, CardValues} from "../ProductCard";
import {useCatalogSessionDispatcher} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";

export function getCardValuesForProductLine(
  productLine: IProductGroupDetails,
  select: (id: string) => void
): CardValues {
  return {
    title: productLine?.title,
    priceString: "",
    priceSubtext: "",
    subtextLine1: productLine?.displayDescription,
    subtextLine2: "",
    primaryImageId: productLine?.imageId,
    hoverImageId: "",
    clickAction: () => select(productLine?.id),
    labels: [],
    onMouseEnter: undefined,
    onMouseLeave: undefined,
  };
}

export const ProductLineCard = (props: {
  productLine: IProductGroupDetails;
  style?: React.CSSProperties;
}) => {
  const {toCatalogFactory} = useCatalogSessionDispatcher();
  return (
    <Card
      height={"16rem"}
      cardValues={getCardValuesForProductLine(
        props.productLine,
        toCatalogFactory
      )}
      style={props.style}
    />
  );
};
