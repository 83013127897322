import React from "react";
import {PricingReviewResult} from "./usePricingReview";
import styled from "styled-components";
import {
  ERROR_FILL,
  ERROR_TEXT,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_HOVER,
  VILLA_SLATE,
  WARNING_FILL,
  WARNING_TEXT,
  WHITE,
} from "../../../../../../_shared/colors";
import PriceReviewSection from "./PriceReviewSection";
import ModifierPricingReviewCsv from "./ModifierPricingReviewCSV";

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  height: calc(100% - 65px);
  overflow: auto;
`;
interface MatrixTablePricingReviewProps {
  review: PricingReviewResult;
  removePriceByIds: any;
}

const MatrixTablePricingReview = (props: MatrixTablePricingReviewProps) => {
  const {review} = props;

  return (
    <Container>
      <ModifierPricingReviewCsv reviews={review?.all} />
      <PriceReviewSection
        rows={review?.edit}
        defaultState={false}
        label={"Edits"}
        backgroundColor={VILLA_SLATE}
        color={WHITE}
      />
      <PriceReviewSection
        rows={review?.error}
        defaultState={false}
        label={"Errors"}
        backgroundColor={ERROR_TEXT}
        color={ERROR_FILL}
      />
      <PriceReviewSection
        rows={review?.warning}
        defaultState={false}
        label={"Warnings"}
        backgroundColor={WARNING_TEXT}
        color={WARNING_FILL}
      />
      <PriceReviewSection
        rows={review?.success}
        defaultState={false}
        label={"Valid"}
        backgroundColor={VILLA_APPLE_GREEN_HOVER}
        color={VILLA_APPLE_GREEN}
      />
    </Container>
  );
};

export default MatrixTablePricingReview;
