import { AxiosInstance } from "axios";
import { AppAhjsApi, AHJ as IAhj } from "@natomas-org/villa-nexus-client";
import { getAxiosInstance } from "../setup";

export class AhjService {
    private axiosInstance;
    private client;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
        // @ts-ignore
        this.client = new AppAhjsApi(undefined, undefined, this.axiosInstance);
    }

    async getAhjByLatLong(
        latitude: number | null | undefined,
        longitude: number | null | undefined
    ) {
        if (!latitude || !longitude) {
            throw new Error("latitude and longitude are required");
        }
        return this.client.getAppAhjs(latitude, longitude);
    }
}

export const getAhjService = async () => {
    const axiosInstance = await getAxiosInstance({ skipAuth: true });
    return new AhjService(axiosInstance);
};
