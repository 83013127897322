import useMountEffect from "../hooks/useMountEffect";
import {useEffect} from "react";
import {loadConfiguration} from "../../../database/firebase/configuration/fetching";
import {fetchWebAppConfig} from "../../../AppConfig";
import history from "../navigation/history";
import {usePageManager} from "../hooks/usePage";
import {useCurrentUser} from "../hooks/useCurrentUser";
import LogRocket from "logrocket";
import setUpReact from "logrocket-react";
import {auth, Utilities} from "../../../database/firebase";
import {ICurrentUser} from "../slices/types/Store";
import {
  Customer,
  ICustomerInfo,
  IPublicProjectInfo,
  PROJECT_PUBLIC_COLLECTION_KEY,
} from "@natomas-org/core";
import {trackPageView} from "../hooks/useTracking";
import {resetWarning} from "../slices/NavigationSlice";
import {fetchImage} from "../../../database/firebase/images/fetching";
import {
  convertDocumentsToCompletedProjectsDictionary,
  PublicProjectInfo,
} from "@natomas-org/service";
import {MAX_COOKIE_EXPIRATION_DAYS, setCookie} from "../cookies";
import {store} from "../../../store";
import firebase from "firebase";
import {setCompletedProjects} from "../slices/CompletedProjectsSlice/CompletedProjectsSlice";
import ReactGA from "react-ga4";
import {isProd} from "../application";
import DocumentData = firebase.firestore.DocumentData;

let initializedAnalytics = false;
let previousUID: string | null = null;

export const DataManager = () => {
  const {user: fullUser, loggedInUser, isAdmin} = useCurrentUser();

  // Do not remove usePageManager!
  usePageManager();
  useMountEffect(() => {
    history.listen(() => {
      // TODO B2B Needs to be reworked -> URLManager

      // if (shouldStartTracking(getURLPath())) {
      //   initializeAnalytics();
      // }

      store.dispatch(resetWarning());

      trackPageView();
    });

    fetchWebAppConfig();
  });

  useEffect(() => {
    // @ts-ignore
    const firebaseUser = auth.currentUser;
    const newUID = firebaseUser != null ? firebaseUser.uid : null;
    if (previousUID !== newUID) {
      previousUID = newUID;
      fetchDataForUser();
    }

    const status = firebaseUser ? "user" : "visitor";

    setCookie("web-app-status", status, {
      expirationDays: MAX_COOKIE_EXPIRATION_DAYS,
      rootDomain: true,
    });
  }, [loggedInUser]);

  useEffect(() => {
    Utilities.collection(PROJECT_PUBLIC_COLLECTION_KEY).onSnapshot(
      (snapshots) => {
        if (snapshots.empty) {
          console.log("No matching documents.");
          return;
        }
        const documents: DocumentData[] = [];
        snapshots.forEach((snapshot) => {
          documents.push(snapshot);
        });
        const allProjects =
          convertDocumentsToCompletedProjectsDictionary(documents);
        store.dispatch(setCompletedProjects(allProjects));
        if (!!allProjects) {
          Object.values(allProjects).forEach(
            (projectDetails: IPublicProjectInfo) => {
              // Fetch project image in background if applicable
              const imageId = PublicProjectInfo.getHeroImageId(projectDetails);
              fetchImage(imageId, false);
            }
          );
        }
      }
    );
  }, []);

  useEffect(() => {
    identifyUser(fullUser, loggedInUser, isAdmin);
  }, [fullUser, loggedInUser, isAdmin]);

  return null;
};

const fetchDataForUser = () => {
  return loadConfiguration();
};

export const enableAnalytics = () => {
  if (initializedAnalytics || !analyticsAllowed()) {
    return;
  }
  initializedAnalytics = true;
  // Initialize Google Analytics
  ReactGA.initialize("G-PZHCFP48KJ");
  // Initialize LogRocket
  LogRocket.init("dvu2wa/natomas", {
    rootHostname: "villahomes.com",
    shouldCaptureIP: false,
    dom: {
      // Don't record form inputs
      inputSanitizer: true,
    },
    network: {
      requestSanitizer: (request) => {
        if (request.body && request.body.includes("password")) {
          // don't log passwords
          request.body = undefined;
          return request;
        }

        // otherwise log the request normally
        return request;
      },
    },
  });
  setUpReact(LogRocket);
};

const identifyUser = (
  fullUser: ICustomerInfo | null | undefined,
  loggedInUser: ICurrentUser | undefined | null,
  isAdmin: boolean
) => {
  if (!loggedInUser || !analyticsAllowed()) {
    return;
  }

  const name = fullUser
    ? Customer.getFullName(fullUser)
    : loggedInUser.displayName;
  let email = loggedInUser.email;

  LogRocket.identify(loggedInUser.uid, {
    name: name,
    email: email,
    isAdmin: isAdmin,
  });
};

export const disableAnalytics = () => {
  if (!initializedAnalytics || !analyticsAllowed()) {
    return;
  }

  initializedAnalytics = false;
  // Clear tracking and prevent any new hits
  ReactGA.set({anonymizeIp: true}); // Example: anonymizing IP
  ReactGA.reset(); // Resets all metrics and dimensions
  LogRocket.identify({
    anonymous: true,
  });
};

const analyticsAllowed = () => {
  return isProd;
};
