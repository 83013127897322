import React from "react";
import {InfoSetItemOption} from "../../shared/interfaces";
import {
  ReadOnlyFactoryInfoSetItemFieldTitle,
  ReadOnlyFactoryInfoSetItemFieldValue,
  ReadOnlyFactoryInfoSetItemOptionCard,
  ReadOnlyFactoryInfoSetItemOptionCardTitle,
} from "./styles";

export const ReadOnlyFactoryInfoSetItemOption = (props: {
  id: string;
  option?: InfoSetItemOption;
}) => {
  const {id, option} = props;
  const {value, details, variants, source, assembly, pricingPackage, omit} =
    option ?? {};
  if (!id) {
    return null;
  }
  return (
    <ReadOnlyFactoryInfoSetItemOptionCard key={id}>
      {!!value && (
        <ReadOnlyFactoryInfoSetItemOptionCardTitle>
          {value}
        </ReadOnlyFactoryInfoSetItemOptionCardTitle>
      )}
      {!!details && (
        <>
          <ReadOnlyFactoryInfoSetItemFieldTitle>
            Details:
          </ReadOnlyFactoryInfoSetItemFieldTitle>
          <ReadOnlyFactoryInfoSetItemFieldValue>
            {details}
          </ReadOnlyFactoryInfoSetItemFieldValue>
        </>
      )}
      {!!variants && (
        <>
          <ReadOnlyFactoryInfoSetItemFieldTitle>
            Variants:
          </ReadOnlyFactoryInfoSetItemFieldTitle>
          <ReadOnlyFactoryInfoSetItemFieldValue>
            {variants}
          </ReadOnlyFactoryInfoSetItemFieldValue>
        </>
      )}
      {!!source && (
        <>
          <ReadOnlyFactoryInfoSetItemFieldTitle>
            Sourced By:
          </ReadOnlyFactoryInfoSetItemFieldTitle>
          <ReadOnlyFactoryInfoSetItemFieldValue>
            {source}
          </ReadOnlyFactoryInfoSetItemFieldValue>
        </>
      )}
      {!!assembly && (
        <>
          <ReadOnlyFactoryInfoSetItemFieldTitle>
            Assembly Location:
          </ReadOnlyFactoryInfoSetItemFieldTitle>
          <ReadOnlyFactoryInfoSetItemFieldValue>
            {assembly}
          </ReadOnlyFactoryInfoSetItemFieldValue>
        </>
      )}
      {!!pricingPackage && (
        <>
          <ReadOnlyFactoryInfoSetItemFieldTitle>
            Pricing Package:
          </ReadOnlyFactoryInfoSetItemFieldTitle>
          <ReadOnlyFactoryInfoSetItemFieldValue>
            {pricingPackage}
          </ReadOnlyFactoryInfoSetItemFieldValue>
        </>
      )}
      {!!omit && (
        <>
          <ReadOnlyFactoryInfoSetItemFieldTitle>
            Omitting Credit?
          </ReadOnlyFactoryInfoSetItemFieldTitle>
          <ReadOnlyFactoryInfoSetItemFieldValue>
            {omit}
          </ReadOnlyFactoryInfoSetItemFieldValue>
        </>
      )}
    </ReadOnlyFactoryInfoSetItemOptionCard>
  );
};
