import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../styles/globals";
import {VILLA_FOREST_GREEN} from "../../../../../../_shared/colors";

export const WelcomeCTAWrapper = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const StyledLinkSpan = styled.span`
  color: ${VILLA_FOREST_GREEN};
  text-decoration: underline;
  cursor: pointer;
  width: max-content;
  &:hover {
    color: black;
  }
`;
export const WelcomeCTAButtonContainer = styled.div`
  column-gap: 10px;
  row-gap: 10px;
  display: flex;
  padding: 16px 0;
  flex-wrap: wrap-reverse;
  flex-direction: column;
  justify-content: flex-start;
`;
