import React from "react";
import {StudioControllerContainer} from "./styles";
import {useDispatch} from "react-redux";
import {setShowEditingConfirmation} from "../../../../_shared/slices/StudioSlice";

import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";

import {ButtonProps} from "../../../../../0-app/2-component/Button/interface";
import Button from "../../../../../0-app/2-component/Button";

export interface StudioDesignControllerProps {
  buttons?: ButtonProps[];
  hideEdit?: boolean;
}

export const StudioController = (props: StudioDesignControllerProps) => {
  const {buttons, hideEdit} = props;
  const {isEditing} = useStudioSettings();
  const dispatch = useDispatch();

  const startEditing = () => {
    dispatch(setShowEditingConfirmation(true));
  };

  const startEditingButton: ButtonProps = {
    onClick: startEditing,
    hidden: isEditing,
    id: "start-editing",
    label: "Edit design",
    theme: "secondary",
    containerStyle: {width: "fit-content"},
  };

  return (
    <StudioControllerContainer>
      {buttons?.map((button: ButtonProps, index: number) => {
        return <Button {...button} key={"footer-right-button-" + index} />;
      })}
      {!hideEdit && <Button {...startEditingButton} />}
    </StudioControllerContainer>
  );
};
