import {CallToActionSectionProps} from "../../../SSDDashboardView/components/ANDY-components/CallToActionSection";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";
import {
  ICustomerInfo,
  IDocumentDetails,
  IGCloudFileStorageDetails,
  IProjectInfo,
  PSA_DOCUMENT_ID,
  StringParameter,
} from "@natomas-org/core";
import {FONT_FAMILY_REGULAR} from "../../../SSDDashboardView/styles/globals";
import {
  scrollToElement,
  scrollToElementWithOffset,
  setURLQuery,
} from "../../../../../_shared/navigation/_helpers";
import {
  PORTAL_TOUR_ID,
  PORTAL_UNIT_VIEW_ID,
} from "../../HCPTitleNav/navigation_ids";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import {URL_PARAM_KEY_PAGE_ID} from "@natomas-org/service";
import {PRESENTATION_PAGE_ID} from "../../../../_shared/navigation/constants";
import {NatomasButtonProps} from "../../../../../_shared/generics/button";
import {ESTIMATE_DOCUMENT_ID} from "@natomas-org/core/dist/esm/constants/documents";
import {storage} from "../../../../../../database/firebase";
import {downloadFileToDesktop} from "../../../SitePlanView/logic";
import {DOCUMENT_PDF_PROPOSAL_PATH} from "../../../../../../paths";

export interface IGreetingStatus {
  scheduledModelHomeTour: boolean;
  selectedProduct: boolean;
}

export const GREETING_WAITING_FOR_HC = (
  to: any,
  status: IGreetingStatus
): CallToActionSectionProps => {
  const buttons: NatomasButtonProps[] = [];

  if (status?.selectedProduct) {
    buttons.push({
      label: "Review design selections",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_UNIT_VIEW_ID, {top: 70}, true),
      option: StyleOption.SECONDARY,
    });
  } else {
    buttons.push({
      label: "Choose your home",
      clickEvent: () => to(NavigationPaths.CATALOG),
      option: StyleOption.PRIMARY,
    });
  }

  if (!status?.scheduledModelHomeTour) {
    buttons.push({
      label: "Schedule model home tour",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_TOUR_ID, {top: 70}, true),
      option: StyleOption.PRIMARY_ALT,
    });
  }

  return {
    buttons: buttons,
    footerLinks: [],
    footerTexts: [],
    titles: [],
    titleComponents: [
      {
        text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, we’re excited to start working on your project and are currently assigning your personal Home Consultant.`,
        style: {},
      },
      {
        text: `Within one business day your Home Consultant will reach out via email to introduce themselves and summarize next steps. In the meantime, please review your design selections.`,
        style: {
          fontFamily: FONT_FAMILY_REGULAR,
          fontSize: 12,
          marginTop: "16px",
        },
      },
    ],
  };
};

export const GREETING_SCHEDULE_KICK_OFF = (
  launchCalendly: () => void,
  status: IGreetingStatus
): CallToActionSectionProps => {
  const buttons: NatomasButtonProps[] = [
    {
      label: "Schedule kick-off call",
      clickEvent: launchCalendly,
      option: StyleOption.PRIMARY,
    },
  ];

  if (!status?.scheduledModelHomeTour) {
    buttons.push({
      label: "Schedule model home tour",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_TOUR_ID, {top: 70}, true),
      option: StyleOption.PRIMARY_ALT,
    });
  }

  return {
    buttons: buttons,
    footerLinks: [],
    footerTexts: [],
    titles: [],
    titleComponents: [
      {
        text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, meet ${StringParameter.OWNER_FIRST_NAME}. They will be your personal Home Consultant. Let’s schedule your project kick-off call next.`,
        style: {},
      },
      {
        text: "On this call we will cover site feasibility, floorplans, utilities, infrastructure, and design. Please schedule a 1 hour kick-off call below.",
        style: {
          fontFamily: FONT_FAMILY_REGULAR,
          fontSize: 12,
          marginTop: "16px",
        },
      },
    ],
  };
};

export const GREETING_UPCOMING_KICK_OFF = (
  to: any,
  status: IGreetingStatus
): CallToActionSectionProps => {
  const buttons: NatomasButtonProps[] = [];

  if (!status?.scheduledModelHomeTour) {
    buttons.push({
      label: "Schedule model home tour",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_TOUR_ID, {top: 70}, true),
      option: StyleOption.PRIMARY_ALT,
    });
  }

  if (status?.selectedProduct) {
    buttons.push({
      label: "Review design selections",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_UNIT_VIEW_ID, {top: 70}, true),
      option: StyleOption.SECONDARY,
    });
  } else {
    buttons.push({
      label: "Choose your home",
      clickEvent: () => to(NavigationPaths.CATALOG),
      option: StyleOption.PRIMARY,
    });
  }

  return {
    buttons: buttons,
    footerLinks: [],
    footerTexts: [],
    titles: [],
    titleComponents: [
      {
        text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, your project kick-off call is scheduled for ${StringParameter.DATE}. We are very excited to chat soon!`,
        style: {},
      },
      {
        text: "On this call we will cover site feasibility, floor plans, utilities, infrastructure, and design.",
        style: {
          fontFamily: FONT_FAMILY_REGULAR,
          fontSize: 12,
          marginTop: "16px",
        },
      },
    ],
  };
};

export const GREETING_UPCOMING_DESIGN_SESSION = (
  to: any,
  status: IGreetingStatus
): CallToActionSectionProps => {
  const buttons: NatomasButtonProps[] = [];
  if (!status?.scheduledModelHomeTour) {
    buttons.push({
      label: "Schedule model home tour",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_TOUR_ID, {top: 70}, true),
      option: StyleOption.PRIMARY_ALT,
    });
  }

  if (status?.selectedProduct) {
    buttons.push({
      label: "Review design selections",
      clickEvent: () =>
        scrollToElementWithOffset(PORTAL_UNIT_VIEW_ID, {top: 70}, true),
      option: StyleOption.SECONDARY,
    });
  } else {
    buttons.push({
      label: "Choose your home",
      clickEvent: () => to(NavigationPaths.CATALOG),
      option: StyleOption.PRIMARY,
    });
  }

  return {
    buttons: buttons,
    footerLinks: [],
    footerTexts: [],
    titles: [],
    titleComponents: [
      {
        text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, your design session meeting is scheduled for ${StringParameter.DATE}. We are very excited to chat soon!`,
        style: {},
      },
      {
        text: "On this call we will cover your detailed quote, project timeline, and Phase 1. Please reach out to your home consultant if you have any questions.",
        style: {
          fontFamily: FONT_FAMILY_REGULAR,
          fontSize: 12,
          marginTop: "16px",
        },
      },
    ],
  };
};

export const GREETING_KICK_OFF_COMPLETE_NO_UPCOMING_PROPOSAL: CallToActionSectionProps =
  {
    buttons: [
      {
        label: "Review your design selections",
        type: "button",
        clickEvent: () => {
          scrollToElement(PORTAL_UNIT_VIEW_ID, true);
        },
        option: StyleOption.PRIMARY,
      },
    ],
    footerLinks: [],
    footerTexts: [],
    titles: [],
    titleComponents: [
      {
        text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, your PSA presentation will be scheduled soon!`,
        style: {lineHeight: "120%"},
      },
      {
        text: "On this call we’ll present you with a detailed quote, project timeline and next steps. Please reach out to your Home Consultant in the meantime with any questions.",
        style: {
          fontFamily: FONT_FAMILY_REGULAR,
          fontSize: 12,
          marginTop: "16px",
        },
      },
    ],
  };

export const GREETING_UPCOMING_PROPOSAL_NO_LOCK: CallToActionSectionProps = {
  buttons: [
    {
      label: "Review your design selections",
      type: "button",
      clickEvent: () => {
        scrollToElement("HCP_UNIT_VIEW", true);
      },
      option: StyleOption.PRIMARY,
    },
  ],
  footerLinks: [],
  footerTexts: [],
  titles: [],
  titleComponents: [
    {
      text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, your PSA presentation is scheduled for ${StringParameter.DATE}. We look forward to it!`,
      style: {lineHeight: "120%"},
    },
    {
      text: "On this call we’ll present you with a detailed quote, project timeline and next steps. Please reach out to your Home Consultant in the meantime with any questions.",
      style: {fontFamily: FONT_FAMILY_REGULAR, fontSize: 12, marginTop: "16px"},
    },
  ],
};

export const GREETING_UPCOMING_PROPOSAL_LOCKED: CallToActionSectionProps = {
  buttons: [],
  footerLinks: [],
  footerTexts: [],
  titles: [],
  titleComponents: [
    {
      text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, your PSA presentation is scheduled for ${StringParameter.DATE}. We look forward to it!`,
      style: {lineHeight: "120%"},
    },
    {
      text: "On this call we’ll present you with a detailed quote, project timeline and next steps. Please reach out to your Home Consultant in the meantime with any questions.",
      style: {fontFamily: FONT_FAMILY_REGULAR, fontSize: 12, marginTop: "16px"},
    },
  ],
};

export const GREETING_PROPOSAL_COMPLETE = (
  globalConfig: any,
  projectInfo: IProjectInfo,
  isNatMobile: boolean,
  budgetHidden: boolean,
  documentDictionary: {[p: string]: IDocumentDetails},
  info: ICustomerInfo | undefined
): CallToActionSectionProps => {
  const buttons: NatomasButtonProps[] = [];

  if (globalConfig.HCP_PRESENTATION_LIVE && !budgetHidden) {
    buttons.push({
      label: "View project proposal",
      clickEvent: () => {
        setURLQuery(URL_PARAM_KEY_PAGE_ID, PRESENTATION_PAGE_ID);
      },
      size: NatSize.SMALL,
      option: StyleOption.PRIMARY,
    });
  }
  if (!budgetHidden && projectInfo.id) {
    const viewPDF = () => {
      const url = DOCUMENT_PDF_PROPOSAL_PATH + "?pid=" + projectInfo.id;
      window.open(url, "_blank");
    };
    buttons.push({
      id: "download-proposal-pdf",
      label: "Download Proposal PDF",
      clickEvent: viewPDF,
      size: NatSize.SMALL,
    });
  } else {
    const psaDoc = documentDictionary[PSA_DOCUMENT_ID];
    const estimateDoc = documentDictionary[ESTIMATE_DOCUMENT_ID];
    let budgetDoc: null | IDocumentDetails = null;
    let title: string | null = null;
    let filename: string | null = null;
    if (psaDoc && !psaDoc.hidden) {
      budgetDoc = psaDoc;
      title = "Signed PSA";
      filename = `${info?.person_info?.last_name ?? ""}_PSA_Signed.pdf`;
    } else if (estimateDoc && !estimateDoc.hidden) {
      budgetDoc = estimateDoc;
      title = "Project Estimate";
      filename = `${info?.person_info?.last_name ?? ""}_Budget_Estimate.pdf`;
    }
    if (budgetDoc && title) {
      buttons.push({
        label: `Download ${title}`,
        clickEvent: async () => {
          const url = await storage
            .ref(
              (budgetDoc?.details as IGCloudFileStorageDetails)
                ?.storageRefPath ?? budgetDoc?.url
            )
            .getDownloadURL();
          return await downloadFileToDesktop(
            url,
            filename ?? budgetDoc?.filename ?? document?.title,
            isNatMobile
          );
        },
        option: StyleOption.SECONDARY,
        spinnerEnabled: true,
        size: NatSize.SMALL,
      });
    }
  }

  let subtextToUse = TITLE_SUBTEXT_DEFAULT;
  return {
    buttons: buttons,
    footerLinks: [],
    footerTexts: [],
    titles: [],
    titleComponents: [
      {
        text: `Hi ${StringParameter.CUSTOMER_FIRST_NAME}, please review your proposal below! Reach out to your Home Consultant if any revisions are needed.`,
        style: {},
      },
      {
        text: subtextToUse,
        style: {
          fontFamily: FONT_FAMILY_REGULAR,
          fontSize: "0.875rem",
          marginTop: "16px",
        },
      },
    ],
  };
};

const TITLE_SUBTEXT_DEFAULT = "";
