import React from "react";
import styled from "styled-components";
import Button from "../../../2-component/Button";
import useActivitySlice from "../../../../components/_shared/hooks/useActivity/useActivitySlice";

export interface IAvatarAdminProps {}

const AvatarAdmin = (props: IAvatarAdminProps) => {
  const {activeCustomerId, activeProjectId} = useActivitySlice();

  return (
    <Container>
      <Button
        id={"avatar_admin_view"}
        data-cy={"avatar_admin_view"}
        label={"Admin"}
        onClick={() => {
          window.open(`/admin`);
        }}
        size={"small"}
        theme={"secondary"}
      />
      <Button
        id={"avatar_admin_view_customer"}
        data-cy={"avatar_admin_view_customer"}
        label={"Admin - Customer"}
        disabled={!activeCustomerId}
        theme={"secondary"}
        size={"small"}
        onClick={() => {
          window.open(`/admin/customer?uid=${activeCustomerId}`);
        }}
      />
      <Button
        id={"avatar_admin_view_customer_project"}
        data-cy={"avatar_admin_view_customer_project"}
        theme={"secondary"}
        size={"small"}
        label={"Admin - Project"}
        disabled={!activeCustomerId || !activeProjectId}
        onClick={() => {
          window.open(
            `/admin/customer?uid=${activeCustomerId}&pid=${activeProjectId}`
          );
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  background: #e7e7e4;
  width: 100%;
`;

export default AvatarAdmin;
