import React, {useMemo} from "react";
import {useFormik} from "formik";
import {InputProps} from "../Input/interface";
import {FormProps} from "./interface";
import {ButtonContainer, Form as StyledForm} from "./style";
import {Inputs} from "../Input/useInputElements";
import {Buttons} from "../Button/useButtonElements";
import {ButtonProps} from "../Button/interface";

const getInitialValues = (inputs?: InputProps[]) => {
  return (
    inputs?.reduce((acc, item) => {
      // @ts-ignore
      acc[item.id] = item?.defaultValue ?? undefined;
      return acc;
    }, {}) ?? {}
  );
};

const Form = (props: FormProps) => {
  const {inputs, buttons, onSubmit} = props;
  const {
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    getFieldMeta,
    errors,
    values,
  } = useFormik({
    initialValues: getInitialValues(inputs),
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      onSubmit?.(values);
    },
  });
  const _inputs: InputProps[] = useMemo(() => {
    return (
      inputs?.map((input) => {
        const {value, touched, error} = getFieldMeta(input.id);
        return {
          ...input,
          onChange: handleChange,
          value: value,
          error: touched ? error : undefined,
          setError: (error: string | undefined) => {
            setFieldTouched(input.id, true, false);
            setFieldError(input.id, error);
          },
        };
      }) ?? []
    );
  }, [inputs, handleChange, setFieldError, getFieldMeta]);

  const allRequiredComplete = useMemo(() => {
    return inputs?.every((input) => {
      const currentValue = (values as any)?.[input.id];
      return currentValue !== "" && currentValue !== undefined;
    });
  }, [inputs, values]);

  const _buttons: ButtonProps[] = useMemo(() => {
    const isDisabled =
      Object.keys(errors).length > 0 || allRequiredComplete === false;
    const defaultButtonStyle: Partial<ButtonProps> = {
      style: {
        width: "100%",
      },
    };
    return (
      buttons?.map((button) => {
        if (button.type === "submit") {
          return {
            ...button,
            ...defaultButtonStyle,
            disabled: isDisabled,
          };
        }
        return {...button, ...defaultButtonStyle};
      }) ?? [
        {
          id: "todo-create-a-submit-button-for-form",
          label: "Complete",
          type: "submit",
          ...defaultButtonStyle,
          disabled: isDisabled,
        },
      ]
    );
  }, [buttons, errors, allRequiredComplete]);

  return (
    <StyledForm onSubmit={allRequiredComplete ? handleSubmit : undefined}>
      <Inputs inputs={_inputs} />
      <ButtonContainer>
        <Buttons buttons={_buttons} />
      </ButtonContainer>
    </StyledForm>
  );
};

export default Form;
