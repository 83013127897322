import React, {useEffect, useState} from "react";
import VCL from "@natomas-org/villa-component-library";
import styled from "styled-components";
import {LoadingSpinner} from "../../../../../mapping/views/components/Common/LoadingSpinner";
import {SpinnerContainer} from "../styles";
import Button from "../../../../../../0-app/2-component/Button";

const NeedProduct = (props: {toCatalog: () => void}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []); // Empty dependency array to run only once on mount

  if (!mounted) {
    return (
      <Container>
        <SpinnerContainer>
          <LoadingSpinner isFullScreen={false} />
        </SpinnerContainer>
      </Container>
    );
  }
  return (
    <Container>
      <VCL.Components.Atoms.Header
        properties={{
          children: "We need a little more information.",
          style: {fontWeight: "500", fontSize: "24px", textAlign: "center"},
        }}
      />
      <SubLabel>
        In order to provide you with an instant quote, please first select a
        home design.
      </SubLabel>
      <Button
        id={"need-product-browse-homes"}
        label={"Browse homes"}
        onClick={props.toCatalog}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;

const SubLabel = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export default NeedProduct;
