import {UTMSliceType} from "./UTMSliceType";
import {
  CustomerProjectInfo,
  ICompletedProjectsDictionary,
  IConfiguration,
  IConfigurationMap,
  IConfigurationSite,
  ICustomerInfo,
  IDocumentDetails,
  IFactoryInfo,
  IImageInfo,
  IProduct,
  IProjectNoteDetails,
  ProjectPriceMode,
} from "@natomas-org/core";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../../intake-form/constants";
import {IMapSliceState} from "./MapTypes";
import {NatomasButtonProps} from "../../generics/button";
import {IAddressSlice} from "../AddressSlice/helper";
import {INatomasMapSlice} from "../NatomasMapSlice/NatomasMapSlice";
import {IAppVersionInfo} from "../Global";
import {
  IAdminFactoryInventoryEditorSlice,
  IAdminStudioManagerSlice,
  ICatalogSessionSlice,
  IOptionsSlice,
} from "../CatalogSlice/interfaces";
import {IFactorySlice} from "../FactorySlice";
import {IPropertyDataSlice} from "../PropertyDataSlice/PropertyDataSlice";
import firebase from "firebase/app";
import {IProjectSlice} from "../ProjectSlice/ProjectSlice";
import {ICartSlice} from "../CartSlice/CartSlice";
import {IActivitySlice} from "../ActivitySlice/ActivitySlice";
import {IDesignSlice} from "../DesignSlice";
import {ICartSessionSlice} from "../CartSessionSlice";
import {IBudgetSlice} from "../BudgetSlice/helper";

export interface ICurrentUser {
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  emailVerified: boolean;
  uid: string;
}

export interface IGlobalSlice {
  app_version: IAppVersionInfo | null;
  config: any;
  firebaseInitialized: any;
  images: {[key: string]: IImageInfo};
  imagesFetched: any;
  url: any;
  user: ICurrentUser | null;
  userInitialized: boolean | null;
  users: {[key: string]: ICustomerInfo | null};
  windowHeight: number;
  windowWidth: number;
}

export interface IConfigurationSlice {
  editingSelections: any;
  showEditingConfirmation: any;
  showConfirmation: any;
  fetchedProjectSummary: CustomerProjectInfo | null | undefined;
  fetchedProjectOpportunity: CustomerProjectInfo | null | undefined;
  fetchedProjectSmartsheet: CustomerProjectInfo | null | undefined;
  fetchedProjectNotes: {[noteId: string]: IProjectNoteDetails} | undefined;
  fetchedProjectDocuments:
    | {[collectionDocumentId: string]: IDocumentDetails}
    | undefined;
  fetchedConfiguration: IConfiguration | null | undefined;
  fetchedConfigurationSnapshot: any;
  fetchedConfigurationMap: IConfigurationMap | null | undefined;
  fetchedConfigurationSite: IConfigurationSite | undefined | null;
  currentConfigurationId: any;
}

export interface IIntakeFormSliceState {
  step: string;
  redirect_type: INTAKE_FORM_REDIRECT_TYPE | null;
  redirect_url: string | null;
}

export enum AdminProductCatalogMatrixModes {
  // ProductInfoTable
  PRODUCT_INFO = "product_info",
  // ProductModifierTable
  DESCRIPTIONS = "description",
  PRICE_SET = "price_set",
}

// Sub modes of ProductModifierTable -> Price Set
export enum AdminProductCatalogMatrixSubModes {
  COST = "cost",
  PRICE = "price",
}

export enum AdminProductCatalogMatrixTitleModes {
  TITLE = "title",
  INTERNAL = "internal_title",
}

export enum AdminProductCatalogMatrixIncludeOptions {
  ALL = "All",
  MODELS = "Models",
  INVENTORY = "Inventory",
}

export enum AdminProductCatalogMatrixFilterByOptions {
  NONE = "None",
  PRODUCT = "Product",
  CATEGORY = "Category",
  MODIFIER_GROUP = "Modifier Group",
  MODIFIER = "Modifier",
}

export interface IAdminProductCatalogMatrixFilterOptions {
  include?: AdminProductCatalogMatrixIncludeOptions;
  filterBy?: AdminProductCatalogMatrixFilterByOptions;
  filterIds?: string[];
}

export interface IAdminProductCatalogSlice {
  demoPricingMode: ProjectPriceMode;
  matrixFilters: IAdminProductCatalogMatrixFilterOptions;
  matrixMode: AdminProductCatalogMatrixModes;
  matrixSubMode: AdminProductCatalogMatrixSubModes;
  matrixTitleMode: AdminProductCatalogMatrixTitleModes;
}

export interface IAdminSlice {
  page: string | null;
  view: string | null;
  productCatalog: IAdminProductCatalogSlice;
  factoryLines: {[id: string]: IFactoryInfo};
  isFetching: {[id: string]: boolean};
}

export interface IPageSlice {
  additionalLeftPadding: number;
  pageWidth: number;
  containerWidth: number;
  containerPadding: number;
  columnCount: number;
  gutterWidthPercentage: number;
  gutterWidthPixels: number;
}

export interface IStudioSlice {
  configuration: any | undefined;
  editingSelections: boolean;
  modifierGroups: any | undefined;
  product: IProduct | undefined;
  saving: boolean;
  section: any | undefined;
  selections: any;
  showCartLibrary: boolean;
  showEditingConfirmation: boolean;
  showOverwriteProjectWarning: boolean;
  showSavingConfirmation: boolean;
  step: number | undefined;
  hash: string | undefined;
}

export interface INavigationWarning {
  header: string;
  description?: string;
  customCancelLabel?: string;
  customContinueLabel?: string;
  additionalButtons?: NatomasButtonProps[];
}

export interface INavigationSlice {
  show: boolean;
  warning: INavigationWarning | undefined;
  latestNavigationRequestType: any;
  latestNavigationRequestArgs: any;
}

export interface ITag {
  id: string;
  title: string;
  author: string;
  timestamp: number;
}

export interface ITagsSlice {
  product_catalog_tags: {[key: string]: ITag};
}

export interface IImageFinderSlice {
  selectedImageId: string | undefined;
  searchCategories: any;
  searchModifiers: any;
  zoomModifier: number;
}

export interface IUserSlice {
  userLeadInfo: {[key: string]: any};
  userActions: {[key: string]: any};
  userPermissions: {[key: string]: any};
  userAuthInfo: IUserAuthInfo;
}

export interface IUserAuthInfo {
  [key: string]: firebase.UserInfo;
}

export interface IStore {
  activity: IActivitySlice;
  address: IAddressSlice;
  adminStudioManager: IAdminStudioManagerSlice;
  adminFactoryInventoryEditor: IAdminFactoryInventoryEditorSlice;
  administration: IAdminSlice;
  auth: any;
  budget: IBudgetSlice;
  cart: ICartSlice;
  cartSession: ICartSessionSlice;
  catalogSession: ICatalogSessionSlice;
  completedProjects: {
    allProjects: ICompletedProjectsDictionary | undefined;
    propertyData: ICompletedProjectsDictionary | undefined;
  };
  configuration: IConfigurationSlice;
  design: IDesignSlice;
  factory: IFactorySlice;
  global: IGlobalSlice;
  imageFinder: IImageFinderSlice;
  intakeForm: IIntakeFormSliceState;
  map: IMapSliceState;
  natomasMap: INatomasMapSlice;
  navigation: INavigationSlice;
  options: IOptionsSlice;
  page: IPageSlice;
  portal: any;
  project: IProjectSlice;
  propertyData: IPropertyDataSlice;
  studio: IStudioSlice;
  tags: ITagsSlice;
  user: IUserSlice;
  utm: UTMSliceType;
  session: any;
}

export enum StudioState {
  UNKNOWN = "Unknown", // Unknown / Unset studio state
  BLANK = "Blank", // No product to design
  SHOWCASE = "Showcase", // View inventory units
  BUILDER = "Builder", // Configure inventory units
  DESIGNER = "Designer", // Design a model
}
