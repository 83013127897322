//Packages
import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
//Core
import {Customer, FULL_PORTAL_STAGES, Project} from "@natomas-org/core";
//Styles
import {PortalLayout} from "./styles";
//Shared
import {getPageComponent} from "../_shared/navigation/PageNavigation";
import {useUrl} from "../../_shared/hooks/useUrl";
import {getStore} from "../../../store";
import {updateAdditionLeftPadding} from "../../_shared/slices/PageSlice";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {useCustomerStatus} from "../../_shared/hooks/useCustomerStatus";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {HCPDashboardLayout} from "./HCPDashboardLayout/HCPDashboardLayout";
import {FPDashboardLayout} from "./FPDashboardLayout/FPDashboardLayout";
import {THANK_YOU_PAGE_ID} from "../_shared/navigation/constants";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";
import {Header} from "../../../0-app/1-container/header/header";
import B2BView from "./B2BView";

export const ProjectSummaryLayout = () => {
  const {pageId} = useUrl();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {isAdmin, isSignedIn} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {info, id: pid, isDevelopmentByVilla} = useActiveProject();
  const displayThankYouPage =
    pageId === THANK_YOU_PAGE_ID && !isCustomerPreDeposit;
  const displayPortalV2 = !isCustomerPreDeposit && !displayThankYouPage;
  // TODO: This should be a check to nexus on what we should show.
  // Question: how do we get the customer type? opportunity -> lead -> default: d2c (doesn't look like this is scoped)
  // Currently checking either if you are CostPlus or if you were a Villa Intake + self register b2b
  const displayB2BPortal =
    customer?.used_b2b_intake || isDevelopmentByVilla || false;
  const currentStage = Project.getCurrentStage(info);
  const displayFullPortal =
    currentStage &&
    FULL_PORTAL_STAGES.includes(currentStage) &&
    !displayThankYouPage;

  useEffect(() => {
    getStore().dispatch(updateAdditionLeftPadding(0));
    return () => {
      getStore().dispatch(updateAdditionLeftPadding(0));
    };
  }, []);

  const getSelectedTab = (tab: string | null): JSX.Element => {
    return getPageComponent(tab);
  };

  let browserPageTitle = "Villa | Project Summary";
  if (customer && isAdmin) {
    browserPageTitle = `Villa | ${Customer.getLastName(customer)} Project`;
  }

  let mainContent: any;
  if (displayB2BPortal) {
    mainContent = (
      <>
        <Header navigation_override={<></>} />
        <B2BView />
      </>
    );
  } else if (displayFullPortal) {
    mainContent = (
      <>
        <Header navigation_override={<></>} />
        <FPDashboardLayout pageId={pageId} />;
      </>
    );
  } else if (displayPortalV2) {
    mainContent = (
      <>
        <Header navigation_override={<></>} />
        <HCPDashboardLayout pageId={pageId} />
      </>
    );
  } else {
    mainContent = (
      <>
        <Header />
        {getSelectedTab(pageId)}
      </>
    );
  }

  return (
    <>
      <LoadingPanel show={!isSignedIn || !pid} showAuth={true} />
      <PortalLayout id={"portal-content-wrapper"}>
        <Helmet>
          <title>{browserPageTitle}</title>
          <meta name="description" content="Your Villa Dashboard" />
        </Helmet>
        <ErrorBoundary>{mainContent}</ErrorBoundary>
      </PortalLayout>
    </>
  );
};
