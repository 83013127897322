import React from "react";
import {ImageProps} from "./interface";
import ImagePlaceholder from "../../1-container/images/placeholder";

const Image = (props: ImageProps) => {
  if (!props.alt || props.alt === "") {
    console.error("Image must have an alt prop");
    return null;
  }
  return <img {...props} alt={props.alt} onLoad={() => <ImagePlaceholder />} />;
};

export default Image;
