import React, {Fragment} from "react";
import {Page, Text} from "@react-pdf/renderer";
import {RPDFTable} from "../../../../_shared/generics/pdf/RPDFTable";
import {styles} from "../styles";
import {IPageProps} from "../template";
import {ProjectPriceMode} from "@natomas-org/core";

interface SummaryPageProps extends IPageProps {
  summaryData: any;
  additionalSiteCostsTextArray: any;
  priceMode: ProjectPriceMode;
}

const SummaryPage = (props: SummaryPageProps) => {
  const {
    summaryData,
    footer,
    additionalSiteCostsTextArray: additionalSiteCostsText,
    priceMode,
  } = props;
  if (!summaryData) {
    return null;
  }
  const AdditionalCostsText = () => {
    if (additionalSiteCostsText && priceMode === ProjectPriceMode.CostPlus) {
      return (
        <Fragment>
          {(additionalSiteCostsText as any[])?.map((textData, idx) => {
            return (
              <Text
                style={[
                  styles.secondary_subtitle,
                  {
                    color: textData.textColor,
                    fontWeight: textData.bold ? "bold" : "normal",
                    marginTop: idx === 0 ? "10" : undefined,
                  },
                ]}
              >
                {textData.text}
              </Text>
            );
          })}
        </Fragment>
      );
    }
    return null;
  };

  return (
    <Page orientation={"landscape"} wrap key={"summary"} style={styles.page}>
      <Text style={styles.title}>Project Proposal Summary</Text>
      <RPDFTable data={summaryData} />
      <AdditionalCostsText />
      {footer}
    </Page>
  );
};

export default SummaryPage;
