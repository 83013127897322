import React from "react";
import {IProduct} from "@natomas-org/core";
import {
  CartItemProductDetailsDescription,
  CartItemProductPreviewContainer,
  CartItemProductPreviewDescription,
  CartItemProductPreviewDescriptionContainer,
  CartItemProductPreviewTitle,
} from "./styles";
import {getBedBathString} from "../../../../../../studio/_shared/product";

interface CartItemProductPreviewProps {
  product?: IProduct;
}

const CartItemProductPreview = (props: CartItemProductPreviewProps) => {
  const {product} = props;
  if (!product) return null;
  return (
    <CartItemProductPreviewContainer>
      <CartItemProductPreviewTitle>
        {product?.title}
      </CartItemProductPreviewTitle>
      <CartItemProductPreviewDescriptionContainer>
        <CartItemProductPreviewDescription>
          {product?.displayDescription}
        </CartItemProductPreviewDescription>
        <CartItemProductDetailsDescription>
          {getBedBathString(product, "line")}
        </CartItemProductDetailsDescription>
      </CartItemProductPreviewDescriptionContainer>
    </CartItemProductPreviewContainer>
  );
};

export default CartItemProductPreview;
