import {Address, IAddressDetails} from "@natomas-org/core";
import {
  GoogleMapsContainer,
  GoogleMapsPin,
  GoogleMapsPinContainer,
  GoogleMapsPinLabel,
} from "../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/QuickInfoTab/GeneralInfoSection/styles";
import GoogleMapReact from "google-map-react";
import React, {useState} from "react";
import pin from "../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/QuickInfoTab/GeneralInfoSection/assets/pin.png";

const GOOGLE_MAPS_API_KEY = "AIzaSyCwZWAlda8aZcqdbNqTaU_AqZ2Dqj7-vMM";
export const BasicLocationMap = (props: {
  address: IAddressDetails | null | undefined;
  style?: React.CSSProperties;
}) => {
  const {address, style} = props;
  if (address && address?.longitude && address?.latitude) {
    return (
      <GoogleMapsContainer style={style}>
        <GoogleMapReact
          bootstrapURLKeys={{key: GOOGLE_MAPS_API_KEY}}
          center={{
            lat: address.latitude,
            lng: address.longitude,
          }}
          defaultZoom={18}
          options={{scrollwheel: false}}
        >
          <GoogleMapPin
            lat={address.latitude}
            lng={address.longitude}
            text={Address.getFullAddress(address)}
          />
        </GoogleMapReact>
      </GoogleMapsContainer>
    );
  }
  return <></>;
};

const GoogleMapPin = ({text}: any) => {
  const [addressLabelVisible, setAddressLabelVisible] =
    useState<boolean>(false);
  return (
    <>
      <GoogleMapsPinContainer>
        <GoogleMapsPin
          src={pin}
          alt="pin"
          onClick={() => {
            setAddressLabelVisible(!addressLabelVisible);
          }}
        />
        <GoogleMapsPinLabel
          style={{
            visibility: addressLabelVisible ? "visible" : "hidden",
          }}
        >
          {text}
        </GoogleMapsPinLabel>
      </GoogleMapsPinContainer>
    </>
  );
};
