import styled from "styled-components";

// ------------------------------
// Styled Components
// ------------------------------

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const DiamondIcon = styled.svg`
  height: 32px;
  width: 32px;
  border-radius: 5px;
  background-color: #cafdff;
`;

export const Text = styled.span`
  color: #5a4e4e;
  font-feature-settings: "liga" off, "clig" off;

  /* body/body-lg/default */
  font-family: "Inter-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 22px; /* 137.5% */
`;

export const StyledLink = styled.a`
  color: #000;
  font-feature-settings: "liga" off, "clig" off;

  /* body/body-lg/medium */
  font-family: "Inter-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 22px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;
