import React, {useEffect} from "react";
import {NatButton, NatLink} from "../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {useDesigns} from "../../../../../../_shared/hooks/useDesign/useDesigns";
import {useDispatch} from "react-redux";
import {setActiveCartItemId} from "../../../../../../_shared/slices/ActivitySlice/ActivitySlice";
import {executeRequest} from "../../../../../../../database/firebase/api";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {
  NatNewDocumentIcon,
  NatRefreshIcon,
} from "../../../../../../_shared/icon/icons";
import {RevealCostsToggle} from "../../../../../../estimate/RevealCostsToggle";
import ProposalPDFViewButton from "../../../../../DeveloperView/ProposalPDFButton";
import LatestEstimateLink from "./LatestEstimateLink";
import styled from "styled-components";

export const DesignSelectionsSpreadsheet = (props: {FULL_WIDTH?: boolean}) => {
  const {configuration: activeDesign} = useActiveDesign();
  const {cartItemIds, id: pid} = useActiveProject();
  const designs = useDesigns(cartItemIds);
  const configuration = activeDesign ?? designs[pid];
  const dispatch = useDispatch();
  let designSpreadsheet = null;
  const spreadsheetId: string | undefined =
    configuration?.configuration?.spreadsheetId ??
    configuration?.spreadsheetId ??
    undefined;
  if (spreadsheetId) {
    designSpreadsheet =
      "https://docs.google.com/spreadsheets/d/" + spreadsheetId;
  }
  console.log("designSpreadsheet", designSpreadsheet);
  useEffect(() => {
    if (!configuration?.id) {
      dispatch(setActiveCartItemId(cartItemIds[0]));
    }
  }, [cartItemIds, configuration?.id, dispatch]);

  return (
    <NatFlex
      FULL_WIDTH={props.FULL_WIDTH ?? true}
      style={{padding: "0.5rem 1rem", margin: "0 0"}}
      ROW
      NOWRAP
      NOGAP
      VERTICAL_ALIGN={NatVerticalAlign.CENTER}
    >
      <NatFlex VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
        <NatButton
          icon={
            designSpreadsheet != null
              ? {icon: <NatRefreshIcon />, iconPosition: IconPosition.ONLY}
              : {
                  icon: <NatNewDocumentIcon />,
                  iconPosition: IconPosition.LEFT,
                }
          }
          label={
            designSpreadsheet != null
              ? ""
              : "Generate Design Selections Spreadsheet"
          }
          size={NatSize.SMALL}
          spinnerEnabled={true}
          clickEvent={() => {
            return executeRequest(
              "/project/v1/generateDesignSelectionSpreadsheet",
              {projectId: pid}
            );
          }}
        />
        <NatLink
          label={"Design Selections Spreadsheet"}
          openInNewTab={true}
          hidden={!designSpreadsheet}
          option={StyleOption.PRIMARY_LINK}
          link={designSpreadsheet ?? undefined}
        />
        <ProposalPDFViewButton />
      </NatFlex>
    </NatFlex>
  );
};
export const B2BBudgetSummaryTab = React.memo(() => {
  return (
    <EstimateTableContainer>
      <RevealCostsToggle />
      <LatestEstimateLink />
      <DesignSelectionsSpreadsheet />
    </EstimateTableContainer>
  );
});

const EstimateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  padding: 16px;
`;
