import React, {useEffect, useState} from "react";
//Shared
import {LoadingPanelSpinner} from "../loading-panel/styles";
//Local
import {IconOnlyContainer, NatomasButton, TextContainer} from "./styles";
import {
  getAdditionalStyling,
  getAdditionalTextStylingFromButtonStyle,
  getBoxShadow,
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getHoverBorderSize,
  getHoverBoxShadow,
  getHoverTextColor,
  getHoverTextDecoration,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextContent,
  getTextSize,
  IconPosition,
  INatStyle,
  StyleOption,
} from "../_shared";
// Icons
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {isBlankString} from "@natomas-org/core";
import {trackButtonClick} from "../../hooks/useTracking";
import {usePage} from "../../hooks/usePage";
import {NatomasButtonProps} from "./NatButtonProps";
import {useTheme} from "styled-components";
import ReactTooltip from "react-tooltip";

export const NatButton = (props: NatomasButtonProps): JSX.Element => {
  const {
    option,
    textStyle,
    clickEvent,
    clickEventParam,
    label,
    type,
    size,
    disabled,
    style,
    icon,
    fontFamily,
    spinnerEnabled,
    hidden,
    trackingId,
    id,
    tooltip,
    tooltipPlacement,
  } = props;
  const theme = useTheme() as {
    button: {[key in StyleOption]: INatStyle};
  };
  const {isNatMobile} = usePage();
  const [spin, setSpin] = useState<boolean>(false);

  useEffect(() => {
    if (!spinnerEnabled) {
      setSpin(false);
    }
  }, [spinnerEnabled]);

  const runClickEvent = (event?: any) => {
    if (clickEventParam) {
      return clickEvent(clickEventParam);
    } else if (clickEvent) {
      return clickEvent(event);
    }
  };

  const attemptClickEvent = (event: any) => {
    trackButtonClick(label, id, trackingId);

    if (spinnerEnabled) {
      setSpin(true);
      runClickEvent()?.then(() => {
        setSpin(false);
      });
    } else {
      runClickEvent(event);
    }
  };

  const getSpinner = () => {
    if (spinnerEnabled && spin)
      return (
        <LoadingPanelSpinner size={getTextSize(size)}>
          <AiOutlineLoading3Quarters />
        </LoadingPanelSpinner>
      );
  };

  TextContainer.defaultProps = {
    fontSize: getTextSize(size),
    iconPosition: icon?.iconPosition,
    id: `${id}-text`,
    hasLabel: !isBlankString(label),
    additionalTextStyling: getAdditionalTextStylingFromButtonStyle(option),
  };

  let themeStyle: any = {style: style};
  if (theme?.button && !option) {
    themeStyle = {...themeStyle, ...theme?.button};
  } else if (!!option && !!theme?.button?.[option]) {
    themeStyle = {...themeStyle, ...theme?.button[option]};
  }

  const getContent = () => {
    if (icon?.iconPosition === IconPosition.ONLY) {
      return (
        <span
          style={{
            display: "flex",
            width: "100%",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <IconOnlyContainer
            fontSize={getTextSize(size)}
            style={{
              flexGrow: 1,
            }}
          >
            {icon?.icon}
          </IconOnlyContainer>
        </span>
      );
    } else {
      return (
        <TextContainer
          fontSize={getTextSize(size)}
          iconPosition={icon?.iconPosition}
          id={id + "-text"}
          hasLabel={!isBlankString(label)}
          additionalTextStyling={themeStyle?.additionalTextStyling}
          style={
            props.spinnerEnabledWithText && spin
              ? {
                  display: "flex",
                  columnGap: "0.5rem",
                  flexDirection: "row",
                }
              : undefined
          }
        >
          {(!spin || props.spinnerEnabledWithText === true) &&
            getTextContent(icon, label, spin)}
          {getSpinner()}
        </TextContainer>
      );
    }
  };
  NatomasButton.defaultProps = {
    boxShadow: getBoxShadow(option),
    disabled: disabled || (spinnerEnabled && spin && type !== "submit"),
    enabledBackgroundColor: getEnabledBackgroundColor(option),
    enabledBorderColor: getEnabledBorderColor(option),
    initialBackgroundColor: getInitialBackgroundColor(option),
    fontFamily: fontFamily,
    hidden: hidden,
    hoverBackgroundColor: getHoverBackgroundColor(option),
    hoverBorderColor: getHoverBorderColor(option),
    hoverBoxShadow: getHoverBoxShadow(option),
    hoverTextColor: getHoverTextColor(option),
    hoverTextDecoration: getHoverTextDecoration(option),
    borderSize: getHoverBorderSize(option),
    id: id,
    initialBorderColor: getInitialBorderColor(option),
    initialTextColor: getInitialTextColor(option),
    initialTextDecoration: getInitialTextDecoration(option),
    name: trackingId,
    onClick: attemptClickEvent,
    size: getTextSize(size),
    equalizePadding: icon?.iconPosition === IconPosition.ONLY,
    type: type ?? "button",
    additionalStyling: getAdditionalStyling(option),
  };
  return (
    <>
      <NatomasButton data-for={"tooltip-" + id} data-tip {...themeStyle}>
        {getContent()}
      </NatomasButton>
      {!isNatMobile && tooltip && id ? (
        <ReactTooltip
          id={"tooltip-" + id}
          effect="solid"
          place={tooltipPlacement ?? "top"}
          multiline={true}
        >
          {tooltip}
        </ReactTooltip>
      ) : null}
    </>
  );
};
