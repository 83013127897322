import {
  ActionContainer,
  ProductLineInfoBlockInfo,
  ProductLineInfoBlockInfoRow,
  ProductLineInfoBlockTitle,
} from "../styles";
import {ListingStatusTag} from "../../../_shared/ListingStatusTag";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {setURLPathAndQuery} from "../../../../../../../_shared/navigation/_helpers";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {NatEditIcon} from "../../../../../../../_shared/icon/icons";
import {DateUtils} from "@natomas-org/core";
import React, {Fragment} from "react";
import {useCatalogSessionDispatcher} from "../../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";

export const FactoryLineStudioBlock = (props: {details: any}) => {
  const {details} = props;
  const {selectFactory} = useCatalogSessionDispatcher();
  return (
    <Fragment>
      <ProductLineInfoBlockInfo>
        <ProductLineInfoBlockTitle>Studio</ProductLineInfoBlockTitle>
        <ProductLineInfoBlockInfoRow>
          <ActionContainer>
            <ListingStatusTag status={details.status} />
            (v{details?.version}) {details?.published_date ? "Live" : "Draft"}
          </ActionContainer>
          <ActionContainer>
            <NatButton
              label={"Edit Design Studio"}
              clickEvent={() => {
                setURLPathAndQuery("/admin/product-line");
                selectFactory(details?.id);
              }}
              option={StyleOption.PRIMARY_BLACK}
              size={NatSize.SMALL}
              icon={{
                icon: <NatEditIcon />,
                iconPosition: IconPosition.ONLY,
              }}
            />
            {/*<ProductLineOptionsDropdown factoryLineId={details?.id} />*/}
          </ActionContainer>
        </ProductLineInfoBlockInfoRow>
        {details?.published_date && (
          <ProductLineInfoBlockInfoRow>
            <div>Published</div>
            {DateUtils.getFullDate(new Date(details?.published_date), {
              showYear: true,
            })}
          </ProductLineInfoBlockInfoRow>
        )}
        <ProductLineInfoBlockInfoRow>
          <div>Internal</div>
          {details?.internal_title}
        </ProductLineInfoBlockInfoRow>
        <ProductLineInfoBlockInfoRow>
          <div>Title</div>
          {details?.title}
        </ProductLineInfoBlockInfoRow>
        {/*<ProductLineInfoBlockInfoRow>*/}
        {/*  <div>Models</div>*/}
        {/*  {details?.modelCount}*/}
        {/*</ProductLineInfoBlockInfoRow>*/}
        {/*<ProductLineInfoBlockInfoRow>*/}
        {/*  <div>Inventory</div>*/}
        {/*  {details?.inventoryCount}*/}
        {/*</ProductLineInfoBlockInfoRow>*/}
      </ProductLineInfoBlockInfo>
    </Fragment>
  );
};
