import styled from "styled-components";
import {HEADER_Z_INDEX} from "../../../../../_shared/styles";

export const MapRightStickyHeaderContainer = styled.div<{
  isMobile?: boolean;
}>`
  transition: height 150ms ease-in-out;
  position: sticky;
  top: ${(props) => (props.isMobile ? `0` : "0")};
  background-color: white; // #f7f5f2;
  overflow: initial;
  align-items: center;
  display: flex;
  align-content: center;
  justify-items: center;
  justify-content: center;
  z-index: ${`calc(${HEADER_Z_INDEX} - 1000)`};
`;

export const MapStickyHeaderItemsContainer = styled.div`
  width: 100%;
`;
