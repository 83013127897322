import React, {HTMLAttributes} from "react";
import close from "./close.svg";
import styled from "styled-components";
import {BLACK} from "../../colors";

const Close = (props: HTMLAttributes<HTMLImageElement>) => (
  <Container>
    <Img {...props} src={close} alt="Close" />
  </Container>
);

const Img = styled.img`
  width: 32px;
  height: 32px;
  padding: 0;
  cursor: pointer;
  z-index: 999999999;
  background: white;
  border-radius: 50%;
  border: 1px solid white;
  :hover {
    border: 1px solid var(--black-10);
    #villa-modal-close-icon {
      fill: ${BLACK};
      stroke: ${BLACK};
    }
  }
  margin: 16px 16px 0 0;
  transition: all 0.2s ease-in-out;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  width: 100%;
  height: 0;
`;

export default Close;
