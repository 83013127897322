import React from "react";
import {ButtonProps} from "./interface";
import {Button as StyledButton, Container, Error} from "./style";

const Button = (props: ButtonProps) => {
  const {label, containerStyle, error, theme, size, ...buttonProps} = props;
  return (
    <Container style={{...containerStyle}}>
      <StyledButton {...buttonProps} theme={theme} size={size}>
        {label}
      </StyledButton>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Button;
