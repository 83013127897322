import React, {useCallback, useMemo, useState} from "react";
import {
  CollapsableContainerContent,
  CollapsableContainerIcon,
  CollapsableContainerLabel,
  CollapsableContainerWrapper,
} from "./styles";
import {NatRightCaratIcon} from "../../icon/icons";

interface CollapsableContainerProps {
  label: string;
  children: React.ReactNode;
  options?: {
    align?: "left" | "right";
    expand?: boolean;
  };
}

const NatCollapsableContainer = (props: CollapsableContainerProps) => {
  const {label, children, options} = props;
  const [show, setShow] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setShow((current: boolean) => !current);
  }, []);

  const Label: React.JSX.Element = useMemo(() => {
    // Defaults to left-aligned
    const showLeft: boolean = options?.align !== "right";
    const showRight: boolean = options?.align === "right" ?? false;
    return (
      <CollapsableContainerLabel expand={options?.expand} onClick={toggle}>
        {showLeft && (
          <NatRightCaratIcon style={CollapsableContainerIcon(show)} />
        )}
        {label}
        {showRight && (
          <NatRightCaratIcon style={CollapsableContainerIcon(show)} />
        )}
      </CollapsableContainerLabel>
    );
  }, [label, options, show, toggle]);

  return (
    <CollapsableContainerWrapper>
      {Label}
      <CollapsableContainerContent show={show}>
        {children}
      </CollapsableContainerContent>
    </CollapsableContainerWrapper>
  );
};

export default NatCollapsableContainer;
