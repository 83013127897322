import React, {Fragment, useMemo} from "react";
import {getRelatedProducts} from "./helpers";
import {ProductList} from "../ProductList";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {useFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import styled from "styled-components";
import {ProductViewBottomSection} from "../../../views/ProductView/styles";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {useCatalogSessionDispatcher} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";
import Button from "../../../../../0-app/2-component/Button";
import {scrollToTop} from "../../../../_shared/navigation/_helpers";

export interface RelatedProductsProps {
  product: any;
}

export const RelatedProducts = (props: RelatedProductsProps) => {
  const {product} = props;
  const {products} = useFactoryLine({factoryId: product.productGroupId});
  const fullSize = useDynamicValue(fullColumnWidths);
  const {resetCatalogSessionSelections} = useCatalogSessionDispatcher();
  const count = useDynamicValue({
    forFour: 2,
    forEight: 2,
    forTwelve: 3,
    forSixteen: 4,
  });

  const relatedProducts = useMemo(() => {
    if (!!product && !!count) {
      return getRelatedProducts(product, Object.values(products ?? {}), count);
    } else return [];
  }, [product, count, products]);

  return (
    <Fragment>
      <RelatedProductsWrapper>
        <ProductList
          title={"Explore related products"}
          hideDescription={true}
          disableSorting={true}
          products={relatedProducts}
        />
      </RelatedProductsWrapper>
      <PageElement size={fullSize} height={"auto"}>
        <ProductViewBottomSection>
          <Button
            id={"nat-button-browse-all-homes"}
            label={"Browse all homes"}
            theme={"secondary"}
            size={"large"}
            onClick={() => {
              resetCatalogSessionSelections();
              scrollToTop(true);
            }}
          />
        </ProductViewBottomSection>
      </PageElement>
    </Fragment>
  );
};

const RelatedProductsWrapper = styled.div`
  display: ${(props) =>
    props.theme?.ProductViewContent?.showRelatedHomes === false
      ? "none"
      : undefined};
`;
