import React, {createContext, useContext} from "react";
import {useColumnCount} from "../../../_shared/hooks/usePage";

interface LayoutContextType {
  isStacked: boolean;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};

export const LayoutProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const page = useColumnCount();
  const isStacked = page.columnCount <= 8;

  return (
    <LayoutContext.Provider value={{isStacked}}>
      {children}
    </LayoutContext.Provider>
  );
};
