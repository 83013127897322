import {
  NatModal,
  NatModalProps,
  NatModalSize,
} from "../../../_shared/generics/modal/NatModal";
import React, {useCallback, useMemo} from "react";
import ContentContainer from "./components/ContentContainer";
import InstantQuoteQuestionnaireForm from "./components/QuestionnaireForm";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {usePage} from "../../../_shared/hooks/usePage";
import SuccessScreen from "./components/SuccessScreen/SuccessScreen";
import {VILLA_APPLE_GREEN_LIGHT_1} from "../../../_shared/colors";
import useActiveCustomer from "../../../_shared/hooks/useCustomer/useActiveCustomer";
import NeedProduct from "./components/NeedProduct";
import {useActiveCartItem} from "../../../_shared/hooks/useCart/useCartItem/useActiveCartItem";
import {useTracking} from "../../../_shared/hooks/useTracking";
import {Customer} from "@natomas-org/core";
import Button from "../../../../0-app/2-component/Button";

const InstantQuoteContinue = () => {
  // current user
  const {trackEvent} = useTracking();
  const {to} = useNavigation();
  const toCatalog = useCallback(() => {
    to(NavigationPaths.CATALOG);
  }, [to]);
  const {isNatMobile} = usePage();
  const {info} = useActiveCustomer();
  const customerData = useMemo(() => {
    if (!info) {
      return undefined;
    }
    return {
      "email": Customer.getContactEmail(info),
      "first-name": Customer.getFirstName(info),
      "last-name": Customer.getLastName(info),
    };
  }, [info]);
  // Modal state
  const [showModal, setShowModal] = React.useState(false);
  const [step, setStep] = React.useState(0);

  const activeCartItem = useActiveCartItem();

  const nextStep = () => {
    setStep((prev) => {
      return prev + 1;
    });
  };

  // reset the step if the user has already completed the first step
  const reset = useCallback(() => {
    // const contact = getContactFromSessionStorage();
    // if (contact) {
    trackEvent("instant-quote-open-questionnaire");
    setStep(0);
    // }
  }, [trackEvent]);

  const hideModal = () => {
    document.body.scrollTo(0, 0);
    document.body.style.overflowY = "auto";
    setShowModal(false);
  };

  const continueAction = () => {
    document.body.style.overflowY = "hidden";
    reset();
    setShowModal(true);
    document.body.scrollTo(0, 0);
  };

  const modalProps: NatModalProps = useMemo(() => {
    let content = null;
    switch (step) {
      case 0:
        content = (
          <ContentContainer
            header={"Tell us more about your project"}
            subHeader={`A Villa expert will reach out to you shortly. In the meantime, don't
          hesitate to reach out to us directly at <a href="mailto:hello@villahomes.com">hello@villahomes.com</a>`}
            content={
              !customerData ? undefined : (
                <InstantQuoteQuestionnaireForm
                  onSubmit={() => {
                    trackEvent("instant-quote-submitted-questionnaire");
                    nextStep();
                  }}
                  customerData={customerData}
                />
              )
            }
          />
        );
        break;
      case 1:
        content = !customerData ? (
          <ContentContainer
            header={"Tell us more about your project"}
            subHeader={`A Villa expert will reach out to you shortly. In the meantime, don't
          hesitate to reach out to us directly at <a href="mailto:hello@villahomes.com">hello@villahomes.com</a>`}
            content={undefined}
          />
        ) : (
          <SuccessScreen dismiss={hideModal} customerData={customerData} />
        );
        break;
      default:
        content = <></>;
        break;
    }

    let modal: NatModalProps = {
      show: showModal,
      onlyShowContent: true,
      modalBackgroundColor: step === 1 ? VILLA_APPLE_GREEN_LIGHT_1 : undefined,
      enforceHeight: isNatMobile,
      size: NatModalSize.LARGE,
      customPadding: "0",
      content: content,
      hideCloseIcon: false,
      handleClose: hideModal,
    };
    return modal;
  }, [step, customerData, showModal, isNatMobile, trackEvent]);

  const modalOverride: NatModalProps | null = useMemo(() => {
    if (activeCartItem) {
      return null;
    } else {
      return {
        onlyShowContent: true,
        hideCloseIcon: false,
        handleClose: undefined,
        show: true,
        content: <NeedProduct toCatalog={toCatalog} />,
      };
    }
  }, [activeCartItem, toCatalog]);

  const continueProps = modalOverride ?? modalProps;

  return (
    <>
      <Button
        id={"continue-with-instant-quote"}
        label={"Continue"}
        onClick={continueAction}
        size={"large"}
        containerStyle={{width: "350px"}}
      />
      <NatModal {...continueProps} />
    </>
  );
};

export default InstantQuoteContinue;
