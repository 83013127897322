import {
  ISeriesSummaryColumnDetails,
  ISeriesSummaryColumnInfo,
  ISeriesSummaryColumnProduct,
  ISeriesSummaryColumnProductInfo,
} from "../../interfaces";
import {
  IFactoryInfo,
  IProduct,
  IProductGroupDetails,
  priceTextFromMicros,
} from "@natomas-org/core";
import {getProductionTimelineInfo} from "../../../../../../../portal/views/ProposalView/constants/timelineHelpers";
import {ICatalogProductsMetadata} from "../../../../../../../_shared/hooks/useProductCatalog/useProducts";

export const getSSCInfoProps = (
  info?: IFactoryInfo
): ISeriesSummaryColumnInfo | null => {
  if (!info) {
    return null;
  }
  return {
    fullTurnKeyDescription:
      "Project management, permits, build, delivery, install, warranty",
    standardFeatures: info?.marketing?.standardFeatures ?? "",
    customFeatures: info?.marketing?.customFeatures ?? "",
  };
};

export const getSSCDetailsProps = (
  details?: IProductGroupDetails
): ISeriesSummaryColumnDetails | null => {
  if (!details) {
    return null;
  }
  return {
    id: details?.id ?? "",
    imageId: details?.imageId ?? "",
    title: details?.title ?? "",
    timelineInMonths: getProductionTimelineInfo(details)?.totalMonths ?? 0,
  };
};

// Add interface for product info here
export const getSSCProductInfoProps = (
  info: ICatalogProductsMetadata,
  products: IProduct[]
): ISeriesSummaryColumnProductInfo | null => {
  if (!products) {
    return null;
  }
  if (!info?.lowestPrice) {
    return null;
  }
  return {
    startingAtPrice: priceTextFromMicros(info?.lowestPrice, "accurate"),
    products: products.map((product): ISeriesSummaryColumnProduct => {
      return {
        id: product?.id ?? "",
        title: product?.title ?? "",
        bathroomCount: product?.productDetails?.bathrooms ?? 0,
        bedroomCount: product?.productDetails?.bedrooms ?? 0,
        squareFootage: product?.productDetails?.squareFeet ?? 0,
        priceInMicros: product?.price?.price ?? 0,
      };
    }),
  };
};
