import {
  AccountInfoText,
  FullScreenCenteredContent,
  LandingText,
  LandingTextDescription,
  LogoutButtonContainer,
} from "../styles";
import React from "react";
import {NatButton} from "../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../_shared/generics/_shared";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {logOut} from "../../../../database/firebase";
import {LOGIN_PATH} from "../../../../paths";

export const DownForMaintenanceView = () => {
  const {to} = useNavigation();
  const {loggedInUser} = useCurrentUser();
  return (
    <>
      <FullScreenCenteredContent>
        <LandingText>{"We'll be right back!"}</LandingText>
        <LandingTextDescription>
          {
            "We are currently updating the Villa experience, please check back again soon."
          }
        </LandingTextDescription>
      </FullScreenCenteredContent>
      <LogoutButtonContainer>
        <NatButton
          size={NatSize.SMALL}
          label={"Logout"}
          type={"button"}
          option={StyleOption.PRIMARY_ALT}
          clickEvent={() => () =>
            logOut().then(() => (window.location.pathname = LOGIN_PATH))}
        />
        <AccountInfoText>{loggedInUser?.email}</AccountInfoText>
      </LogoutButtonContainer>
    </>
  );
};
