import React from "react";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {handleCredential} from "../../../api/Auth/Login/handleCredential";
import Google from "../auth-button/Google";
import {useTracking} from "../../../components/_shared/hooks/useTracking";

const SignUpForm = (props: {onSignUp: (values?: CreateLeadSchema) => void}) => {
  const {trackEvent} = useTracking();
  return (
    <Google
      id={"sign-up-google-button"}
      label={"Sign up with Google"}
      onSubmit={(values: any) => {
        const cred = handleCredential(values);
        trackEvent("Registration Sign Up", {
          provider: "google",
          auth_user_id: cred?.auth_user_id,
        });
        return props.onSignUp(cred);
      }}
    />
  );
};

export default SignUpForm;
