import history from "./history";

export enum HistoryChangeType {
  PUSH = "push",
  REPLACE = "replace",
  OPEN = "open",
}
export const scrollToTop = (smooth: boolean) => {
  const scrollIds = [
    "portal-content-wrapper",
    "main-root",
    "main-design-content-wrapper",
  ];
  const options: any = {
    top: 0,
    left: 0,
  };
  if (smooth) {
    options.behavior = "smooth";
  }

  window.scrollTo(options);
  scrollIds.forEach((documentId) => {
    const element = document.getElementById(documentId);
    element?.scroll(options);
  });
};

export const scrollToElement = (elementId: string, smooth?: boolean) => {
  let options: any = {};
  if (smooth) {
    options.behavior = "smooth";
  }
  const mainContentElement = document.getElementById(elementId);
  mainContentElement?.scrollIntoView(options);
};

export const scrollToElementWithOffset = (
  elementId: string,
  offSet: {top: number},
  smooth?: boolean
) => {
  const doc = document?.getElementById(elementId);
  if (doc) {
    let options: ScrollToOptions = {
      behavior: smooth ? "smooth" : "auto",
      left: 0,
      top:
        doc.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offSet.top,
    };
    window?.scrollTo(options);
  }
};

export const setMultipleURLQueryKeyValues = (
  keyValues: {key: string; value: string | null}[],
  mode: HistoryChangeType = HistoryChangeType.PUSH,
  pathname?: string
) => {
  const initialSearch = window.location.search;
  let path = pathname ?? window.location.pathname;
  let searchParams = new URLSearchParams(initialSearch);
  console.log("NEW - M", searchParams);
  keyValues.forEach(({key, value}) => {
    updateSearchParam(searchParams, key, value);
  });
  const params = `?${searchParams.toString()}`;
  setNewURLQuery(history, path, params, mode);
};

export const setURLQueryKeyValue = (key: string, value: string | null) => {
  let pathname = window.location.pathname;
  const initialSearch = window.location.search;
  let searchParams = new URLSearchParams(initialSearch);
  console.log("NEW - S", searchParams);
  updateSearchParam(searchParams, key, value);
  setNewURLQuery(history, pathname, searchParams.toString());
};

export const replaceURLQueryKeyValue = (key: string, value: string | null) => {
  let pathname = window.location.pathname;
  const initialSearch = window.location.search;
  let searchParams = new URLSearchParams(initialSearch);
  console.log("REPLACE", key, value);
  updateSearchParam(searchParams, key, value);
  setNewURLQuery(
    history,
    pathname,
    searchParams.toString(),
    HistoryChangeType.REPLACE
  );
};

export const setURLQuery = (key: string, value: string | null) => {
  setURLQueryKeyValue(key, value);
};

const updateSearchParam = (
  searchParams: URLSearchParams,
  key: string,
  value: string | null
) => {
  if (value != null) {
    if (searchParams.get(key) !== value) {
      searchParams.set(key, value);
    }
  } else {
    searchParams.delete(key);
  }
};

const setNewURLQuery = (
  history: any,
  pathname: string,
  searchParams: string,
  mode: HistoryChangeType = HistoryChangeType.PUSH
) => {
  const initialSearch = window.location.search;
  const oldSearchParams = new URLSearchParams(initialSearch);
  if (oldSearchParams.toString() !== searchParams) {
    if (mode === HistoryChangeType.PUSH) {
      history.push({pathname: pathname, search: searchParams});
    } else if (mode === HistoryChangeType.REPLACE) {
      history.replace({pathname: pathname, search: searchParams});
    } else if (mode === HistoryChangeType.OPEN) {
      window.open(pathname + searchParams, "_blank");
    }
  }
};

export const formNewURL = (options: {
  baseURL?: string;
  pathName?: string;
  params?: {key: string; value?: string | null}[];
  useAbsoluteURL?: boolean;
}) => {
  let baseURL = "";
  if (options.useAbsoluteURL) {
    baseURL = options?.baseURL ?? window.location.origin;
  }
  const pathName = options?.pathName ?? window.location.pathname;

  const searchParams = new URLSearchParams(window.location.search);
  options?.params?.forEach((param) => {
    if (param.value != null) {
      searchParams.set(param.key, param.value);
    } else {
      searchParams.delete(param.key);
    }
  });
  const search = "?" + searchParams.toString();

  return baseURL + pathName + search;
};

export const setURLPathAndQuery = (
  pathname: string,
  key?: string,
  value?: string
) => {
  let searchParams = new URLSearchParams(window.location.search);
  if (key != null) {
    if (value != null) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  }
  console.log("SET - PUSH", searchParams);
  history.push({pathname: pathname, search: searchParams.toString()});
};

export const setURLPathAndMultipleQueries = (
  pathname: string,
  keyValues: {key: string; value: string | null}[],
  mode: HistoryChangeType = HistoryChangeType.PUSH
) => {
  setMultipleURLQueryKeyValues(keyValues, mode, pathname);
};

export const setURLHash = (hashValue: string) => {
  window.location.hash = hashValue;
};
