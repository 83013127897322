import React from "react";
import styled from "styled-components";

const ChevronSvg = styled.svg`
  width: 16px; // Change the size as needed
  height: 16px; // Change the size as needed
  pointer-events: none;
  cursor: pointer;
  transition: all 120ms ease-in-out;
`;

const ChevronDown = (props: React.SVGProps<SVGPathElement>) => {
  return (
    <ChevronSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill={"none"}
    >
      <path
        d="M4 6L8 10L12 6"
        {...props}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </ChevronSvg>
  );
};

const HeaderChevronDown = styled(ChevronDown)``;

export default HeaderChevronDown;
