import React from "react";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {ContainerWrapper} from "./styles";
import {LayoutProvider, useLayout} from "./LayoutContext";
import {LeftContent} from "./LeftContent";
import {RightContent} from "./RightContent";
import costa_mesa from "./costa_mesa.jpg";
import bolitas from "./bolinas.jpg";
import folsom from "./folsom.jpg";
import san_diego from "./san_diego.jpg";
import milpitas from "./milpitas.jpg";
import santa_cruz from "./santa_cruz.jpg";

const slides = [
  {
    title: "Costa Mesa, California",
    description: "Two detached ADUs",
    image: costa_mesa,
  },
  {
    title: "Bolinas, California",
    description: "Two detached ADUs",
    image: bolitas,
  },
  {
    title: "Folsom, California",
    description: "Two detached ADUs",
    image: folsom,
  },
  {
    title: "San Diego, California",
    description: "Two detached ADUs",
    image: san_diego,
  },
  {
    title: "Milpitas, California",
    description: "Two detached ADUs",
    image: milpitas,
  },
  {
    title: "Santa Cruz, California",
    description: "Three detached ADUs",
    image: santa_cruz,
  },
];

const B2BViewContent: React.FC = () => {
  const {isStacked} = useLayout();

  return (
    <>
      <div style={{height: isStacked ? "8px" : "36px"}} />
      <ContainerWrapper isStacked={isStacked}>
        <LeftContent />
        <RightContent slides={slides} />
      </ContainerWrapper>
      <div style={{height: isStacked ? "8px" : "36px"}} />
    </>
  );
};

const B2BView: React.FC = () => {
  return (
    <PageContainer backgroundColor={"#EDEBE7"}>
      <LayoutProvider>
        <B2BViewContent />
      </LayoutProvider>
    </PageContainer>
  );
};

export default B2BView;
