import {AuthenticationFormState} from "./interface";

const CustomerSegments: AuthenticationFormState[] = [
  AuthenticationFormState.CONFIRM_CUSTOMER,
  AuthenticationFormState.CONFIRM_BUSINESS,
  AuthenticationFormState.CONFIRM_DEVELOPMENT,
];

export const validateAuthenticationFormState = async (props: {
  currentMode: AuthenticationFormState;
  targetMode?: AuthenticationFormState;
  hasAuthId?: boolean;
  hasLead?: boolean;
  hasEmail?: boolean;
  hasLeadSegment?: boolean;
  hasProjectIds?: boolean;
  reset?: () => void;
}) => {
  console.log("Validating form state", props);
  return getValidState(props);
};

const getValidState = async (props: {
  currentMode: AuthenticationFormState;
  targetMode?: AuthenticationFormState;
  hasAuthId?: boolean;
  hasLead?: boolean;
  hasEmail?: boolean;
  hasLeadSegment?: boolean;
  hasProjectIds?: boolean;
  reset?: () => void;
}) => {
  const {targetMode, currentMode, hasEmail, hasLeadSegment, hasAuthId, hasLead, hasProjectIds} = props;



  // Check if either current or target mode matches these special states
  const specialConditions = [
    AuthenticationFormState.LOGGING_OUT,
    AuthenticationFormState.COMPLETE,
    AuthenticationFormState.NOT_AVAILABLE_YET,
    AuthenticationFormState.RESET_PASSWORD,
  ];
  
  // If the target mode is a special state and the current mode is also a special state, return the current mode
  if (targetMode === AuthenticationFormState.COMPLETE && currentMode === AuthenticationFormState.NOT_AVAILABLE_YET) {
    return targetMode;
  } else if (targetMode && specialConditions.includes(targetMode)) {
    return targetMode;
  } else if (!targetMode && specialConditions.includes(currentMode)) {
    return currentMode;
  }

  // If the target mode is not set, use the current mode to determine the target
  const target: AuthenticationFormState = targetMode ?? currentMode;

  // If the user has a lead, and project ids, and is not on a customer segment, show complete
  // This handles dismissing the modal if the user has previously submitted!
  if (hasLead && hasProjectIds && !CustomerSegments.includes(target)) {
    return AuthenticationFormState.COMPLETE;
  }

  // If the user does not have a lead, show the targeted form, or login
  if (!hasAuthId) {
    return [
      AuthenticationFormState.CREATE_CONTACT,
      AuthenticationFormState.LOGIN_LANDING,
      AuthenticationFormState.SIGN_UP_LANDING,
    ].includes(target)
      ? target
      : AuthenticationFormState.LOGIN_LANDING;
  }

  // All users must have an email
  if (!hasEmail) {
    console.error(
      "Credential must have an email, resetting user and form state."
    );
    props.reset?.();
  }

  if (!hasLeadSegment || !hasProjectIds) {
    return [
      AuthenticationFormState.CONFIRM_BUSINESS,
      AuthenticationFormState.CONFIRM_CUSTOMER,
      AuthenticationFormState.CONFIRM_DEVELOPMENT,
      AuthenticationFormState.SELECT_CUSTOMER_TYPE,
    ].includes(target)
      ? target
      : AuthenticationFormState.SELECT_CUSTOMER_TYPE;
  }
  return target;
};
