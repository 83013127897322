import React from "react";
// const TmShapeLogoPath = require("../../../assets/logos/villa-logo-cropped.svg");
import TmShapeLogoPathReversed from "../../../assets/logos/villa-logo-shape-tm-reversed.svg";

const TmShapeLogo = (props: any) => {
  const style = props.style || {};
  const alt = props.alt || "TmShape Logo";

  return <img src={TmShapeLogoPathReversed} style={style} alt={alt} />;
};

export default TmShapeLogo;
