import React, {Dispatch, SetStateAction} from "react";
import {
  NatModal,
  NatModalSize,
} from "../../../../_shared/generics/modal/NatModal";
import {HOME_PAGE_ID} from "../../../../portal/_shared/navigation/constants";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {SmartImage} from "../../../../_shared/generics/image/SmartImage";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import {
  getSelectedModifiersFromSnapshot,
  makeNounPlural,
} from "@natomas-org/core";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useSelectedProduct} from "../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";
import {LINK_COLOR, LINK_HOVER_COLOR} from "../../../../_shared/colors";
import Button from "../../../../../0-app/2-component/Button";

export const DesignHomeModalWarning = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  continueAction: any;
  title: string;
  continueString: string;
  showComparison: boolean;
  content: string;
}) => {
  const {configuration, snapshot, id} = useActiveDesign();
  const product = useSelectedProduct();
  const {to} = useNavigation();
  const size = useDynamicValue(fullColumnWidths);
  const closeDesignHomeModalWarning = () => {
    props.setShow(false);
  };

  let upgradeText = "(0 upgrades)";
  if (snapshot && (configuration?.product || product?.id)) {
    const modifiers = getSelectedModifiersFromSnapshot(
      snapshot.snapshot,
      configuration?.product?.id ?? product?.id
    );
    const modifiersList = Object.values(modifiers).filter(
      (modifier: any) => modifier.adjustedPriceMicros !== 0
    );
    upgradeText = `${modifiersList.length} upgrade${makeNounPlural(
      modifiersList.length
    )}`;
  }
  if (!configuration) {
    return null;
  }

  return (
    <NatModal
      show={props.show}
      handleClose={closeDesignHomeModalWarning}
      content={
        <>
          <WarningContainer>
            {!!product && product?.imageId && props.showComparison && (
              <div style={{display: "flex", gap: "10px"}}>
                <UnitView
                  imageId={configuration?.product?.imageId ?? ""}
                  ADUTitle={configuration?.product?.title}
                  description={configuration?.product?.displayDescription}
                  priceUpgradeText={upgradeText}
                  size={size}
                  showChangeButton={false}
                  title={"Previous home"}
                  previous={true}
                />
                <UnitView
                  imageId={product?.imageId}
                  ADUTitle={product?.title}
                  description={product?.displayDescription}
                  priceUpgradeText={""}
                  size={size}
                  showChangeButton={false}
                  title={"New home"}
                  previous={false}
                />
              </div>
            )}
            <div>
              {props.content}
              {" If you would like assistance, please reach out to us through "}
              <DashboardLink
                onClick={() => {
                  to(NavigationPaths.PORTAL, HOME_PAGE_ID, id);
                }}
              >
                your dashboard
              </DashboardLink>
              .
            </div>
          </WarningContainer>
        </>
      }
      size={NatModalSize.NORMAL}
      header={props.title}
      footerButtons={[
        {
          label: "Cancel",
          trackingId: "your-unit-warning-dismiss",
          clickEvent: closeDesignHomeModalWarning,
          option: StyleOption.SECONDARY_ALT,
          type: "button",
        },
        {
          label: props.continueString,
          option: StyleOption.DESTRUCTIVE,
          clickEvent: () => {
            props.continueAction();
            closeDesignHomeModalWarning();
          },
          type: "button",
          trackingId: "your-unit-continue-to-catalog",
        },
      ]}
    />
  );
  // }
};

interface ADUProps {
  title: string;
  ADUTitle: string;
  imageId: string;
  priceUpgradeText: string;
  description: string;
  size: number;
  showChangeButton: boolean;
  previous: boolean;
}

export const UnitView = (props: ADUProps) => {
  const {
    title,
    ADUTitle,
    imageId,
    priceUpgradeText,
    size,
    showChangeButton,
    previous,
  } = props;
  const {to} = useNavigation();
  return (
    <BlurbContainer previous={previous}>
      <PageElement size={size} height={"auto"}>
        <UnitBlurbTitle>{title}</UnitBlurbTitle>
      </PageElement>
      <PageElement size={size} height={"auto"}>
        <UnitImage style={{height: "7rem"}}>
          <SmartImage imageId={imageId} />
        </UnitImage>
      </PageElement>
      <PageElement size={size} height={"auto"}>
        <UnitTitle>{ADUTitle}</UnitTitle>
        <UnitBlurbText>{priceUpgradeText}</UnitBlurbText>
        {/*<UnitBlurbDescription>{description}</UnitBlurbDescription>*/}
        {showChangeButton && (
          <>
            <div
              data-tip
              data-for={"tooltip-change-adu"}
              style={{width: "100%"}}
            >
              <Button
                containerStyle={{
                  width: "100%", //changeADUButtonWidthPercent + "%",
                }}
                id={"payments-change-home"}
                label={"Change home"}
                theme={"secondary"}
                onClick={() => to(NavigationPaths.CATALOG, {})}
              />
            </div>
            <ReactTooltip
              place={"bottom"}
              id={"tooltip-change-adu"}
              effect="solid"
            >
              Choose your favorite, you can change your mind even after deposit
            </ReactTooltip>
          </>
        )}
      </PageElement>
    </BlurbContainer>
  );
};

const BlurbContainer = styled.div<{previous: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 0;
  padding: 0.25rem;
`;

const UnitImage = styled.div`
  height: 14rem;
  width: 100%;
  position: relative;

  .fullDiv {
    border-radius: 1rem;
  }
`;
const UnitBlurbTitle = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_REGULAR};
  vertical-align: text-top;
  text-align: left;
  height: 1.5rem;
`;

const UnitTitle = styled.div`
  font-size: 1rem;
  font-family: ${FONT_FAMILY_BOLD};
  vertical-align: text-top;
`;

export const UnitBlurbText = styled.div`
  vertical-align: text-top;
  font-size: 16px;
  padding-top: 4px;
  line-height: 133%;
  color: rgba(0, 0, 0, 0.7);
  height: 100%;
`;

export const DashboardLink = styled.a`
  font-family: ${FONT_FAMILY_BOLD};
  cursor: pointer;
  color: ${LINK_COLOR};
  &:hover {
    color: ${LINK_HOVER_COLOR};
  }
`;

export const WarningContainer = styled.div`
  margin: 1rem 0;
  div {
    padding-bottom: 0.5rem;
    &:last-child {
      padding-bottom: 0;
    }
  }
`;
