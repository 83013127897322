import { NavLink } from "react-router-dom";
import {HEADER_Z_INDEX} from "src/components/_shared/styles";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  background-color: white; // #f7f5f2;
  z-index: ${HEADER_Z_INDEX};
  position: relative;
  height: 100px;
`;

export const MobileHeaderContainer = styled.div<{custom_mode?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  background-color: white; // #f7f5f2;
  z-index: ${HEADER_Z_INDEX};
  height: 70px;
  /* If custom_mode is studio, make sticky and to 0, left 0, and right 0*/
  position: ${({custom_mode}) =>
    custom_mode === "studio" ? "fixed" : "relative"};
  top: 0;
  left: 0;
  right: 0;
`;
export const MobileHeaderMenuSheet = styled.div<{show: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 32px 64px 32px;
  width: 100%;
  position: fixed;
  top: 70px;
  height: calc(100vh - 70px);
  left: ${({show}) => (show ? "0" : "-100%")};
  transition: all 500ms ease;
  background-color: white; // #f7f5f2;
  z-index: ${HEADER_Z_INDEX - 100}; // Ensure it sits above other content
  cursor: pointer;
  overflow-y: auto;
`;

export const MobileAvatarMenuSheet = styled.div<{show: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 32px 64px 32px;
  width: 100%;
  position: fixed;
  top: 70px;
  height: calc(100vh - 70px);
  right: ${({show}) => (show ? "0" : "-100%")};
  transition: all 500ms ease;
  background-color: white; // #f7f5f2;
  z-index: ${HEADER_Z_INDEX - 100}; // Ensure it sits above other content
  cursor: pointer;
  overflow-y: auto;
`;

export const MobileContainerGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  transition: all 240ms ease-in-out;
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-end;
  white-space: nowrap;
`;

export const MobileHeaderLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`;

export const HeaderLinkContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  path {
    stroke: #04171b;
    stroke-width: 1;
    transition: all 120ms ease-in-out;
    pointer-events: none;
  }
  &:hover {
    path {
      pointer-events: none;
      stroke: #0080a7;
      stroke-width: 2;
    }
  }
`;

export const HeaderLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const MobileMenuLink = styled(NavLink)`
  color: #5a4e4e;
  font-feature-settings: "liga" off, "clig" off;

  /* body/body-lg/medium */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  &:hover,
  &:focus {
    color: #0080a7;
    text-decoration: none;
  }
`;

export const Rotator = styled.div<{rotate: boolean}>`
  transform: rotate(${(props) => (props.rotate ? "180deg" : "0deg")});
  transition: all 120ms ease-in-out;
`;

export const HeaderLink = styled(NavLink)<{selected?: boolean}>`
  text-decoration: none;
  font-feature-settings: "liga" off, "clig" off;
  color: ${(props) => {
    return props.selected ? "#0080a7" : "#5A4E4E";
  }};
  &:hover,
  &:focus {
    color: #0080a7;
    text-decoration: none;
  }
  /* title/section-title */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: ${(props) => (props.selected ? 600 : 500)};
  font-style: normal;
  font-size: 20px;
  line-height: 20px; /* 160% */
  letter-spacing: -0.2px;
  margin: 0;
  cursor: pointer;
`;
