import React from "react";
import Image from "../../../2-component/Image";
import logo from "./villa-logo-cropped.svg";
import {ImageProps} from "../../../2-component/Image/interface";

const Logo = (props: Partial<ImageProps>) => {
  return (
    <Image
      src={logo}
      alt={"Villa Homes"}
      style={{
        width: "111px",
        margin: "32px auto 32px auto",
        display: "block",
      }}
      {...props}
    />
  );
};

export default Logo;
