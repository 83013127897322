import React, {HTMLAttributes} from "react";
import styled from "styled-components";

const Header = (props: HTMLAttributes<HTMLHeadingElement>) => {
  return <StyledHeader {...props} />;
};

const StyledHeader = styled.h1`
  color: #3d4f54;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "p22-mackinac-pro", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  white-space: pre-wrap;
  width: 100%;
  margin-bottom: 16px;
`;

export default Header;
