import React, {useMemo} from "react";
import styled from "styled-components";
import {LinkTheme, TextProps} from "./interface";

const Text = (props: TextProps) => {
  const {text, links, ...rest} = props;
  const children = useMemo(() => {
    if (!links) return <Span>{text}</Span>;
    // Constructing the regular expression pattern
    const keywordsPattern = links
      .map((link) => link.replacementKey.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
      .join("|");
    const regex = new RegExp(`(${keywordsPattern})`, "g");

    const allParts = text.split(regex);
    return allParts.map((part, index) => {
      const link = links.find((link) => link.replacementKey === part);
      if (link) {
        const {replacementKey, type, ...linkProps} = link;
        return (
          <A {...linkProps} key={index} type={type}>
            {replacementKey}
          </A>
        );
      }
      return <Span key={index}>{part}</Span>;
    });
  }, [text, links]);

  return <Container {...rest}>{children}</Container>;
};

const Span = styled.span`
  color: #667085;

  /* Text sm/Normal */
  font-family: "Inter-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin: 0;
`;

const A = styled.a<{theme?: LinkTheme}>`
  /* Text sm/Normal */
  font-family: "Inter-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 14px;

  cursor: pointer;

  margin: 0;

  ${(props) => {
    switch (props.theme) {
      case "action":
        return `
          color: #368836;
        `;
      case "default":
      default:
        return `
          text-decoration-line: underline;
          font-weight: 400;
          color: #667085;
        `;
    }
  }}
`;

const Container = styled.div`
  color: #807d7a;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  line-height: 14px;
`;

export default Text;
