import React, {useMemo} from "react";
import styled from "styled-components";
import Button from "../../../2-component/Button";
import Text from "../../../2-component/Text";

export interface IAvatarDynamicCallToActionProps {
  mode?: "select-a-home" | "order-feasibility-study" | "none";
  customer_email: string;
}

const AvatarDynamicCallToAction = (props: IAvatarDynamicCallToActionProps) => {
  const {mode = "none", customer_email} = props;
  const content = useMemo(() => {
    switch (mode) {
      case "select-a-home":
        return <SelectAHomeContent />;
      case "order-feasibility-study":
        return <OrderFeasibilityStudyContent customer_email={customer_email} />;
      default:
        return null;
    }
  }, [mode]);
  if (!content) {
    return null;
  }
  return <Container>{content}</Container>;
};

const SelectAHomeContent = () => {
  return (
    <>
      <TextStyle>
        <Text text={"Select a home to get a free estimate"} />
      </TextStyle>
      <Button
        id={"avatar_browse_homes"}
        data-cy={"avatar_browse_homes"}
        label={"Browse Homes"}
        size={"small"}
        onClick={() => {
          window.location.pathname = "/products";
        }}
      />
    </>
  );
};

const OrderFeasibilityStudyContent = (props: {customer_email?: string}) => {
  return (
    <>
      <TextStyle>
        <Text
          text={"Want to move fast? Order a Feasibility Study."}
          links={[
            {
              replacementKey: "Feasibility Study",
              href: "/feasibility-study",
              target: "_blank",
              rel: "noreferrer",
            },
          ]}
        />
      </TextStyle>
      {
        <Button
          id={"avatar_order_feasibility"}
          data-cy={"avatar_order_feasibility"}
          label={"Order a Feasibility Study"}
          size={"small"}
          onClick={() => {
            window.open(
              props.customer_email
                ? "https://buy.stripe.com/6oEg2tcyT6GWaLS28b?prefilled_email=" +
                    props.customer_email
                : "https://buy.stripe.com/6oEg2tcyT6GWaLS28b"
            );
          }}
        />
      }
    </>
  );
};

const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 10px;
  background: #e7f5ff;
  width: 100%;
`;

const TextStyle = styled.span`
  span {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;

    /* body/body-md/medium */
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 142.857% */
  }
  a {
    color: #0080a7;
    font-feature-settings: "liga" off, "clig" off;

    /* body/body-md/medium */
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px;
    text-decoration-line: none;
  }
`;

export default AvatarDynamicCallToAction;
