import React, {Dispatch, SetStateAction, useEffect, useMemo} from "react";

//Hooks
//Views
import {MainView} from "../../../views/MainView/CatalogMainView";
import {ProductView} from "../../../views/ProductView";
import {ProductLineView} from "../../../views/ProductLineView";

//Core
import {isEmpty, ListingStatusDetails} from "@natomas-org/core";

//Shared
//Styles
import {CatalogContainer} from "../../../views/styles";
import {CatalogMode} from "../../../views/PortalCatalog";
import {InventoryView} from "../../../views/InventoryView";
import {NonServiceableBlurb} from "../NonServiceableBlurb";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useSelectedProduct} from "../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {useFactoryLines} from "../../../../_shared/hooks/useProductCatalog/useFactoryLines/useFactoryLines";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {setMultipleURLQueryKeyValues} from "../../../../_shared/navigation/_helpers";
import {PROJECT_ID_URL_KEY} from "../../../../../database/firebase/configuration/configuration";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {ThemeProvider} from "styled-components";
import {useSelector} from "react-redux";
import {IStore} from "../../../../_shared/slices/types/Store";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {useLocation, useParams} from "react-router-dom";
import {getURLParameter} from "src/components/_shared/cookies";

const Catalog = (props: {
  applyDefaultFilterOptions: boolean;
  defaultMode?: CatalogMode;
  setShowFinder?: Dispatch<SetStateAction<boolean>>;
}) => {
  /*
   * Catalog has currently two modes that are anticipated.
   * 1. Website Catalog (simply browsing)
   * 2. Portal Catalog (browsing and selection)
   * */

  const {defaultMode} = props;
  //Custom Hooks
  const {factoryLines} = useFactoryLines({
    listingStatus: {[ListingStatusDetails.LISTED]: true},
  });

  const unit = getURLParameter("unit");
  const gid = getURLParameter("gid");
  // if path is products/readybuilt
  const path = useLocation();
  const {customer} = useCurrentCustomer();
  const isPublicApp = useIsPublicApp();
  const {id} = useActiveProject();

  const mode = useMemo(() => {
    if (path.pathname === "/products/readybuilt") {
      return CatalogMode.INVENTORY;
    } else if (unit) {
      return CatalogMode.PRODUCT;
    } else if (gid) {
      return CatalogMode.PRODUCT_LINE;
    } else if (factoryLines && !isEmpty(factoryLines)) {
      return defaultMode;
    }
    return defaultMode;
  }, [factoryLines, defaultMode, unit, gid]);

  useEffect(() => {
    // Make sure non-admin account holders are viewing current project catalog, not public catalog
    if (!!customer && isPublicApp) {
      setMultipleURLQueryKeyValues([{key: PROJECT_ID_URL_KEY, value: id}]);
    }
  }, [customer, id]);

  let Content;
  switch (mode) {
    case CatalogMode.PRODUCT:
      Content = <ProductView />;
      break;
    case CatalogMode.PRODUCT_LINE:
      Content = <ProductLineView />;
      break;
    case CatalogMode.PRODUCT_LINES:
      Content = <MainView />;
      break;
    case CatalogMode.INVENTORY:
      Content = <InventoryView />;
      break;
    default:
      Content = <></>;
      break;
  }

  const showNoServiceBlurb: boolean = useMemo(() => {
    return (
      (mode === CatalogMode.NONE &&
        factoryLines &&
        factoryLines.length === 0) ??
      true
    );
  }, [mode, factoryLines]);

  // const appSwitcher =
  //   isNatMobile && isPublicApp ? (
  //     <PageContainer>
  //       <AppSwitcher />
  //     </PageContainer>
  //   ) : null;
  return (
    <>
      {/*<CatalogPath*/}
      {/*  showMainView={true}*/}
      {/*  inventory={mode === CatalogMode.INVENTORY}*/}
      {/*/>*/}
      {/*{appSwitcher}*/}
      <NonServiceableBlurb show={showNoServiceBlurb} pid={id} />
      <CatalogContent>{Content}</CatalogContent>
    </>
  );
};

const CatalogContent = (props: {children: React.ReactNode}) => {
  const {isAdmin} = useCurrentUser();
  const productViewContentWidth = useDynamicValue({
    forFour: 4,
    forEight: 5,
    forTwelve: 8,
    forSixteen: 12,
  });
  const {adminCatalogMode} = useCatalogSession();
  const columnCount = useSelector((state: IStore) => state.page.columnCount);
  const gutterWidthPercent = useSelector(
    (state: IStore) => state.page.gutterWidthPercentage
  );
  const containerPadding = useSelector(
    (state: IStore) => state.page.containerPadding
  );
  const CatalogTheme = {
    User: {
      isAdmin: isAdmin,
    },
    Page: {
      columnCount: columnCount,
      isNatMobile: columnCount === 4,
      gutterWidthPercentage: gutterWidthPercent,
      gutterWidthPixels: containerPadding,
    },
    Card: {
      columnWidth: 4,
      borderRadius: "10px",
      imageHeight: "10.3125rem",
      height: adminCatalogMode ? "20rem" : "16.25rem",
    },
    CatalogSession: {
      adminCatalogMode: adminCatalogMode,
    },
    ProductViewContent: {
      width: productViewContentWidth,
      showPriceCard: true,
      showTitle: true,
      showEmbeddedMap: true,
    },
    Filters: {
      gapBetweenFilters: "1rem",
      showVerticalLine: columnCount !== 4,
      marginPaddingTop: "0.5rem",
      marginPaddingBottom: "0.5rem",
      marginLeftRight: columnCount === 4 ? "0.5rem" : undefined,
      filterHeight: columnCount === 4 ? "3rem" : "2rem",
      distanceBetweenTextAndChevron: "1rem",
      filterButtonStyling:
        columnCount === 4
          ? {width: "100%", flexGrow: 1}
          : {width: "max-content"},
    },
  };
  return (
    <CatalogContainer>
      <ThemeProvider theme={CatalogTheme}>{props.children}</ThemeProvider>
    </CatalogContainer>
  );
};

export {Catalog};
