// BuildingMultipleUnitsBanner.js

import React, {HTMLProps} from "react";
import {Container, DiamondIcon, StyledLink, Text} from "./styles";
import {usePage} from "../../../components/_shared/hooks/usePage";
// ------------------------------
// Sub-component for the Diamond
// ------------------------------

// props are svg element props
export const DarkBlueDiamond = (props: {
  style?: HTMLProps<SVGElement>["style"];
}) => (
  <DiamondIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    style={{
      minHeight: "32px",
      minWidth: "32px",
      ...props.style,
    }}
  >
    <path
      d="M8 0L16 8L8 16L0 8L8 0Z"
      fill="#0080A7"
      transform="translate(8,8)"
    />
  </DiamondIcon>
);

// ------------------------------
// Main Component
// ------------------------------

const BuildingMultipleUnitsBanner = ({showDiamond = false}) => {
  const {columnCount} = usePage();
  const isStacked = columnCount <= 8;

  return (
    <Container>
      {showDiamond && <DarkBlueDiamond style={{marginRight: "8px"}} />}
      <Text
        style={{
          fontSize: showDiamond ? (isStacked ? "14px" : "16px") : "12px",
        }}
      >
        Building multiple units?{" "}
        <StyledLink
          style={{
            fontSize: showDiamond ? (isStacked ? "14px" : "16px") : "12px",
          }}
          target={"_blank"}
          href="/contact-us"
        >
          Contact us for pricing
        </StyledLink>
      </Text>
    </Container>
  );
};

export default BuildingMultipleUnitsBanner;
