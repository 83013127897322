export interface Link {
  id: string;
  label: string;
  pathname: string | null;
  is_selected?: () => boolean;
}

export const all_links: {[id: string]: Link} = {
  header_browse_homes: {
    id: "header_browse_homes",
    label: "Browse ADUs",
    pathname: "/products",
    is_selected: () => {
      return window.location.pathname.includes("/products");
    },
  },
  header_what_we_do: {
    id: "header_what_we_do",
    label: "What we do",
    pathname: null, // This is a dropdown link, no direct href
  },
  header_resources: {
    id: "header_resources",
    label: "Resources",
    pathname: null, // This is a dropdown link, no direct href
  },
  header_contact_us: {
    id: "header_contact_us",
    label: "Contact us",
    pathname: "/contact-us",
  },
  header_sign_in: {
    id: "header_sign_in",
    label: "Sign in",
    pathname: "/login",
  },
  header_for_homeowners: {
    id: "header_for_homeowners",
    label: "ADUs for homeowners",
    pathname: "/for-homeowners",
  },
  header_for_multifamily: {
    id: "header_multifamily",
    label: "ADUs for multifamily",
    pathname: "/multifamily",
  },
  header_for_developers: {
    id: "header_for_developers",
    label: "Services for developers",
    pathname: "/for-developers",
  },
  header_about_villa_our_mission_and_team: {
    id: "header_about_villa_our_mission_and_team",
    label: "Our mission & team",
    pathname: "/about-us",
  },
  header_about_villa_adu_pricing: {
    id: "header_about_villa_adu_pricing",
    label: "ADU pricing",
    pathname: "/pricing",
  },
  header_about_villa_offsite_construction: {
    id: "header_about_villa_offsite_construction",
    label: "Offsite construction",
    pathname: "/for-developers",
  },
  header_about_villa_map_my_lot: {
    id: "header_about_villa_map_my_lot",
    label: "Map my lot",
    pathname: "/map",
  },
  header_about_villa_featured_projects: {
    id: "header_about_villa_featured_projects",
    label: "Featured projects",
    pathname: "/featured-projects",
  },
  header_about_villa_press: {
    id: "header_about_villa_press",
    label: "Press",
    pathname: "/press",
  },
  header_about_villa_our_blog: {
    id: "header_about_villa_our_blog",
    label: "Blog",
    pathname: "/blog",
  },
};

export const about_me_menu_links = [
  all_links.header_about_villa_our_mission_and_team,
  all_links.header_about_villa_adu_pricing,
  all_links.header_about_villa_offsite_construction,
  all_links.header_about_villa_map_my_lot,
  all_links.header_about_villa_featured_projects,
  all_links.header_about_villa_press,
  all_links.header_about_villa_our_blog,
];
