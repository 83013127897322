import React, {useMemo} from "react";
import styled from "styled-components";
import {
  Address,
  getPricingForUnitAndPriceMode,
  priceTextFromMicros,
  ProjectPriceMode,
} from "@natomas-org/core";
import {InstantQuoteHomeCard} from "./components/InstantQuoteHomeCard";
import {InstantQuotePriceCard} from "./components/InstantQuotePriceCard";
import {MobileStickyBottomCenteredButton} from "../../_shared/components/MobileStickyBottom";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {usePage} from "../../../_shared/hooks/usePage";
import {viewInventoryUnitInStudio} from "../../../_shared/hooks/useStudio/helper";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {getUpgradesList} from "../../../_shared/snapshot";
import {FAQAndScheduleAssessment} from "../SSDDashboardView/components/ANDY-components/FAQAndScheduleAssessment";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../_shared/hooks/useDesign/useActiveDesign";
import {InstantQuoteB2BPlaceholder} from "./components/InstantQuoteB2BPlaceholder";
import {useActiveCartItem} from "../../../_shared/hooks/useCart/useCartItem/useActiveCartItem";
import useActivitySliceDispatcher from "../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import useInstantQuote from "../../../../api/InstantQuote/useInstantQuote";
import VCL from "@natomas-org/villa-component-library";
import {bulletPoints, ISiteWorkBucket} from "./interface";
import {InstantQuoteEmptySiteWorkCard} from "./components/InstantQuoteEmptySiteWorkCard";
import {
  getLocationLabelFromKey,
  LocationStandardFinishingLabelSlug,
} from "../../../../0-app/1-container/admin-product-instant-quote-page/EditLocation";
import InstantQuoteContinue from "../../../admin/DeveloperView/InstantQuoteContinue";
import {GRANITE, WARM_NEUTRAL} from "../../../_shared/colors";
import InstantQuoteDisclaimer from "./components/InstantQuoteDisclaimer";
import InstantQuoteModelHomes from "./components/InstantQuoteModelHomes";
import IntakeQuoteB2BBanner from "./components/IntakeQuoteB2BBanner";
import Button from "../../../../0-app/2-component/Button";

export const InstantQuoteView = () => {
  // Disclaimer modal
  const {id, address, projectPriceMode, projectOverheadProfit} =
    useActiveProject();
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {configuration, snapshot} = useActiveDesign();
  const {id: cartItemId} = useActiveCartItem() ?? {};
  const {to} = useNavigation();
  const product = useMemo(() => {
    if (configuration?.product) return configuration.product;
    else return null;
  }, [configuration]);
  const {data: productInstantQuote} = useInstantQuote(
    product?.id,
    address?.latitude ?? undefined,
    address?.longitude ?? undefined
  );
  const siteWorkPricing: {
    rows: ISiteWorkBucket[];
    totalSiteWorkPrice: number;
    homeFinishing: {slug: string; price: number};
  } = useMemo(() => {
    if (productInstantQuote) {
      const siteWork = productInstantQuote.filter(
        (item: any) => item.slug !== LocationStandardFinishingLabelSlug
      );
      const homeFinishing: {slug: string; price: number} =
        productInstantQuote.find(
          (item: any) => item.slug === LocationStandardFinishingLabelSlug
        );
      const standardFinishing = {
        slug: LocationStandardFinishingLabelSlug,
        price: homeFinishing?.price ?? 0,
      };
      const rows: ISiteWorkBucket[] = siteWork.map((item: any) => {
        return {
          title: getLocationLabelFromKey(item.slug),
          priceText: priceTextFromMicros(item.price, "accurate"),
          bulletPoints: bulletPoints[item.slug] ?? [],
        };
      });
      const totalSWPrice = siteWork.reduce((acc, item) => acc + item.price, 0);

      return {
        rows: rows,
        totalSiteWorkPrice: totalSWPrice,
        homeFinishing: standardFinishing,
      };
    }
    return {
      rows: [],
      totalSiteWorkPrice: 0,
      homeFinishing: {slug: LocationStandardFinishingLabelSlug, price: 0},
    };
  }, [productInstantQuote]);

  const totalEstimatePrice = useMemo(() => {
    const unitPrice = getPricingForUnitAndPriceMode(
      configuration,
      projectPriceMode,
      {
        addSalesTax: true,
        opFraction: projectOverheadProfit,
      }
    );
    return (
      (unitPrice?.totalDesignPriceMicros ?? 0) +
      siteWorkPricing.totalSiteWorkPrice +
      siteWorkPricing.homeFinishing.price
    );
  }, [configuration, projectPriceMode, projectOverheadProfit, siteWorkPricing]);

  const {containerPadding} = usePage();

  const callToActionWidth = useDynamicValue(fullColumnWidths);
  const isNatMobile = useDynamicValue({
    forFour: true,
    forEight: true,
    forTwelve: false,
    forSixteen: false,
  });
  const priceCardContainer = useDynamicValue({
    forFour: 4,
    forEight: 8,
    forTwelve: 4,
    forSixteen: 16 / 3,
  });
  const {SHOW_FAQ_EVERYWHERE} = useGlobalConfiguration();
  if (projectPriceMode === ProjectPriceMode.CostPlus) {
    return <InstantQuoteB2BPlaceholder id={id} />;
  }
  const infoCards = (to: any) => {
    const upgradesList = getUpgradesList(snapshot, projectPriceMode);
    const upgradePriceString = priceTextFromMicros(
      getPricingForUnitAndPriceMode(configuration, projectPriceMode, {
        addSalesTax: true,
        opFraction: projectOverheadProfit,
      })?.upgradesPriceMicros ?? 0,
      "accurate"
    );
    return (
      <ThreeCardContainer isNatMobile={isNatMobile}>
        <>
          <PageElement size={priceCardContainer} height={"auto"}>
            <InstantQuoteHomeCard
              priceMode={projectPriceMode}
              product={product}
              homeFinishing={siteWorkPricing?.homeFinishing}
              address={address ? Address.getFullAddress(address) : ""}
              button={
                <Button
                  id={"instant-quote-to-catalog-button"}
                  label={"Change home"}
                  onClick={() => to(NavigationPaths.CATALOG, {})}
                  type={"button"}
                  theme={"secondary"}
                  containerStyle={{width: "fit-content"}}
                />
              }
            />
          </PageElement>
          <PageElement size={priceCardContainer} height={"auto"}>
            <InstantQuotePriceCard
              price={upgradePriceString}
              priceDescription={
                !!product?.inventory_info ? "upgrade value" : "Upgrades"
              }
              lineItems={upgradesList}
              button={
                <Button
                  disabled={product?.disableCustomization}
                  label={
                    product?.disableCustomization
                      ? "Customization Unavailable"
                      : !!product?.inventory_info
                      ? "View design"
                      : upgradesList.length === 0
                      ? "Design home"
                      : "Edit design selections"
                  }
                  onClick={
                    !!product?.inventory_info
                      ? () => viewInventoryUnitInStudio(to, product)
                      : () => {
                          setActiveCartItemById(cartItemId);
                          to(NavigationPaths.STUDIO, {cartItemId: cartItemId});
                        }
                  }
                  id={"instant-quote-to-design"}
                  containerStyle={{width: "fit-content"}}
                  theme={upgradesList.length === 0 ? "primary" : "secondary"}
                />
              }
              displayCheck={false}
            />
          </PageElement>
          <PageElement size={priceCardContainer} height={"auto"}>
            {productInstantQuote && (
              <InstantQuotePriceCard
                price={priceTextFromMicros(
                  siteWorkPricing.totalSiteWorkPrice,
                  "accurate"
                )}
                priceDescription={"Site work estimate"}
                lineItems={siteWorkPricing.rows ?? []}
                displayCheck={true}
              />
            )}
            {!productInstantQuote && (
              <InstantQuoteEmptySiteWorkCard
                title={"Unable to determine site work"}
                content={
                  "We need a little more information to provide you with a site work estimate."
                }
                button={{
                  id: "site-work-contact-us-cta",
                  label: "Please contact us",
                  theme: "secondary",
                  containerStyle: {width: "fit-content", marginTop: "auto"},
                  onClick: () => window.location.assign("/contact-us"),
                }}
              />
            )}
          </PageElement>
        </>
      </ThreeCardContainer>
    );
  };

  return (
    <>
      {isNatMobile && (
        <MobileStickyBottomCenteredButton
          callToActionButton={<InstantQuoteContinue />}
          descriptionText={""}
          descriptionSuffixText={""}
        />
      )}
      <PageContainer>
        <InstantQuoteContainer>
          <PageElement size={callToActionWidth} height={"auto"}>
            <CallToActionContainer>
              <ProjectEstimateTextContainer isMobile={isNatMobile}>
                <TotalProjectCostEstimate>
                  {productInstantQuote
                    ? "Instant quote"
                    : "Base unit price + upgrades"}
                  <InstantQuoteDisclaimer />
                </TotalProjectCostEstimate>
                <TotalCostContainer>
                  {priceTextFromMicros(totalEstimatePrice, "accurate")}
                </TotalCostContainer>
                <IntakeQuoteB2BBanner />
              </ProjectEstimateTextContainer>
              {!isNatMobile && <InstantQuoteContinue />}
            </CallToActionContainer>
          </PageElement>
        </InstantQuoteContainer>
      </PageContainer>
      <PageContainer overridePadding={true}>
        <FullWidthContent
          style={{
            paddingLeft: containerPadding,
            paddingRight: containerPadding,
            alignContent: "flex-start",
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "flex-start",
            paddingBottom: isNatMobile ? "1rem" : "",
          }}
        >
          {infoCards(to)}
        </FullWidthContent>
      </PageContainer>
      <PageContainer>
        <InstantQuoteModelHomes
        // TODO Phase 2 auto populate customer data with confirm details information
        />
      </PageContainer>
      {SHOW_FAQ_EVERYWHERE && (
        <FAQAndScheduleAssessment defaultExpanded={false} />
      )}
      <PageContainer>
        <VCL.Components.Atoms.Disclaimer
          section_properties={{
            style: {fontSize: "18px"},
          }}
          html={[
            "Villa does not guarantee the accuracy of estimated pricing at this stage, and the estimated price is subject to change at any time in Villa's sole discretion. Villa makes no representation or warranty with respect to the actual price and disclaims any and all liability for any variance between the estimated and actual prices." as unknown as TrustedHTML,
          ]}
        />
      </PageContainer>
      <VCL.Components.Atoms.Spacer
        style={{height: "150px"}}
        properties={{hidden: !isNatMobile}}
      />
    </>
  );
};

const ThreeCardContainer = styled.div<{isNatMobile: boolean}>`
  width: 100%;
  ${(props) => (props.isNatMobile ? "" : "margin-right: 0")};
  ${(props) => (props.isNatMobile ? "" : "align-content: flex-start")};
  ${(props) => (props.isNatMobile ? "" : "justify-content: flex-start")};
  ${(props) => (props.isNatMobile ? "" : "align-items: flex-start")};
  ${(props) => (props.isNatMobile ? "" : "display: flex")};
`;

const InstantQuoteContainer = styled.div`
  margin-top: 10px;
`;
const TotalCostContainer = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 48px;

  cursor: default;
`;
const TotalProjectCostEstimate = styled.p`
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: ${GRANITE};
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;
const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  margin: 0;
`;
const ProjectEstimateTextContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${(props) => (props.isMobile ? "6px" : "16px")};
`;
const FullWidthContent = styled.div`
  padding: 1rem;
  margin-right: 0;
  margin-left: 0;
  margin-top: 1rem;
  background-color: ${WARM_NEUTRAL};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
