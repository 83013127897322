import React from "react";
import {PageElement} from "../../../../../../../_shared/generics/page/components/PageElement";
import {SmartImage} from "../../../../../../../_shared/generics/image/SmartImage";
import {GenericImageWrapper} from "../styles";
import {
  getKStringFromMicros,
  getPricingForUnitAndPriceMode,
  ICostPlusSitePricing,
  isEmpty,
  ISitePricing,
  Product,
} from "@natomas-org/core";
import {useGlobalConfiguration} from "../../../../../../../_shared/hooks/useGlobalConfiguration";
import {PricingMode} from "../../../../../../../_shared/slices/GlobalSlice";
import {useDynamicValue} from "../../../../../../../_shared/hooks/useDynamicValue";
import {
  HomeSelectionButtons,
  HomeSelectionDetails,
  HomeSelectionPriceRange,
  HomeSelectionTagline,
  HomeSelectionTitle,
} from "./styles";
import {getPricingForSite} from "../../../../../../_shared/ConfigurationPricingHelper";
import {getTotalProposalPrice} from "../../../../../PaymentsView/components/SiteWorkInfoCard";
import {INSTANT_ESTIMATE_PAGE_ID} from "../../../../../../_shared/navigation/constants";
import {toPage} from "../../../../../../_shared/navigation/PageNavigation";
import {
  SITE_WORK_PHRASE,
  SITE_WORK_PHRASE_FIRST_WORD_CAPITAL,
} from "../../../../../../../_shared/constants/labels";
import {NavigationPaths} from "../../../../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../../../../_shared/hooks/useNavigation";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {useCatalogSessionDispatcher} from "../../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";
import Button from "../../../../../../../../0-app/2-component/Button";

export const HomeSelectionBanner = (props: {showEstimate: boolean}) => {
  const {site, projectPriceMode, projectOverheadProfit} = useActiveProject();
  const {configuration} = useActiveDesign();
  const {product} = configuration ?? {};
  const {PRICING_MODE} = useGlobalConfiguration();
  const {toCatalogProduct} = useCatalogSessionDispatcher();
  const pricing = getPricingForUnitAndPriceMode(
    configuration,
    projectPriceMode,
    {addSalesTax: true, opFraction: projectOverheadProfit}
  );
  const {to} = useNavigation();

  const width = useDynamicValue({
    forFour: 4,
    forEight: 8,
    forTwelve: 6,
    forSixteen: 8,
  });

  if (!product || isEmpty(product)) return <></>;

  const getPriceString = () => {
    if (props.showEstimate) {
      let sitePricing: ISitePricing | ICostPlusSitePricing | null = null;
      if (site) {
        sitePricing = getPricingForSite(site, projectPriceMode, {
          opFraction: projectOverheadProfit,
        });
      }

      let preConAndSiteWorkPriceMicrosNoOP =
        sitePricing?.siteTotalMicros?.preConAndSiteWorkPriceMicros;
      if (sitePricing && "siteAndPreConMicrosNoOP" in sitePricing) {
        preConAndSiteWorkPriceMicrosNoOP =
          sitePricing?.siteAndPreConMicrosNoOP?.preConAndSiteWorkPriceMicros;
      }
      return (
        SITE_WORK_PHRASE_FIRST_WORD_CAPITAL +
        ": " +
        getTotalProposalPrice(pricing, preConAndSiteWorkPriceMicrosNoOP)
      );
    } else if (PRICING_MODE === PricingMode.UNIT) {
      return (
        // @ts-ignore
        "Starting at " + getKStringFromMicros(Product.getUnitPrice(product))
      );
    } else return "Starting at " + getKStringFromMicros(product?.priceMicros);
  };

  const getProductDetails = () => {
    const title =
      product?.productDetails?.bedrooms +
      " bed | " +
      product?.productDetails?.bathrooms +
      " bath | " +
      product?.productDetails?.squareFeet +
      " sq. ft. | " +
      product?.productDetails?.dimensions.frontLength.feet +
      "' × " +
      product?.productDetails?.dimensions.sideLength.feet +
      "'";
    return <HomeSelectionDetails>{title}</HomeSelectionDetails>;
  };

  return (
    <>
      <PageElement size={width} height={"auto"}>
        <GenericImageWrapper height={"16rem"}>
          <SmartImage imageId={product?.imageId} />
        </GenericImageWrapper>
      </PageElement>
      <PageElement size={width} height={"auto"}>
        <HomeSelectionTitle>{product?.title}</HomeSelectionTitle>
        {product?.tagline && (
          <HomeSelectionTagline>{product?.tagline}</HomeSelectionTagline>
        )}
        {getProductDetails()}
        <HomeSelectionPriceRange>{getPriceString()}</HomeSelectionPriceRange>
        <HomeSelectionButtons>
          <div>
            <Button
              id={"see-product-details"}
              label={"See product details"}
              size={"small"}
              theme={"secondary"}
              onClick={() => {
                to(NavigationPaths.CATALOG);
                toCatalogProduct(product);
              }}
            />
          </div>
          {!props.showEstimate && (
            <div>
              <Button
                id={"browse-all-homes"}
                label={"Browse all homes"}
                type={"button"}
                size={"small"}
                theme={"secondary"}
                onClick={() => {
                  to(NavigationPaths.CATALOG);
                }}
              />
            </div>
          )}
          {props.showEstimate && (
            <div>
              <Button
                id={"view-instant-estimate"}
                label={"View " + SITE_WORK_PHRASE}
                size={"small"}
                theme={"secondary"}
                onClick={() => {
                  toPage(INSTANT_ESTIMATE_PAGE_ID);
                }}
              />
            </div>
          )}
        </HomeSelectionButtons>
      </PageElement>
    </>
  );
};
