import React, {Suspense, useMemo} from "react";
import {Redirect, Route} from "react-router-dom";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {DataManager} from "./components/_shared/generics/DataManager";
import {IStore} from "./components/_shared/slices/types/Store";
import {useCurrentUser} from "./components/_shared/hooks/useCurrentUser";
import {useGlobalConfiguration} from "./components/_shared/hooks/useGlobalConfiguration";
import {DownForMaintenanceView} from "./components/admin/LandingViews/DownForMaintenanceView";
import {
  formNewURL,
  setURLPathAndQuery,
} from "./components/_shared/navigation/_helpers";
import {RedirectAction} from "./components/_shared/navigation/RedirectActionComponent";
import {
  ADMIN_ROOT_PATH,
  CATALOG_ROOT_PATH,
  DEVELOPMENT_INTAKE_PATH,
  DOCUMENT_PATH,
  LOGIN_PATH,
  MAP_PATH,
  QUIZ_PATH,
  START_PATH,
  STUDIO_PATH,
  SUMMARY_PATH,
} from "./paths";
import "./styles.scss";
import {RootManagement} from "./root";
import {ProjectSummaryLayout} from "./components/portal/views";
import CatalogRoutes from "./routes/CatalogRoutes";
import MapLayout from "./routes/MapRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import Studio from "./components/studio";
import DocumentRoutes from "./routes/DocumentRoutes";
import useActiveCustomerUser from "./components/_shared/hooks/useCustomer/useActiveUser";
import useActivitySlice from "./components/_shared/hooks/useActivity/useActivitySlice";
import {EmptyPage} from "./components/_shared/generics/empty-page";
import useActivitySliceDispatcher from "./components/_shared/hooks/useCustomer/useActivitySliceDispatcher";
import GlobalFooter from "./0-app/2-component/GlobalFooter";
import AuthenticationContent from "./0-app/0-page/auth-content";
import {Header} from "./0-app/1-container/header/header";

const MainRoot = styled.div`
  flex-grow: 1;
`;

// Don't include any more logic in this component, we need firebase to initialize before loading anything else.
export const App = () => {
  const isFirebaseInitialized = useSelector(
    (state: IStore) => state.global.firebaseInitialized === true
  );
  if (!isFirebaseInitialized) {
    return null;
  }
  return <InitializedApp />;
};

// This
const InitializedApp = () => {
  const {loggedInUser, isAdmin} = useCurrentUser();
  const {activeCustomerId, activeProjectId} = useActivitySlice();
  const {setActiveProjectById} = useActivitySliceDispatcher();
  const customerUserDoc = useActiveCustomerUser(activeCustomerId);
  const isProjectArchived: boolean = useMemo(() => {
    if (!activeProjectId || !activeCustomerId) {
      return false;
    } else {
      return (
        customerUserDoc?.archived_project_ids?.includes(activeProjectId) ??
        false
      );
    }
  }, [
    activeCustomerId,
    activeProjectId,
    customerUserDoc?.archived_project_ids,
  ]);
  const {IS_CUSTOMER_DOWN_FOR_MAINTENANCE} = useGlobalConfiguration();
  const webAppDown =
    loggedInUser && !isAdmin && IS_CUSTOMER_DOWN_FOR_MAINTENANCE;

  return (
    <>
      <MainRoot id={"main-root"} className="App">
        <DataManager />
        <RootManagement />
        {!isProjectArchived && <MainRoutes downForMaintenance={webAppDown} />}
        {isProjectArchived && (
          <EmptyPage
            title={"This project has been archived"}
            buttons={[
              {
                label: "Visit Dashboard",
                clickEvent: () => {
                  setActiveProjectById(undefined);
                },
              },
            ]}
          />
        )}
      </MainRoot>
      {/*
        We do not want to show the global footer on the map, studio, or inside the product page --> the mobile view
        "Base Unit Price" footer interferes with the global footer.
        Be sure to update src/components/catalog/views/PortalCatalog.tsx with any changes
    */}
      {`${window.location.pathname}${window.location.search}`.match(
        /^\/(map.*|products(.*)?unit=.*|studio.*|admin.*|document.*)/
      ) ? (
        <></>
      ) : (
        <GlobalFooter />
      )}
    </>
  );
};

const MainRoutes = (props: {downForMaintenance: boolean}) => {
  if (props.downForMaintenance) {
    return <DownForMaintenanceView />;
  }
  return (
    <Suspense fallback={<div />}>
      {/*// Needs Headers*/}
      <Route exact path={STUDIO_PATH}>
        <Studio
          options={{
            headless: false,
          }}
        />
      </Route>
      <Route path={MAP_PATH}>
        <Header />
        <MapLayout />
      </Route>
      <Route path={"/completed-project-map"}>
        <RedirectAction
          action={() => setURLPathAndQuery(MAP_PATH, "view", "completed")}
        />
      </Route>
      <Route path={CATALOG_ROOT_PATH}>
        <Header />
        <CatalogRoutes />
      </Route>
      <Route path={SUMMARY_PATH}>
        <ProjectSummaryLayout />
      </Route>
      {/*// Does not need Headers*/}
      <Route path={QUIZ_PATH}>
        <RedirectAction action={() => setURLPathAndQuery(CATALOG_ROOT_PATH)} />
      </Route>
      <Route path={ADMIN_ROOT_PATH}>
        <AdminRoutes />
      </Route>
      <Route path={DOCUMENT_PATH}>
        <DocumentRoutes />
      </Route>
      <Route path={LOGIN_PATH}>
        <AuthenticationContent
          redirect={() => setURLPathAndQuery(SUMMARY_PATH)}
        />
      </Route>
      <Route path={START_PATH}>
        <AuthenticationContent
          redirect={() => setURLPathAndQuery(CATALOG_ROOT_PATH)}
        />
      </Route>
      {/* TODO CAN WE DEPRECATE START */}
      <Route exact path="/design">
        <RedirectAction action={() => setURLPathAndQuery(STUDIO_PATH)} />
      </Route>
      <Route path="/multi-family">
        <RedirectAction action={() => setURLPathAndQuery(START_PATH)} />
      </Route>
      <Route path="/multifamily">
        <RedirectAction action={() => setURLPathAndQuery(START_PATH)} />
      </Route>
      <Route path={DEVELOPMENT_INTAKE_PATH}>
        <RedirectAction action={() => setURLPathAndQuery(START_PATH)} />
      </Route>
      <Route path={"/interest/comfort-line"}>
        <RedirectAction action={() => setURLPathAndQuery(START_PATH)} />
      </Route>
      <Route path="/contact">
        <Redirect to={"/contact-us"} />
      </Route>
      <Route path="/intake-error">
        <RedirectAction action={() => setURLPathAndQuery(LOGIN_PATH)} />
      </Route>
      <Route path={"/build"}>
        <RedirectAction
          action={() => {
            window.location.href = formNewURL({pathName: MAP_PATH});
          }}
        />
      </Route>
      {/* TODO CAN WE DEPRECATE END */}
    </Suspense>
  );
};

export default App;
