import styled from "styled-components";
import {isMobile} from "../../../navigation";
import {
  AVATAR_PILL_HOVER_COLOR,
  DROPDOWN_ACCENT_COLOR,
  VILLA_BLUE,
} from "../../../colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const AvatarDropdownContainer = styled.div<{
  hasInitials: boolean;
  isLarge?: boolean;
  size: number;
}>`
  margin-left: 0;
  min-width: ${(props) =>
    props.size && props.hasInitials
      ? props.size + "px"
      : props.size
      ? props.size + "px"
      : "30px"};
  height: ${(props) =>
    props.size && props.hasInitials
      ? props.size + "px"
      : props.size
      ? props.size + "px"
      : "30px"};
  color: white;
  background-color: ${VILLA_BLUE};
  text-align: center;
  font-size: ${(props) => (props.isLarge ? "16px;" : "12px;")};
  border-radius: 50%;
  border: ${(props) => (props.hasInitials && !props.isLarge ? "" : "")};
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
`;

export const AvatarPillGreenDot = styled.div`
  position: absolute;
  top: 8px;
  left: 21px;
  width: 10px;
  height: 10px;
  background: #82c275;
  border: 1px solid white;
  border-radius: 50%;
  transition: all 75ms ease-in;
`;

export const StatusIndicatorGreenDot = styled.div`
  position: relative;
  background: #82c275;
  height: 12px;
  width: 12px;
  border: 1px solid white;
  border-radius: 50%;
  transition: all 75ms ease-in;
  margin-left: 8px;
`;

export const AvatarDropdownPillContainer = styled.div<{
  hoverFill: string;
  width?: string;
  mirror?: boolean;
}>`
  display: flex;
  position: relative;
  padding: ${(props) =>
    props.mirror ? "4px 10px 4px 3px" : "3px 3px 4px 10px"};
  align-items: center;
  margin-left: 0;
  min-width: 66px;
  max-width: ${(props) => props.width ?? "unset"};
  height: 38px;
  color: white;
  justify-content: space-between;
  background-color: white;
  border: 2px solid ${AVATAR_PILL_HOVER_COLOR};
  text-align: center;
  border-radius: 19px;
  cursor: pointer;
  transition: all 75ms ease-in, max-width 200ms ease-in-out;
  overflow: hidden;
  &:hover {
    background-color: ${(props) => props.hoverFill};
    ${AvatarPillGreenDot} {
      border-color: ${(props) => props.hoverFill};
    }
  }
`;

export const AvatarDropdownInitials = styled.div`
  text-align: center;
  position: relative;
  top: 54%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  user-select: none;
`;

export const AvatarIconContainer = styled.div`
  position: relative;
  ${AvatarDropdownInitials} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const AvatarName = styled.div`
  font-size: 18px;
  font-family: ${FONT_FAMILY_BOLD};
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const AvatarEmail = styled.div`
  margin-top: -0.25rem;
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const AvatarDropdownHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const AvatarDropdownHeader = styled.div`
  padding-left: 10px;
  column-gap: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const AvatarDropdownHeaderWrapper = styled.div<{
  width: number;
}>`
  text-align: center;
  padding-top: 10px;
  width: ${isMobile() ? "100%" : (props) => props.width + "px"};
  padding-bottom: 10px;
  border-radius: 0.5rem;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: #f6f6f6;
  }
`;

export const AvatarDropdownContent = styled.div<{
  width: number;
}>`
  width: ${(props) => props.width + "px"};
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const AvatarDropdownDotContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  svg {
    background-color: white;
  }
`;

export const AvatarBreak = styled.div`
  border-top: 1px solid ${DROPDOWN_ACCENT_COLOR};
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const AvatarDropdownItemContainer = styled.div`
  column-gap: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  svg {
    flex-shrink: 0;
    background-color: white;
  }
`;
