import React from "react";
import {IModelHomeInfo, MODEL_HOMES} from "./ModelHomeInfo";
import {PageElementWrapper} from "../../../../../_shared/generics/page/components/PageElementWrapper";
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {ModelHomeCard} from "./ModelHomeCard";

export const ModelHomeList = (props: IModelHomeListProps) => {
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 8,
  });
  return (
    <PageElementWrapper>
      {MODEL_HOMES.map((info) => {
        return (
          <PageElement
            size={size}
            height={"20rem"}
            key={info.location_title + info.product_title}
          >
            <ModelHomeCard
              title={info.location_title}
              subtitle={info.product_title}
              clickEvent={() => {
                props.selectModelHome(info);
              }}
            />
          </PageElement>
        );
      })}
    </PageElementWrapper>
  );
};

export interface IModelHomeListProps {
  selectModelHome: (info: IModelHomeInfo) => void;
}
