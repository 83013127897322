import {useCatalogSession} from "../../components/_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useEffect} from "react";
import {tryFetchingCatalogFactory} from "../FetchingManager";
import {getFactoryKeysFromEligibility} from "./helper";
import {useUrl} from "../../components/_shared/hooks/useUrl";
import {useCatalogSessionDispatcher} from "../../components/_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";

export const CatalogSliceManager = () => {
  const {eligibility, selectedProductId, selectedProductGroupId} =
    useCatalogSession();
  const {url, getProductInfoFromURL} = useUrl();
  const {selectProductById, selectFactory} = useCatalogSessionDispatcher();
  useEffect(() => {
    const {productId, productGroupId} = getProductInfoFromURL();
    if (productId && productId !== selectedProductId) {
      selectProductById(productId);
    }
    if (productGroupId && productGroupId !== selectedProductGroupId) {
      selectFactory(productGroupId);
    }
  }, [
    url,
    getProductInfoFromURL,
    selectedProductId,
    selectedProductGroupId,
    selectProductById,
    selectFactory,
  ]);

  useEffect(() => {
    const keys: string[] = getFactoryKeysFromEligibility(eligibility);
    if (keys?.length > 0) {
      keys.forEach((key: string) => {
        tryFetchingCatalogFactory(key);
      });
    }
  }, [eligibility]);

  return null;
};
