import {useCurrentCustomer} from "../../../../components/_shared/hooks/useCurrentCustomer";
import {useMemo} from "react";
import {
  Customer,
  CustomerProjectInfo,
  ICustomerInfo,
  INITIAL_DEPOSIT_PAYMENT_EVENT_ID,
} from "@natomas-org/core";
import useCustomerProjects from "../../../../components/_shared/hooks/useProject/useCustomerProjects";
import {getEventObjectFromUser} from "../../../../components/portal/_shared/utilities";
import useActiveProject from "../../../../components/_shared/hooks/useProject/useActiveProject";
import {useActiveCartItem} from "../../../../components/_shared/hooks/useCart/useCartItem/useActiveCartItem";
import {useCurrentUser} from "../../../../components/_shared/hooks/useCurrentUser";
import {setURLPathAndQuery} from "../../../../components/_shared/navigation/_helpers";
import {SUMMARY_PATH} from "../../../../paths";
import {INSTANT_ESTIMATE_PAGE_ID} from "../../../../components/portal/_shared/navigation/constants";
import {useLocation} from "react-router-dom";

const is_customer_post_deposit = (
  userInfo: ICustomerInfo | null,
  projectSummary: CustomerProjectInfo | null
) => {
  if (!userInfo) {
    return false;
  }
  // Does the user have the deposit event in their events, or the boolean on the user doc.
  const is_user_marked =
    getEventObjectFromUser(userInfo, INITIAL_DEPOSIT_PAYMENT_EVENT_ID) !=
      null || userInfo?.initial_fee_paid;

  const does_project_have_opportunity_id = !!projectSummary?.opportunity_id;

  return is_user_marked || does_project_have_opportunity_id;
};

export interface IAvatarProps {
  initialized: boolean;
  has_many_projects: boolean;
  has_product_selection: boolean;
  has_cart_enabled: boolean;
  is_pre_deposit: boolean;
  is_user_signed_in: boolean;
  is_user_an_admin: boolean;
  email: string;
  initials: string;
  full_name: string;
  header_cta?: {
    label: string;
    onClick: () => void;
  };
}

export const useAvatarMenu = (): IAvatarProps => {
  const {isAdmin, isSignedIn} = useCurrentUser();
  const {customer, user_id} = useCurrentCustomer();
  const {projectIds} = useCustomerProjects(user_id);
  const {mergedCustomerProjectInfos: customerProjectInfo, isCartEnabled} =
    useActiveProject();
  const activeCartItem = useActiveCartItem();
  const {pathname, search} = useLocation();
  const initialized = useMemo(() => {
    return !!customer;
  }, [customer]);

  const has_many_projects = useMemo(() => {
    return projectIds ? projectIds.length > 1 : false;
  }, [projectIds]);

  const has_product_selection = useMemo(() => {
    return !!activeCartItem;
  }, [activeCartItem]);

  const is_pre_deposit = useMemo(() => {
    return customer
      ? !is_customer_post_deposit(customer, customerProjectInfo ?? null)
      : false;
  }, [customer, customerProjectInfo]);

  const header_cta:
    | {
        label: string;
        onClick: () => void;
      }
    | undefined = useMemo(() => {
    if (!initialized) {
      return undefined;
    }
    // If the user is pre deposit and is not on the instant quote page, show View my estimate
    const showing_instant_quote =
      search.includes("page=estimate") || search.includes("page=assessment");
    const showing_summary = pathname.includes(SUMMARY_PATH);
    const should_show_estimate =
      is_pre_deposit &&
      has_product_selection &&
      !(showing_summary && showing_instant_quote);
    if (should_show_estimate) {
      return {
        label: "View my estimate",
        onClick: () => {
          setURLPathAndQuery(SUMMARY_PATH, "page", INSTANT_ESTIMATE_PAGE_ID);
        },
      };
    } else if (!is_pre_deposit && !showing_summary) {
      return {
        label: "View my project",
        onClick: () => {
          setURLPathAndQuery(SUMMARY_PATH, "page");
        },
      };
    }
    return undefined;
  }, [pathname, search, is_pre_deposit, initialized]);

  const has_cart_enabled = useMemo(() => {
    return isCartEnabled;
  }, [isCartEnabled]);

  const customer_state = {
    initialized,
    has_many_projects,
    has_product_selection,
    is_pre_deposit,
    has_cart_enabled,
  };

  const customer_info: {
    initialized: boolean;
    initials: string;
    full_name: string;
    email: string;
  } = useMemo(() => {
    if (!customer) {
      return {
        initialized: false,
        initials: isAdmin ? "" : "",
        full_name: isAdmin ? "No customer found" : "",
        email: "",
      };
    }
    const first_name = Customer.getFirstName(customer);
    const i = first_name.substring(0, 1);

    return {
      initialized: true,
      initials: i,
      full_name: Customer.getFullName(customer),
      email: Customer.getContactEmail(customer),
    };
  }, [customer, isAdmin]);

  return {
    ...customer_state,
    ...customer_info,
    header_cta,
    is_user_signed_in: isSignedIn,
    is_user_an_admin: isAdmin,
    initialized: customer_info.initialized && customer_state.initialized,
  };
};
