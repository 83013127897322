import {InfoSetColumnSelectFilter} from "./components/ColumnSelectFilter";
import {InfoSetColumnToggleFilter} from "./components/ColumnToggleFilter";
import {
  ASSEMBLY_KEY,
  CONDITIONS_KEY,
  DETAILS_KEY,
  EXTERNAL_CATEGORY_KEY,
  EXTERNAL_DESCRIPTION_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  INTERNAL_NOTES_KEY,
  LABEL_KEY,
  MULTI_OPTION_KEY,
  OMIT_KEY,
  PRICING_PACKAGE_KEY,
  SOURCE_KEY,
  VALUE_KEY,
  VARIANTS_KEY,
} from "../shared/constants";

export enum InfoSetColumnGroup {
  ASSIGNMENT = "Assignment",
  CATALOG = "Catalog",
  INTERNAL = "Internal",
  ITEM = "Item",
  ITEM_DETAILS = "Item Details",
}

export enum InfoSetAssignmentColumn {
  MULTIPLE = "Multiple",
  OPTIONS = "Options",
  CONDITIONS = "Conditions",
}

export enum InfoSetInternalColumn {
  ID = "Id",
  REPORT = "Report",
  CATEGORY = "Category",
}

export enum InfoSetItemDetailsColumn {
  OMIT = "Omit",
  SOURCE = "Source",
  ASSEMBLY = "Assembly",
  PRICING_PACKAGE = "Pricing Package",
  DETAILS = "Details",
  VARIANTS = "Variants",
}

export enum InfoSetCatalogColumn {
  CATEGORY = "Category",
  DESCRIPTION = "Description",
  INTERNAL_NOTES = "Internal Notes",
}

export const infoSetColumns = [
  {
    Header: InfoSetColumnGroup.INTERNAL,
    columns: [
      {
        Header: InfoSetInternalColumn.ID,
        accessor: ID_KEY,
        disableSortBy: true,
      },
      {
        Header: InfoSetInternalColumn.REPORT,
        accessor: INCLUDED_ORDER_FORM_KEY,
        Filter: InfoSetColumnToggleFilter,
      },
      {
        Header: InfoSetInternalColumn.CATEGORY,
        accessor: INTERNAL_CATEGORY_KEY,
        Filter: InfoSetColumnSelectFilter,
      },
    ],
  },
  {
    Header: InfoSetColumnGroup.ITEM,
    columns: [
      {
        Header: "Key",
        accessor: LABEL_KEY,
        locked: true,
      },
    ],
  },
  {
    Header: InfoSetColumnGroup.ASSIGNMENT,
    columns: [
      {
        Header: InfoSetAssignmentColumn.MULTIPLE,
        accessor: MULTI_OPTION_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetAssignmentColumn.OPTIONS,
        accessor: VALUE_KEY,
        disableFilters: true,
        disableSortBy: true,
        locked: true,
      },
      {
        Header: InfoSetAssignmentColumn.CONDITIONS,
        accessor: CONDITIONS_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
  },
  {
    Header: InfoSetColumnGroup.ITEM_DETAILS,
    columns: [
      {
        Header: InfoSetItemDetailsColumn.OMIT,
        accessor: OMIT_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetItemDetailsColumn.SOURCE,
        accessor: SOURCE_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetItemDetailsColumn.ASSEMBLY,
        accessor: ASSEMBLY_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetItemDetailsColumn.PRICING_PACKAGE,
        accessor: PRICING_PACKAGE_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetItemDetailsColumn.DETAILS,
        accessor: DETAILS_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetItemDetailsColumn.VARIANTS,
        accessor: VARIANTS_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
  },
  {
    Header: InfoSetColumnGroup.CATALOG,
    columns: [
      {
        Header: InfoSetCatalogColumn.CATEGORY,
        accessor: EXTERNAL_CATEGORY_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetCatalogColumn.DESCRIPTION,
        accessor: EXTERNAL_DESCRIPTION_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: InfoSetCatalogColumn.INTERNAL_NOTES,
        accessor: INTERNAL_NOTES_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
  },
];

export const getHeaderProps = (column: any) => {
  if (column?.canSort) {
    return column.getHeaderProps(column.getSortByToggleProps());
  } else {
    return column.getHeaderProps();
  }
};
