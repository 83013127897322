import React, {useMemo} from "react";
import {useFilters, useSortBy, useTable} from "react-table";
import {getTableColumns} from "./structure";
import {AdminInventoryTableCell} from "./AdminInventoryTableCell";
import {ReactTableColumnHeader} from "../../../../../../../factory-info-set/InfoSetTable/components/ColumnHeader";
import {
  InfoSetContainer,
  InfoSetTableController,
  ReactTableHeaderRowCell,
  ReactTableHeaderRowCellContent,
} from "../../../../../../../factory-info-set/InfoSetTable/styles";
import {InventoryTable} from "./styles";
import {IFactoryInventoryTableItem} from "./interfaces";
import ColumnController from "../../../../../../../factory-info-set/InfoSetTable/components/_shared/ColumnController";
import AdminInventoryStatusController from "./AdminInventoryStatusController";
import {Divider} from "../../../../../../../_shared/generics/divider";

interface FactoryInventoryTableProps {
  inventory: IFactoryInventoryTableItem[];
  setEditingProduct?: (id: string) => void;
  options?: {
    stickyHeight?: number;
    showFactoryColumn?: boolean;
  };
}
export const FactoryInventoryTable = (props: FactoryInventoryTableProps) => {
  const {inventory, setEditingProduct, options} = props;
  // Use the state and functions returned from useTable to build your UI
  const initialState = {
    sortBy: [
      {
        id: "status",
        desc: true,
      },
      {
        id: "daysSinceStorageDateTime",
        desc: true,
      },
      {
        id: "price",
        desc: true,
      },
    ],
    hiddenColumns: [
      "title",
      "margin",
      "marginPercentage",
      "unitPrice",
      "upgradePrice",
      "factoryCost",
      "salesTax",
      "storageDateTime",
      "productionDateTime",
      "blacklinesUrl",
    ],
    filters: [{id: "status", value: [3, 2]}],
  };

  const columns = useMemo(() => {
    return getTableColumns(options?.showFactoryColumn ?? false);
  }, [options?.showFactoryColumn]);

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = useMemo(
    () => ({
      Cell: AdminInventoryTableCell,
    }),
    []
  );

  const {
    state,
    setFilter,
    getTableProps,
    getTableBodyProps,
    allColumns,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      // @ts-ignore
      setEditingProduct,
      // @ts-ignore
      initialState,
      defaultColumn,
      // @ts-ignore
      columns,
      data: inventory,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy
  );

  // Render the UI for your table
  return (
    <>
      <InfoSetTableController>
        <Divider />
        <AdminInventoryStatusController
          inventory={inventory}
          filters={state.filters}
          setFilter={(value: string) => setFilter("status", value)}
        />
        <Divider />
        <ColumnController allColumns={allColumns} />
      </InfoSetTableController>
      <InfoSetContainer>
        <InventoryTable
          {...getTableProps()}
          stickyHeight={options?.stickyHeight}
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  const headerProps = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <ReactTableHeaderRowCell
                      {...headerProps}
                      onClick={undefined}
                    >
                      <ReactTableHeaderRowCellContent>
                        <ReactTableColumnHeader
                          column={column}
                          onClick={headerProps?.onClick}
                        />
                      </ReactTableHeaderRowCellContent>
                    </ReactTableHeaderRowCell>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </InventoryTable>
      </InfoSetContainer>
    </>
  );
};
