import useActivitySliceDispatcher from "../../useCustomer/useActivitySliceDispatcher";
import useCartLibrary from "../useCartLibrary";
import {CartItemSource} from "../useCartItem/useCartItemMetadata";
import {ICartItem, ICartItemDetailsADU, IProduct} from "@natomas-org/core";
import {getCartItemFromStudio} from "../../../../../database/firebase/project/cart/adu";
import {saveProjectCart} from "../../../../../database/firebase/project/cart";
import {useCallback} from "react";
import {updateCartLibrary} from "../../../../../database/firebase/user/cart_library";
import useActiveCustomer from "../../useCustomer/useActiveCustomer";

const useCatalogCartController = () => {
  const {info} = useActiveCustomer();
  const {projectId, activeProjectCartItems, customerCartItems} =
    useCartLibrary();
  const {setActiveCartItemById} = useActivitySliceDispatcher();

  const _saveCartItemToCartLibrary = useCallback(
    (item: ICartItem) => {
      const cartItems = getCartItems(item, customerCartItems);
      console.log("saveCartItem: CART_LIBRARY", cartItems);
      return updateCartLibrary(info, cartItems).then(() => {
        setActiveCartItemById(item.id);
      });
    },
    [customerCartItems, info, setActiveCartItemById]
  );

  const _saveCartItemToProject = useCallback(
    (item: ICartItem) => {
      const cartItems = getCartItems(item, activeProjectCartItems);
      console.log("saveCartItem: PROJECT_CART", cartItems);
      return saveProjectCart(projectId, cartItems).then(() => {
        setActiveCartItemById(item.id);
      });
    },
    [activeProjectCartItems, projectId, setActiveCartItemById]
  );

  const _saveToSource = useCallback(
    (item: ICartItem, source: CartItemSource) => {
      if (source === CartItemSource.CART_LIBRARY) {
        return _saveCartItemToCartLibrary(item);
      } else {
        return _saveCartItemToProject(item);
      }
    },
    [_saveCartItemToCartLibrary, _saveCartItemToProject]
  );

  const saveCartItem: (
    product: IProduct,
    cartItemId: string,
    source: CartItemSource,
    currentCartItem?: ICartItem
  ) => void = useCallback(
    (
      product: IProduct,
      cartItemId: string,
      source: CartItemSource,
      currentCartItem?: ICartItem
    ) => {
      const item: ICartItem = getCartItemFromStudio(
        product,
        cartItemId,
        currentCartItem
      );

      return _saveToSource(item, source);
    },
    [_saveToSource]
  );

  const updateCartItemQuantity: (
    quantity: number,
    cartItem: ICartItem,
    source: CartItemSource
  ) => void = useCallback(
    (quantity: number, cartItem: ICartItem, source: CartItemSource) => {
      if (quantity <= 0) {
        return;
      }
      if (quantity === cartItem.quantity) {
        return;
      }
      const item = {
        ...cartItem,
        quantity,
      };

      return _saveToSource(item, source);
    },
    [_saveToSource]
  );

  const updateCartItemDisplayCode: (
    display_code: string,
    cartItem: ICartItem,
    source: CartItemSource
  ) => void = useCallback(
    (display_code: string, cartItem: ICartItem, source: CartItemSource) => {
      if (!display_code) {
        return;
      }
      if (display_code?.length === 0) {
        return;
      }
      if (!cartItem.details) {
        return;
      }
      const details: ICartItemDetailsADU = {
        ...cartItem.details,
        display_code,
      };
      const item = {
        ...cartItem,
        details,
      };

      return _saveToSource(item, source);
    },
    [_saveToSource]
  );

  return {
    saveCartItem,
    updateCartItemQuantity,
    updateCartItemDisplayCode,
  };
};

export const getCartItems = (
  newCartItem: ICartItem,
  cartItems: ICartItem[]
): ICartItem[] => {
  const others = cartItems.filter((item) => item.id !== newCartItem.id);
  return [newCartItem, ...others];
};
export default useCatalogCartController;
