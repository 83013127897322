import styled from "styled-components";
import React from "react";

export const ContainerWrapper = styled.div<{isStacked: boolean}>`
  display: flex;
  ${(props) =>
    props.isStacked
      ? `
    flex-direction: column;
    align-items: center;
  gap:12px;
  `
      : `
    align-items: flex-start;
    align-self: stretch;
    background: #13343b;
  gap:24px;
  `}
  border-radius: 15px;
  position: relative;
  overflow: hidden;
`;

export const LeftContainer = styled.div<{isStacked: boolean}>`
  ${(props) =>
    props.isStacked
      ? `
    width: 100%;
    background: #13343b;
    overflow: hidden;
      border-radius: 10px;
  gap: 48px;
  padding: 48px 24px;
    `
      : `
    width: 50%;
  gap: 48px;
  padding: 128px 64px;

    `}
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RightContainer = styled.div<{isStacked: boolean}>`
  ${(props) =>
    props.isStacked
      ? `
    width: 100%;
    height: 300px;
    position: relative;
    background: #13343b;
      overflow: hidden;
      border-radius: 10px;
    `
      : `
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  //  center/cover
  background: #2e565d;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ImageGradientOverlay = styled.div<{isStacked: boolean}>`
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 0;
  right: 0;

  ${(props) =>
    props.isStacked
      ? `
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.8) 100%
  );
  `
      : `
 background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0.8) 100%
  );
 `}
`;

export const Title = styled.h1<{isStacked: boolean}>`
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;

  /* title/jumbo-title */
  font-family: "p22-mackinac-pro", sans-serif;
  font-size: ${(props) => (props.isStacked ? "40px" : "52px")};
  font-style: normal;
  font-weight: 700;
  line-height: ${(props) => (props.isStacked ? "64px" : "72px")};
  letter-spacing: -1.2px;
  margin: 0;
`;

export const Description = styled.p`
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;

  /* title/section-title */
  font-family: "Inter-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 550;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
  margin: 0;
`;

export const ContactContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: fit-content;
  border-radius: 10px;
  background: #2e565d;
`;

export const ContactDescription = styled.p`
  color: #f7f6f5;
  font-feature-settings: "liga" off, "clig" off;
  flex-grow: 1;
  /* body/body-md/semibold */
  font-family: "Inter-Medium", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 650;
  line-height: 20px; /* 142.857% */
  margin: 0;
`;

export const ContactPhotoContainer = styled.div`
  display: flex;
  position: relative;
  min-width: 90px;
  height: 52px;
`;

export const ContactPhoto = styled.img`
  display: flex;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  border: 2px solid #fff;
`;

export const ArrowButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
`;

export const ArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 21px;
  border: 1px solid #adb5bd;
  background: #fff;
  cursor: pointer;
  svg {
    min-width: 16px;
    min-height: 16px;
  }
`;

export const ImageTitle = styled.h2<{isStacked: boolean}>`
  ${(props) =>
    props.isStacked
      ? `
  position: absolute;
  bottom: 48px;
  left: 0;
  padding: 0 0 12px 32px;
  max-width: 100%;
  `
      : `
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 38px 32px;
  max-width: 50%`}

  pointer-events: none;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "p22-mackinac-pro", sans-serif; /* "P22 Mackinac Pro" */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.44px; /* 208% */
  letter-spacing: -0.632px;
  margin: 0;
`;

export const ImageDescription = styled.p<{isStacked: boolean}>`
  ${(props) =>
    props.isStacked
      ? `
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 32px 32px;
  max-width: 100%;
  font-size: 16px;
  `
      : `
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 32px 38px 0;
  font-size: 18px;
  max-width: 50%`}

  text-align: right;
  pointer-events: none;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Inter-Regular", sans-serif; /* "Inter" */
  font-style: normal;
  font-weight: 400;
  line-height: 37.44px; /* 208% */
  letter-spacing: -0.632px;
  margin: 0;
`;

export const LeftArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7 13L2 8.00005M2 8.00005L7 3.00005M2 8.00005L14 8.00005"
      stroke="#04171B"
      stroke-width="1.1232"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RightArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M9 2.99995L14 7.99995M14 7.99995L9 13M14 7.99995L2 7.99995"
      stroke="#04171B"
      stroke-width="1.1232"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
