import React, {useEffect, useState} from "react";
import {StyleOption} from "../../../../../../../../_shared/generics/_shared";
import {NatButton} from "../../../../../../../../_shared/generics/button";
import {useCurrentCustomer} from "../../../../../../../../_shared/hooks/useCurrentCustomer";
import {sendWelcomeEmail} from "../../../../../../../../../database/firebase/email";
import {fetchWelcomeEmail} from "./helper";
import useActiveProject from "../../../../../../../../_shared/hooks/useProject/useActiveProject";

export const SendWelcomeEmailAction = () => {
  const {customer} = useCurrentCustomer();
  const {id} = useActiveProject();
  const [welcomeEmailAlreadySent, setWelcomeEmailAlreadySent] =
    useState<boolean>(false);

  const executeWelcomeEmail = () => {
    if (customer != null && id != null) {
      setWelcomeEmailAlreadySent(true);
      return sendWelcomeEmail(customer.user_id, id).then(() => {
        return fetchWelcomeEmail(setWelcomeEmailAlreadySent, customer, id);
      });
    }
  };

  useEffect(() => {
    if (customer?.user_id) {
      fetchWelcomeEmail(setWelcomeEmailAlreadySent, customer, id);
    }
  }, [customer?.user_id]);

  return (
    <NatButton
      label={(welcomeEmailAlreadySent ? "Re-send" : "Send") + " welcome email"}
      type={"button"}
      clickEvent={executeWelcomeEmail}
      option={
        welcomeEmailAlreadySent ? StyleOption.SECONDARY : StyleOption.PRIMARY
      }
      style={{minWidth: "20rem", width: "100%"}}
    />
  );
};
