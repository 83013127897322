// HamburgerIcon.tsx
import React from "react";
import styled from "styled-components";

const DynamicHamburger = styled.div`
  width: 24px;
  height: auto;
  background-color: transparent;
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

const Line = styled.span`
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background-color: #04171b;
  margin: 4px 0;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
`;

const HamburgerIcon: React.FC<{close: boolean}> = (props: {close: boolean}) => {
  const {close} = props;
  return (
    <DynamicHamburger>
      <Line
        style={close ? {transform: "translateY(6px) rotate(45deg)"} : undefined}
      />
      <Line style={close ? {opacity: 0} : undefined} />
      <Line
        style={
          close ? {transform: "translateY(-6px) rotate(-45deg)"} : undefined
        }
      />
    </DynamicHamburger>
  );
};

export default HamburgerIcon;
