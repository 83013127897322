import {CallToActionSectionProps} from "../CallToActionSection";
import {
  ICustomerInfo,
  IProduct,
  IProjectInfo,
  Project,
} from "@natomas-org/core";
import {
  ILinkButton,
  NatomasButtonProps,
} from "../../../../../../_shared/generics/button";
import {
  CUSTOMER_STATUS,
  FEASIBILITY_STATUS,
  ICustomerStatus,
} from "../../../../../../_shared/hooks/useCustomerStatus";
import {
  getChooseHomeSection,
  getDesignHomeSection,
  getInfeasibleSectionDashboard,
  getInstantEstimateSection,
  getProjectProposalSection,
} from "../CallToActionSection/sections";
import {
  getSFFeasibilityDescriptionText,
  getSFFeasibilityFooterText,
} from "../../../../../../catalog/_shared/components/Feasibility/constants";

enum DashboardStatus {
  LOADING,
  CHOOSE_HOME,
  SELECT_UPGRADES,
  INSTANT_ESTIMATE,
  GET_PROPOSAL,
}

export const getProjectSummaryComponent = (
  customerStatus: ICustomerStatus,
  projectSummary: IProjectInfo | null | undefined,
  feasibility_status: FEASIBILITY_STATUS,
  customer: ICustomerInfo,
  product: IProduct | null | undefined,
  to: any
): CallToActionSectionProps => {
  let status: DashboardStatus = DashboardStatus.LOADING;
  if (!!projectSummary && !!customerStatus) {
    const currentDashboardStatus = getDashboardStatus(customerStatus);
    status = currentDashboardStatus.status;
  }
  return getSection(
    getSectionInfo(status, feasibility_status, customer, to),
    projectSummary,
    product,
    customer
  );
};
export const getSection = (
  content: CallToActionSectionProps,
  projectSummary: IProjectInfo | null | undefined,
  product: any,
  customer: any
): CallToActionSectionProps => {
  const name = !!projectSummary
    ? Project.getClientFirstName(projectSummary)
    : "";

  const productName = !!product ? product.title : "selected home";

  return {
    titles: getTitles(content.titles, name, productName),
    buttons: getButtons(content.buttons),
    footerTexts: getFooterTexts(content.footerTexts),
    footerLinks: getFooterLinks(content.footerLinks, customer),
  };
};

const getButtons = (buttons: NatomasButtonProps[]): NatomasButtonProps[] => {
  return buttons;
};

const getFooterTexts = (footerTexts: JSX.Element[]): JSX.Element[] => {
  return footerTexts;
};

const getFooterLinks = (
  footerLinks: ILinkButton[],
  customer: any
): ILinkButton[] => {
  return footerLinks.map((f: ILinkButton) => {
    return Object.assign({}, f, {clickEventParam: customer});
  });
};

const getTitles = (
  rawTitles: string[],
  name: string,
  product: string
): string[] => {
  return rawTitles.map((rawTitle: string) => {
    let newTitle = rawTitle;
    if (name) newTitle = newTitle.replace(/\$NAME/g, name);
    if (product) newTitle = newTitle.replace(/\$PRODUCT/g, product);
    return newTitle;
  });
};
export const getDashboardStatus = (
  customerStatus: any
): {
  status: DashboardStatus;
  progress: {currentStepNumber: number; totalStepsCount: number};
} => {
  const totalStepsCount = 5;
  let currentStepNumber: number = 5;
  let status = DashboardStatus.GET_PROPOSAL;
  if (customerStatus?.instantEstimateStatus === CUSTOMER_STATUS.COMPLETED) {
    status = DashboardStatus.GET_PROPOSAL;
    currentStepNumber = 4;
  }
  if (
    customerStatus?.instantEstimateStatus === CUSTOMER_STATUS.IN_PROGRESS &&
    customerStatus?.designProductStatus === CUSTOMER_STATUS.COMPLETED
  ) {
    status = DashboardStatus.INSTANT_ESTIMATE;
    currentStepNumber = 3;
  }
  if (customerStatus?.designProductStatus === CUSTOMER_STATUS.IN_PROGRESS) {
    status = DashboardStatus.SELECT_UPGRADES;
    currentStepNumber = 2;
  }
  if (customerStatus?.productSelectedStatus === CUSTOMER_STATUS.IN_PROGRESS) {
    status = DashboardStatus.CHOOSE_HOME;
    currentStepNumber = 1;
  }
  return {
    status: status,
    progress: {
      currentStepNumber: currentStepNumber,
      totalStepsCount: totalStepsCount,
    },
  };
};

const getSectionInfo = (
  status: DashboardStatus,
  serviceableArea: string,
  customer: ICustomerInfo,
  navigateTo: any
): CallToActionSectionProps => {
  switch (status) {
    case DashboardStatus.CHOOSE_HOME:
      if (serviceableArea === FEASIBILITY_STATUS.INFEASIBLE) {
        const infeasibleSectionDashboard =
          getInfeasibleSectionDashboard(navigateTo);
        return {
          buttons: infeasibleSectionDashboard.buttons,
          footerLinks: infeasibleSectionDashboard.footerLinks,
          footerTexts:
            customer?.status_details &&
            customer?.status_details?.length > 0 &&
            getSFFeasibilityFooterText(customer?.status_details[0])
              ? ([
                  getSFFeasibilityFooterText(customer?.status_details[0]),
                ] as JSX.Element[]) ?? infeasibleSectionDashboard.footerTexts
              : infeasibleSectionDashboard.footerTexts,
          titles:
            customer?.status_details &&
            customer?.status_details?.length > 0 &&
            getSFFeasibilityDescriptionText(customer?.status_details[0])
              ? [getSFFeasibilityDescriptionText(customer?.status_details[0])]
              : infeasibleSectionDashboard.titles,
        };
      } else {
        return getChooseHomeSection(navigateTo);
      }
    case DashboardStatus.SELECT_UPGRADES:
      return getDesignHomeSection(navigateTo);
    case DashboardStatus.INSTANT_ESTIMATE:
      return getInstantEstimateSection(navigateTo);
    case DashboardStatus.GET_PROPOSAL:
      return getProjectProposalSection(navigateTo);
    default:
      return {buttons: [], footerLinks: [], footerTexts: [], titles: []};
  }
};
