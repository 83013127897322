import styled from "styled-components";
import React from "react";

export interface IPopupMenu extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
}
export const PopupMenu = styled.div<{visible: boolean}>`
  position: absolute;
  top: 36px; // Position it below the link
  left: 0;
  display: block;
  z-index: 100; // Ensure it's above other elements
  opacity: ${({visible}) => (visible ? 1 : 0)};
  pointer-events: ${({visible}) => (visible ? "all" : "none")};
  transition: opacity 150ms cubic-bezier(0.85, 0.5, 0.85, 0.5);
  min-width: 100px;
  border: 1px solid #e4e4e7;
  border-radius: 10px;
  background-color: white; // #f7f5f2;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
`;

export const PopupMenuContainer = styled.div`
  display: inline-flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

export const PopupMenuItem = styled.a`
  color: #04171b;
  font-feature-settings: "liga" off, "clig" off;
  &:hover,
  &:focus {
    color: #0080a7;
    text-decoration: none;
  }
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 22px; /* 137.5% */
  text-decoration: none;
`;
