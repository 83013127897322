import React from "react";
import {
  ArrowButton,
  ArrowButtonContainer,
  ImageDescription,
  ImageGradientOverlay,
  ImageTitle,
  LeftArrowSvg,
  RightArrowSvg,
  RightContainer,
} from "./styles";
import {useLayout} from "./LayoutContext";
import {Slide, useCarousel} from "./useCarousel";
import SlideShow from "./SlideShow";

interface RightContentProps {
  slides: Slide[];
}

export const RightContent: React.FC<RightContentProps> = ({slides}) => {
  const {isStacked} = useLayout();
  const {nextSlide, prevSlide, currentSlideData, currentSlideIndex} =
    useCarousel(slides);

  return (
    <RightContainer isStacked={isStacked}>
      <SlideShow currentSlideIndex={currentSlideIndex} slides={slides} />
      <ImageGradientOverlay isStacked={isStacked} />
      <ArrowButtonContainer style={{left: "0"}}>
        <ArrowButton onClick={prevSlide}>
          <LeftArrowSvg />
        </ArrowButton>
      </ArrowButtonContainer>
      <ArrowButtonContainer style={{right: "0"}}>
        <ArrowButton onClick={nextSlide}>
          <RightArrowSvg />
        </ArrowButton>
      </ArrowButtonContainer>
      <ImageTitle isStacked={isStacked}>{currentSlideData.title}</ImageTitle>
      <ImageDescription isStacked={isStacked}>
        {currentSlideData.description}
      </ImageDescription>
    </RightContainer>
  );
};
