import React from "react";
import Button from "../../../2-component/Button";
import styled from "styled-components";
import {AvatarMenu, IAvatarMenuProps} from "./avatar-menu";

export const MobileHeaderButtons = (props: IAvatarMenuProps) => {
  if (props.avatar.is_user_signed_in) {
    return (
      <Container>
        {/*<CartButton />*/}
        <AvatarMenu {...props} />
      </Container>
    );
  } else {
    return (
      <Container>
        <Button
          id={"header_sign_in"}
          data-cy={"header_sign_in"}
          label={"Sign in"}
          theme={"secondary"}
          onClick={props.signInAction}
          size={"small"}
        />
      </Container>
    );
  }
};

const Container = styled.div`
  margin-right: 16px;
  flex-direction: row;
  gap: 8px;
`;
