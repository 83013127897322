import React from "react";
import Text from "../../2-component/Text";
import SignUpForm from "../../1-container/forms/SignUpForm";
import Button from "../../2-component/Button";
import {ContentWrapper} from "./style";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {Divider} from "../../../components/_shared/generics/divider";
import styled from "styled-components";
import Email from "../../1-container/auth-button/Email";

const SignUpContent = (props: {
  close: () => void;
  onSignUp: (values?: CreateLeadSchema) => void;
}) => {
  return (
    <ContentWrapper>
      <Text
        text={
          "A free account lets you save your Villa design, see how it fits on your lot and get an instant quote."
        }
        style={{
          textAlign: "center",
          fontSize: "14px !important",
          lineHeight: "18px !important",
        }}
      />
      <ContentContainer>
        <SignUpForm onSignUp={props.onSignUp} />
        <Text
          text={
            "By signing in, you agree that you have read, understood and agree to the Terms of Use and have read and acknowledge the Privacy Policy."
          }
          links={[
            {
              replacementKey: "Privacy Policy",
              target: "_blank",
              theme: "bold",
              href: "https://villahomes.com/privacy-policy/",
            },
            {
              replacementKey: "Terms of Use",
              target: "_blank",
              theme: "bold",
              href: "https://villahomes.com/terms-of-use/",
            },
          ]}
        />
      </ContentContainer>
      <Divider color={"#D0D5DD"} />
      <ContentContainer>
        <Email
          id={"sign-up-email-button"}
          label={"Sign up with email"}
          onClick={() => {
            return props.onSignUp();
          }}
        />
        <Button
          id={"leave-sign-up-form"}
          label={"Log in to existing account"}
          onClick={props.close}
          theme={"secondary"}
          type={"button"}
          containerStyle={{width: "100%"}}
        />
      </ContentContainer>
    </ContentWrapper>
  );
};

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  a {
    font-size: 12px !important;
  }
  span {
    font-size: 12px !important;
  }
`;

export default SignUpContent;
