import React, {ReactElement} from "react";
import TmShapeLogo from "src/components/_shared/logos/logos";
import {SocialMediaAccounts} from "src/components/_shared/socials";
import {
  ContactUs,
  ContactUsButton,
  ContainerColumn,
  ContainerLeft,
  ContainerRight,
  ContainerRow,
  FooterCopyright,
  GlobalFooterContainer,
  LegalLink,
  SocialMediaLinks,
  SocialMediaLinksContainer,
} from "./styles";

const legalLinks = [
  {
    key: "termsOfUse",
    label: "Terms of Use",
    link: "https://villahomes.com/terms-of-use/",
  },
  {
    key: "privacyPolicy",
    label: "Privacy Policy",
    link: "https://villahomes.com/privacy-policy/",
  },
  {
    key: "accessibility",
    label: "Accessibility",
    link: "https://villahomes.com/accessibility/",
  },
];

const GlobalFooter = (): ReactElement => {
  const year = new Date().getFullYear();

  return (
    <GlobalFooterContainer>
      <ContainerLeft>
        <TmShapeLogo
          style={{
            width: "8rem",
            margin: "12px",
          }}
          alt={"Villa Homes Logo"}
        />
        <ContactUs>
          <p>Have questions?</p>
          <ContactUsButton href="/contact-us" target="_blank">
            Contact us
          </ContactUsButton>
        </ContactUs>
      </ContainerLeft>
      <ContainerRight>
        <ContainerColumn>
          <ContainerRow>
            {legalLinks.map((link) => {
              return (
                <LegalLink key={link.key}>
                  <a href={link.link} target="_blank" rel="noreferrer">
                    {link.label}
                  </a>
                </LegalLink>
              );
            })}
            <SocialMediaLinksContainer>
              <SocialMediaLinks>
                {SocialMediaAccounts.map((account) => {
                  const Icon = account.icon;
                  return (
                    <li key={account.platform}>
                      <a
                        href={account.link}
                        style={{fontSize: 0}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon />
                        {account.platform}
                      </a>
                    </li>
                  );
                })}
              </SocialMediaLinks>
            </SocialMediaLinksContainer>
          </ContainerRow>
          <ContainerRow>
            <FooterCopyright>
              © {year} Natomas Labs, Inc. All rights reserved.
            </FooterCopyright>
          </ContainerRow>
        </ContainerColumn>
      </ContainerRight>
    </GlobalFooterContainer>
  );
};

export default GlobalFooter;
