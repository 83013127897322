import {FloatingDevRow, FloatingDivContainer} from "./styles";
import React, {useState} from "react";
import {isDev} from "../../application";
import {NatomasMapFeature} from "@natomas-org/core";

export const NatomasMapDebugWindow = (props: {
  cursor: string;
  defaultCursor: string;
  isEditing: boolean;
  isCreating: boolean;
  feature: NatomasMapFeature | undefined;
  lng: number;
  lat: number;
  zoom: number;
  debugValue: any;
}) => {
  const {
    cursor,
    defaultCursor,
    isEditing,
    isCreating,
    feature,
    lat,
    lng,
    zoom,
    debugValue,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  if (!isDev) {
    return null;
  }
  return (
    <FloatingDivContainer
      position={"bottom-center"}
      onClick={() => setIsOpen((current) => !current)}
    >
      <FloatingDevRow sticky={true} hasAction={true}>
        Debug {isOpen ? "(Close)" : "(Open)"}
      </FloatingDevRow>
      {isOpen && (
        <>
          <FloatingDevRow>Active Cursor: {cursor}</FloatingDevRow>
          <FloatingDevRow>Default Cursor: {defaultCursor}</FloatingDevRow>
          <FloatingDevRow>isEditing: {isEditing ? "Yes" : "No"}</FloatingDevRow>
          <FloatingDevRow>
            isCreating: {isCreating ? "Yes" : "No"}
          </FloatingDevRow>
          <FloatingDevRow>Longitude: {lng}</FloatingDevRow>
          <FloatingDevRow>Latitude: {lat}</FloatingDevRow>
          <FloatingDevRow>Zoom: {zoom}</FloatingDevRow>
          {debugValue && (
            <>
              <FloatingDevRow>*------CURRENT FEATURE------*</FloatingDevRow>
              <FloatingDevRow>
                {JSON.stringify(feature, null, 2)}
              </FloatingDevRow>
            </>
          )}
          {debugValue && (
            <>
              <FloatingDevRow>*------MOST-RECENT-SAVE------*</FloatingDevRow>
              <FloatingDevRow>
                {JSON.stringify(debugValue, null, 2)}
              </FloatingDevRow>
            </>
          )}
        </>
      )}
    </FloatingDivContainer>
  );
};
