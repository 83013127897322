import {useCurrentUser} from "../../useCurrentUser";
import {useUrl} from "../../useUrl";
import {CUSTOMER_STATUS, useCustomerStatus} from "../../useCustomerStatus";
import {
  AvatarDropdownContainer,
  AvatarDropdownDotContainer,
  AvatarDropdownInitials,
  AvatarDropdownPillContainer,
  AvatarIconContainer,
  AvatarPillGreenDot,
  StatusIndicatorGreenDot,
} from "./styles";
import {
  AVATAR_PILL_HOVER_COLOR,
  DESTRUCTIVE_FILL,
  VILLA_BLUE,
  WHITE,
} from "../../../colors";
import {GiHamburgerMenu} from "react-icons/gi";
import {
  getAllDropdownTodoItems,
  getSignedInToDoOptions,
  IDropdownList,
} from "./dropdownItems";
import React from "react";
import {shouldShowGreenDotOnAvatar} from "./helpers";
import {useCurrentCustomer} from "../../useCurrentCustomer";
import {RiShieldFill, RiShieldUserFill} from "react-icons/ri";
import {Customer} from "@natomas-org/core";
import {BsPersonCircle} from "react-icons/bs";
import {JSXDropdownOption} from "../../../generics/popup/dropdown/styles";
import {useNavigation} from "../index";
import {CheckMark} from "../../../generics/CheckMark";
import useActiveProject from "../../useProject/useActiveProject";

export const Avatar = (props: {
  size: number;
  isLarge: boolean;
  initials: string;
  isAdmin: boolean;
  isArchived: boolean;
  customer: any;
  loggedInUser: any;
  children?: any;
}) => {
  const {size, loggedInUser, initials, isAdmin, isArchived, customer, isLarge} =
    props;
  return (
    <AvatarDropdownContainer
      hasInitials={initials !== ""}
      className={"bold"}
      size={size}
      isLarge={isLarge}
      style={{
        backgroundColor: isAdmin || !loggedInUser ? WHITE : VILLA_BLUE,
      }}
    >
      {props.children}
      <AvatarIcon
        size={size + 2}
        isAdmin={isAdmin}
        customer={customer}
        initials={initials}
        color={isArchived ? DESTRUCTIVE_FILL : VILLA_BLUE}
      />
    </AvatarDropdownContainer>
  );
};

const AvatarIcon = (props: {
  size: number;
  isAdmin: boolean;
  customer: any;
  initials: string;
  color: string;
}) => {
  const {isAdmin, initials, color, size, customer} = props;
  if (isAdmin && !customer) {
    return <RiShieldUserFill size={size} color={color} />;
  } else if (isAdmin && customer) {
    return (
      <AvatarIconContainer>
        <RiShieldFill size={size} color={color} />
        <AvatarDropdownInitials>{initials}</AvatarDropdownInitials>
      </AvatarIconContainer>
    );
  } else if (customer && Customer.getFirstName(customer) !== "") {
    return <AvatarDropdownInitials>{initials}</AvatarDropdownInitials>;
  } else {
    return <BsPersonCircle size={size} color={color} />;
  }
};

export const AvatarPill = (props: {
  initials: string;
  shouldDisplayPostDepositPortalV2: boolean;
  onClick: () => void;
}) => {
  const {initials, shouldDisplayPostDepositPortalV2, onClick} = props;
  const {isAdmin, loggedInUser} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  // TODO implement archive flag in projects
  const isArchived = false;
  const {address: projectAddress, id: projectId} = useActiveProject();
  const {pageId, getURLPath} = useUrl();
  const {to} = useNavigation();
  const {status, customerStatus, isCustomerPreDeposit} = useCustomerStatus();
  return (
    <AvatarDropdownPillContainer
      id={"avatar-dropdown-pill"}
      hoverFill={AVATAR_PILL_HOVER_COLOR}
      onClick={onClick}
    >
      <GiHamburgerMenu size={15} color={VILLA_BLUE} />
      <Avatar
        size={28}
        isLarge={false}
        initials={initials}
        isAdmin={isAdmin}
        isArchived={isArchived}
        customer={customer}
        loggedInUser={loggedInUser}
      />
      {isCustomerPreDeposit &&
        shouldShowGreenDotOnAvatar(
          getSignedInToDoOptions(
            isCustomerPreDeposit,
            shouldDisplayPostDepositPortalV2
          ),
          getAllDropdownTodoItems(
            status,
            customerStatus,
            to,
            projectAddress,
            projectId
          ),
          pageId,
          getURLPath()
        ) && <AvatarPillGreenDot />}
    </AvatarDropdownPillContainer>
  );
};

export const ProgressCircleForStatus = (props: {
  status: any;
  description?: string;
}) => {
  const {status} = props;
  return (
    <div style={{zIndex: 1, display: "flex", height: "fit-content"}}>
      <CheckMark
        fillCircle={true}
        incomplete={status !== CUSTOMER_STATUS.COMPLETED}
        inProgress={status === CUSTOMER_STATUS.IN_PROGRESS}
      />
    </div>
  );
};

export const CurrentStepIndicator: any = (stepStatus: any) => {
  if (stepStatus === CUSTOMER_STATUS.IN_PROGRESS) {
    return <StatusIndicatorGreenDot />;
  }
};

export const ProgressSVG = (
  toDoOptions: string[],
  DROPDOWN_TODO_ITEMS: IDropdownList
) => {
  let startingHeight = -10;
  let counter = 0;
  const rowHeight = 40;
  return (
    <svg height="200" width="50" style={{position: "absolute"}}>
      {toDoOptions.map((id: string) => {
        startingHeight += rowHeight;
        counter += 1;
        if (counter < toDoOptions.length) {
          return DROPDOWN_TODO_ITEMS[id]?.status ===
            CUSTOMER_STATUS.COMPLETED ||
            DROPDOWN_TODO_ITEMS[id]?.status === CUSTOMER_STATUS.SKIPPED ? (
            <line
              x1="32"
              y1={startingHeight}
              x2="32"
              y2={startingHeight + rowHeight}
              style={{stroke: "#82c275", strokeWidth: 2}}
              key={"line-" + id}
            />
          ) : DROPDOWN_TODO_ITEMS[id]?.status === CUSTOMER_STATUS.DISABLED ||
            DROPDOWN_TODO_ITEMS[id]?.status === CUSTOMER_STATUS.IN_PROGRESS ? (
            <line
              x1="32"
              y1={startingHeight}
              x2="32"
              y2={startingHeight + rowHeight}
              style={{stroke: "rgba(0, 0, 0, .4)", strokeWidth: 2}}
              key={"line-" + id}
            />
          ) : null;
        } else {
          return null;
        }
      })}
      ;
    </svg>
  );
};

export const AvatarDropdownDotContainerComponent = (
  status: any,
  title: string
) => {
  return (
    <AvatarDropdownDotContainer>
      <div>{title}</div>
    </AvatarDropdownDotContainer>
  );
};

export const AvatarComponentMapping = (
  dropdownStrings: string[],
  dropdownItems: IDropdownList,
  selectedOption: string | null,
  height?: number
) => {
  return dropdownStrings.map((id: string) => {
    return (
      <JSXDropdownOption
        key={"nat-dropdown-" + id}
        disabled={dropdownItems[id]?.status === CUSTOMER_STATUS.DISABLED}
        onClick={() => {
          dropdownItems[id].action();
        }}
        style={{height: height + "px"}}
        active={id === selectedOption}
      >
        {dropdownItems[id]?.label}
      </JSXDropdownOption>
    );
  });
};
