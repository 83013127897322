import styled from "styled-components";
import React, {useState} from "react";
import {BsCheckCircleFill} from "react-icons/bs";
import {MdArrowForwardIos} from "react-icons/md";
import design_graphic from "../../../../../assets/images/graphics/girl-with-paint-brush.svg";
import {BLACK, GRANITE, PRIMARY_BUTTON_COLOR} from "../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../SSDDashboardView/styles/globals";
import {ISiteWorkBucket} from "../interface";

export const InstantQuotePriceCard = (props: {
  price: string;
  priceDescription: string;
  lineItems: ISiteWorkBucket[];
  displayCheck: boolean;
  button?: any;
  noPadding?: boolean;
}) => {
  return (
    <InstantQuotePriceCardContainer noPadding={props.noPadding ?? false}>
      <TotalPriceContainer>
        <TotalPriceText>{props.price}</TotalPriceText>
        <TotalPriceDescription>{props.priceDescription}</TotalPriceDescription>
      </TotalPriceContainer>
      {props.lineItems.length === 0 && (
        <img
          width={"100%"}
          style={{margin: "auto"}}
          src={design_graphic}
          alt={"start design"}
        />
      )}
      <div
        style={{marginBottom: "auto", marginTop: "16px"}}
        hidden={!props.lineItems || props.lineItems.length === 0}
      >
        {props.lineItems.map((lineItem, index: number) => {
          return (
            <>
              <div key={lineItem.title}>
                <RowItem
                  nameAndPrice={lineItem}
                  displayCheck={props.displayCheck}
                />
              </div>
              <HR />
            </>
          );
        })}
      </div>
      <ButtonContainer hidden={!props.button}>{props.button}</ButtonContainer>
    </InstantQuotePriceCardContainer>
  );
};

const ButtonContainer = styled.div``;

const RowItemContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
`;
const RowLineAndArrowContainer = styled.div<{expanded: boolean}>`
  display: flex;
  height: 20px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  svg {
    transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
    transition: transform 200ms ease-in-out;
  }
`;
const RowLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
`;
const ItemNameTitle = styled.div`
  font-size: 14px;
  font-family: ${FONT_FAMILY_BOLD};
  padding-left: 6px;
`;
const ItemNamePrice = styled.div`
  font-size: 14px;
  color: #4d4d4d;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export interface IRowItem {
  nameAndPrice: ISiteWorkBucket;
  displayCheck: boolean;
}
export const RowItem = (props: IRowItem) => {
  const [expanded, setExpanded] = useState(false);
  const hasBulletPoints = props.nameAndPrice.bulletPoints.length > 0;
  return (
    <RowItemContainer
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <RowLineAndArrowContainer expanded={expanded}>
        <MdArrowForwardIos
          size={14}
          visibility={hasBulletPoints ? "visible" : "hidden"}
        />
        <RowLineContainer>
          <ItemNameTitle>{props.nameAndPrice.title}</ItemNameTitle>
          <ItemNamePrice>{props.nameAndPrice.priceText}</ItemNamePrice>
        </RowLineContainer>
      </RowLineAndArrowContainer>
      <div
        style={{
          fontSize: "12px",
          maxHeight: expanded ? "250px" : "0px",
          overflow: "hidden",
          opacity: expanded ? 1 : 0,
          transition:
            "visibility 0s, opacity 350ms linear, max-height 350ms ease-out",
        }}
      >
        {props.displayCheck
          ? props.nameAndPrice.bulletPoints.map((str: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    marginTop: "10px",
                  }}
                  key={str}
                >
                  <BsCheckCircleFill
                    size={"14px"}
                    color={PRIMARY_BUTTON_COLOR}
                    style={{
                      maxWidth: "14px",
                      maxHeight: "14px",
                      minWidth: "14px",
                      minHeight: "14px",
                      marginTop: "2px",
                    }}
                  />
                  {str}
                </div>
              );
            })
          : props.nameAndPrice.bulletPoints.map((str: any) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    marginTop: "10px",
                    paddingLeft: "1.25rem",
                  }}
                  key={str}
                >
                  {str}
                </div>
              );
            })}
      </div>
    </RowItemContainer>
  );
};

export const InstantQuotePriceCardContainer = styled.div<{noPadding: boolean}>`
  background-color: white;
  border-radius: 10px;
  padding: ${(props) => (props.noPadding ? 0 : 1)}rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 516px;
  height: fit-content;
  width: 100%;
`;

export const TotalPriceText = styled.h2`
  color: ${BLACK};

  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;

export const StandardFinishingPriceText = styled.p`
  color: ${BLACK};
  margin: 0;
  /* Paragraph/P2/Medium */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const StandardFinishingDescription = styled.p`
  color: ${GRANITE};
  margin: 0;

  /* Paragraph/P2/Regular */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TotalPriceDescription = styled.div`
  color: ${GRANITE};
  margin: 0;

  /* Paragraph/P2/Regular */
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const HR = styled.hr`
  width: 100%;
`;
