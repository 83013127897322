import React from "react";
import {storage} from "../../../../../database/firebase";
import {ProjectTimelineLayout} from "../../ProposalView/ProjectTimelineLayout";
import {DTC_PORTAL_TIMELINE_ID} from "../HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {IHCPViewProps} from "../interfaces";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {ProjectPriceMode} from "@natomas-org/core";
import {B2BTimelineSlide} from "../../ProposalBudgetView/components/Presentation/B2BTimelineSlide/B2BTimelineSlide";
import {downloadFileToDesktop} from "../../SitePlanView/logic";
import Button from "../../../../../0-app/2-component/Button";

export const HCPTimeline = (props: IHCPViewProps) => {
  const {width} = props;
  const {projectPriceMode, info, isDevelopmentByVilla} = useActiveProject();
  const {configuration} = useActiveDesign();
  if (projectPriceMode === ProjectPriceMode.CostPlus) {
    return (
      <PortalSection
        headerTitle={"Project Schedule"}
        width={width}
        sectionId={DTC_PORTAL_TIMELINE_ID}
      >
        <B2BTimelineSlide
          displayOptions={{
            displayQuarters: isDevelopmentByVilla,
            showTooltip: true,
            showSectionTitles: true,
            showLabelsOnBars: false,
            showLegend: true,
          }}
        />
      </PortalSection>
    );
  }
  if (!configuration?.product) {
    return null;
  }

  return (
    <PortalSection
      headerTitle={"Project Schedule"}
      width={width}
      sectionId={DTC_PORTAL_TIMELINE_ID}
    >
      <ProjectTimelineLayout />
      <Button
        onClick={() => {
          // @ts-ignore
          return (storage as any)
            .ref("assets/portal/payment_milestone_one_pager.pdf")
            .getDownloadURL()
            .then((url: any) => {
              return downloadFileToDesktop(url, "Villa_Payment_Milestones.pdf");
            });
        }}
        label={"Payment Milestones PDF"}
        id={"download-payment-milestones-pdf"}
        size={"small"}
        containerStyle={{width: "fit-content"}}
      />
    </PortalSection>
  );
};
