import {IAddressDetails, IGLMapState} from "@natomas-org/core";
import {
  HistoryChangeType,
  setMultipleURLQueryKeyValues,
} from "../../../navigation/_helpers";
import {URL_PARAM_KEY_MAP_VIEW_MODE_ID} from "@natomas-org/service";

export const getURLSearchParamsFromAddressDetailsAndGLMapState = (
  addressDetails: IAddressDetails,
  mapState?: IGLMapState
): URLSearchParams => {
  let enrichedAddressDetails: IAddressDetails | null = addressDetails;
  const replaceParam = (param: string, value: string) => {
    if (params.has(param)) {
      params.delete(param);
    }
    params.append(param, value);
  };
  const formattedUrl = new URL(window.location.href);
  const params = new URLSearchParams(formattedUrl?.search ?? undefined);
  if (mapState?.mLng) {
    replaceParam("mLng", Number(mapState.mLng).toFixed(18).toString()); //Number(map.current.getCenter()?.lng).toFixed(4));
  }
  if (mapState?.mLat) {
    replaceParam("mLat", Number(mapState.mLat).toFixed(18).toString()); //Number(map.current.getCenter()?.lat).toFixed(7));
  }
  if (mapState?.bearing) {
    replaceParam("bearing", mapState.bearing.toString());
  } else {
    params.delete("bearing");
  }
  if (mapState?.pitch) {
    replaceParam("pitch", mapState.pitch.toString());
  } else {
    params.delete("pitch");
  }
  if (mapState?.zoom) {
    replaceParam("zoom", mapState.zoom.toString());
  } else {
    params.delete("zoom");
  }
  if (enrichedAddressDetails?.full_address) {
    replaceParam("address", enrichedAddressDetails.full_address);
  } else {
    params.delete("address");
  }
  if (enrichedAddressDetails?.city) {
    replaceParam("city", enrichedAddressDetails.city);
  } else {
    params.delete("city");
  }
  if (enrichedAddressDetails?.state) {
    replaceParam("state", enrichedAddressDetails.state);
  } else {
    params.delete("state");
  }
  if (enrichedAddressDetails?.longitude) {
    replaceParam("vey", enrichedAddressDetails.longitude.toString());
  } else {
    params.delete("vey");
  }
  params.delete("lng");
  if (enrichedAddressDetails?.latitude) {
    replaceParam("vex", enrichedAddressDetails.latitude.toString());
  } else {
    params.delete("vex");
  }
  params.delete("lat");
  if (enrichedAddressDetails?.street_number) {
    replaceParam("street_number", enrichedAddressDetails.street_number);
  } else {
    params.delete("street_number");
  }
  if (enrichedAddressDetails?.street) {
    replaceParam("street", enrichedAddressDetails.street);
  } else {
    params.delete("street");
  }
  if (enrichedAddressDetails?.zip) {
    replaceParam("postal_code", enrichedAddressDetails.zip);
  } else {
    params.delete("postal_code");
  }
  if (enrichedAddressDetails?.county) {
    replaceParam("county", enrichedAddressDetails.county);
  } else {
    params.delete("county");
  }
  if (enrichedAddressDetails?.country) {
    replaceParam("country", enrichedAddressDetails.country);
  } else {
    params.delete("country");
  }
  return params;
};

export const leaveMap = (isPublicApp: boolean) => {
  setMultipleURLQueryKeyValues(
    [
      {key: "lat", value: null},
      {key: "vex", value: null},
      {key: "lng", value: null},
      {key: "vey", value: null},
      {key: "mLng", value: null},
      {key: "mLat", value: null},
      {key: "zoom", value: null},
      {key: "pitch", value: null},
      {key: "bearing", value: null},
      {key: "street_number", value: null},
      {key: "address", value: null},
      {key: "street", value: null},
      {key: "city", value: null},
      {key: "state", value: null},
      {key: "postal_code", value: null},
      {key: "county", value: null},
      {key: "country", value: null},
      {key: URL_PARAM_KEY_MAP_VIEW_MODE_ID, value: null},
    ],
    HistoryChangeType.REPLACE
  );
};
