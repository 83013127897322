import React from "react";
import {CartSectionSoftContainer} from "./styles";
import NatCollapsableContainer from "../../../../../_shared/generics/collapsable-container/NatCollapsableContainer";

interface CartSectionContainerProps {
  label: string;
  children: React.ReactNode;
}

const CartSectionContainer = (props: CartSectionContainerProps) => {
  const {label, children} = props;

  return (
    <NatCollapsableContainer label={label}>
      <CartSectionSoftContainer>{children}</CartSectionSoftContainer>
    </NatCollapsableContainer>
  );
};

export default CartSectionContainer;
