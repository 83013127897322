import {InfoSetItem} from "../../shared/interfaces";
import {INTERNAL_CATEGORY_KEY} from "../../shared/constants";

export const shouldShowCategoryLink = (
  item: InfoSetItem,
  previousItem: InfoSetItem
) => {
  return (
    item?.[INTERNAL_CATEGORY_KEY] !== previousItem?.[INTERNAL_CATEGORY_KEY]
  );
};
