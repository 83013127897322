import React from "react";
import {DesignSelectionsSpreadsheet} from "../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/BudgetSummaryTab";
import {RevealCostsToggle} from "./RevealCostsToggle";
import styled from "styled-components";
import LatestEstimateLink from "../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/BudgetSummaryTab/LatestEstimateLink";

interface TableControllerProps {}

const D2CBudgetSummaryTab = (props: TableControllerProps) => {
  return (
    <EstimateTableContainer>
      <RevealCostsToggle />
      <LatestEstimateLink />
      <DesignSelectionsSpreadsheet />
    </EstimateTableContainer>
  );
};

const EstimateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  padding: 16px;
`;

export default D2CBudgetSummaryTab;
