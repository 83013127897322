import React from "react";
import {
  ContactContainer,
  ContactDescription,
  ContentContainer,
  Description,
  LeftContainer,
  Title,
} from "./styles";
import {useLayout} from "./LayoutContext";
import {DarkBlueDiamond} from "../../../../0-app/1-container/building-multiple-units-cta";

export const LeftContent: React.FC = () => {
  const {isStacked} = useLayout();

  return (
    <LeftContainer isStacked={isStacked}>
      <ContentContainer>
        <Title isStacked={isStacked}>Your project is in the works</Title>
        <Description>
          Our team is currently working on bringing your project to life. If you
          have any questions or want to explore additional product choices, your
          Villa representative is here to help.
        </Description>
        <ContactContainer>
          <DarkBlueDiamond />
          <ContactDescription>
            Contact your Villa representative for further assistance.
          </ContactDescription>
        </ContactContainer>
      </ContentContainer>
    </LeftContainer>
  );
};
