import styled from "styled-components";
import {DEVELOPER_FILL_SECTION, GRAY, VILLA_SLATE} from "../../_shared/colors";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 24px;
  font-family: "JetBrains Mono", monospace;
  background-color: ${VILLA_SLATE};
`;
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
  font-family: "JetBrains Mono", monospace;
  background-color: ${GRAY};
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px;
  background-color: ${DEVELOPER_FILL_SECTION};
  width: 100%;
`;

export const Header = styled.h1`
  color: whitesmoke;
  font-size: 24px;
`;

export const Span = styled.span`
  color: whitesmoke;
  font-size: 14px;
`;
export const Description = styled.p`
  color: whitesmoke;
  font-size: 12px;
`;
export const Pre = styled.pre`
  color: whitesmoke;
  font-size: 14px;
`;

export const Button = styled.button`
  font-size: 14px;
`;

export const JSONContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  padding: 24px;
  font-family: "JetBrains Mono", monospace;
  background-color: ${DEVELOPER_FILL_SECTION};
`;
