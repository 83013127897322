import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {IProject} from "../../slices/ProjectSlice/ProjectSlice";
import {useMemo} from "react";
import {
  CustomerProjectInfo,
  DEFAULT_OVERHEAD_PROFIT_FRACTION,
  IAddressDetails,
  IConfigurationSite,
  IProjectInfo,
  Project,
  ProjectPriceMode,
  ProjectPricingModeSettings,
  PSA_DOCUMENT_ID,
  UNIT_PERMIT_FEE_PHASE_1_MICROS,
  UNIT_PERMIT_FEE_PHASE_2_MICROS,
} from "@natomas-org/core";
import {getProjectRoot} from "./helper";
import {ESTIMATE_DOCUMENT_ID} from "@natomas-org/core/dist/esm/constants/documents";
import {useActiveProjectDocuments} from "./useActiveProjectDocuments";

export interface IUseProject {
  id: string;
  cartItemIds: string[];
  cartItemCount: number;
  address: IAddressDetails | null;
  info?: IProjectInfo | null;
  mergedCustomerProjectInfos?: CustomerProjectInfo;
  site: IConfigurationSite | undefined;
  projectPriceMode: ProjectPriceMode;
  projectPriceSettings: ProjectPricingModeSettings | undefined;
  projectOverheadProfit: number | undefined;
  isCartEnabled: boolean;
  isDevelopmentByVilla: boolean;
  isProjectLocked?: boolean;
  phase1Micros?: number;
  phase2Micros?: number;
  budgetHidden: boolean;
}

export const useProject = (pid?: string) => {
  const {
    id,
    cartItemIds: cartItemIdArray,
    mergedCustomerProjectInfos,
    projectInfo,
    site,
  } = useSelector((state: IStore) => getProjectRoot(state, pid)) as IProject;
  const {documentDictionary} = useActiveProjectDocuments();
  const psaVisible =
    documentDictionary[PSA_DOCUMENT_ID] &&
    !documentDictionary[PSA_DOCUMENT_ID].hidden;
  const estimateVisible =
    documentDictionary[ESTIMATE_DOCUMENT_ID] &&
    !documentDictionary[ESTIMATE_DOCUMENT_ID].hidden;

  const address: IAddressDetails | null = useMemo(() => {
    if (projectInfo) {
      return Project.getAddress(projectInfo);
    } else return null;
  }, [projectInfo]);
  const projectPriceModeSettings =
    mergedCustomerProjectInfos?.settings?.pricing_mode;
  const projectOverheadProfit = useMemo(() => {
    return (
      projectPriceModeSettings?.overhead_profit_fraction ??
      DEFAULT_OVERHEAD_PROFIT_FRACTION
    );
  }, [projectPriceModeSettings?.overhead_profit_fraction]);

  const isCartEnabled: boolean = useMemo(() => {
    return mergedCustomerProjectInfos?.settings?.cart_enabled ?? false;
  }, [mergedCustomerProjectInfos?.settings]);

  const cartItemIds = useMemo(() => {
    if (!cartItemIdArray || cartItemIdArray.length < 1) {
      return [];
    }
    if (!isCartEnabled) {
      return cartItemIdArray.slice(0, 1);
    }
    return cartItemIdArray;
  }, [cartItemIdArray, isCartEnabled]);
  const projectPriceMode =
    projectPriceModeSettings?.mode ?? ProjectPriceMode.DTC; // Setting DTC as the default for now
  const sanitizedProjectPricingModeSettings: Omit<
    ProjectPricingModeSettings,
    "edited_by_email" | "edited_timestamp"
  > = useMemo(() => {
    if (projectPriceMode === ProjectPriceMode.CostPlus) {
      let phase1Micros = UNIT_PERMIT_FEE_PHASE_1_MICROS;
      let phase2Micros = UNIT_PERMIT_FEE_PHASE_2_MICROS;
      if (projectPriceModeSettings) {
        if (
          "phase1Micros" in projectPriceModeSettings &&
          projectPriceModeSettings["phase1Micros"]
        ) {
          phase1Micros = projectPriceModeSettings["phase1Micros"];
        }
        if (
          "phase2Micros" in projectPriceModeSettings &&
          projectPriceModeSettings["phase2Micros"]
        ) {
          phase2Micros = projectPriceModeSettings["phase2Micros"];
        }
      }
      return {
        mode: ProjectPriceMode.CostPlus,
        overhead_profit_fraction: projectOverheadProfit,
        phase1Micros: phase1Micros,
        phase2Micros: phase2Micros,
        complex_pricing_enabled:
          projectPriceModeSettings?.complex_pricing_enabled ?? false,
      };
    } else {
      return {
        mode: ProjectPriceMode.DTC,
        overhead_profit_fraction: projectOverheadProfit,
        complex_pricing_enabled:
          projectPriceModeSettings?.complex_pricing_enabled ?? false,
      };
    }
  }, [projectOverheadProfit, projectPriceMode, projectPriceModeSettings]);
  const phase1Micros = useMemo(() => {
    if (projectPriceMode === ProjectPriceMode.CostPlus) {
      const savedPhase1 =
        mergedCustomerProjectInfos?.settings?.pricing_mode &&
        "phase1Micros" in mergedCustomerProjectInfos?.settings?.pricing_mode
          ? mergedCustomerProjectInfos?.settings?.pricing_mode?.["phase1Micros"]
          : UNIT_PERMIT_FEE_PHASE_1_MICROS;
      return savedPhase1 ?? UNIT_PERMIT_FEE_PHASE_1_MICROS;
    }
    return undefined;
  }, [mergedCustomerProjectInfos?.settings?.pricing_mode, projectPriceMode]);
  const phase2Micros = useMemo(() => {
    if (projectPriceMode === ProjectPriceMode.CostPlus) {
      const savedPhase2 =
        mergedCustomerProjectInfos?.settings?.pricing_mode &&
        "phase2Micros" in mergedCustomerProjectInfos?.settings?.pricing_mode
          ? mergedCustomerProjectInfos?.settings?.pricing_mode?.["phase2Micros"]
          : UNIT_PERMIT_FEE_PHASE_2_MICROS;
      return savedPhase2 ?? UNIT_PERMIT_FEE_PHASE_2_MICROS;
    }
    return undefined;
  }, [mergedCustomerProjectInfos?.settings?.pricing_mode, projectPriceMode]);
  return {
    id: id,
    cartItemIds: cartItemIds,
    cartItemCount: cartItemIds?.length ?? 0,
    address: address,
    info: projectInfo,
    mergedCustomerProjectInfos: mergedCustomerProjectInfos,
    site: site,
    projectPriceMode: projectPriceMode,
    projectOverheadProfit: projectOverheadProfit, // May be undefined if DTC mode
    isCartEnabled: isCartEnabled,
    isProjectLocked: projectInfo?.settings?.design_lock?.is_locked,
    projectPriceSettings: sanitizedProjectPricingModeSettings,
    isDevelopmentByVilla:
      projectPriceModeSettings?.mode === ProjectPriceMode.CostPlus,
    phase1Micros: phase1Micros,
    phase2Micros: phase2Micros,
    budgetHidden: psaVisible || estimateVisible || !site?.visible,
  } as IUseProject;
};
