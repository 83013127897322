import {useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchUser} from "../../../database/firebase/user";
import {isAdmin} from "../user/UserUtils";
import {ICustomerInfo} from "@natomas-org/core";
import {ICurrentUser, IStore} from "../slices/types/Store";
import {useUsers} from "./useUser/useUsers";

interface IUseCurrentCustomer {
  uid: string | undefined;
  user_id: string | undefined;
  user: ICustomerInfo | null;
  authInitialized: boolean;
  actions: any;
  loggedInUser: ICurrentUser | undefined | null;
  isSignedIn: boolean;
  isAdmin: boolean;
}

// This hook is for retrieving information about the current LOGGED IN user
export const useCurrentUser = (): IUseCurrentCustomer => {
  const loggedInUser = useSelector((state: IStore) => state.global.user);
  const users = useUsers();
  const authInitialized = useSelector(
    (state: IStore) => state.global.userInitialized
  );
  const userActionsMap = useSelector((state: IStore) => state.user.userActions);

  useEffect(() => {
    if (loggedInUser && users[loggedInUser.uid] == null) {
      fetchUser(loggedInUser.uid, false);
    }
  }, [loggedInUser, users]);

  const loggedInUserIsAdmin = isAdmin(loggedInUser);
  const userInfo = users[loggedInUser?.uid ?? ""];
  const userActions = userActionsMap[loggedInUser?.uid ?? ""] ?? {};

  return {
    loggedInUser,
    uid: loggedInUser?.uid,
    user_id: loggedInUser?.uid,
    user: userInfo,
    authInitialized: authInitialized != null,
    actions: userActions,
    isSignedIn: !!loggedInUser?.uid,
    isAdmin: loggedInUserIsAdmin,
  };
};
