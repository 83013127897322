import React, {useEffect, useState} from "react";
import {mergeObjects, SearchUsersRequest} from "@natomas-org/core";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {DatePickerInput} from "../../../../ProductLineView/components/DatePickerInput";
import {Flex} from "../../../../../_shared/styles/general";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";

export const SearchFilters = (props: {
  publishFilters: (filters: SearchUsersRequest) => void;
  initialFilters: SearchUsersRequest;
  closeModal?: () => void;
}) => {
  const {publishFilters, initialFilters, closeModal} = props;
  const [filters, setFilters] = useState<SearchUsersRequest>(initialFilters);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const currentStartDate = filters.dateRange?.startTimestamp
    ? new Date(filters.dateRange?.startTimestamp)
    : undefined;
  const currentEndDate = filters.dateRange?.endTimestamp
    ? new Date(filters.dateRange?.endTimestamp)
    : undefined;

  return (
    <div style={{position: "relative"}}>
      <h3>Apply filters to your search</h3>
      <NatFlex>
        <DatePickerInput
          value={currentStartDate}
          fieldInfo={{
            key: "start-date",
            name: "Start Date",
            type: "date-picker",
            showTime: true,
            endTime: currentEndDate,
          }}
          initialValue={currentStartDate}
          onChange={(value: Date) => {
            const newFilters = {
              dateRange: {
                startTimestamp: value.getTime(),
              },
            } as SearchUsersRequest;
            const mergedFilters = mergeObjects(filters, newFilters);
            setFilters(mergedFilters);
          }}
        />
        <DatePickerInput
          value={currentEndDate}
          fieldInfo={{
            key: "end-date",
            name: "End Date",
            type: "date-picker",
            showTime: true,
            startTime: currentStartDate,
          }}
          initialValue={currentEndDate}
          onChange={(value: Date) => {
            const newFilters = {
              dateRange: {
                endTimestamp: value.getTime(),
              },
            } as SearchUsersRequest;
            const mergedFilters = mergeObjects(filters, newFilters);
            setFilters(mergedFilters);
          }}
        />
      </NatFlex>
      <br />
      <br />
      <Flex columnGap={"10px"}>
        <NatButton
          label={"Clear filters"}
          type={"button"}
          clickEvent={() => {
            publishFilters({
              searchText: initialFilters.searchText,
              dateRange: undefined,
            });
          }}
          option={StyleOption.SECONDARY_ALT}
        />
        <NatButton
          label={"Save Filters"}
          type={"button"}
          clickEvent={() => {
            publishFilters(filters);
            if (closeModal) {
              closeModal();
            }
          }}
          option={StyleOption.PRIMARY_ALT}
        />
      </Flex>
    </div>
  );
};
