import React, {useMemo} from "react";
import styled from "styled-components";
import {GRANITE} from "../../../components/_shared/colors";

const LegalCopyright = () => {
  const copy: string = useMemo(() => {
    const year = new Date().getFullYear();
    return `© ${year}, Natomas Labs, Inc., All rights reserved.`;
  }, []);

  return <Container id={"villa-legal-copyright"}>{copy}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;
  color: ${GRANITE};
`;
export default LegalCopyright;
