import React from "react";
import {DTCPresentationView} from "../../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/DTCPresentationView";

export class DTCProposalPreview extends React.Component {
  render() {
    return (
      <div id="componentToPrintId">
        <DTCPresentationView hideClose={true} readyToPrint={true} />
      </div>
    );
  }
}
