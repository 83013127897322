import {CallToActionSectionProps} from "./index";
import {INSTANT_ESTIMATE_PAGE_ID} from "../../../../../_shared/navigation/constants";
import {StyleOption} from "../../../../../../_shared/generics/_shared";
import React from "react";
import {SITE_WORK_PHRASE} from "../../../../../../_shared/constants/labels";
import {NavigationPaths} from "../../../../../../_shared/hooks/useNavigation/paths";
import {OutOfServiceSelectionStyledDiv} from "./styles";

// $NAME is the ref for the customer's name
// $PRODUCT is the ref for the customer's selected product

// const generalCallLink: ILinkButton = {
//   label: "Schedule a chat with us.",
//   clickEvent: scheduleACallClicked,
// };

export const OutOfServiceSection = () => {
  return (
    <OutOfServiceSelectionStyledDiv>
      Questions? Email us at{" "}
      <a href="mailto: hello@villahomes.com">hello@villahomes.com</a>.
    </OutOfServiceSelectionStyledDiv>
  );
};

export const getInfeasibleSectionDashboard = (
  to: any
): CallToActionSectionProps => {
  return {
    buttons: [
      {
        label: "View all homes",
        type: "button",
        clickEvent: () => to(NavigationPaths.CATALOG),
        option: StyleOption.PRIMARY,
      },
    ],
    footerLinks: [],
    footerTexts: [<OutOfServiceSection />],
    titles: ["We’re sorry, your address is outside our current delivery area."],
  };
};

export const getChooseHomeSection = (to: any): CallToActionSectionProps => {
  return {
    buttons: [
      {
        label: "Browse our homes",
        type: "button",
        clickEvent: () => to(NavigationPaths.CATALOG),
        option: StyleOption.PRIMARY_ALT,
      },
    ],
    footerLinks: [],
    footerTexts: [],
    titles: [
      "Hi $NAME, it looks like you haven’t chosen a home yet.",
      "Browse our catalog and find the ADU just right for you.",
    ],
  };
};

export const getDesignHomeSection = (to: any): CallToActionSectionProps => {
  return {
    buttons: [
      {
        label: "Explore design options",
        type: "button",
        clickEvent: () => {
          to(NavigationPaths.STUDIO);
        },
        option: StyleOption.PRIMARY_ALT,
      },
    ],
    footerLinks: [],
    footerTexts: [],
    titles: [
      "Hi $NAME, you chose the $PRODUCT last time you were here.",
      "Would you like to select upgrades for this ADU?",
    ],
  };
};

export const getInstantEstimateSection = (
  to: any
): CallToActionSectionProps => {
  return {
    buttons: [
      {
        label: "Get instant quote",
        type: "button",
        clickEvent: () =>
          to(NavigationPaths.PORTAL, {page: INSTANT_ESTIMATE_PAGE_ID}),
        option: StyleOption.PRIMARY_ALT,
      },
    ],
    footerLinks: [],
    footerTexts: [],
    titles: [
      "Hi $NAME, you designed the $PRODUCT last time you were here.",
      "Get your instant quote with a quick site work estimate.",
    ],
  };
};

export const getProjectProposalSection = (
  to: any
): CallToActionSectionProps => {
  return {
    buttons: [
      {
        label: "Review " + SITE_WORK_PHRASE,
        type: "button",
        clickEvent: () => {
          to(NavigationPaths.PORTAL, {page: INSTANT_ESTIMATE_PAGE_ID});
        },
        option: StyleOption.PRIMARY_ALT,
        trackingId: "splash-screen-review-instant-quote",
      },
    ],
    footerLinks: [],
    footerTexts: [],
    titles: [
      "Hi $NAME, your instant quote for the $PRODUCT is complete!",
      "If you’re ready, let’s get started on your project proposal.",
    ],
  };
};
