import {useSelector} from "react-redux";
import {isAdmin} from "../../../_shared/user/UserUtils";

export const ConditionalRendering = (props) => {
  const {mode, approvedRender, deniedRender, show} = props;
  const currentUser = useSelector((state) => state.global.user);
  const adminMode = useSelector((state) => state.portal.adminMode);
  let success;
  const denied = deniedRender !== undefined ? deniedRender : null;

  switch (mode) {
    case "admin-activated":
      success = isAdmin(currentUser) && adminMode;
      break;
    case "admin":
      success = isAdmin(currentUser);
      break;
    case "owner":
      success = isAdmin(currentUser) || true;
      break;
    default:
      success = true;
  }

  if (success) {
    if (show != null) {
      return show === true ? approvedRender : denied;
    } else {
      return approvedRender;
    }
  }

  return denied;
};
