import React, {useMemo} from "react";
import {
  getDesignSnapshotUpgradeInfo,
  IDesignUpgradeCategoryInfo,
  IDesignUpgradeInfo,
  ProjectPriceMode,
} from "@natomas-org/core";
import CartItemDesignCategoryPreview from "./CartItemDesignCategoryPreview";
import {Divider} from "../../../../../../_shared/generics/divider";
import {CartItemDesignContainer} from "./styles";

interface CartItemDesignPreviewProps {
  snapshot: any;
  pricingMode: ProjectPriceMode;
}

const CartItemDesignPreview = (props: CartItemDesignPreviewProps) => {
  const {snapshot, pricingMode} = props;
  const upgradeInfo: IDesignUpgradeInfo = useMemo(() => {
    // TODO B2B Review: This is a hack to get the upgrades list.
    return getDesignSnapshotUpgradeInfo(snapshot, pricingMode);
  }, [pricingMode, snapshot]);
  return (
    <CartItemDesignContainer>
      {Object.values(upgradeInfo)?.map(
        (category: IDesignUpgradeCategoryInfo, index: number) => {
          return (
            <>
              <Divider hidden={index === 0} />
              <CartItemDesignCategoryPreview category={category} key={index} />
            </>
          );
        }
      )}
    </CartItemDesignContainer>
  );
};

export default CartItemDesignPreview;
