import React from "react";
import Text from "../../2-component/Text";
import {ContentWrapper} from "./style";
import {InputLibrary} from "../../1-container/forms/library/inputs";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import Form from "../../2-component/Form/form";
import {useAddress} from "../../../components/_shared/hooks/useAddress";
import {IAddressDetails} from "@natomas-org/core";
import {ButtonProps} from "../../2-component/Button/interface";

interface ConfirmDeveloperContentProps {
  schema?: CreateLeadSchema;
  additionalButtons?: ButtonProps[];
  onSubmit: (values: any, addressDetails: IAddressDetails) => void;
}

const getInputs = (
  defaultFirst?: string,
  defaultLast?: string,
  defaultAddress?: IAddressDetails,
  setAddressDetails?: (address: IAddressDetails) => void
) => {
  return [
    {...InputLibrary.firstNameInput, row: 1, defaultValue: defaultFirst},
    {...InputLibrary.lastNameInput, row: 1, defaultValue: defaultLast},
    InputLibrary.phoneInput,
    {
      ...InputLibrary.addressInput,
      defaultValue: defaultAddress?.full_address,
      label: "Please provide your mailing address for product availability",
      textProps: {
        text: "You can change this at any time.",
      },
      setObject: setAddressDetails,
    },
    InputLibrary.marketingConsentCheckbox,
  ];
};

const ConfirmDeveloperContent = (props: ConfirmDeveloperContentProps) => {
  const {schema, additionalButtons, onSubmit} = props;
  const {url, publicAddress} = useAddress();
  const initialAddress = url ?? publicAddress ?? undefined;
  const [addressDetails, setAddressDetails] = React.useState<
    IAddressDetails | undefined
  >(initialAddress);
  const inputs = getInputs(
    schema?.["first_name"],
    schema?.["last_name"],
    initialAddress,
    setAddressDetails
  );
  return (
    <ContentWrapper>
      <Text
        text={
          "Enter your information to create a free account,\n explore our catalog, and more."
        }
        style={{
          textAlign: "center",
          fontSize: "14px !important",
          lineHeight: "18px !important",
        }}
      />
      <Form
        key={"confirm-developer-form"}
        inputs={inputs}
        onSubmit={(values) => {
          if (addressDetails) onSubmit?.(values, addressDetails);
          else window.alert("No address");
        }}
        buttons={[
          ...(additionalButtons ?? []),
          {
            id: "for-developer-form-confirm",
            label: "Confirm",
            disabled: !addressDetails,
            type: "submit",
          },
        ]}
      />
    </ContentWrapper>
  );
};

export default ConfirmDeveloperContent;
