import React from "react";
import {QuickInfoDisclaimer} from "./styles";
import {FeasibilityInfoSection} from "./FeasibilityInfoSection";
import {GeneralInfoSection} from "./GeneralInfoSection";
import {SiteWorkSection} from "./SiteWorkSection";
import {AdvancedStatusesSection} from "./AdvancedStatusesSection";
import {CustomerProjectViewContentContainer} from "../styles";
import {GeoDataInfoSection} from "./GeoDataSection";
import {ProjectParcelDataSection} from "./ParcelDataSection";
import {usePropertyData} from "../../../../../../_shared/hooks/usePropertyData/usePropertyData";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {
  NatJustifyContent,
  NatVerticalAlign,
} from "../../../../../../_shared/generics/_shared";
import PricingInfoSection_Deprecated from "./PricingInfoSection/deprecation";

export const QuickInfoTab = () => {
  const {projectPropertyData} = usePropertyData();
  return (
    <>
      <CustomerProjectViewContentContainer>
        <NatFlex
          FULL_WIDTH
          JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
          VERTICAL_ALIGN={NatVerticalAlign.BOTTOM}
          ROW
          style={{paddingTop: "0.5rem"}}
        >
          <QuickInfoDisclaimer>
            This tab is read-only, and is should just be used as a reference
            tool.
          </QuickInfoDisclaimer>
        </NatFlex>
        <PricingInfoSection_Deprecated />
        <GeneralInfoSection />
        {projectPropertyData && (
          <>
            <FeasibilityInfoSection propertyData={projectPropertyData} />
            <GeoDataInfoSection propertyData={projectPropertyData} />
            <SiteWorkSection propertyData={projectPropertyData} />
            <ProjectParcelDataSection propertyData={projectPropertyData} />
          </>
        )}
        <AdvancedStatusesSection />
        <br />
        <br />
      </CustomerProjectViewContentContainer>
    </>
  );
};
