import React from "react";
import {FullScreenCenteredContent, LandingText} from "../styles";
import {NatButton} from "../../../_shared/generics/button";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {signInWithGoogle} from "../../../../database/firebase";
import {StyleOption} from "../../../_shared/generics/_shared";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";

export const PermissionDeniedView = (props: {
  initialized: boolean;
  fetchingPermissions: boolean;
  isAdmin: boolean;
}) => {
  const {to} = useNavigation();
  const {initialized, isAdmin, fetchingPermissions} = props;
  if (!initialized || (fetchingPermissions && isAdmin)) {
    return (
      <FullScreenCenteredContent>
        <LandingText>Verifying Account</LandingText>
        <LandingText>Checking your account permissions...</LandingText>
      </FullScreenCenteredContent>
    );
  }
  return (
    <FullScreenCenteredContent>
      <LandingText>Error 401: Permission denied.</LandingText>
      <LandingText>
        Please sign into an administrator account or contact your manager about
        permissions.
      </LandingText>
      <div style={{alignSelf: "center"}}>
        <NatFlex>
          <NatButton
            label={"Back to home"}
            type={"button"}
            option={StyleOption.SECONDARY}
            clickEvent={() => (window.location.pathname = "/")}
          />

          <NatButton
            label={"Login as admin"}
            trackingId={"employee-login"}
            type={"button"}
            clickEvent={signInWithGoogle}
            option={StyleOption.PRIMARY}
          />
        </NatFlex>
      </div>
    </FullScreenCenteredContent>
  );
};
