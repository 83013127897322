import {
  toAdmin,
  toAdminCompletedProjects,
  toAdminCustomer,
  toAdminCustomerProject,
  toAdminDataExport,
  toAdminEmployeeManagement,
  toAdminInventory,
  toAdminProductCatalog,
  toAdminProductCatalogRouting,
  toCatalog,
  toCatalogInventory,
  toCustomerDatabase,
  toDeveloperDashboard,
  toDocuments,
  toIntakeForm,
  toLogin,
  toMap,
  toNavigateBack,
  toPDFDocuments,
  toPortal,
  toProposalPDF,
  toQuiz,
  toStudio,
  toUnitById,
} from "./navigation";
import {IAddressDetails, IProduct} from "@natomas-org/core";
import {MapViewMode} from "@natomas-org/service";

export enum NavigationPaths {
  ADMIN = "admin",
  ADMIN_CUSTOMER = "admin-customer",
  ADMIN_CUSTOMER_DATABASE = "admin-customer-database",
  ADMIN_CUSTOMER_PROJECT = "admin-customer-project",
  ADMIN_COMPLETED_PROJECTS = "admin-completed-projects",
  ADMIN_EMPLOYEE_MANAGEMENT = "admin-employee-management",
  ADMIN_DATA = "admin-data",
  ADMIN_PRODUCTS = "admin-products",
  ADMIN_INVENTORY = "admin-inventory",
  ADMIN_PRODUCT_ROUTING = "admin-product-routing",
  PUBLIC_CATALOG = "public_catalog",
  CATALOG = "catalog",
  CART = "cart",
  CATALOG_INVENTORY = "catalog-i",
  // TODO Should deprecate DESIGN when we are ready and migrate everything to STUDIO
  DOCUMENT = "document",
  DOCUMENT_PDF = "document-pdf",
  DOCUMENT_PDF_PROPOSAL = "document-pdf-proposal",
  DEVELOPER = "developer",
  INTAKE = "intake",
  LOGIN = "login",
  PORTAL = "portal",
  PROFILE = "profile",
  STUDIO = "studio",
  UNIT = "unit",
  PROPERTY_MAP = "property_map",
  COMPLETED_PROJECTS_MAP = "completed_projects_map",
  BACK = "back",
  QUIZ = "quiz",
}

export const getRequestedPathFunction = (request: NavigationPaths) => {
  switch (request) {
    case NavigationPaths.ADMIN:
      return toAdmin;
    case NavigationPaths.ADMIN_CUSTOMER:
      return toAdminCustomer;
    case NavigationPaths.ADMIN_CUSTOMER_DATABASE:
      return toCustomerDatabase;
    case NavigationPaths.ADMIN_EMPLOYEE_MANAGEMENT:
      return toAdminEmployeeManagement;
    case NavigationPaths.ADMIN_CUSTOMER_PROJECT:
      return toAdminCustomerProject;
    case NavigationPaths.ADMIN_COMPLETED_PROJECTS:
      return toAdminCompletedProjects;
    case NavigationPaths.ADMIN_DATA:
      return toAdminDataExport;
    case NavigationPaths.ADMIN_PRODUCTS:
      return toAdminProductCatalog;
    case NavigationPaths.ADMIN_INVENTORY:
      return toAdminInventory;
    case NavigationPaths.ADMIN_PRODUCT_ROUTING:
      return toAdminProductCatalogRouting;
    case NavigationPaths.CATALOG:
      return toCatalog;
    case NavigationPaths.CART:
      return toPortal({page: "cart"});
    case NavigationPaths.PUBLIC_CATALOG:
      return () => {
        toCatalog({publicCatalog: true});
      };
    case NavigationPaths.CATALOG_INVENTORY:
      return toCatalogInventory;
    case NavigationPaths.DOCUMENT:
      return toDocuments;
    case NavigationPaths.DOCUMENT_PDF:
      return toPDFDocuments();
    case NavigationPaths.DOCUMENT_PDF_PROPOSAL:
      return toProposalPDF();
    case NavigationPaths.DEVELOPER:
      return toDeveloperDashboard;
    case NavigationPaths.INTAKE:
      return toIntakeForm;
    case NavigationPaths.LOGIN:
      return toLogin;
    case NavigationPaths.PORTAL:
      return toPortal;
    case NavigationPaths.PROFILE:
      return toPortal({page: "profile"});
    case NavigationPaths.STUDIO:
      return toStudio;
    case NavigationPaths.UNIT:
      return toUnitById;
    case NavigationPaths.COMPLETED_PROJECTS_MAP:
      return (projectAddress?: IAddressDetails, projectProduct?: IProduct) => {
        toMap(
          MapViewMode.COMPLETED_PROJECTS,
          projectAddress,
          projectProduct
        ).then((r) => {});
      };
    case NavigationPaths.PROPERTY_MAP:
      return (projectAddress?: IAddressDetails, projectProduct?: IProduct) => {
        toMap(MapViewMode.PROPERTY_MAP, projectAddress, projectProduct).then(
          (r) => {}
        );
      };
    case NavigationPaths.BACK:
      return toNavigateBack;
    case NavigationPaths.QUIZ:
      return toQuiz;
    default:
      return null;
  }
};
