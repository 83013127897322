import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";

export const HeaderContent = styled.div<{isMobile: boolean}>`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: ${(props) => (props.isMobile ? "0" : "1rem")};
`;

export const HeaderTitle = styled.h1<{lineHeight: string; isMobile: boolean}>`
  display: flex;
  font-size: 1.25rem;
  text-align: left;
  margin: 0;
  font-family: ${FONT_FAMILY_MEDIUM};
  justify-content: flex-start;
  white-space: nowrap;
  flex-grow: 1;
`;

export const NavigationButtons = styled.div<{margin?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.5rem;
  margin-right: 0.5rem;
`;
