import {INVENTORY_UNIT_PREFIX} from "../../../../../_shared/constants/labels";
import {useGlobalConfiguration} from "../../../../../_shared/hooks/useGlobalConfiguration";

export const useProductModeDescriptor = () => {
  const {INVENTORY_PROGRAM} = useGlobalConfiguration();
  const inventoryText = `${INVENTORY_UNIT_PREFIX} units are pre-designed, built, and ready to be
        delivered to your backyard within ${INVENTORY_PROGRAM.DELIVERY_MONTHS} months.`;
  const builtToOrderText = `Built to order homes can be designed to your taste from a selection of
        finishes and upgrades.`;

  return {
    inventory: inventoryText,
    customBuilt: builtToOrderText,
  };
};
