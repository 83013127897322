import {useDispatch} from "react-redux";
import {
  setProductGroupId,
  setProductGroupVersionId,
  setProductId,
} from "../../../slices/CatalogSlice/CatalogSessionSlice";
import {useCallback} from "react";
import {useCatalogSessionState} from "./useCatalogSessionState";
import {
  HistoryChangeType,
  setMultipleURLQueryKeyValues,
  setURLPathAndMultipleQueries,
} from "../../../navigation/_helpers";
import {
  URL_PARAM_KEY_PRODUCT_GROUP_ID,
  URL_PARAM_KEY_UNIT_ID,
} from "@natomas-org/service";
import {IProduct} from "@natomas-org/core";
import {CATALOG_ROOT_PATH} from "../../../../../paths";

export const useCatalogSessionDispatcher = () => {
  const {selectedProductGroupId, selectedProductId} = useCatalogSessionState();
  const dispatch = useDispatch();

  const selectProductById = useCallback(
    (productId: string | null) => {
      dispatch(setProductId(productId));
    },
    [dispatch]
  );

  // Select by product ID only
  const toCatalogProductById: (id: string | null) => void = useCallback(
    (productId: string | null) => {
      if (selectedProductId !== productId) {
        selectProductById(productId);
        navigateToProductById(productId); // Separate navigation logic
      }
    },
    [selectProductById, selectedProductId]
  );

  // Selects a product in the current session
  const selectProduct = useCallback(
    (product: IProduct) => {
      const {id: productId, productGroupId, versionId} = product;
      if (!productId || !productGroupId) return;
      navigateToProduct(productGroupId, productId, HistoryChangeType.PUSH);
      dispatch(setProductId(productId));
      dispatch(setProductGroupId(productGroupId));
      dispatch(setProductGroupVersionId(versionId ?? null));
    },
    [dispatch]
  );

  // Select by full product (including group and version)
  const toCatalogProduct: (
    product?: IProduct,
    historyChangeType?: HistoryChangeType
  ) => void = useCallback(
    (product?: IProduct, historyChangeType?: HistoryChangeType) => {
      if (!product) return;
      const {id: productId, productGroupId, versionId} = product;
      if (!productId || !productGroupId) return;

      navigateToProduct(productGroupId, productId, historyChangeType); // Separate navigation logic
    },
    [selectProduct, selectedProductId]
  );

  const selectFactory = useCallback(
    (factoryId: string | null) => {
      dispatch(setProductId(null));
      dispatch(setProductGroupId(factoryId));
      dispatch(setProductGroupVersionId(null));
    },
    [dispatch]
  );

  // Select by factory id only
  const toCatalogFactory: (id: string | null) => void = useCallback(
    (factoryId: string | null) => {
      // if (selectedProductGroupId !== factoryId) {
      //   selectFactory(factoryId);
      // }
      navigateToFactory(factoryId); // Separate navigation logic
    },
    [selectFactory, selectedProductGroupId]
  );

  // Reset all selections
  const resetCatalogSessionSelections: () => void = useCallback(() => {
    dispatch(setProductGroupId(null));
    dispatch(setProductGroupVersionId(null));
    dispatch(setProductId(null));
    setMultipleURLQueryKeyValues(
      [
        {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
        {key: URL_PARAM_KEY_UNIT_ID, value: null},
      ],
      HistoryChangeType.PUSH
    );
  }, [dispatch]);

  return {
    toCatalogProduct,
    toCatalogProductById,
    toCatalogFactory,
    selectProductById,
    selectProduct,
    selectFactory,
    resetCatalogSessionSelections,
  };
};

const navigateToProductById = (productId: string | null) => {
  setMultipleURLQueryKeyValues(
    [{key: URL_PARAM_KEY_UNIT_ID, value: productId}],
    HistoryChangeType.PUSH
  );
};

const navigateToProduct = (
  productGroupId: string,
  productId: string,
  historyChangeType?: HistoryChangeType
) => {
  setMultipleURLQueryKeyValues(
    [
      {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: productGroupId},
      {key: URL_PARAM_KEY_UNIT_ID, value: productId},
    ],
    historyChangeType ?? HistoryChangeType.PUSH
  );
};

const navigateToFactory = (factoryId: string | null) => {
  setURLPathAndMultipleQueries(
    CATALOG_ROOT_PATH,
    [{key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: factoryId}],
    HistoryChangeType.PUSH
  );
};
