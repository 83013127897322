import styled from "styled-components";
import {Button} from "../../2-component/Button/style";

// Contains all the content of authentication modal
export const ContentContainer = styled.div<{isWider: boolean}>`
  max-width: ${(props) => (props.isWider ? "750px" : "450px")};
  margin: 16px auto 16px auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Button} {
    width: 100%;
  }
  background-color: #fff;
  border-radius: 10px;
`;

// Contains the dynamic content of the authentication modal
// - This component should be mounted dynamically to make use of the fadeIn animation
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  a {
    font-size: 12px !important;
  }
  span {
    font-size: 12px !important;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
