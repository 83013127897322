import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  IPermissions,
  useAllPermissions,
  usePermissions,
} from "../../_shared/hooks/usePermissions/usePermissions";
import {fetchAllEmployees} from "../../../database/firebase/user";
import {AdminHeaderTitle} from "../_shared/components/AdminHeaderTitle";
import {
  EmployeeManagementContainer,
  EmployeeManagementTitle,
  EmployeeTable,
  EmployeeTableContainer,
} from "./styles";
import {AddEmployeeCard} from "./AddEmployeeCard";
import {EmployeeRow} from "./EmployeeCard";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";
import {NatModal} from "../../_shared/generics/modal/NatModal";
import {EmployeeAccessControlView} from "./EmployeeAccessControlView";
import {useSelector} from "react-redux";
import {IStore} from "../../_shared/slices/types/Store";
import firebase from "firebase/app";
import {sortEmployeesByName} from "./helper";

export interface IEmployee {
  permissions: IPermissions;
  authInfo?: firebase.UserInfo;
}

export const EmployeeManagementView = () => {
  const {uid} = useCurrentUser();
  const {to} = useNavigation();
  const {fullAccess} = usePermissions(uid);
  const employeePermissions = useAllPermissions();
  const userAuthInfoMap = useSelector(
    (state: IStore) => state?.user?.userAuthInfo
  );
  const [targetUID, setTargetUID] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchAllEmployees().then(() => {
      console.log("Fetched all employee(s) info");
    });
  }, []);

  const employees: IEmployee[] = useMemo(() => {
    return sortEmployeesByName(
      employeePermissions?.map((permission: IPermissions): IEmployee => {
        return {
          authInfo: userAuthInfoMap[permission.uid],
          permissions: permission,
        };
      })
    );
  }, [employeePermissions, userAuthInfoMap]);

  const EmployeesRows = useMemo(() => {
    return (
      <>
        {employees?.map((employee: IEmployee) => {
          const eid = employee?.permissions?.uid;
          return (
            <EmployeeRow
              key={eid + "_employee_card"}
              employee={employee}
              locked={uid === eid}
              changeEmployeePermissions={() => setTargetUID(eid)}
            />
          );
        }) ?? <>Nobody</>}
      </>
    );
  }, [employees, uid]);

  const closeAccessController = useCallback(() => {
    setTargetUID(undefined);
  }, [setTargetUID]);

  return (
    <EmployeeManagementContainer>
      <AdminHeaderTitle clickEvent={() => to(NavigationPaths.ADMIN)} />
      <EmployeeTableContainer>
        <EmployeeManagementTitle>
          Employee Access Management
        </EmployeeManagementTitle>
        <EmployeeTable>{EmployeesRows}</EmployeeTable>
      </EmployeeTableContainer>
      <NatModal
        handleClose={closeAccessController}
        show={!!targetUID}
        content={
          <EmployeeAccessControlView
            uid={targetUID}
            handleClose={closeAccessController}
          />
        }
      />
      {fullAccess && <AddEmployeeCard />}
    </EmployeeManagementContainer>
  );
};
