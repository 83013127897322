// Local is_test check for lead tracking in LogRocket

import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";

export const isLeadTest = (payload: CreateLeadSchema) => {
  const {email, first_name, last_name} = payload;
  const isFirstNameTest = first_name?.toLowerCase().includes("test") ?? false;
  const isLastNameTest = last_name?.toLowerCase().includes("test") ?? false;
  const isEmailTest = email?.toLowerCase().includes("cypress.account") ?? false;
  const isNameTest = isFirstNameTest && isLastNameTest;
  return isNameTest || isEmailTest;
};
