import React from "react";
import Form from "../../2-component/Form/form";
import {InputProps} from "../../2-component/Input/interface";
import {ButtonProps} from "../../2-component/Button/interface";

const id = "customer_type";

const inputs: InputProps[] = [
  {
    id: id,
    type: "radio",
    label: "",
    required: true,
    selectionProps: [
      {value: "D2C ADUs", label: "Homeowner looking to build an ADU"},
      {
        value: "B2B ADUs",
        label: "Multifamily operator looking to build ADUs",
      },
      {
        value: "Fee Building Development",
        label: "Developer looking to build homes",
      },
    ],
  },
];

const CustomerTypeForm = (props: {
  buttons: ButtonProps[];
  onSubmit: (props: string) => void;
}) => {
  return (
    <Form
      key={"confirm-customer-type-form"}
      label={""}
      inputs={inputs}
      onSubmit={(values) => {
        props.onSubmit(values[id]);
      }}
      buttons={[
        ...props.buttons,
        {
          id: "customer-type-continue",
          label: "Continue",
          type: "submit",
        },
      ]}
    />
  );
};

export default CustomerTypeForm;
