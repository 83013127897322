import React from "react";
import Button from "../../../2-component/Button";
import {AvatarMenu, IAvatarMenuProps} from "./avatar-menu";

export const HeaderButtons = (props: IAvatarMenuProps) => {
  if (props.avatar.is_user_signed_in) {
    return (
      <>
        {/*<CartButton />*/}
        <AvatarMenu {...props} />
      </>
    );
  } else {
    return (
      <>
        <Button
          id={"header_sign_in"}
          data-cy={"header_sign_in"}
          label={"Sign in"}
          theme={"secondary"}
          onClick={props.signInAction}
          size={"large"}
        />
        <Button
          id={"header_contact_us"}
          data-cy={"header_contact_us"}
          label={"Contact us"}
          onClick={() => (window.location.pathname = "/contact-us")}
          size={"large"}
        />
      </>
    );
  }
};
