import React from "react";
import styled from "styled-components";
import OurProcessBlueDiamond from "./assets/blue_diamond";
import Image from "../../2-component/Image";
import homeowner from "./assets/homeowner.png";
import multifamily from "./assets/multifamily.png";
import developer from "./assets/developer.png";
import {all_links} from "./links";

const ImageStyle: React.CSSProperties = {
  height: "100%",
  width: "100%",
  alignSelf: "center",
  borderRadius: "10px",
  objectFit: "cover",
  aspectRatio: "40/19",
};

export const OurProcess = (props: {}) => {
  return (
    <Container>
      <OurProcessSection>
        <Row
          onClick={() =>
            (window.location.pathname =
              all_links.header_about_villa_how_it_works.pathname || "")
          }
        >
          <OurProcessBlueDiamond />
          <Label>{"What we do"}</Label>
        </Row>
        <Description>
          We redesigned the homebuilding process to make it faster, more
          efficient, and more impactful for all.
        </Description>
      </OurProcessSection>
      <Divider />
      <SectionContainer>
        <Section
          onClick={() =>
            (window.location.pathname =
              all_links.header_for_homeowners.pathname ?? "")
          }
        >
          <ImageContainer>
            <Image
              alt={all_links.header_for_homeowners.label}
              src={homeowner}
              id={`${all_links.header_for_homeowners.id}-image`}
              style={ImageStyle}
            />
            <GradientOverlay />
          </ImageContainer>
          <ForLabel
            href={all_links.header_for_homeowners.pathname ?? ""}
            id={all_links.header_for_homeowners.id}
          >
            {all_links.header_for_homeowners.label}
          </ForLabel>
        </Section>
        <Section
          onClick={() =>
            (window.location.pathname =
              all_links.header_for_multifamily.pathname ?? "")
          }
        >
          <ImageContainer>
            <Image
              alt={all_links.header_for_multifamily.label}
              src={multifamily}
              id={`${all_links.header_for_multifamily.id}-image`}
              style={ImageStyle}
            />
            <GradientOverlay />
          </ImageContainer>
          <ForLabel
            href={all_links.header_for_multifamily.pathname ?? ""}
            id={all_links.header_for_multifamily.id}
          >
            {all_links.header_for_multifamily.label}
          </ForLabel>
        </Section>
        <Section
          onClick={() =>
            (window.location.pathname =
              all_links.header_for_developers.pathname ?? "")
          }
        >
          <ImageContainer>
            <Image
              alt={all_links.header_for_developers.label}
              src={developer}
              id={`${all_links.header_for_developers.id}-image`}
              style={ImageStyle}
            />
            <GradientOverlay />
          </ImageContainer>
          <ForLabel
            href={all_links.header_for_developers.pathname ?? ""}
            id={all_links.header_for_developers.id}
          >
            {all_links.header_for_developers.label}
          </ForLabel>
        </Section>
      </SectionContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  background: white; // #f7f5f2;
  padding-bottom: 32px;
`;

const ImageContainer = styled.div`
  position: relative;
  flex-grow: 1;
`;
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px; /* Match the image's border radius */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 57.66%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

const Divider = styled.div`
  width: 1px;
  height: 229px;
  flex-shrink: 0;
  background-color: #adb5bd;
`;

const Label = styled.h2`
  color: #04171b;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  white-space: nowrap;
  /* title/xsmall-title */
  font-family: "p22-mackinac-pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.54px;
  margin: 0;
`;

const ForLabel = styled.a`
  color: #04171b;
  font-feature-settings: "liga" off, "clig" off;

  /* title/section-title */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 32px; /* 160% */
  letter-spacing: -0.2px;
  text-align: left;
  text-decoration: none;

  width: 100%;
  &:hover,
  &:focus {
    color: #0080a7;
    text-decoration: none;
  }
`;

const Description = styled.p`
  color: #5a4e4e;
  font-feature-settings: "liga" off, "clig" off;
  white-space: pre-wrap;
  /* body/body-lg/default */
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 22px; /* 137.5% */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
`;

const SectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 32px; // three items, two gaps, 64px
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 16px;
  height: 220px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const OurProcessSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 225px;
  flex-shrink: 0;
`;
