import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  AddressContent,
  AddressContentWrapper,
  AddressStringWrapper,
  DropdownCombinedSearchBarTitle,
  DropdownCombinedSearchBarWrapper,
  DropdownCombinedSearchBarWrapperMobile,
  DropdownLocationContainer,
  getDropdownLocationPopupStyling,
  Input,
  SearchBarContainer,
  StyledInput,
} from "./styles";
import {IoCloseOutline} from "react-icons/io5";
import {useDispatch} from "react-redux";
import {
  handleBuildSearchScriptLoad,
  LOCATION_SEARCH_AUTOCOMPLETE_TYPE,
} from "./helpers";
import {AiFillExclamationCircle} from "react-icons/ai";
import {VILLA_WARNING_YELLOW} from "../../../constants";
import {VILLA_APPLE_GREEN, VILLA_BLUE} from "../../../../_shared/colors";
import {scrollToTop} from "../../../../_shared/navigation/_helpers";
import {Address, IAddressDetails} from "@natomas-org/core";
import {useAddress} from "../../../../_shared/hooks/useAddress";
import {MapContainerProperties} from "../../../views/MapMainView/styles";
import {
  NatCheckCircleFill,
  NatCheckCircleHollow,
  NatSearchIcon,
} from "../../../../_shared/icon/icons";
import {HiMapPin} from "react-icons/hi2";
import {usePage} from "../../../../_shared/hooks/usePage";
import {ADDRESS_BAR_STYLE} from "./constants";
import {NatModal} from "../../../../_shared/generics/modal/NatModal";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {NatSize, NatSizeType} from "../../../../_shared/generics/_shared";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import {BLACK} from "../../colors";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../_shared/generics/label/NatLabel";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const SearchBar = (props: {
  autoFocus: boolean;
  isLarge?: boolean;
  hideVerifiedIcon?: boolean;
  address?: IAddressDetails;
  isEnabled?: boolean;
  isMobile?: boolean;
  hollowVerifiedIcon?: boolean;
  style?: React.CSSProperties;
  barStyle?: ADDRESS_BAR_STYLE;
  searchType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE;
  iconColor?: string;
  onSubmit?: () => void;
}) => {
  const {
    isLarge,
    barStyle,
    isEnabled,
    isMobile,
    style,
    onSubmit,
    address,
    searchType,
  } = props;
  const [query, setQuery] = useState<string | undefined>("");
  const [verifiedAddress, setVerifiedAddress] = useState<boolean | undefined>();
  const autoCompleteRef = useRef(null);
  const dispatch = useDispatch();
  let updateSearchType: React.MutableRefObject<
    ((setSearchType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE) => {}) | undefined
  > = useRef();
  let iconColor: string = !!props.iconColor
    ? props.iconColor
    : VILLA_APPLE_GREEN;
  useEffect(() => {
    if (Address.getFullAddress(address)) {
      setQuery(Address.getFullAddress(address));
    }
  }, [address]);
  useEffect(() => {
    if (!!updateSearchType.current) {
      return;
    }
    updateSearchType.current = handleBuildSearchScriptLoad(
      setQuery,
      autoCompleteRef,
      dispatch,
      setVerifiedAddress,
      searchType,
      onSubmit
    );
  }, [dispatch, onSubmit, searchType]);
  useEffect(() => {
    if (!!updateSearchType?.current && searchType) {
      updateSearchType.current(searchType);
    }
  }, [searchType]);
  useEffect(() => {
    if (Address.isInvalidAddress(address)) {
      return;
    }
    if (searchType === LOCATION_SEARCH_AUTOCOMPLETE_TYPE.STREET_ADDRESS) {
      setQuery(
        isMobile && !Address.isInvalidAddress(address)
          ? Address.getStreetNumberAndStreet(address)
          : Address.getFullAddress(address)
      );
      setVerifiedAddress(!Address.isInvalidAddress(address));
    } else {
      setQuery(
        Address.getFullAddress(
          Object.assign({}, address, {
            full_address: "",
            street_number: "",
            street: "",
          })
        )
      );
      setVerifiedAddress(!!Address.getCoordinates(address));
    }
  }, [address, isMobile, searchType]);
  const SEARCH_BAR_ICON_SIZE = isLarge ? 32 : 24;
  const clearIcon = (
    <>
      <label style={{fontSize: 0}} htmlFor={`right-icon`}>
        Clear
      </label>
      <button
        style={{
          padding: "0 0",
          cursor: "pointer",
          fontSize: 0,
        }}
        onClick={() => {
          setQuery("");
        }}
        className="right-icon"
        id={"right-icon"}
      >
        Clear
        <IoCloseOutline color={VILLA_BLUE} size={SEARCH_BAR_ICON_SIZE} />
      </button>
    </>
  );
  const verifiedIcon = props?.hollowVerifiedIcon ? (
    <div className="right-icon">
      <NatCheckCircleHollow
        size={SEARCH_BAR_ICON_SIZE}
        color={VILLA_APPLE_GREEN}
      />
    </div>
  ) : (
    <div className="right-icon">
      <NatCheckCircleFill
        size={SEARCH_BAR_ICON_SIZE}
        color={VILLA_APPLE_GREEN}
      />
    </div>
  );

  const errorIcon = (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        setQuery("");
      }}
      className="right-icon"
    >
      <AiFillExclamationCircle
        size={SEARCH_BAR_ICON_SIZE}
        color={VILLA_WARNING_YELLOW}
      />
    </div>
  );

  return (
    <SearchBarContainer isMobile={isMobile} style={style}>
      <StyledInput
        className={"inputWithIcon"}
        isLarge={isLarge ?? false}
        isMobile={isMobile}
      >
        <label
          style={{fontSize: 0}}
          htmlFor={`villa-build-address-input-${barStyle}`}
        >
          Address
        </label>
        <Input
          autoComplete={"off"}
          isMobile={isMobile}
          ref={autoCompleteRef}
          type={"search"}
          disabled={!isEnabled}
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={
            searchType === LOCATION_SEARCH_AUTOCOMPLETE_TYPE.AREA
              ? "Enter an address, neighborhood, city, or ZIP code"
              : isMobile
              ? "Street address"
              : "Enter a street address"
          }
          onSubmit={(e) => {
            e.preventDefault();
          }}
          onFocus={() => {
            scrollToTop(true); // This helps mobile users
          }}
          className={verifiedAddress === false ? "error-input" : ""}
          id={`villa-build-address-input-${barStyle}`}
          autoFocus={false}
          isLarge={isLarge ?? false}
        />
        {!props.hideVerifiedIcon && !isMobile && verifiedAddress !== undefined
          ? verifiedAddress
            ? verifiedIcon
            : errorIcon
          : clearIcon}
        <div className="left-icon">
          <NatSearchIcon size={SEARCH_BAR_ICON_SIZE} color={iconColor} />
        </div>
      </StyledInput>
    </SearchBarContainer>
  );
};

export const PublicAppSearchBar = (props: {
  addressBarStyle: ADDRESS_BAR_STYLE;
  displayMobile?: boolean;
  areaMapStyling?: React.CSSProperties;
  mappingToolStyling?: React.CSSProperties;
  hollowVerifiedIcon?: boolean;
  style?: React.CSSProperties;
  searchType?: LOCATION_SEARCH_AUTOCOMPLETE_TYPE;
  iconColor?: string;
  autoFocus?: boolean;
  hideVerification?: boolean;
}) => {
  const {propertySearch, publicAddress, areaSearch} = useAddress();
  const {
    displayMobile,
    areaMapStyling,
    mappingToolStyling,
    hollowVerifiedIcon,
    style,
    searchType,
    iconColor,
    autoFocus,
    hideVerification,
    addressBarStyle,
  } = props;
  const [showAddressBar, setShowAddressBar] = useState(false);
  const searchBarAddressSource = useMemo(() => {
    const emptyAddress = Address.dataToAddress();
    const propertyAddressExists: boolean =
      !!propertySearch && !Address.isInvalidAddress(propertySearch);
    switch (addressBarStyle) {
      case ADDRESS_BAR_STYLE.PIN_INLINE: // PUBLIC CATALOG
      case ADDRESS_BAR_STYLE.PIN_HEADER: // SPLASH SCREEN
        return publicAddress ?? emptyAddress;
      case ADDRESS_BAR_STYLE.SEARCH_BAR: // MAP
      case ADDRESS_BAR_STYLE.SEARCH_BAR_MODAL:
      default:
        if (searchType === LOCATION_SEARCH_AUTOCOMPLETE_TYPE.AREA) {
          // MAP (area view)
          if (areaSearch && areaSearch?.address) {
            return areaSearch?.address;
          }
        }
        if (searchType === LOCATION_SEARCH_AUTOCOMPLETE_TYPE.STREET_ADDRESS) {
          // Map (property view)
          if (propertyAddressExists) {
            return propertySearch;
          }
        }
        return emptyAddress;
    }
  }, [addressBarStyle, areaSearch, propertySearch, publicAddress, searchType]);

  const calculatedSearchBarType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE =
    useMemo(() => {
      if (
        searchBarAddressSource &&
        !Address.isInvalidAddress(searchBarAddressSource)
      ) {
        return searchType ?? LOCATION_SEARCH_AUTOCOMPLETE_TYPE.STREET_ADDRESS;
      }
      if (
        !searchBarAddressSource ||
        Address.isInvalidAddress(searchBarAddressSource)
      ) {
        return searchType ?? LOCATION_SEARCH_AUTOCOMPLETE_TYPE.AREA;
      }

      return searchType ?? LOCATION_SEARCH_AUTOCOMPLETE_TYPE.AREA;
    }, [searchBarAddressSource, searchType]);

  const {isNatMobile} = usePage();
  const CombinedSearchBar = (
    <div
      style={{
        display: "grid",
        ...MapContainerProperties,
        ...mappingToolStyling,
      }}
    >
      <SearchBar
        barStyle={addressBarStyle}
        address={searchBarAddressSource}
        isMobile={displayMobile}
        autoFocus={autoFocus ?? true}
        isLarge={false}
        isEnabled={true}
        hideVerifiedIcon={hideVerification ?? true}
        style={{...areaMapStyling, ...style}}
        hollowVerifiedIcon={hollowVerifiedIcon}
        searchType={calculatedSearchBarType}
        iconColor={iconColor}
        onSubmit={
          showAddressBar // && !isNatMobile
            ? () => {
                setShowAddressBar(false);
              }
            : undefined
        }
      />
    </div>
  );
  const popupButtonRef = useRef<HTMLInputElement>(null);
  const addressDisplayString = useMemo(() => {
    if (!!searchBarAddressSource) {
      if (!Address.isInvalidAddress(searchBarAddressSource)) {
        return `${Address.getStreetNumberAndStreet(searchBarAddressSource)}`;
      }
      return `${Address.getCity(searchBarAddressSource)}, ${Address.getState(
        searchBarAddressSource
      )}`;
    } else return "Enter a location";
  }, [searchBarAddressSource]);
  if (
    addressBarStyle === ADDRESS_BAR_STYLE.PIN_HEADER ||
    addressBarStyle === ADDRESS_BAR_STYLE.PIN_INLINE
  ) {
    const mobilePopupContent = (
      <DropdownCombinedSearchBarWrapperMobile>
        <NatLabel
          label={"Enter your address"}
          type={NatLabelType.H4}
          weight={NatLabelWeight.BOLD}
        />
        {CombinedSearchBar}
        <div
          style={{
            backgroundColor: "white",
            height: "40rem",
            width: "100%",
            left: "0",
            position: "fixed",
          }}
        />
      </DropdownCombinedSearchBarWrapperMobile>
    );
    const popupContent = (
      <DropdownCombinedSearchBarWrapper>
        {CombinedSearchBar}
      </DropdownCombinedSearchBarWrapper>
    );
    return (
      <>
        <DropdownLocationContainer ref={popupButtonRef}>
          <AddressContentWrapper
            forHeader={addressBarStyle === ADDRESS_BAR_STYLE.PIN_HEADER}
            isMobile={isNatMobile}
          >
            <NatDropdown
              contentItemArray={
                // isNatMobile
                //   ? []
                //   :
                [
                  {
                    id: "title",
                    label: "Enter your address",
                    component: (
                      <DropdownCombinedSearchBarTitle>
                        Enter your address
                      </DropdownCombinedSearchBarTitle>
                    ),
                  },
                  {
                    id: "search",
                    label: "Search",
                    component: showAddressBar ? popupContent : <></>,
                  },
                ]
              }
              handleClose={() => {
                setShowAddressBar(false);
              }}
              setShow={setShowAddressBar}
              show={showAddressBar}
              style={{
                ...NatDropdownStyles[DropdownStyles.SINGLE_ELEMENT][
                  NatSizeType.NORMAL
                ],
                TYPE: NatPopupType.HIDE,
                dropdownButtonCSS: {
                  backgroundColor: "transparent",
                  padding: "0 0",
                  margin: "0 0",
                  paddingRight: "0.15rem",
                  top:
                    addressBarStyle === ADDRESS_BAR_STYLE.PIN_INLINE
                      ? "0.15rem"
                      : undefined,
                  position:
                    addressBarStyle === ADDRESS_BAR_STYLE.PIN_INLINE
                      ? "relative"
                      : undefined,
                  fontFamily: FONT_FAMILY_BOLD,
                },
                popupCSS: getDropdownLocationPopupStyling(
                  addressBarStyle === ADDRESS_BAR_STYLE.PIN_HEADER
                ),
                POPUP_ITEM_SIDE_PADDING: NatSize.NORMAL,
                POPUP_ITEM_TOP_BOTTOM_PADDING: undefined,
                DROPDOWN_CHEVRON_COLOR: BLACK,
                ADDITIONAL_STYLE_OPTIONS: [
                  NatPopupStyleOptions.NO_CLICK_THRU,
                  NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE,
                  NatPopupStyleOptions.NO_ITEMS_BORDER,
                  NatPopupStyleOptions.NO_PADDING,
                  NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
                  NatPopupStyleOptions.NO_PADDING,
                ],
              }}
              toggleButtonOrLabel={
                <AddressContent
                  onClick={() => {
                    setShowAddressBar(!showAddressBar);
                  }}
                  forHeader={addressBarStyle === ADDRESS_BAR_STYLE.PIN_HEADER}
                >
                  <HiMapPin
                    color={VILLA_APPLE_GREEN}
                    style={{
                      paddingRight: "0.25rem",
                      height: "2rem",
                      width: "1.75rem",
                    }}
                  />
                  <AddressStringWrapper>
                    {addressDisplayString}
                  </AddressStringWrapper>
                </AddressContent>
              }
            />
          </AddressContentWrapper>
          <NatModal
            removeFixedPlacementLogic={true}
            content={showAddressBar ? mobilePopupContent : <></>}
            show={showAddressBar && isNatMobile && false}
            handleClose={() => {
              setShowAddressBar(false);
            }}
            customSize={{height: "20rem", width: "100%"}}
          />
        </DropdownLocationContainer>
      </>
    );
  }
  return CombinedSearchBar;
};
