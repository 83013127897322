import React, {useMemo} from "react";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElementWrapper} from "../../../../_shared/generics/page/components/PageElementWrapper";
import {PORTAL_OVERVIEW_ID} from "../../HCPDashboardLayout/HCPTitleNav/navigation_ids";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {
  Address,
  IProjectStage,
  IProjectStageDisplayNames,
  Project,
  TeamMemberRole,
} from "@natomas-org/core";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {TeamMemberCard} from "../../../_shared/components/TeamContactCard/TeamMemberCard";
import {useCalendly} from "../../../../_shared/calendly/useCalendly";
import {
  BLACK,
  GRANITE,
  VILLA_LILAC,
  VILLA_MINT,
  VILLA_VANILLA,
} from "../../../../_shared/colors";
import {CardTag} from "../../../../catalog/_shared/components/ProductCard/styles";
import {useProjectBudget} from "../../../../_shared/hooks/useProjectBudget/useProjectBudget";

export const FPOverviewView = () => {
  const width = useDynamicValue({
    forFour: 4,
    forSixteen: 12,
    forTwelve: 8,
    forEight: 4,
  });
  const {info, id, mergedCustomerProjectInfos} = useActiveProject();
  const {unitSelectionSummaryString} = useProjectBudget();

  const teamMember = Project.getPrimaryContactTeamMember(info, {
    fallbackRole: TeamMemberRole.PC,
  });
  const {launchCalendly, calendlyWidget} = useCalendly({
    projectId: id,
  });
  const generalMeetingLink = Project.getTeamMemberLink(info, "general-meeting");
  const shouldLookMobile = useDynamicValue({
    forFour: true,
    forEight: false,
    forTwelve: false,
    forSixteen: false,
  });
  const content = useMemo(() => {
    if (!info) {
      return {
        title: "",
        subtitle: "",
        phase: null,
      };
    }
    const name = Project.getClientFirstName(info);

    const stage = info?.status_details?.stage;
    switch (stage) {
      case IProjectStage.PERMITTING:
        return {
          title: `Hi ${name}, we’re working directly with your local jurisdiction to submit and obtain permit approval.`,
          subtitle:
            "Our goal is to make the process as smooth and stress-free as possible. Reach out to your Villa representative if you have any questions!",
          phase: {
            label: IProjectStageDisplayNames[IProjectStage.PERMITTING],
            color: VILLA_LILAC,
          },
        };
      case IProjectStage.ATB:
        return {
          title: `Hi ${name}, now that your permits have been approved, our team is confirming the project's scope of work and pricing, and creating your Authorization to Build (ATB).`,
          subtitle:
            "Our goal is to make the process as smooth and stress-free as possible. Reach out to your Villa representative if you have any questions!",
          phase: {
            label: IProjectStageDisplayNames[IProjectStage.ATB],
            color: VILLA_MINT,
          },
        };
      case IProjectStage.PRE_ATB:
      case IProjectStage.DESIGN_AND_ENGINEERING:
      default:
        return {
          title: `Hi ${name}, we’ll be working with you to gather important information and confirming all design selections so we can create your permitting plan.`,
          subtitle:
            "Our goal is to make the process as smooth and stress-free as possible. Reach out to your Villa representative if you have any questions!",
          phase: {
            label:
              IProjectStageDisplayNames[IProjectStage.DESIGN_AND_ENGINEERING],
            color: VILLA_VANILLA,
          },
        };
    }
  }, [info]);
  const projectInfoTextArray = useMemo(() => {
    const address = Address.getFullAddress(info?.address);
    const infoArray = [];
    if (info?.address) {
      infoArray.push(address);
    }
    if (mergedCustomerProjectInfos?.code) {
      infoArray.push(mergedCustomerProjectInfos?.code);
    }
    if (unitSelectionSummaryString) {
      infoArray.push(unitSelectionSummaryString);
    }
    return infoArray;
  }, [
    info?.address,
    mergedCustomerProjectInfos?.code,
    unitSelectionSummaryString,
  ]);
  return (
    <div id={PORTAL_OVERVIEW_ID}>
      <PageContainer>
        <br />
        <PageElementWrapper>
          <PageElement size={width} height={"auto"}>
            <NatLabel label={content.title} type={NatLabelType.H2} />
            <div style={{display: "inline-block", paddingBottom: "1.5rem"}}>
              <span style={{color: GRANITE}}>Project Information: </span>
              <span style={{color: BLACK}}>
                {projectInfoTextArray.join(" • ")}
              </span>
            </div>
            <CardTag
              key={`${content?.phase?.label}-tag`}
              style={{backgroundColor: content?.phase?.color}}
              hidden={!content?.phase?.label}
            >
              {content?.phase?.label}
            </CardTag>
          </PageElement>
          <PageElement size={4} height={"auto"}>
            <TeamMemberCard
              layout={"full-details"}
              shouldLookMobile={shouldLookMobile}
            />
          </PageElement>
        </PageElementWrapper>
        {calendlyWidget}
      </PageContainer>
    </div>
  );
};
