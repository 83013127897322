import React from "react";
import {ActionButtonContainer} from "../../../../_shared/styles";
import {EditPasswordSetting} from "./EditPasswordSetting";

export const AccountLoginEditor = () => {
  return (
    <>
      <ActionButtonContainer>
        <EditPasswordSetting />
      </ActionButtonContainer>
    </>
  );
};
