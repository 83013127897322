import styled from "styled-components";

export const FullName = styled.div`
  color: #04171b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "p22-mackinac-pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  padding: 10px 0;
`;
