import styled from "styled-components";
import {
  ERROR_COLOR,
  ERROR_FILL,
  ERROR_TEXT,
} from "../../../../../../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const StudioEditorWarningContainer = styled.div<{collapsed: boolean}>`
  width: 100%;
  margin: 16px 0;

  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 16px;
  font-size: 16px;
  overflow-y: hidden;
  cursor: pointer;

  background-color: ${ERROR_FILL};
  border-radius: 16px;
  border: 2px solid ${ERROR_COLOR};
  color: ${ERROR_TEXT};

  transition: max-height 150ms ease-in-out;
  max-height: ${(props) => (props.collapsed ? "60px" : "300px")};
`;

export const StudioEditorHeaderRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StudioEditorHeaderLabelContainer = styled(
  StudioEditorHeaderRowContainer
)`
  font-family: ${FONT_FAMILY_MEDIUM};
  height: 24px;
`;

export const StudioEditorCollapseButtonContainer = styled.div<{
  collapsed: boolean;
}>`
  height: 24px;
  width: 24px;
  transform: ${(props) => !props.collapsed && "rotate(90deg)"};
  transition: all 200ms ease-in-out;
`;
