import {useState} from "react";

export interface Slide {
  title: string;
  description: string;
  image: string;
}

export const useCarousel = (slides: Slide[]) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return {
    nextSlide,
    prevSlide,
    currentSlideData: slides[currentSlide],
    currentSlideIndex: currentSlide,
  };
};
