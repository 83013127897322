import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {IPublicProjectInfo, SortDropdownValues} from "@natomas-org/core";
import {ProductListContainer} from "../../../views/styles";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {usePage} from "../../../../_shared/hooks/usePage";
import {ProductFlexList} from "../ProductList/styles";
import {MapProjectDetailsModal} from "../../../../mapping/views/components/ProjectDetailsModal";
import {NatModal} from "../../../../_shared/generics/modal/NatModal";
import {useMap} from "../../../../_shared/hooks/useMap";
import {ProjectCard} from "../ProjectCard";
import {getRelatedProjects} from "./helpers";
import {ProjectViewBottomSection} from "../../../views/ProductView/styles";
import {useDispatch} from "react-redux";
import {ViewportDialogue} from "./styles";
import {IPublicProjectInfoModalPayload} from "../../../../mapping/views/components/ProjectDetailsModal/constants";
import {NearbyHomesNoMap} from "./components/NearbyHomesNoMap";
import {useCompletedProjects} from "../../../../_shared/hooks/useCompletedProjects";
import {
  resetGLMapProjectToPreview,
  setGLMapProjectToView,
} from "../../../../map/slices/mapSlice";
import {store} from "../../../../../store";
import {SectionTitle} from "../ProductList/components/SectionTitle";
import Button from "../../../../../0-app/2-component/Button";

export enum RelatedProjectsCategory {
  SQFT,
  ALL,
}

export interface RelatedProjectsProps {
  findRelatedMethod?: RelatedProjectsCategory;
  product?: any;
  productSort?: SortDropdownValues;
  includeHorizontalRule?: boolean;
  title?: string;
  description?: string;
  numberOfProductsInRow?: number;
  projectsToDisplay?: IPublicProjectInfo[];
  showAllCards?: boolean;
  cardHeight?: number;
  imageHeight?: number;
  formatDisplayForMap?: boolean;
  setProjectModalPayload?: Dispatch<
    SetStateAction<IPublicProjectInfoModalPayload | null>
  > | null;
  showProjectMap?: boolean;
  setShowProjectMap?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RelatedProjects = (props: RelatedProjectsProps) => {
  const {
    product,
    findRelatedMethod,
    productSort,
    includeHorizontalRule,
    description,
    title,
    numberOfProductsInRow,
    projectsToDisplay,
    showAllCards,
    cardHeight,
    imageHeight,
    formatDisplayForMap,
    setProjectModalPayload,
    showProjectMap,
    setShowProjectMap,
  } = props;
  const {glMapState} = useMap();
  const {completedProjects} = useCompletedProjects();
  const allProjects = Object.values(completedProjects ?? []);
  const dispatch = useDispatch();
  const {columnCount} = usePage();
  const relatedProjectsArray = useMemo(() => {
    if (formatDisplayForMap && projectsToDisplay) {
      return projectsToDisplay ?? [];
    }
    return getRelatedProjects(
      projectsToDisplay ?? allProjects,
      findRelatedMethod ?? RelatedProjectsCategory.ALL,
      product,
      productSort
    );
  }, [product, projectsToDisplay, allProjects]);
  const DEFAULT_PROJECT_DISPLAY_NUM =
    showAllCards === true ? relatedProjectsArray?.length ?? 6 : 6;
  const {isNatMobile} = usePage();

  let titleToDisplay: string;
  if (
    formatDisplayForMap &&
    !!glMapState?.projectsInViewport &&
    glMapState?.projectsInViewport?.length > 0 &&
    false
  ) {
    titleToDisplay = `Viewing ${
      glMapState?.projectsInViewport?.length ?? 0
    } completed Villa home${
      glMapState?.projectsInViewport?.length === 1 ? "" : "s"
    }`;
  } else {
    titleToDisplay = title ?? "Viewing completed Villa homes";
  }
  let width = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 4,
    forSixteen: 4,
  });
  const count = useDynamicValue({
    forFour: 1,
    forEight: 2,
    forTwelve: 3,
    forSixteen: 4,
  });
  if (!!numberOfProductsInRow) {
    width = columnCount / numberOfProductsInRow;
  }
  const pageSize = count * 3;
  const [showMore, setShowMore] = useState<number>(DEFAULT_PROJECT_DISPLAY_NUM);
  useEffect(() => {
    if (formatDisplayForMap) {
      // No need for this within the 'completed projects' view
      return;
    }
    // Run once count has loaded, set the initial value
    if (!showMore && !!relatedProjectsArray) {
      setShowMore(DEFAULT_PROJECT_DISPLAY_NUM);
    } else {
      // If the screen size is adjusted after load, make the rows even
      setShowMore(showMore - (showMore % count));
    }
  }, [relatedProjectsArray, showMore]);
  if ((relatedProjectsArray?.length < 1 || !showMore) && !formatDisplayForMap) {
    return <></>;
  }
  const shouldShowPagination =
    !formatDisplayForMap &&
    DEFAULT_PROJECT_DISPLAY_NUM < relatedProjectsArray?.length &&
    !showAllCards;

  const ProjectModal = React.memo(() => {
    return (
      <NatModal
        content={
          <MapProjectDetailsModal
            projectData={
              typeof glMapState?.projectToPreview?.projectDetails !==
                "number" && glMapState?.projectToPreview?.fullView
                ? glMapState?.projectToPreview?.projectDetails ?? null
                : null
            }
            viewOnMapAction={null}
            showTextLocation={true}
            handleClose={() => {
              store.dispatch(resetGLMapProjectToPreview());
            }}
          />
        }
        customSize={{
          height: "100%",
          width: "30rem",
        }}
        customPadding={"0 0"}
        show={
          !formatDisplayForMap &&
          !!(
            typeof glMapState?.projectToPreview?.projectDetails !== "number" &&
            glMapState?.projectToPreview?.fullView
          )
        }
        handleClose={() => {
          store.dispatch(resetGLMapProjectToPreview());
        }}
        onlyShowContent={true}
        removeFixedPlacementLogic={true}
      />
    );
  });
  return (
    <>
      {includeHorizontalRule === true && (
        <>
          <hr />
          {formatDisplayForMap && <br />}
        </>
      )}
      <ProjectModal />
      <ProductListContainer removeBottomMargin={isNatMobile}>
        <SectionTitle
          description={description ?? null}
          title={titleToDisplay}
          linkText={null}
        />
        <>
          {formatDisplayForMap && relatedProjectsArray?.length > 0 && (
            <ViewportDialogue>
              {`${relatedProjectsArray?.length} featured Villa homes`}
            </ViewportDialogue>
          )}
          <ProductFlexList>
            {relatedProjectsArray?.map((project: IPublicProjectInfo, idx) => {
              if (idx >= showMore && showAllCards !== true) {
                return <div key={`project-card-${idx}`} />;
              }
              return (
                <ProjectCard
                  project={project}
                  key={`project-card-${idx}`}
                  setStateOnHover={formatDisplayForMap}
                  onClick={() => store.dispatch(setGLMapProjectToView(project))}
                />
              );
            })}
          </ProductFlexList>
        </>
      </ProductListContainer>
      <ProjectViewBottomSection
        style={formatDisplayForMap ? {marginBottom: "0"} : undefined}
      >
        {shouldShowPagination && (
          <Button
            id={
              showMore > relatedProjectsArray?.length
                ? "show-fewer-related-projects"
                : "show-more-related-projects"
            }
            label={
              showMore > relatedProjectsArray?.length
                ? "Show fewer"
                : "Show more"
            }
            size={"large"}
            theme={"secondary"}
            onClick={() => {
              if (showMore > relatedProjectsArray?.length) {
                setShowMore(count);
              } else {
                setShowMore(showMore + pageSize);
              }
            }}
            hidden={relatedProjectsArray?.length <= count}
          />
        )}
      </ProjectViewBottomSection>
      {formatDisplayForMap && (
        <NearbyHomesNoMap
          columnCount={columnCount}
          setProjectModalPayload={setProjectModalPayload}
          relatedProjectsArray={relatedProjectsArray}
          width={width}
          dispatch={dispatch}
          showMore={showMore}
          formatDisplayForMap={formatDisplayForMap}
          imageHeight={imageHeight}
          cardHeight={cardHeight}
          showAllCards={showAllCards || formatDisplayForMap}
          includeHeader={relatedProjectsArray?.length === 0 && !isNatMobile}
          title={
            relatedProjectsArray?.length < 1
              ? "Completed homes nearby"
              : "More nearby completed homes"
          }
          mapState={glMapState}
          completedProjects={completedProjects}
          setShowProjectMap={setShowProjectMap}
          showProjectMap={showProjectMap}
        />
      )}
    </>
  );
};
