import firebase from "firebase";
import {findUserOfProject} from "./users";
import {IProjectInfo, ProjectUserType} from "@natomas-org/core";
import {Utilities} from "../../database/firebase";
import {saveProjectUser} from "../../database/firebase/project/users";
import DocumentReference = firebase.firestore.DocumentReference;

export interface NatomasProjectDocumentEnhancements {
  ownerId: string;
  projectId: string;
}

export const enhanceNatomasProjectDoc = async (
  ref: DocumentReference,
  enhancements: NatomasProjectDocumentEnhancements
): Promise<void> => {
  const data = (await Utilities.getDocData(ref)) as IProjectInfo;

  // Check if the owner is saved in the project
  const currentOwnerId = findUserOfProject(
    data?.users ?? {},
    ProjectUserType.OWNER
  );
  if (!currentOwnerId) {
    return await saveProjectUser(
      data ?? {},
      enhancements.ownerId,
      ProjectUserType.OWNER
    );
  }
};
