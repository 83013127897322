import {useQuery} from "@tanstack/react-query";
import {getLeadService} from "./index";

const fetchLeadBy = async (): Promise<boolean> => {
  try {
    return getLeadService().then(async (service) => {
      return service.getLeadExists().then((response) => {
        return response;
      });
    });
  } catch (error) {
    console.error("LeadServiceError", error);
    return true;
  }
};

const useLeadExists = (auth_user_id?: string) => {
  const {
    data: nexus_lead_exists,
    refetch,
    isFetching,
  } = useQuery<boolean, Promise<boolean>>([auth_user_id], fetchLeadBy, {
    cacheTime: 0,
  });
  return {
    data: nexus_lead_exists,
    refetch: refetch,
    isFetching: isFetching,
  };
};

export default useLeadExists;
