import React from "react";
import {PageContainer} from "../../../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../../../_shared/generics/page/components/PageElement";
import {ThankYouBanner} from "./components/ThankYouBanner";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../../_shared/hooks/useDynamicValue";

export const ThankYouPage = () => {
  const fullWidth = useDynamicValue(fullColumnWidths);
  return (
    <PageContainer overridePadding={false}>
      <br />
      <PageElement size={fullWidth} height={"auto"}>
        <ThankYouBanner />
      </PageElement>
    </PageContainer>
  );
};
