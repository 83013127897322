import {
  IPopupMenu,
  PopupMenu,
  PopupMenuContainer,
  PopupMenuItem,
} from "./menu_popup";
import React from "react";

export const AboutUsMenu = (props: IPopupMenu) => {
  const {visible, ...rest} = props;
  return (
    <PopupMenu visible={visible} {...rest}>
      <PopupMenuContainer>
        <PopupMenuItem
          id={"header_about_villa_our_mission_and_team"}
          data-cy={"header_about_villa_our_mission_and_team"}
          href="/about-us"
        >
          Our mission & team
        </PopupMenuItem>
        <PopupMenuItem
          id={"header_about_villa_adu_pricing"}
          data-cy={"header_about_villa_adu_pricing"}
          href="/pricing"
        >
          ADU pricing
        </PopupMenuItem>
        <PopupMenuItem
          id={"header_about_villa_offsite_construction"}
          data-cy={"header_about_villa_offsite_construction"}
          href="/process"
        >
          Offsite construction
        </PopupMenuItem>
        <PopupMenuItem
          id={"header_about_villa_map_my_lot"}
          data-cy={"header_about_villa_map_my_lot"}
          href="/map"
        >
          Map my lot
        </PopupMenuItem>
        <PopupMenuItem
          id={"header_about_villa_featured_projects"}
          data-cy={"header_about_villa_featured_projects"}
          href="/featured-projects"
        >
          Featured projects
        </PopupMenuItem>
        <PopupMenuItem
          id={"header_about_villa_press"}
          data-cy={"header_about_villa_press"}
          href="/press"
        >
          Press
        </PopupMenuItem>
        <PopupMenuItem
          id={"header_about_villa_our_blog"}
          data-cy={"header_about_villa_our_blog"}
          href="/blog"
        >
          Blog
        </PopupMenuItem>
      </PopupMenuContainer>
    </PopupMenu>
  );
};
