import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "./SSDDashboardView/styles/globals";

const PortalLayout = styled.div`
  min-height: 100%;
`;

const MainContentWrapper = styled.div`
  z-index: 1;
  height: 100%;
  background: white;
`;

const HeaderContainer = styled.div`
  margin-left: ${(props: {isMobile: any}) =>
    props?.isMobile() ? "90px" : "40px"};
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-top: 40px;
`;

const LogoContainer = styled.img`
  cursor: pointer;
  text-align: left;
  width: 75px;
`;

const GridHeader = styled.div<{isMobile: any}>`
  text-align: ${(props) => (props?.isMobile() ? "center" : "left")};
  padding: 0.8125rem 0;
  font-size: 1.5rem;
  height: 100%;
  line-height: 1.5rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props?.isMobile() ? "center" : "space-between"};
  flex-direction: row;

  & > div,
  img {
    min-width: 100px;
  }
`;

export {
  HeaderContainer,
  LogoContainer,
  GridHeader,
  PortalLayout,
  MainContentWrapper,
};
