import {NatDropdownProps, NatPopupStyleOptions} from "../constants";
import {BLACK, GRANITE} from "../../../../colors";
import {IconPosition, NatSize, StyleOption} from "../../../_shared";
import {NatButton} from "../../../button";
import {NatDownChevron, NatUpChevron} from "../../../../icon/icons";
import React from "react";
import styled from "styled-components";

const STANDARD_DROPDOWN_STYLE_OPTIONS = [
  StyleOption.PRIMARY_DROPDOWN,
  StyleOption.PRIMARY_LINK,
  StyleOption.FILTERS_ACTIVE,
  StyleOption.FILTERS_HIGHLIGHTED,
  StyleOption.FILTERS,
  StyleOption.ADMIN_SOLID,
  StyleOption.APP_SWITCHER,
  StyleOption.SHADOW_TOGGLE,
];
export const DropdownButton = (props: NatDropdownProps) => {
  const {style, show, setShow} = props;
  const isStandardToggle =
    !style?.DROPDOWN_BUTTON_STYLE ||
    STANDARD_DROPDOWN_STYLE_OPTIONS.includes(style?.DROPDOWN_BUTTON_STYLE);
  const hideChevronDivider = style?.ADDITIONAL_STYLE_OPTIONS?.includes(
    NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER
  );
  const hideChevron = style?.ADDITIONAL_STYLE_OPTIONS?.includes(
    NatPopupStyleOptions.HIDE_CHEVRON
  );
  const chevronStyling = {
    margin: "0 0",
    color: style?.DROPDOWN_CHEVRON_COLOR
      ? style?.DROPDOWN_CHEVRON_COLOR
      : GRANITE,
  };
  const buttonElement = props.toggleButtonOrLabel;
  if (isStandardToggle) {
    // Filters button
    return (
      <DropdownElementWrapper>
        <NatButton
          disabled={props.disabled}
          icon={{
            icon: (
              <DropdownIconElementWrapper
                style={{
                  ...style?.dropdownButtonCSS,
                }}
              >
                <div
                  hidden={!props.icon}
                  style={{
                    paddingRight: "0.5rem",
                    ...style?.dropdownButtonCSS,
                  }}
                >
                  {props.icon?.icon}
                </div>
                <DropdownButtonWrapper style={style?.dropdownButtonCSS}>
                  {buttonElement}
                </DropdownButtonWrapper>
                <DropdownChevronAndChevronDividerContainer>
                  <DropdownChevronContainer
                    hidden={!isStandardToggle || hideChevronDivider}
                    style={chevronStyling}
                  >
                    |
                  </DropdownChevronContainer>
                  {show && !hideChevron ? (
                    <NatUpChevron style={chevronStyling} />
                  ) : !hideChevron ? (
                    <NatDownChevron style={chevronStyling} />
                  ) : null}
                </DropdownChevronAndChevronDividerContainer>
              </DropdownIconElementWrapper>
            ),
            iconPosition: IconPosition.ONLY,
          }}
          label={""}
          option={
            !!style.DROPDOWN_BUTTON_STYLE
              ? style.DROPDOWN_BUTTON_STYLE
              : show
              ? StyleOption.FILTERS_HIGHLIGHTED
              : StyleOption.FILTERS
          }
          size={style.DROPDOWN_BUTTON_SIZE ?? NatSize.SMALL}
          clickEvent={() => {
            setShow && setShow(!show);
          }}
          style={props.style?.dropdownButtonCSS}
        />
      </DropdownElementWrapper>
    );
  } else if (!isStandardToggle && typeof buttonElement === "string") {
    // Generic button from text
    return (
      <NatButton
        disabled={props.disabled}
        icon={props.icon}
        label={buttonElement}
        option={style?.DROPDOWN_BUTTON_STYLE}
        size={style.DROPDOWN_BUTTON_SIZE ?? NatSize.SMALL}
        clickEvent={() => {
          setShow && setShow(!show);
        }}
        style={props.style?.dropdownButtonCSS}
      />
    );
  } else {
    // Adding onClick to element
    return (
      <DropdownElementWrapper
        onClick={() => {
          setShow && setShow(!show);
        }}
        style={{
          color: "inherit",
          cursor: "pointer",
          ...props.style?.dropdownButtonCSS,
        }}
      >
        {buttonElement}
      </DropdownElementWrapper>
    );
  }
};

const DropdownChevronContainer = styled.div`
  padding-left: ${(props) =>
    props.theme?.Filters?.distanceBetweenTextAndChevron ?? "1rem"};
  font-size: 0.75rem;
`;
const DropdownChevronAndChevronDividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  color: ${GRANITE};
  align-items: center;
  justify-content: flex-end;
  flex-grow: ${(props) => (props.theme?.Page?.isNatMobile ? undefined : 1)};
`;

const DropdownButtonWrapper = styled.div`
  color: ${BLACK};
  font-size: 0.75rem;
`;
const DropdownIconElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0;
  color: ${GRANITE};
  align-items: center;
  justify-content: ${(props) =>
    props.theme?.Page?.isNatMobile ? "center" : "space-between"};
  flex-grow: 1;
`;

const DropdownElementWrapper = styled.div`
  ${(props) => props.theme?.Filters?.filterButtonStyling};
  width: inherit;
  height: inherit;
`;
