import React from "react";
import styled from "styled-components";
import Image from "../../2-component/Image";
import map_alert from "../../0-assets/map_alert.svg";
import Text from "../../2-component/Text";
import Button from "../../2-component/Button";

const NotAvailableYetContent = (props: {continue: () => void}) => {
  return (
    <Container>
      <Image
        alt={"map alert"}
        src={map_alert}
        style={{width: "40px", marginBottom: "16px"}}
      />
      <Label>
        <Text text={"Sorry, we're not available in your neighborhood yet."} />
      </Label>
      <Description>
        <Text
          text={
            "We hope to serve your area soon! In the meantime, feel free to browse our product offerings and contact us anytime with any questions."
          }
          links={[
            {
              replacementKey: "contact us",
              href: "/contact-us",
            },
          ]}
        />
      </Description>
      <Button
        id={"not_available_yet_continue"}
        label={"Continue"}
        onClick={props.continue}
        containerStyle={{width: "fit-content"}}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Label = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  span {
    display: flex;
    color: #000;
    justify-content: center;
    text-align: center;
    font-feature-settings: "liga off, clig off";
    font-family: "p22-mackinac-pro", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.54px;
  }
`;

const Description = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 48px;
  max-width: 535px;
  span {
    color: #5a4e4e;
    text-align: center;
    font-feature-settings: "liga off, clig off";
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  a {
    color: #0080a7;
    font-feature-settings: "liga off, clig off";
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;
export default NotAvailableYetContent;
