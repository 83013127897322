import React, {useEffect, useRef, useState} from "react";
import {
  OrderFormTableCellCustomInputElement,
  OrderFormTableCellValueContainer,
} from "./styles";
import {IOrderFormTableCellValue} from "../interfaces";
import {OrderFormTableCellController} from "./shared/OrderFormTableCellController";
import {NatButton} from "../../../../../../../../_shared/generics/button";
import {
  NatSize,
  StyleOption,
} from "../../../../../../../../_shared/generics/_shared";

export const OrderFormTableCellCustomInput = (props: {
  setValue: (newValue: string) => void;
  cell: IOrderFormTableCellValue;
}) => {
  const {setValue, cell} = props;
  const {value: currentValue, locked: currentLockedStatus} = cell ?? {};
  const customInputRef = useRef<HTMLInputElement | null>(null);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(currentLockedStatus ?? false);

  useEffect(() => {
    const string = customInputRef?.current?.value ?? "";
    const result =
      string?.localeCompare(currentValue) != 0 && string?.length > 0;
    if (canSave != result) {
      setCanSave(result);
      setLocked(true);
    }
  }, [currentValue, customInputRef?.current?.value]);

  return (
    <OrderFormTableCellValueContainer>
      {!locked ? (
        <OrderFormTableCellCustomInputElement
          placeholder={"Custom"}
          ref={customInputRef}
          type={"text"}
          defaultValue={currentValue}
          onChange={() => {
            const string = customInputRef?.current?.value ?? "";
            const result =
              string?.localeCompare(currentValue) != 0 && string?.length > 0;
            if (canSave != result) {
              setCanSave(result);
            }
          }}
        />
      ) : currentValue?.length > 0 ? (
        currentValue
      ) : (
        "Requires Input"
      )}
      {!locked && canSave && (
        <NatButton
          label={"Save"}
          size={NatSize.XSMALL}
          option={StyleOption.PRIMARY_ALT}
          clickEvent={() => setValue(customInputRef?.current?.value ?? "")}
        />
      )}
      <OrderFormTableCellController
        cell={cell}
        hasValue={!!cell?.value && cell.value?.length > 0}
        setLocked={setLocked}
        locked={locked}
      />
    </OrderFormTableCellValueContainer>
  );
};
