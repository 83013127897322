import React, {createContext, useEffect, useMemo, useState} from "react";
import {
  InfoContainer,
  MapAndInfoContainer,
  MapContainer,
  MapRightContainer,
  MobileMapContainer,
} from "./styles";
import {useMapping} from "../../../mapping/views/UseMapping";
import {MAP_STYLES} from "../../../mapping/views/theme/constants";
import {Helmet} from "react-helmet-async";
import {useMap} from "../../../_shared/hooks/useMap";
import {Page} from "../../../_shared/generics/page";
import {usePage} from "../../../_shared/hooks/usePage";
import {useEventHandler} from "../../../_shared/hooks/useEventHandler";
import {
  MapContentSidebar,
  MapStickyHeaders,
} from "./components/MapContentSidebar";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {useAddress} from "../../../_shared/hooks/useAddress";
import {LoadingPanel} from "../../../_shared/generics/loading-panel";
import {elementInViewport, getPageTitle} from "./helpers";
import {MapBottomBarContent} from "./components/MapBottomBarContent";
import {
  MAP_MAIN_BUILD_CONTAINER_DIV_ID,
  MAP_MAIN_INNER_CONTAINER_DIV_ID,
} from "./constants";
import {useSelectedProduct} from "../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {useCatalogSession} from "../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useSelector} from "react-redux";
import {IStore} from "../../../_shared/slices/types/Store";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {ThemeProvider} from "styled-components";
import LegalCopyright from "../../../../0-app/1-container/legal-copyright";

export const FiltersContext = createContext(true);

export const MapMainView = () => {
  const {leafletMapState, parcelView, parcelViewAvailable, glMapState} =
    useMap();
  const {propertySearch, areaSearch} = useAddress();
  const {to} = useNavigation();
  const {isNatMobile} = usePage();
  const selectedProduct = useSelectedProduct();
  const displayingMobileUnitPreview = isNatMobile && !!selectedProduct;
  const {mappingPreview, modal, setProjectModalPayload, completedProjectsMap} =
    useMapping({
      displayProps: {
        readOnly: false,
        minimal: false,
        showSideBar: false,
        showSaveButton: false,
        hideDisclaimer: true,
        fullExperience: !displayingMobileUnitPreview,
        fullScreen: true,
        hideAHJ: isNatMobile, // Removing this on mobile since we have the AHJ popup
        mapTileLayer: MAP_STYLES.BUILD3D,
        panningDisabled: displayingMobileUnitPreview,
        updateLayout: false,
        displayAHJAsModal: isNatMobile,
      },
      address: propertySearch,
      product: selectedProduct,
      unitPlacement: undefined,
    });
  const {adminCatalogMode} = useCatalogSession();
  const columnCount = useSelector((state: IStore) => state.page.columnCount);
  const cardColumnWidthNoProduct = useDynamicValue({
    forFour: 2,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 4,
  });
  const gutterWidthPercentageNoProduct =
    useSelector((state: IStore) => state.page.gutterWidthPercentage) / 2;
  const MapTheme = useMemo(() => {
    if (!selectedProduct) {
      return {
        User: {
          isAdmin: false,
        },
        Page: {
          columnCount: columnCount / 2,
          gutterWidthPercentage: gutterWidthPercentageNoProduct,
          isNatMobile: columnCount === 4,
        },
        CatalogSession: {
          adminCatalogMode: adminCatalogMode,
        },
        Catalog: {
          marginLeftRight: "1rem",
        },
        Card: {
          columnWidth: cardColumnWidthNoProduct,
          borderRadius: "10px",
          imageHeight: "10.3125rem",
          height: adminCatalogMode ? "20rem" : "16.25rem",
        },
        Filters: {
          gapBetweenFilters: "0.325rem",
          distanceBetweenTextAndChevron: "0.5rem",
          showVerticalLine: columnCount !== 4,
          marginPaddingTop: "0.5rem",
          marginPaddingBottom: "0.5rem",
          marginLeft: "0.5rem",
          marginRight: "0.5rem",
          filterHeight: columnCount === 4 ? "3rem" : "1.5rem",
          filterButtonStyling:
            columnCount === 4
              ? {width: "100%", flexGrow: 1}
              : {width: "max-content"},
        },
      };
    }
    return {
      User: {
        isAdmin: false,
      },
      Page: {
        columnCount: 4,
        gutterWidthPercentage: 0,
        isNatMobile: columnCount === 4,
      },
      Card: {
        columnWidth: 4,
        borderRadius: "10px",
        imageHeight: "10.3125rem",
        height: "16.25rem",
      },
      Catalog: {
        marginLeftRight: "1rem",
      },
      ProductViewContent: {
        width: 4,
        showPriceCard: false,
        showTitle: false,
        showEmbeddedMap: false,
        showRelatedHomes: false,
      },
      Filters: {
        gapBetweenFilters: "0.5rem",
        distanceBetweenTextAndChevron: "0.5rem",
        showVerticalLine: false,
        marginPaddingTop: "0.5rem",
        marginPaddingBottom: "0.5rem",
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        marginLeftRight: isNatMobile ? "0.5rem" : undefined,
        filterHeight: columnCount === 4 ? "3rem" : "1.5rem",
        filterButtonStyling:
          columnCount === 4
            ? {width: "100%", flexGrow: 1}
            : {width: "max-content"},
      },
    };
  }, [
    isNatMobile,
    adminCatalogMode,
    cardColumnWidthNoProduct,
    columnCount,
    gutterWidthPercentageNoProduct,
    selectedProduct,
  ]);
  const [shouldLowerZIndex, setShouldLowerZIndex] = useState(true);
  useEventHandler("scroll", () => {
    const el = document.getElementById("build-scroll-anchor");
    const isInViewport = elementInViewport(el);
    setShouldLowerZIndex(!isInViewport);
  });
  useEffect(() => {
    setShouldLowerZIndex(!leafletMapState.unitToPreview);
  }, [leafletMapState.unitToPreview]);
  const validMobilePropertyMapDisplay = useMemo(() => {
    return parcelView && mappingPreview;
  }, [mappingPreview, parcelView]);
  const [showProjectMap, setShowProjectMap] = useState(false);
  const onClickDesign = () => {
    to(NavigationPaths.STUDIO, {
      productId: selectedProduct?.id,
      productGroupId: selectedProduct?.productGroupId,
    });
  };

  const ContentSidebar = (
    <FiltersContext.Provider value={shouldLowerZIndex}>
      <MapContentSidebar
        onClickDesign={onClickDesign}
        setProjectModalPayload={setProjectModalPayload}
        mappingPreview={mappingPreview ?? undefined}
        showProjectMap={showProjectMap}
        setShowProjectMap={setShowProjectMap}
      />
      <LegalCopyright />
    </FiltersContext.Provider>
  );
  const BottomBarContent = (
    <MapBottomBarContent
      shouldLowerZIndex={shouldLowerZIndex}
      show={() => {}}
      parcelView={parcelView ?? false}
      setShouldShowProjectMap={setShowProjectMap}
      shouldShowProjectMap={showProjectMap}
    />
  );

  const mapToShow = useMemo(() => {
    return validMobilePropertyMapDisplay ? (
      <MobileMapContainer fullScreen={false}>
        {mappingPreview}
      </MobileMapContainer>
    ) : (
      <MobileMapContainer fullScreen={true}>
        {completedProjectsMap}
      </MobileMapContainer>
    );
  }, [completedProjectsMap, mappingPreview, validMobilePropertyMapDisplay]);

  if (isNatMobile) {
    return (
      <Page>
        <Helmet>
          <title>{getPageTitle(parcelView, propertySearch, areaSearch)}</title>
          <meta name="description" content="Build with Villa" />
        </Helmet>
        <div style={{maxWidth: "100%"}}>
          {modal}
          {/*<PortalHeader showAppSwitcher={true} />*/}
          {mapToShow}
          <ThemeProvider theme={MapTheme}>
            <MapStickyHeaders
              parcelView={parcelView}
              parcelViewAvailable={parcelViewAvailable}
              selectedProduct={selectedProduct}
            />
            {(!showProjectMap || validMobilePropertyMapDisplay) &&
              ContentSidebar}
            {BottomBarContent}
          </ThemeProvider>
        </div>
      </Page>
    );
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle(parcelView, propertySearch, areaSearch)}</title>
        <meta name="description" content="Build with Villa" />
      </Helmet>
      <ThemeProvider theme={MapTheme}>
        {modal}
        <MapAndInfoContainer>
          <MapContainer isDesigning={false}>
            {glMapState?.mLng && glMapState?.mLat && mappingPreview}
            <LoadingPanel
              disableAnimation={true}
              show={false}
              relativePositioning={true}
            />
          </MapContainer>
          <InfoContainer
            id={MAP_MAIN_BUILD_CONTAINER_DIV_ID}
            isDesigning={false}
          >
            <MapStickyHeaders
              parcelView={parcelView}
              parcelViewAvailable={parcelViewAvailable}
              selectedProduct={selectedProduct}
            />
            <MapRightContainer id={MAP_MAIN_INNER_CONTAINER_DIV_ID}>
              {ContentSidebar}
              {BottomBarContent}
            </MapRightContainer>
          </InfoContainer>
        </MapAndInfoContainer>
      </ThemeProvider>
    </>
  );
};
