import React, {useEffect, useMemo, useRef, useState} from "react";
import {Layout, message} from "antd";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import Map from "./components/PropertyMap";
import "./App.css";
import {
  MAP_ANIMATION_DURATION_SECONDS,
  MAP_BUTTON_SPACING,
  MAP_BUTTON_Z_INDEX,
} from "./theme/constants";
import styled from "styled-components";
import {Address, shallowEquals} from "@natomas-org/core";
import {NatButton} from "../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../_shared/generics/_shared";
import {IMappingProps} from "../constants";
import {useMap} from "../../_shared/hooks/useMap";
import {useDispatch} from "react-redux";
import {
  setLeafletMapBounds,
  setLeafletMapSatelliteEnabled,
  setMapStateAddressId,
} from "../../map/slices/mapSlice";
import {BsGlobe, BsHouseFill, BsMap} from "react-icons/bs";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {MAP_BUTTONS_TEXT} from "./components/AreaMap/constants";
import {LoadingPanelSpinner} from "../../_shared/generics/loading-panel/styles";
import {isMobile} from "../../_shared/navigation";
import {RiArrowGoBackFill} from "react-icons/ri";
import ReactTooltip from "react-tooltip";
import {MapButtonContainer} from "./components/PropertyMap/styled";
import {usePage} from "../../_shared/hooks/usePage";
import {MapProjectDetailsModal} from "./components/ProjectDetailsModal";
import {NatModal} from "../../_shared/generics/modal/NatModal";
import {
  IModalPayload,
  IPublicProjectInfoModalPayload,
} from "./components/ProjectDetailsModal/constants";
import {AreaMapLegend} from "./components/AreaMap/components/AreaMapLegend";
import {PublicAppSearchBar} from "../../map/_shared/components/PublicAppSearchBar";
import {useAddress} from "../../_shared/hooks/useAddress";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";
import {ADDRESS_BAR_STYLE} from "../../map/_shared/components/PublicAppSearchBar/constants";
import {LOCATION_SEARCH_AUTOCOMPLETE_TYPE} from "../../map/_shared/components/PublicAppSearchBar/helpers";
import AreaMap from "./components/AreaMap";
import {usePropertyDataWithAddressId} from "../../_shared/hooks/usePropertyData/usePropertyData";
import {
  getBounds,
  getSanitizedParcelGeometryData,
} from "./components/PropertyMap/helpers";

const {Content} = Layout;

message.config({
  maxCount: 1,
});

const ModalMapContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
`;

const ModalMap = styled.div`
  width: 90vw;
  height: 90vh;
  top: 5vh;
  left: 5vw;
  position: fixed;
`;

export const useMapping = (mappingProps: IMappingProps) => {
  const {
    isLoading,
    leafletMapState,
    parcelView,
    parcelViewAvailable,
    displayingAddressID,
  } = useMap();
  const {
    address: addressDetails,
    product,
    displayProps,
    saveToProjectId,
    unitPlacement,
  } = mappingProps;
  const [expandMap, setExpandMap] = useState<boolean>(false);
  const [parcelMapOpacity, setParcelMapOpacity] = useState(parcelView ? 1 : 0);
  const [switchMapViewsDisabled, setSwitchMapViewsDisabled] = useState(false);
  const [parcelMapValid, setParcelMapValid] = useState(false);
  const mapRef = useRef(null);
  const expandedMapRef = useRef(null);
  const {isNatMobile} = usePage();
  const dispatch = useDispatch();
  const addressId = Address.getId(addressDetails ?? undefined);
  const mapPropertyData = usePropertyDataWithAddressId(addressId);
  // This state is used to determine whether the project modal should show and, if so, the data to display
  const [projectModalPayload, setProjectModalPayload] =
    useState<IPublicProjectInfoModalPayload | null>(null);
  // This state is used to determine whether the AHJ modal should show and, if so, the data to display
  const [aHJModalPayload, setAHJModalPayload] = useState<
    IModalPayload | undefined
  >({
    isMinimized: true,
  });

  // CHECK TO MAKE SURE MAP DATA IS FETCHED + ACCURATE, SET VIEW AS A RESULT
  useEffect(() => {
    const sanitizedPropertyGeometry = getSanitizedParcelGeometryData(
      mapPropertyData?.propertyGeometry
    );
    const bounds = getBounds(sanitizedPropertyGeometry);
    if (
      sanitizedPropertyGeometry &&
      addressId &&
      Address.getId(mapPropertyData?.address) === addressId &&
      mapPropertyData?.propertyGeometry?.parcelGeometry &&
      mapPropertyData?.propertyGeometry?.address &&
      bounds?.isValid()
    ) {
      if (displayingAddressID !== addressId) {
        dispatch(setMapStateAddressId(addressId));
      }
      if (!shallowEquals(bounds, leafletMapState.bounds)) {
        dispatch(setLeafletMapBounds(bounds));
      }
      if (!parcelMapValid) {
        setParcelMapValid(true);
      }
      if (!leafletMapState.satelliteEnabled) {
        dispatch(setLeafletMapSatelliteEnabled(true));
      }
    } else {
      dispatch(setMapStateAddressId(undefined));
      setParcelMapValid(false);
    }
  }, [
    mapPropertyData?.address,
    mapPropertyData?.jurisdictionData?.jurisdiction?.jurisdictionId,
    mapPropertyData?.propertyGeometry,
    mapPropertyData?.propertyGeometry?.address,
    mapPropertyData?.propertyGeometry?.parcelGeometry,
  ]);

  useEffect(() => {
    if (!parcelView) {
      setParcelMapOpacity(0);
    } else {
      setTimeout(() => {
        // Give time for 3D view animation
        setParcelMapOpacity(1);
      }, MAP_ANIMATION_DURATION_SECONDS * 2000);
    }
  }, [parcelView]);

  useEffect(() => {
    if (isLoading) {
      setSwitchMapViewsDisabled(true);
    } else {
      setTimeout(() => {
        setSwitchMapViewsDisabled(false);
      }, MAP_ANIMATION_DURATION_SECONDS * 1000);
    }
  }, [isLoading]);

  const mapContent3D = (
    <>
      <AreaMapLegend />
      <ErrorBoundary>
        <AreaMap setMapProjectModalPayload={setProjectModalPayload} />
      </ErrorBoundary>
    </>
  );
  const nearbyProjectsButtonEnabled = useMemo(() => {
    return (
      !isLoading &&
      addressDetails?.full_address &&
      !!mapPropertyData?.nearbyProjectsInfo?.projectCodes &&
      mapPropertyData?.nearbyProjectsInfo?.projectCodes?.length >= 0
    );
  }, [
    addressDetails?.full_address,
    isLoading,
    mapPropertyData?.nearbyProjectsInfo?.projectCodes,
  ]);
  const loadingLabel: string | null = useMemo(() => {
    const addressString = addressDetails
      ? Address.getStreetNumberAndStreet(addressDetails)
      : null;
    if (!addressString || (!parcelView && !isLoading)) {
      return null;
    }
    const prefix =
      !parcelViewAvailable && !isLoading && addressDetails?.full_address
        ? "No parcel data found "
        : null;
    const nearbyProjectsCount = mapPropertyData?.nearbyProjectsInfo
      ?.projectCodes
      ? mapPropertyData?.nearbyProjectsInfo?.projectCodes?.length
      : 0;
    const suffix = isLoading
      ? "Loading Parcel "
      : nearbyProjectsButtonEnabled && nearbyProjectsCount > 0
      ? `${nearbyProjectsCount} nearby Villa homes`
      : nearbyProjectsButtonEnabled && nearbyProjectsCount === 0 && parcelView
      ? `View completed Villa homes`
      : null;
    if (prefix && suffix) {
      return `${prefix} | ${suffix}`;
    } else if (prefix) {
      return prefix;
    }
    return suffix;
  }, [
    addressDetails,
    parcelViewAvailable,
    isLoading,
    mapPropertyData?.nearbyProjectsInfo?.projectCodes,
    nearbyProjectsButtonEnabled,
    parcelView,
  ]);
  const mapContent = parcelMapValid ? (
    <ErrorBoundary>
      <Map
        unitPlacement={unitPlacement}
        displayProps={displayProps}
        ref={expandMap ? expandedMapRef : mapRef}
        address={addressDetails}
        projectIdToSave={saveToProjectId}
        productToView={
          product?.id && product?.productGroupId
            ? {productId: product?.id, factoryId: product?.productGroupId}
            : undefined
        }
        aHJModalPayload={
          !!displayProps?.displayAHJAsModal && !!aHJModalPayload
            ? aHJModalPayload
            : undefined
        }
        setAHJModalPayload={
          !!displayProps?.displayAHJAsModal && !!setAHJModalPayload
            ? setAHJModalPayload
            : undefined
        }
      />
    </ErrorBoundary>
  ) : undefined;
  if (displayProps.readOnly && !displayProps.fullExperience) {
    return {
      mappingPreview: null,
      expandedMap: null,
      expandButton: null,
    };
  }
  const mapStyleFullExperience: any = {
    height: "100%",
    width: "100%",
    gridColumn: 1,
    gridRow: 1,
    opacity: parcelMapOpacity,
    zIndex: parcelMapOpacity * 7,
    transition: `z-index 0s step-end, opacity ${MAP_ANIMATION_DURATION_SECONDS}s`,
    transitionProperty: "z-index, opacity",
    transitionDelay: `${
      parcelMapOpacity === 1 ? "0" : MAP_ANIMATION_DURATION_SECONDS
    }s, 0s`,
  };

  const mapStyle: any = {
    height: "100%",
    width: "100%",
  };

  const height = "100%";
  let mappingPreview =
    mapContent != null ? (
      <Layout style={{height: height, width: "100%"}}>
        <Content style={mapStyle}>{mapContent}</Content>
      </Layout>
    ) : null;

  const mapStyle3D: any = {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    zIndex: 3,
    gridColumn: 1,
    gridRow: 1,
  };

  const MapButtons = () => {
    const {to} = useNavigation();
    const {catalogAddress} = useAddress();
    return (
      <div
        style={{
          gridColumn: 1,
          gridRow: 1,
          zIndex: 17,
          display: "flex",
          flexDirection: "column",
          rowGap: 0,
          paddingBottom: 0,
          marginBottom: MAP_BUTTON_SPACING,
          paddingRight: MAP_BUTTON_SPACING,
          pointerEvents: "none",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {parcelView ? (
          <MapButtonContainer
            data-tip
            data-for={"tooltip-map-satellite-button"}
          >
            {!isMobile() && (
              <ReactTooltip
                place={"left"}
                id={"tooltip-map-satellite-button"}
                effect="solid"
              >
                {leafletMapState?.satelliteEnabled
                  ? "Street View"
                  : "Satellite View"}
              </ReactTooltip>
            )}
            <NatButton
              hidden={isMobile()}
              label={"Satellite"}
              type={"button"}
              icon={
                leafletMapState?.satelliteEnabled
                  ? {icon: <BsMap />, iconPosition: IconPosition.ONLY}
                  : {icon: <BsGlobe />, iconPosition: IconPosition.ONLY}
              }
              option={StyleOption.MAP_BUTTONS}
              size={NatSize.XLARGE}
              style={{
                zIndex: MAP_BUTTON_Z_INDEX,
                width: "min-content",
                pointerEvents: "all",
              }}
              clickEvent={() => {
                dispatch(
                  setLeafletMapSatelliteEnabled(
                    !leafletMapState?.satelliteEnabled
                  )
                );
              }}
            />
          </MapButtonContainer>
        ) : (
          <></>
        )}
        {!parcelView && (
          <MapButtonContainer data-tip data-for={"tooltip-map-home"}>
            {!(
              switchMapViewsDisabled ||
              isLoading ||
              (!parcelView && !parcelViewAvailable)
            ) &&
              !isMobile() && (
                <ReactTooltip
                  place={"left"}
                  id={"tooltip-map-home"}
                  effect="solid"
                >
                  {parcelView ? "3D map" : `${addressDetails?.full_address}`}
                </ReactTooltip>
              )}
            <NatButton
              hidden={isMobile()}
              label={"Placement"}
              disabled={switchMapViewsDisabled || isLoading}
              icon={{
                icon: parcelView ? (
                  <RiArrowGoBackFill /> // <MdLocationCity />
                ) : (
                  <BsHouseFill />
                ),
                iconPosition: IconPosition.ONLY,
              }}
              option={StyleOption.MAP_BUTTONS}
              type={"button"}
              size={NatSize.XLARGE}
              style={{
                zIndex: MAP_BUTTON_Z_INDEX,
                width: "min-content",
                pointerEvents: "all",
              }}
              clickEvent={() => {
                setSwitchMapViewsDisabled(true);
                setTimeout(
                  // Don't allow quick toggling, it will mess up animations as they're currently set up
                  setSwitchMapViewsDisabled,
                  MAP_ANIMATION_DURATION_SECONDS * 2000
                );
                if (parcelView) {
                  to(NavigationPaths.COMPLETED_PROJECTS_MAP, catalogAddress);
                } else {
                  to(NavigationPaths.PROPERTY_MAP, catalogAddress);
                }
              }}
            />
          </MapButtonContainer>
        )}
        {!!loadingLabel && (
          <MapButtonContainer
            hidden={displayProps?.panningDisabled || isNatMobile}
          >
            <NatButton
              label={loadingLabel ?? ""}
              type={"button"}
              icon={
                !isLoading
                  ? undefined
                  : {
                      icon: (
                        <LoadingPanelSpinner size={"100%"}>
                          <AiOutlineLoading3Quarters color={MAP_BUTTONS_TEXT} />
                        </LoadingPanelSpinner>
                      ),
                      iconPosition: IconPosition.RIGHT,
                    }
              }
              option={StyleOption.MAP_BUTTONS}
              size={NatSize.NORMAL}
              disabled={false}
              clickEvent={() => {
                to(NavigationPaths.COMPLETED_PROJECTS_MAP, catalogAddress);
              }}
              style={{
                opacity: nearbyProjectsButtonEnabled ? 1 : 0.7,
                zIndex: MAP_BUTTON_Z_INDEX,
                // width: "max-content",
                pointerEvents: nearbyProjectsButtonEnabled ? "all" : "none", // Format as a label if not enabled
              }}
            />
          </MapButtonContainer>
        )}
      </div>
    );
  };

  if (displayProps.fullExperience) {
    mappingPreview = (
      <div style={{display: "grid", height: height}}>
        {/*{getBuildAddressLabelBar(*/}
        {/*  isNatMobile,*/}
        {/*  !!configurationMap?.unit,*/}
        {/*  appAddressDetails ?? addressDetails ?? undefined,*/}
        {/*  MapContainerProperties*/}
        {/*) ?? <></>}*/}
        <div
          style={{
            gridColumn: 1,
            gridRow: 1,
            // visibility: configurationMap?.unit ? "hidden" : "visible",
            zIndex: 27,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            transition: "all 150ms ease-in-out",
            transform: product && parcelView ? "scaleY(0)" : "scaleY(1)",
            transformOrigin: "top",
            maxHeight: "3rem",
          }}
        >
          <PublicAppSearchBar
            mappingToolStyling={
              {
                // ...mapStyleFullExperience,
              }
            }
            addressBarStyle={ADDRESS_BAR_STYLE.SEARCH_BAR_MODAL}
            searchType={
              parcelView
                ? LOCATION_SEARCH_AUTOCOMPLETE_TYPE.STREET_ADDRESS
                : LOCATION_SEARCH_AUTOCOMPLETE_TYPE.AREA
            }
          />
        </div>
        <Layout
          style={{
            height: height,
            width: "100%",
            display: "grid",
            gridColumn: 1,
            gridRow: 1,
          }}
        >
          <MapButtons />
          <>
            <Content style={mapStyle3D}>{mapContent3D}</Content>
            <Content style={mapStyleFullExperience}>{mapContent}</Content>
          </>
        </Layout>
      </div>
    );
  }

  const expandedMap = expandMap ? (
    <ModalMapContainer>
      <div
        className={"fullDiv"}
        onClick={() => {
          setExpandMap(false);
        }}
      />
      <ModalMap>
        <Layout style={{height: "100%", width: "100%"}}>
          <Content style={mapStyle}>{mapContent}</Content>
        </Layout>
      </ModalMap>
    </ModalMapContainer>
  ) : null;

  const mappingToolModal: JSX.Element | undefined =
    !!aHJModalPayload?.modalContent && !aHJModalPayload?.isMinimized ? (
      <NatModal
        content={aHJModalPayload?.modalContent}
        customSize={{height: "100%", width: "30rem"}}
        customPadding={"0 0"}
        show={!aHJModalPayload?.isMinimized}
        handleClose={() => {
          setAHJModalPayload({
            isMinimized: true,
            modalContent: aHJModalPayload?.modalContent,
          });
        }}
        onlyShowContent={true}
      />
    ) : undefined;
  const areaMapModalHandleClose = () => {
    setProjectModalPayload(null);
  };
  const areaMapModal = (
    <NatModal
      content={
        <MapProjectDetailsModal
          projectData={projectModalPayload?.mapProjectDetails ?? null}
          viewOnMapAction={() => {
            if (!!projectModalPayload?.viewOnMapAction) {
              projectModalPayload?.viewOnMapAction();
            }
            setProjectModalPayload(null);
          }}
          handleClose={areaMapModalHandleClose}
        />
      }
      customSize={{height: "100%", width: "30rem"}}
      customPadding={"0 0"}
      show={!!projectModalPayload?.mapProjectDetails}
      handleClose={areaMapModalHandleClose}
      onlyShowContent={true}
    />
  );

  const expandButton = null;
  const modal = mappingToolModal ?? areaMapModal;
  return {
    mappingPreview,
    expandedMap,
    expandButton,
    modal,
    setProjectModalPayload,
    propertyMap: mapContent,
    completedProjectsMap: mapContent3D,
  };
};
