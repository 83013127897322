import React from "react";
import Button from "../../../../../0-app/2-component/Button";

export const SaveButton = (props: {saveAction: () => void}) => {
  const {saveAction} = props;

  return (
    <Button
      label={"Save placement"}
      type={"button"}
      onClick={(e: any) => {
        saveAction();
        e?.stopPropagation();
      }}
      size={"small"}
      id={"save-map-placement"}
    />
  );
};
