import useActiveProject from "../_shared/hooks/useProject/useActiveProject";
import React, {useMemo, useState} from "react";
import NatSwitch from "../_shared/generics/switch";
import {Utilities} from "../../database/firebase";
import styled from "styled-components";
import {DESTRUCTIVE_FILL} from "../_shared/colors";
import {useActiveProjectDocuments} from "../_shared/hooks/useProject/useActiveProjectDocuments";
import {ESTIMATE_DOCUMENT_ID} from "@natomas-org/core/dist/esm/constants/documents";
import Text from "../../0-app/2-component/Text";

export const RevealCostsToggle = () => {
  const {site, id} = useActiveProject();
  const {documentDictionary} = useActiveProjectDocuments();
  const estimateDoc = documentDictionary?.[ESTIMATE_DOCUMENT_ID];
  const isEstimateDocHidden = estimateDoc?.hidden === true;
  const isEstimateDocDeleted = estimateDoc?.deleted === true;
  const hasEstimateDoc =
    !!estimateDoc && !isEstimateDocHidden && !isEstimateDocDeleted;
  const [revealCosts, setRevealCosts] = useState<boolean>(
    site?.visible === true
  );
  const options = useMemo(() => {
    return [
      {id: "true", label: "Yes"},
      {
        id: "false",
        label: "No",
        activeColor: DESTRUCTIVE_FILL,
      },
    ];
  }, []);

  const content = !hasEstimateDoc ? (
    <NatSwitch
      options={options}
      activeOption={{
        id: revealCosts?.toString(),
        label: revealCosts ? "Yes" : "No",
      }}
      onOptionChange={(selectedOption) => {
        setRevealCosts(selectedOption.id === "true");
        return Utilities.setDocumentWithMerge(
          Utilities.collection("configurationSites"),
          id,
          {
            visible: selectedOption.id === "true",
          }
        );
      }}
    />
  ) : (
    <Text
      style={{width: "200px"}}
      text={
        "Unable to reveal the Project Estimate. Please delete or hide the current estimate document in the Documents tab to proceed"
      }
    />
  );

  return (
    <Container style={{flexDirection: hasEstimateDoc ? "column" : "row"}}>
      <Label>Reveal Costs</Label>
      {content}
    </Container>
  );
};

const Label = styled.h4`
  margin: 0;
  padding: 0 8px;
  white-space: nowrap;
  font-size: 16px;
`;

const Container = styled.div`
  margin: 0;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
