import styled from "styled-components";
import {HEADER_Z_INDEX} from "../../../_shared/styles";
import {
  APP_HEADER_HEIGHT,
  INFO_TABLE_MAX_WIDTH,
  INFO_TABLE_MIN_WIDTH,
  INFO_TABLE_WIDTH,
  MAP_ANIMATION_SPEED,
  MAP_SEARCH_AND_FILTERS_HEIGHT,
} from "../../constants";
import {
  VILLA2_BLUE,
  VILLA2_BLUE_LIGHT,
  VILLA2_DARK_BLUE,
} from "../../_shared/colors";

export const MapRightContainer = styled.div<{
  isMobile?: boolean;
}>`
  width: ${(props) => (props.isMobile ? "100%" : "40vw")};
  min-width: ${(props) => (props.isMobile ? "100%" : "26rem")};
  margin: auto;
`;
export const MapAndInfoContainer = styled.div`
  transition: all ${MAP_ANIMATION_SPEED}ms linear,
    opacity ${MAP_ANIMATION_SPEED}ms linear;
  display: flex;
`;

export const InfoContainer = styled.div<{
  isDesigning?: boolean;
}>`
  width: ${INFO_TABLE_WIDTH};
  min-width: ${INFO_TABLE_MIN_WIDTH};
  max-width: ${INFO_TABLE_MAX_WIDTH};
  height: calc(100vh - 101px);
  flex-direction: column;
  justify-content: flex-start;
  transition: all ${MAP_ANIMATION_SPEED}ms linear,
    transform ${MAP_ANIMATION_SPEED}ms linear;
  overflow-y: auto;
  position: relative;
`;

export const BottomBarContentWrapper = styled.div<{
  shouldLowerZIndex: boolean;
  isMobile: boolean;
}>`
  position: sticky;
  z-index: ${(props) =>
    props.isMobile
      ? 1000
      : props.shouldLowerZIndex
      ? HEADER_Z_INDEX - 200 - 1
      : HEADER_Z_INDEX - 200 - 2};
  bottom: 0;
  display: flex;
  padding: ${(props) =>
    props.isMobile ? "2rem 1rem 1rem 1rem" : "2rem 0rem 1rem 0rem"};
  width: 100%;
`;

export const BottomBarContentContainer = styled.div<{
  displayingUnit: boolean;
  isMobile: boolean;
}>`
  align-items: center;
  background-color: ${(props) =>
    props.isMobile ? "rgba(255, 255, 255, 0)" : "rgba(255, 255, 255, 1)"};
  padding: ${(props) => (props.isMobile ? "0.5rem 1rem" : "0.95rem 1rem")};
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.isMobile ? "space-around" : "space-between"};
  column-gap: 0.5rem;
  box-shadow: ${(props) =>
    props.isMobile ? "" : "0px 4px 25px rgba(0, 0, 0, 0.1)"};

  border-radius: 1rem;
  border: ${(props) => (props.isMobile ? "none" : "1px solid lightgray")};
  flex-wrap: wrap;
  row-gap: 0.75rem;
`;

export const MapContainerProperties: React.CSSProperties = {
  overflowY: "hidden",
  overflowX: "auto",
  backgroundImage: `radial-gradient(
    circle at center,
    ${VILLA2_DARK_BLUE} 0,
    ${VILLA2_BLUE} 50,
    ${VILLA2_BLUE_LIGHT} 75,
    white 100%
  )`,
  maxHeight: MAP_SEARCH_AND_FILTERS_HEIGHT,
  height: MAP_SEARCH_AND_FILTERS_HEIGHT,
  flexGrow: "1",
  overflow: "hidden",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  width: "auto",
  maxWidth: "100%",
};
export const MapContainer = styled.div<{
  isDesigning?: boolean;
}>`
  overflow-y: hidden;
  background-image: radial-gradient(
    circle at center,
    ${VILLA2_DARK_BLUE} 0,
    ${VILLA2_BLUE} 50,
    ${VILLA2_BLUE_LIGHT} 75,
    white 100%
  );
  //height: calc(100vh - ${APP_HEADER_HEIGHT});
  flex-grow: 1;
  overflow-x: hidden;
`;
export const MobileMapContainer = styled.div<{fullScreen: boolean}>`
  background-image: radial-gradient(
    circle at center,
    ${VILLA2_DARK_BLUE} 0,
    ${VILLA2_BLUE} 50,
    ${VILLA2_BLUE_LIGHT} 75,
    white 100%
  );
  pointer-events: all !important;
  flex-grow: 1;
  height: ${(props) => (props.fullScreen ? `auto` : "50vh")};
  ${(props) => (props.fullScreen ? `width: 100%` : "")};
  ${(props) => (props.fullScreen ? `position: fixed` : "")};
  display: flex;
  flex-direction: column;
`;

export const LoadingGradientContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999999999;
  background: linear-gradient(
    to bottom,
    ${VILLA2_BLUE} 0%,
    ${VILLA2_BLUE_LIGHT} 70%,
    white 100%
  );
`;

export const LoadingGradientText = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
