import React from "react";
import {Slide} from "./useCarousel";

interface SlideShowProps {
  slides: Slide[];
  currentSlideIndex: number;
}

const SlideShow: React.FC<SlideShowProps> = ({slides, currentSlideIndex}) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {/* This inner container holds all slides side-by-side */}
      <div
        style={{
          display: "flex",
          transition: `transform 0.33s ease`,
          transform: `translateX(-${currentSlideIndex * 100}%)`, // Shift slides
          height: "100%",
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            style={{
              /* Each slide takes up 100% of container width */
              minWidth: "100%",
              height: "100%",
              backgroundImage: `url(${slide.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SlideShow;
