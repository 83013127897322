// Interface for a session using the Catalog

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FilterType, ICatalogFilter} from "../../catalog/filter/types";
import {
  CatalogQuizAnswers,
  IEligibleFeatures,
  ProjectPriceMode,
  SortDropdownValues,
  SurveyType,
} from "@natomas-org/core";
import {ICatalogSessionSlice} from "./interfaces";
import {
  getProductGroupFromURL,
  getUnitFromURL,
} from "../../_legacy/logic/ProductUtils";

export const getInitialCatalogSessionState = (): ICatalogSessionSlice => {
  return {
    adminCatalogMode: false,
    eligibility: undefined,
    filters: [],
    selectedProductGroupId: getProductGroupFromURL(),
    selectedProductGroupVersionId: null,
    selectedProductId: getUnitFromURL(),
    sortBy: SortDropdownValues.SQUARE_FOOTAGE_ASC,
    showFiltersModal: false,
    showFiltersDropdown: null,
    allInEstimateCatalog: false,
    bedroomFilterStrictMode: true,
    survey: {} as {[key in SurveyType]: CatalogQuizAnswers},
    priceMode: ProjectPriceMode.DTC,
  };
};

const catalogSessionSlice = createSlice({
  name: "catalogSession",
  initialState: getInitialCatalogSessionState(),
  reducers: {
    addFilter: (state, action) => {
      state.filters = [...state.filters, action.payload];
    },
    overwriteFilter: (state, action) => {
      const f = action.payload;
      // Return array that has all filters except the ones that match the type that you're overwriting
      let persistentFilters = state.filters.filter((filterOption) => {
        return !(filterOption.type === f.type);
      });
      state.filters = [...persistentFilters, f];
    },
    removeFilter: (state, action) => {
      const toRemove = action.payload;
      // Return array that has all elements except the one that you're removing
      state.filters = state.filters.filter((filterOption) => {
        return !(
          filterOption.type === toRemove.type &&
          (toRemove.label ? filterOption.label === toRemove.label : true)
        );
      });
    },
    removeFiltersByTypes: (state, action: PayloadAction<FilterType[]>) => {
      const typesToRemove = action.payload;
      state.filters = state.filters.filter((filterOption: ICatalogFilter) => {
        return !typesToRemove?.includes(filterOption.type);
      });
    },
    hardResetFilters: (state) => {
      state.filters = [];
    },
    resetFilters: (state) => {
      state.filters = [];
    },
    resetFitsLotFilters: (state) => {
      state.filters = state.filters.filter((filterOption) => {
        return filterOption.type === FilterType.ProductParcelFit;
      });
    },
    setAdminCatalogMode: (state, action: PayloadAction<boolean>) => {
      state.adminCatalogMode = action.payload;
    },
    setProductGroupId: (state, action: PayloadAction<string | null>) => {
      state.selectedProductGroupId = action.payload;
    },
    setProductGroupVersionId: (state, action: PayloadAction<string | null>) => {
      if (action.payload !== state.selectedProductGroupVersionId) {
        state.selectedProductGroupVersionId = action.payload;
      }
    },
    setProductId: (state, action: PayloadAction<string | null>) => {
      state.selectedProductId = action.payload;
    },
    setEligibilityMap: (state, action: PayloadAction<IEligibleFeatures>) => {
      state.eligibility = action.payload;
    },
    setProductSort: (state, action: PayloadAction<SortDropdownValues>) => {
      state.sortBy = action.payload;
    },
    setAllInEstimateCatalog: (state, action: PayloadAction<boolean>) => {
      state.allInEstimateCatalog = action.payload;
    },
    setShowFiltersModal: (state, action) => {
      state.showFiltersModal = action.payload;
    },
    setShowFiltersDropdown: (
      state,
      action: PayloadAction<FilterType | null>
    ) => {
      state.showFiltersDropdown = action.payload;
    },
    setCatalogSessionPriceMode: (
      state,
      action: PayloadAction<ProjectPriceMode>
    ) => {
      state.priceMode = action.payload;
    },
    setCatalogBedroomStrictMode(state, action: PayloadAction<boolean>) {
      state.bedroomFilterStrictMode = action.payload;
    },
  },
});

export const catalogSessionReducer = catalogSessionSlice.reducer;
export const {
  addFilter,
  removeFilter,
  removeFiltersByTypes,
  overwriteFilter,
  hardResetFilters,
  resetFilters,
  setAdminCatalogMode,
  setEligibilityMap,
  setProductGroupId,
  setProductGroupVersionId,
  setProductId,
  setProductSort,
  setAllInEstimateCatalog,
  setShowFiltersModal,
  setCatalogBedroomStrictMode,
  setCatalogSessionPriceMode,
} = catalogSessionSlice.actions;
