import styled from "styled-components";
import {isMobile} from "../../../_shared/navigation";
import {NatomasButton} from "../../../_shared/generics/button/styles";
import {BUTTON_Z_INDEX} from "../../../_shared/styles";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {
  DIVIDER_COLOR,
  GRANITE,
  GRAY,
  PRIMARY_BUTTON_COLOR,
} from "../../../_shared/colors";
import {getWidth} from "../../../_shared/hooks/usePage";

const BORDER_RADIUS = "1rem";

export const ProductDescriptionContainerWithStickyPriceCard = styled.div<{
  mobile: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.theme?.ProductViewContent?.showPriceCard ? "row" : "column"};
  justify-content: ${(props) =>
    props.theme?.ProductViewContent === 4 ? "auto" : "space-between"};
  align-items: flex-start;
`;
export const ProductDescriptionContainer = styled.div`
  width: ${(props) =>
    `${getWidth(
      props?.theme?.ProductViewContent?.width,
      props?.theme?.Page?.columnCount,
      props?.theme?.Page?.gutterWidthPercentage
    )}%`};
  margin-right: ${(props) => `${props.theme?.Page?.gutterWidthPercentage}%`};
  display: flex;
  margin-right: ${(props) => `${props.theme?.Page?.gutterWidthPercentage}%`};
  flex-direction: column;
  justify-content: ${(props) =>
    props.theme?.ProductViewContent === 4 ? "auto" : "space-between"};
  align-items: flex-start;
`;

export const ProductImageContainer = styled.div`
  height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .use-spring-carousel-item {
    border-radius: ${BORDER_RADIUS} !important;
  }

  ${NatomasButton} {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: ${BUTTON_Z_INDEX};
  }
`;

export const ProductInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  padding: 2rem 0;
  border-bottom: 1px solid ${DIVIDER_COLOR};
`;

export const ProductInfoTitleContainer = styled.div`
  display: ${(props) =>
    !props.theme?.ProductViewContent?.showTitle ? "none" : "flex"};
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${DIVIDER_COLOR};
`;

export const ProductInfoTitle = styled.div`
  font-size: ${isMobile() ? "1rem" : "1.25rem"};
  font-family: ${FONT_FAMILY_BOLD};
  width: 100%;
`;

export const PriceContainerSticky = styled.div`
  display: ${(props) =>
    props.theme?.Page?.columnCount === 4 ||
    !props.theme?.ProductViewContent?.showPriceCard
      ? "none"
      : undefined};
  position: sticky;
  top: 8px;
  width: 320px;
  z-index: 100;
`;

export const PriceModelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  user-select: none;
  gap: 8px;
  margin-bottom: 32px;
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  & > *:last-child {
    margin-top: 20px;
  }
`;

export const PriceModel = styled.div`
  color: #000;

  /* Heading/H3/Medium */
  font-family: "Inter-Medium", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PriceModelTag = styled.div`
  color: #807d7a;
  /* Paragraph/P3/Regular */
  font-family: "Inter-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const MonthlyPriceLine = styled.div`
  font-size: 0.875rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-right: 0.825rem;
  display: flex;
  align-items: start;
`;
export const MonthlyPriceLineIcon = styled.span`
  font-size: 0.8rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-left: 0.1rem;
`;

export const ProductInfo = styled.div`
  font-size: ${isMobile() ? "0.85rem" : "1rem"};
  width: 100%;
  color: ${GRANITE};
`;

export const ProductInfoImage = styled.div<{height: string}>`
  width: calc(33% - 1rem);
  min-width: 16rem;
  flex-grow: 1;
  height: ${(props) => props.height};
  margin-bottom: 1rem;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProductHighlight = styled.div`
  svg {
    font-size: ${isMobile() ? "1rem" : "2rem"};
  }

  font-size: ${isMobile() ? "1rem" : "2rem"};
  padding-bottom: ${isMobile() ? "0.75rem" : "1.5rem"};

  &:last-child {
    padding-bottom: 0;
  }
`;

export const ProductDetailsWrapper = styled.div<{mobile: boolean}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: ${(props) => (props.mobile ? "4px" : "4px")};
  row-gap: 4px;
  font-size: ${(props) => (props.mobile ? "0.8rem" : "1rem")};
  color: ${GRAY};
`;

export const ProductDetails = styled.div`
  svg {
    margin-right: 4px;
    margin-top: -2px;
  }

  white-space: nowrap;
  display: flex;
  align-items: center;
  //&:after {
  //  margin: 0 0.5rem;
  //  content: "|";
  //}
  &:last-child {
    &:after {
      content: none;
    }
  }
`;

export const ProductViewBottomSection = styled.div`
  justify-content: center;
  align-items: center;
  margin: 32px 0;
  padding-top: 16px;
  display: ${(props) =>
    props.theme?.ProductViewContent?.showRelatedHomes === false
      ? "none"
      : "flex"};
  padding-bottom: ${(props) =>
    props.theme?.Page?.isNatMobile ? "4rem" : undefined};
`;

export const ProjectViewBottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
`;

export const StickyCard = styled.div<{includeSpacer?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 1px 6px ${DIVIDER_COLOR};
  padding: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  background-color: white;
  gap: ${(props) => (props.includeSpacer ? "1rem" : "0")};
`;

export const IncludedTag = styled.div`
  svg {
    font-size: 1rem;
    color: ${PRIMARY_BUTTON_COLOR};
  }
`;
