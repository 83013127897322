import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useFormik} from "formik";
import {updateNatomasUserContactInfo} from "../../../../../../database/firebase/user";
import {getFormInitialValues} from "../../../../../_shared/application";
import {
  ADDRESS_KEY,
  CUSTOMER_DETAILS_FORM,
  EMAIL_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  PHONE_NUMBER_KEY,
} from "../../../../../_shared/forms/FormModels";
import {PersonalInfoRow} from "../PersonalInfoRow";
import profile from "../../../../../../assets/icons/pngs/profile_circle.png";
import mail from "../../../../../../assets/icons/pngs/mail_circle.png";
import phone from "../../../../../../assets/icons/pngs/phone_circle.png";
import pin from "../../../../../../assets/icons/pngs/pin_circle.png";
import {
  Address,
  Customer,
  formatPhoneNumber,
  IAddressDetails,
  ICustomerInfo,
  PhoneNumberFormatType,
} from "@natomas-org/core";
import {constructValidation} from "../../../../../_shared/forms/FormHelper";
import {useDeepEffect} from "../../../../../_shared/hooks/useDeepEffect";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
//Styles
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {ButtonContainer, SectionHeader} from "../../styles";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {saveProjectInfo} from "../../../../../../database/firebase/project";
import {useCustomerStatus} from "../../../../../_shared/hooks/useCustomerStatus";
import {getNewProjectInfo} from "../../../../../_shared/project";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import Button from "../../../../../../0-app/2-component/Button";

const lineItems = [
  {title: "Name", logo: profile, type: "name"},
  {title: "Email", logo: mail, type: "email"},
  {title: "Phone", logo: phone, type: "phone"},
  {title: "Address", logo: pin, type: "address"},
];

const getNewCustomerInfo = (
  customer: ICustomerInfo,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  address: IAddressDetails
) => {
  let newCustomer;
  newCustomer = Customer.setFirstName(customer, firstName);
  newCustomer = Customer.setLastName(newCustomer, lastName);
  newCustomer = Customer.setPrimaryContactPhoneNumber(
    newCustomer,
    formatPhoneNumber(phoneNumber, PhoneNumberFormatType.RAW) ?? ""
  );
  newCustomer = Customer.setContactAddress(newCustomer, address);
  return newCustomer;
};

const getValue = (customer: ICustomerInfo | null, title: string) => {
  if (!customer) return null;
  if (title === "Name") {
    return Customer.getFullName(customer);
  } else if (title === "Email") {
    return Customer.getContactEmail(customer);
  } else if (title === "Phone") {
    return formatPhoneNumber(
      Customer.getPrimaryContactPhoneNumber(customer),
      PhoneNumberFormatType.PARENTHESIS_AND_DASHES
    );
  } else if (title === "Address") {
    return Address.getFullAddress(Customer.getContactAddress(customer));
  }
};

export const AccountDetailsForm = () => {
  // @ts-ignore
  const adminMode = useSelector((state) => state.portal.adminMode);
  const {id, info: projectInfo, address: projectAddress} = useActiveProject();
  const {customer} = useCurrentCustomer();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const [editInfo, setEditInfo] = useState(false);

  const {navigateBack} = useNavigation();
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 8,
  });

  const setFormikValues = () => {
    if (!customer) return;
    formik.setFieldValue(FIRST_NAME_KEY, Customer.getFirstName(customer));
    formik.setFieldValue(LAST_NAME_KEY, Customer.getLastName(customer));
    formik.setFieldValue(EMAIL_KEY, Customer.getContactEmail(customer));
    formik.setFieldValue(ADDRESS_KEY, Customer.getContactAddress(customer));
    formik.setFieldValue(
      PHONE_NUMBER_KEY,
      Customer.getPrimaryContactPhoneNumber(customer)
    );
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(CUSTOMER_DETAILS_FORM),
    validationSchema: constructValidation(CUSTOMER_DETAILS_FORM),
    onSubmit: (values) => {
      if (!customer) return;
      // @ts-ignore
      const {firstName, lastName, phoneNumber, address} = values;
      setEditInfo(false);

      if (isCustomerPreDeposit) {
        const info = getNewProjectInfo(
          firstName,
          lastName,
          phoneNumber,
          address ?? projectAddress,
          id,
          projectInfo
        );
        saveProjectInfo(id, info).then(() => {});
      }
      const newCustomerInfo = getNewCustomerInfo(
        customer,
        firstName,
        lastName,
        phoneNumber,
        address ?? projectAddress
      );
      return updateNatomasUserContactInfo(
        customer.user_id,
        newCustomerInfo,
        null
      );
    },
  });
  useDeepEffect(() => {
    // Everytime user updates - update form
    setFormikValues();
  }, []);

  useDeepEffect(() => {
    if (customer != null && editInfo) {
      setFormikValues();
    }
  }, [customer, editInfo, adminMode]);

  const editButtonId =
    "account-details-" + (editInfo ? "discard-changes" : "edit-info");

  return (
    <PageElement size={size} height={"auto"}>
      <SectionHeader>Account Information</SectionHeader>
      {lineItems.map((lineItem, index) => (
        <PersonalInfoRow
          {...lineItem}
          type={lineItem.type}
          key={"personal-info-" + lineItem.type}
          id={"personal-info-" + lineItem.type}
          value={getValue(customer, lineItem.title)}
          editInfo={editInfo}
          formik={formik}
          setAddressObject={(addressObj: IAddressDetails | null) => {
            addressObj && formik.setFieldValue(ADDRESS_KEY, addressObj);
          }}
        />
      ))}
      <ButtonContainer>
        <Button
          id={editButtonId}
          label={editInfo ? "Discard Changes" : "Go back"}
          onClick={() => {
            if (editInfo) {
              setEditInfo(!editInfo);
            } else {
              navigateBack();
            }
          }}
          theme={"secondary"}
        />
        {editInfo ? (
          <Button
            onClick={() => {
              return formik.submitForm();
            }}
            disabled={Object.keys(formik.errors).length !== 0}
            id={"project-details-save-changes"}
            label={"Save Changes"}
            theme={"primary"}
          />
        ) : (
          <Button
            id={editButtonId}
            label={"Edit Info"}
            onClick={() => {
              setEditInfo(!editInfo);
            }}
            theme={"primary"}
          />
        )}
      </ButtonContainer>
    </PageElement>
  );
};
