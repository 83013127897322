import {toProposal} from "../../../../_shared/hooks/useNavigation/AvatarDropdown/navigation";
import React from "react";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import Button from "../../../../../0-app/2-component/Button";

export const InstantQuoteCTA = (props: {
  id: string;
  title?: string;
  contactAction?: () => void;
}) => {
  const {to} = useNavigation();
  return (
    <div
      className={"vcl-container-primary-16"}
      style={{justifyContent: "center", width: "300px"}}
    >
      <Button
        label={props.title ?? "Continue"}
        type={"button"}
        id={"ssd-continue-from-instant-quote"}
        onClick={() => {
          // TODO: Should we not support this? It is currently only hit by B2B
          toProposal(to, props.id);
        }}
        disabled={false}
      />
    </div>
  );
};
