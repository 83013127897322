import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import React from "react";
import Button from "../../../../0-app/2-component/Button";
import useLatestEstimate from "../../../estimate/useLatestEstimate";

const ProposalPDFViewButton = () => {
  const {to} = useNavigation();
  const {response} = useLatestEstimate();
  const hasNoEstimate = !response;
  return (
    <Button
      disabled={hasNoEstimate}
      id={"download-proposal-pdf"}
      label={"Download Proposal PDF"}
      onClick={() => {
        to(NavigationPaths.DOCUMENT_PDF_PROPOSAL);
      }}
    />
  );
};

export default ProposalPDFViewButton;
