import React, {useState} from "react";
import {IAvatarProps} from "./useAvatarMenu";
import {FullName} from "./styles";
import AvatarDynamicCallToAction from "./avatar-dynamic-cta";
import styled from "styled-components";
import {ProjectSwitcherModal} from "../../../../components/portal/_shared/components/ProjectSwitcherModal";
import {logOut} from "../../../../database/firebase";
import AvatarAdmin from "./avatar-admin-cta";
import Button from "../../../2-component/Button";
import {usePage} from "../../../../components/_shared/hooks/usePage";
import {useNavigation} from "src/components/_shared/hooks/useNavigation";
import {NavigationPaths} from "src/components/_shared/hooks/useNavigation/paths";
import {useAddress} from "src/components/_shared/hooks/useAddress";

const AvatarMenuContent = (props: {avatar: IAvatarProps}) => {
  const {avatar} = props;
  const {columnCount} = usePage();
  const {to} = useNavigation();
  const {catalogAddress} = useAddress();
  const [showProjectSwitcher, setShowProjectSwitcher] = useState(false);

  return (
    <>
      {avatar.is_user_an_admin && <AvatarAdmin />}
      {!!avatar.header_cta &&
        avatar.has_product_selection &&
        columnCount <= 8 && (
          <Button
            id={"header_avatar_menu_action"}
            data-cy={"header_avatar_menu_action"}
            label={avatar.header_cta?.label ?? ""}
            onClick={avatar.header_cta?.onClick}
            theme={"secondary"}
            size={"small"}
            containerStyle={{width: "100%"}}
          />
        )}
      <FullName>{avatar.full_name}</FullName>
      <AvatarDynamicCallToAction
        customer_email={avatar.email}
        mode={
          !avatar.has_product_selection
            ? "select-a-home"
            : avatar.is_pre_deposit
            ? "order-feasibility-study"
            : "none"
        }
      />
      <ProjectSwitcherModal
        show={showProjectSwitcher}
        handleClose={() => {
          setShowProjectSwitcher(false);
        }}
      />
      <Action
        hidden={!avatar.has_many_projects}
        onClick={() => setShowProjectSwitcher((prev) => !prev)}
      >
        My projects
      </Action>
      <Action
        hidden={avatar.has_many_projects}
        onClick={() => to(NavigationPaths.PORTAL)}
      >
        My project
      </Action>
      <Action
        id={"header_avatar_menu_map_my_lot"}
        data-cy={"header_avatar_menu_map_my_lot"}
        onClick={() => {
          to(NavigationPaths.PROPERTY_MAP, catalogAddress);
        }}
      >
        Map my lot
      </Action>
      <Action
        id={"header_avatar_menu_featured_projects"}
        data-cy={"header_avatar_menu_featured_projects"}
        onClick={() => {
          window.open("/featured-projects", "_blank");
        }}
      >
        See where we've built
      </Action>
      <Action
        id={"header_avatar_menu_browse_homes"}
        data-cy={"header_avatar_menu_browse_homes"}
        onClick={() => {
          to(NavigationPaths.CATALOG);
        }}
      >
        Browse homes
      </Action>
      <Divider />
      <Action
        id={"header_avatar_menu_edit_profile"}
        data-cy={"header_avatar_menu_edit_profile"}
        onClick={() => {
          to(NavigationPaths.PROFILE);
        }}
      >
        Edit profile
      </Action>
      <Action
        id={"header_avatar_menu_cart"}
        data-cy={"header_avatar_menu_cart"}
        hidden={!avatar.has_cart_enabled}
        onClick={() => {
          to(NavigationPaths.CART);
        }}
      >
        Cart
      </Action>
      <Action
        id={"header_avatar_menu_contact_us"}
        data-cy={"header_avatar_menu_contact_us"}
        onClick={() => {
          window.open("/contact-us", "_blank");
        }}
      >
        Contact us
      </Action>
      <Action
        id={"header_avatar_menu_sign_out"}
        data-cy={"header_avatar_menu_sign_out"}
        style={{paddingBottom: "10px"}}
        onClick={() =>
          logOut().then(() => {
            to(NavigationPaths.CATALOG);
          })
        }
      >
        Log out
      </Action>
    </>
  );
};

const Action = styled.span`
  color: #04171b;
  font-feature-settings: "liga" off, "clig" off;

  /* body/body-md/default */
  font-family: Inter-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  width: 100%;
  &:hover {
    color: #0080a7;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e4e4e7;
`;

export default AvatarMenuContent;
