import React, {useCallback} from "react";
import VCL from "@natomas-org/villa-component-library";
import homes from "./homes.png";
import {IInstantQuoteCustomerData} from "../QuestionnaireForm/interface";
import {useTracking} from "../../../../../_shared/hooks/useTracking";
import Button from "../../../../../../0-app/2-component/Button";
import styled from "styled-components";
import {BLACK} from "../../../../../_shared/colors";

const SuccessScreen = (props: {
  dismiss: () => void;
  customerData: IInstantQuoteCustomerData;
  back?: {
    label: string;
    onClick: () => void;
  };
}) => {
  const {trackEvent} = useTracking();
  const trackSuccessAction = useCallback(
    (ext: string) => {
      trackEvent(`instant_quote_success_${ext}`);
    },
    [trackEvent]
  );
  const {dismiss, customerData, back} = props;
  return (
    <Container>
      <img
        src={homes}
        style={{
          width: "296px",
          height: "200px",
        }}
        alt={"Success!"}
      />
      <CopyContainer>
        <VCL.Components.Atoms.Header
          properties={{
            children: "You're one step closer to your dream ADU!",
            style: {fontWeight: "500", fontSize: "32px", textAlign: "center"},
          }}
        />
        <DetailsContainer>
          <Label>What’s next?</Label>
          <Text>
            Our team will review your project details and prepare materials for
            your assessment call. We'll be in touch within 1 business day to
            schedule a call.
          </Text>
        </DetailsContainer>
        <Link
          onClick={(event) => {
            event.preventDefault();
            trackSuccessAction("prepay");
            window.open(
              "https://buy.stripe.com/6oEg2tcyT6GWaLS28b?prefilled_email=" +
                customerData.email,
              "_blank"
            );
          }}
        >
          Want to move fast? Prepay for your Feasibility Study
        </Link>
      </CopyContainer>
      <Button
        id={"dismiss-success"}
        theme={"secondary"}
        label={"Go back to your instant quote"}
        onClick={() => {
          trackSuccessAction("dismiss");
          dismiss();
        }}
      />
      <Button
        id={"back-from-success"}
        hidden={!back}
        label={back?.label ?? "Back"}
        onClick={back?.onClick}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 32px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 407px;
  gap: 8px;
`;
const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Label = styled.p`
  font-family: Inter-Regular, serif;
  font-size: 18px;
  color: BLACK;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;
const Text = styled.p`
  font-family: Inter-Regular, serif;
  font-size: 16px;
  color: #495057;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`;
const Link = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  color: ${BLACK};
  text-decoration: underline;
  cursor: pointer;
  transition: font-size 250ms;
  :hover {
    font-size: 15px;
  }
`;

export default SuccessScreen;
